import { ErrorCatch } from "api/ErrorCatch.js";
import React, { useContext, useEffect, useMemo, useState } from "react";
import botApi from "api/botApi.js";
import { signAppContext } from "utils/providers/SignContextProvider.js";
import { chatContext } from "utils/providers/ChatContextProvider.js";
import NormalButton from "components/atomic/NormalButton2";
import styled from "styled-components";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import useKnowledgePagination from "../../../../utils/hooks/useKnowledgePagination.js";
import { DataGrid } from "@mui/x-data-grid";
import KnowledgeUtil from "../../../../utils/knowledgeUtil.js";
import KnowledgeChatBotSelect from "./KnowledgeChatBotSelect.js";
import { popupContext } from "../../../../utils/providers/PopupProvider.js";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import useAddKnowledgeChatBotSelect from "../../../../utils/hooks/useAddKnowledgeChatBotSelect.js";
import LinkIcon from "@mui/icons-material/Link";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import FolderIcon from "@mui/icons-material/Folder";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import AddIcon from "@mui/icons-material/Add";
import {
  checkCrawlSize,
  checkStoreSize,
  getMaxFileSize,
  getUsageDiskSize
} from "../../../../utils/hooks/knowledge/useFileHook.js";
import ChatEmpty from "../../../pages/dashboard/ChatEmpty.js";

const getChatBotData = async ( botUid ) => {
	try {
		const {status, data} = await botApi.getBotInfoByBotUid(botUid);
		if (status === 200) {
			return JSON.parse(data.botInfo);
		}
	} catch (error) {
		console.log('error', error);
		return null;
	}
};

const checkTypeReturnIcon = (item) => {
  if (item === "Folder") {
    return <FolderIcon className="" style={{ color: "#ffd874" }} fontSize="small" />;
  } else if (item === "URL") {
    return <LinkIcon className="" style={{ color: "#3ccde1" }} fontSize="small" />;
  } else if (item === "File") {
    return <InsertDriveFileIcon className="" style={{ color: "#9b41ef" }} fontSize="small" />;
  } else if (item === "DB") {
    return <Inventory2Icon className="" style={{ color: "#e8413d" }} fontSize="small" />;
  }
  return "";
};

export default function LoadKnowAddtional(props) {
  const lengthPerPage = 10;
  const { setLoading, changeIsAddPage, callBackFunc, selectedFolderInfo, pcDataGridRefetch } = props;
  const { chatBotData, setChatBotData } = useContext(chatContext);
  const { handleSignOutApp } = useContext(signAppContext);
  const { selectedOption, loadAddKnowBotList } = useAddKnowledgeChatBotSelect();
  const { updatePopInfo, showTextPop, showConfirmPopCommonCallback } = useContext(popupContext);

  const [selectedDataUidList, setSelectedDataUidList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [prevCurrentPage, setPrevCurrentPage] = useState(0);

  const [parentInfo, setParentInfo] = useState(null);
  const {
    list: viewList,
    total,
    isLoading: isKnowledgeListLoading,
  } = useKnowledgePagination({
    currentPage,
    lengthPerPage,
    botUid: selectedOption?.botUid,
    useBookmarkCheck: null,
    pdataUid: parentInfo?.dataUid ?? null,
    searchValue: null,
    mode: "additional",
    currentBotUid: chatBotData.botUid,
  });

  const goBack = () => {
    setCurrentPage(() => prevCurrentPage ?? 0);
    setPrevCurrentPage(() => 0);
    setParentInfo(null);
  };

  const queryClient = useQueryClient();

  const { mutate: submitCopyBuildBase } = useMutation({
    mutationFn: (dataList) => botApi.copyBuildBase({
      list: dataList,
      selectedFolderInfo,
      ...chatBotData ?? {},
    }),
    onSuccess: async (response, requestData) => {
      let {
        data: { botInfo, resultCode, resultMessage, failList },
        status,
      } = response;
      if (Number(resultCode) === 200) {
        console.log('botInfo', botInfo);
        botInfo = botInfo ? JSON.parse(botInfo) : "";
        if (botInfo) {
          // 세션스토리지 botData에 dataType을 수정하고 setchatBot 해주자
          let tmpBotData = sessionStorage.getItem("botData") ? JSON.parse(sessionStorage.getItem("botData")) : null;
          if (tmpBotData) {
            tmpBotData.dataUid = botInfo.dataUid;
            tmpBotData.dataType = botInfo.dataType;
            
            const stateToPass = JSON.stringify(chatBotData);
            sessionStorage.setItem("botData", stateToPass);
            setChatBotData(tmpBotData);
          }
        }
        
        const newBotData = await getChatBotData(chatBotData?.botUid);
				if (!!newBotData) {
					sessionStorage.setItem("botData", newBotData);
					setChatBotData(newBotData);
				}
        
        queryClient.invalidateQueries(["knowledgePagination"]);

        pcDataGridRefetch();
        changeIsAddPage();
      }
      callBackFunc &&
        callBackFunc(
          resultMessage,
          Number(resultCode) === 200 ? [] : JSON.parse(failList ?? "[]").map((item) => ({ name: item.dataName }))
        );
    },
    onError: (error) => {
      console.log("error", error);
      ErrorCatch(error, handleSignOutApp);
    },
    onSettled: () => {
      console.log("settled");
      setLoading(false);
    },
  });
  const copyBuildBase = async () => {
    if (!selectedDataUidList || selectedDataUidList.length < 1) {
      return showTextPop("선택 된 지식이 없습니다.", () => {});
    }
    const destIndexName = selectedOption?.indexName;
    if (!destIndexName) {
      return showTextPop("선택한 봇에 문제가 있습니다. 관리자에게 문의해주세요");
    }
    
    // 현재 사용중인 지식들의 파일 사이즈 + 선택된 지식들의 파일 사이즈 값이 최대값을 넘는지 확인
    const usageDiskSize = await getUsageDiskSize(chatBotData);
    const currentUsageDiskSize =  selectedDataUidList.reduce((acc, data) => Number(data?.fileSize ?? '0') + acc,0);
    if ((getMaxFileSize(chatBotData) - (usageDiskSize + currentUsageDiskSize)) <= 0) {
      return showTextPop("디스크 용량을 초과했습니다. 일부 파일 선택 해제 후 다시 시도해 주세요.");
    }
    
    if (checkCrawlSize(chatBotData, selectedDataUidList) || checkStoreSize(chatBotData)) {
      return showTextPop("벡터 용량을 초과했습니다. 일부 파일 선택 해제 후 다시 시도해 주세요.");
    }
    
    selectedDataUidList.forEach((item) => {
      item.botUid = chatBotData.botUid;
      item.llmModel = destIndexName;
      item.indexName = chatBotData.indexName;
      item.dataName = JSON.parse(JSON.stringify(item.fileName));
      delete item.name;
    });

    console.log("selectedFolderInfo", selectedFolderInfo);
    if (
      !!selectedFolderInfo &&
      selectedDataUidList.findIndex((item) => item.dataType === 20 && item.isClicked) !== -1
    ) {
      showConfirmPopCommonCallback(
        "지식 등록",
        `데이터를 불러올 때 폴더는 제외하고 파일만 추가됩니다. 진행하시겠습니까?`,
        () => {
          console.log("start~~");
          setLoading(true);
          submitCopyBuildBase(selectedDataUidList.filter((item) => item.dataType !== 20));
        }
      );
    } else {
      setLoading(true);
      submitCopyBuildBase(selectedDataUidList);
    }
  };

  useEffect(() => {
    if (!selectedOption) return;
    // 지식을 불러온다.
    setCurrentPage(0);
    setPrevCurrentPage(0);
    setSelectedDataUidList([]);
  }, [selectedOption]);

  return (!loadAddKnowBotList || loadAddKnowBotList?.length === 0) ? (
    <ChatEmpty MessageText={"지식을 불러올 Assistant가 없습니다. 다른 Assistant에 지식을 넣은 후 다시 시도해주세요."} />
  ) : (
    <>
      <SelectCt style={{ zIndex: 5, position: "relative" }}>
        <Container>
          <KnowledgeChatBotSelect />
        </Container>
      </SelectCt>
      <KnowTopButtonCt>
        {parentInfo && (
          <NormalButton
            style={{ marginLeft: "5px" }}
            buttonClass={"cOrange small"}
            buttonTitle={"뒤로 가기"}
            callBackFunc={goBack}
          />
        )}
        <KnowCountText
          onClick={() => {
            const changedList = selectedDataUidList.reduce(
              (acc, item) => {
                console.log("item", item);
                if (item.type === "Folder" && !acc[item.dataUid])
                  acc[item.dataUid] = {
                    ...item,
                    fileList: [],
                  };
                else if (acc[item?.pdataUid ?? "root"]) acc[item?.pdataUid ?? "root"].fileList.push(item);
                return acc;
              },
              { root: { dataName: "root", fileList: [] } }
            );
            updatePopInfo({
              isEnabled: true,
              currentState: "list",
              props: {
                checkItemList: changedList,
              },
            });
          }}
        >
          선택 지식 :{" "}
          <KnowCountNum>{selectedDataUidList?.filter((item) => item.type !== "Folder").length ?? 0}</KnowCountNum>개
        </KnowCountText>
      </KnowTopButtonCt>
      {/* 선택 할 지식 영역 */}
      <Height100Box
        style={{
          margin: "0",
          paddingBottom: "20px",
        }}
      >
        {/* LoadKnowledgeBox 컴포넌트를 따로 생성해서 처리 */}
        <KnowledgeContainer>
          <LoadKnowAddtionalGrid
            knowledgeList={viewList}
            parentInfo={parentInfo}
            setParentInfo={setParentInfo}
            selectedOption={selectedOption}
            selectedDataUidList={selectedDataUidList}
            setSelectedDataUidList={setSelectedDataUidList}
            lengthPerPage={lengthPerPage}
            total={total}
            currentPage={currentPage}
            isKnowledgeListLoading={isKnowledgeListLoading}
            setCurrentPage={setCurrentPage}
            setPrevCurrentPage={setPrevCurrentPage}
          />
        </KnowledgeContainer>
      </Height100Box>
      <NormalButton
        style={{ marginBottom: "5px" }}
        buttonClass={"small createEmptyFolder2"}
        buttonTitle={
          <>
            <AddIcon />
            지식추가
          </>
        }
        callBackFunc={copyBuildBase}
      />
    </>
  );
}

function LoadKnowAddtionalGrid(props) {
  const {
    knowledgeList,
    setParentInfo,
    selectedDataUidList,
    setSelectedDataUidList,
    selectedOption,
    parentInfo,
    lengthPerPage,
    total,
    isKnowledgeListLoading,
    currentPage,
    setCurrentPage,
    setPrevCurrentPage,
  } = props;
  const { listToRows } = KnowledgeUtil;
  const { chatBotData } = useContext(chatContext);
  const rows = useMemo(() => listToRows(knowledgeList), [knowledgeList, chatBotData]);

  const filterRow = (list) => {
    const targetList = list ?? rows;
    // 현재 선택된 리스트에서 각 폴더의 dataUid 별로 파일을 저장해 구분.
    // 전체 선택 되어 있는지 아닌지 확인하기 위해.
    const filterList = targetList.reduce((acc, item) => {
      const isFolder = item.type === "Folder";
      const targetUid = isFolder || (!isFolder && !item?.pdataUid) ? item.dataUid : item.pdataUid;
      if (!acc[targetUid]) acc[targetUid] = { item, fileList: [] };

      acc[targetUid].fileList.push(item);
      return acc;
    }, {});
    return (list ?? rows)
      .filter(
        (item) =>
          selectedDataUidList.findIndex((subItem) => {
            // 폴더의 하위 데이터들이 전체 선택되어 있는지 확인.
            const isAllCheckedInFolder = !filterList[subItem]
              ? false
              : filterList[subItem].fileList.length === subItem.fileCount;
            return (
              subItem.dataUid === item.dataUid ||
              (subItem.dataUid === item.pdataUid && !!item.pdataUid && isAllCheckedInFolder)
            );
          }) !== -1
      )
      .map((item) => item.id);
  };
  const [selectionModel, setSelectionModel] = useState([]);

  const columns = [
    {
      field: "fileName",
      headerName: "파일명",
      width: 300,
      // sortable: false,
      disableColumnMenu: true,
      renderCell: (info) => {
        return info.row.type === "Folder" ? (
          <button
            className="nameBtn folderViewBtn"
            onClick={() => {
              setSelectionModel([]);
              setPrevCurrentPage(currentPage);
              setCurrentPage(0);
              setParentInfo(info.row);
            }}
          >
            {checkTypeReturnIcon(info.row.type)} {info.row.dataName}
          </button>
        ) : (
          <button className="nameBtn">
            {checkTypeReturnIcon(info.row.type)} {info.row.dataName}
          </button>
        );
      },
    },
    {
      field: "type",
      headerName: "타입",
      width: 100,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "fileCount",
      headerName: "하위 파일",
      width: 120,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (info) => {
        return <>{info.row.type === "Folder" ? <p>{info.row.fileCount}개</p> : <p>---</p>}</>;
      },
    },
    {
      field: "size",
      headerName: "크기",
      sortable: false,
      disableColumnMenu: true,
      width: 200,
    },

    // {
    //   field: "view",
    //   headerName: "폴더 보기",
    //   width: 100,
    //   renderCell: (info) => {
    //     return (
    //       <>
    //         {info.row.type === "Folder" && (
    //           <Button
    //             onClick={() => {
    //               setSelectionModel([]);
    //               setPrevCurrentPage(currentPage);
    //               setCurrentPage(0);
    //               setParentInfo(info.row);
    //             }}
    //             size="medium"
    //             style={{ color: "#A09E9E" }}
    //             startIcon={<DriveFileMoveIcon />}
    //           ></Button>
    //         )}
    //       </>
    //     );
    //   },
    // },
  ];

  // 최상위에서 폴더를 선택했을 때 해당 폴더 하위의 모든 데이터를 불러와 -> setSelectedDataUidList에 저장하기 위해 아래 state 및 useQuery 및 useEffect 사용.
  const [keyForAddSelectedDataUidList, setKeyForAddSelectedDataUidInfo] = useState([]);
  const { data: listForAddSelectedDataUid } = useQuery({
    queryKey: ["listForAddSelectedDataUid", keyForAddSelectedDataUidList, chatBotData.botUid],
    queryFn: () =>
      botApi.getUseKnowDataByPdataUidFromBotUid(chatBotData.botUid, keyForAddSelectedDataUidList).then((response) => {
        if (Number(response?.data?.resultCode ?? "204") === 200) {
          return listToRows(JSON.parse(response?.data?.botKnowDataSetInfo ?? "[]"));
        }

        return [];
      }),
    enabled: keyForAddSelectedDataUidList.length > 0, // 사용 시점 결정. ( 옆의 조건이 true면 실행됨. )
  });

  useEffect(() => {
    if (!isKnowledgeListLoading) setSelectionModel(() => [...filterRow()]);
  }, [rows]);

  useEffect(() => {
    if (keyForAddSelectedDataUidList.length > 0) {
      setSelectedDataUidList((prevState) => [
        ...prevState,
        ...listForAddSelectedDataUid
          .filter((item) => prevState.findIndex((prevStateItem) => prevStateItem.dataUid === item.dataUid) === -1)
          .map((item) => ({ ...item, isClicked: false })),
      ]);
      setKeyForAddSelectedDataUidInfo([]);
    }
  }, [listForAddSelectedDataUid]);

  useEffect(() => {
    setSelectionModel([]);
    setSelectedDataUidList([]);
  }, [chatBotData]);

  useEffect(() => {
    console.log("selectedDataUidList", selectedDataUidList);
  }, [selectedDataUidList]);

  return (
    <DataGrid
      paginationModel={{
        pageSize: lengthPerPage,
        page: currentPage,
      }}
      paginationMode={"server"}
      rowCount={total}
      loading={isKnowledgeListLoading}
      rows={rows}
      columns={columns}
      slots={{ noRowsOverlay: CustomNoRowsOverlay }}
      checkboxSelection
      disableRowSelectionOnClick
      rowSelectionModel={selectionModel}
      onPaginationModelChange={(pageInfo) => setCurrentPage(pageInfo.page)}
      onRowSelectionModelChange={async (selectedList, details) => {
        if (rows.length === 0) return [];

        const selectabledRows = rows.filter((item) => item.isRowSelectable);
        console.log(selectionModel, selectedList, selectabledRows);

        // 이전 선택된 데이터와, 현재 선택된 데이터의 값을 비교해 추가되었는지 삭제 되었는지 확인.
        const isAdd = selectionModel.length < selectedList.length;
        const manyItemList = isAdd ? selectedList : selectionModel;
        const shortItemList = isAdd ? selectionModel : selectedList;

        console.log("manyItemList", manyItemList);
        console.log("shortItemList", shortItemList);

        // 추가/삭제한 대상 정보를 배열로 가져옴
        const targetItems = manyItemList
          .filter((item) => shortItemList.findIndex((shortItem) => shortItem === item) === -1)
          .map((item) => selectabledRows[selectabledRows.findIndex((row) => row.id === item)]);

        // 추가한 목록을 폴더, 파일별로 분류
        const [foldersInTargetItems, filesInTargetItems] = targetItems.reduce(
          (acc, item) => {
            acc[item.type === "Folder" ? 0 : 1].push(item);
            return acc;
          },
          [[], []]
        );

        foldersInTargetItems.forEach((item) => {
          if (item.dataType === 20) item["isClicked"] = selectedList.includes(item.id) && isAdd;
        });

        console.log("foldersInTargetItems", foldersInTargetItems);

        // 폴더를 체크박스 눌러 활성화 시켰을 시 하위 데이터들 가져오기 위해 추가.
        if (isAdd && foldersInTargetItems.length > 0) {
          setKeyForAddSelectedDataUidInfo(foldersInTargetItems);
        }

        setSelectedDataUidList((prevState) => {
          // 현재 item의 dataUid와 선택된 아이템의 dataUid와 일치하는 아이템의 index를 번환.
          const getDataUidMatchDataUid = (item) =>
            !item?.dataUid ? -1 : prevState.findIndex((prevStateItem) => prevStateItem.dataUid === item.dataUid);

          // 즉 현재 아이템의 폴더 index를 반환
          const getDataUidMatchInPdataUid = (item) =>
            !item?.pdataUid ? -1 : prevState.findIndex((prevStateItem) => prevStateItem.dataUid === item.pdataUid);

          // 폴더의 정보를 넘기면  -> 선택된 데이터 리스트 중 해당 폴더의 데이터가 몇 개 선택 되었는지 반환.
          const getSelectedFileCount = (item) =>
            prevState.filter((prevStateItem) => prevStateItem.pdataUid === item?.dataUid).length;

          if (isAdd) {
            // 추가 시
            let addItems = [];
            filesInTargetItems.forEach((item) => {
              // 하위 폴더에서 폴더 추가 안 되어 있으면 추가
              if (item?.pdataUid && getDataUidMatchInPdataUid(item) === -1) addItems.push(parentInfo);

              addItems.push(item);
            });

            foldersInTargetItems
              .filter((item) => getDataUidMatchDataUid(item) === -1)
              .forEach((item) => addItems.push(item));

            return [...prevState, ...addItems];
          } else {
            // 삭제할 때

            filesInTargetItems.forEach((item) => {
              const idx = getDataUidMatchDataUid(item);
              if (idx !== -1) prevState.splice(idx, 1);
            });

            if (!!parentInfo && getSelectedFileCount(parentInfo) === 0)
              prevState.splice(getDataUidMatchDataUid(parentInfo), 1);

            foldersInTargetItems.forEach((item) => {
              // 해당 폴더 uid와 하위 데이터의 uid를 전부 찾아서 배열에서 제거.
              prevState
                .filter(
                  (prevStateItem) => prevStateItem.pdataUid === item.dataUid || prevStateItem.dataUid === item.dataUid
                )
                .forEach((prevStateItem) => {
                  const idx = prevState.findIndex(
                    (subPrevStateItem) => prevStateItem.dataUid === subPrevStateItem.dataUid
                  );
                  if (idx !== -1) prevState.splice(idx, 1);
                });
            });
            return [...prevState];
          }
        });

        setSelectionModel(() => [...selectedList]);
      }}
      localeText={{
        MuiTablePagination: {
          labelDisplayedRows: ({ from, to, count }) => `${count}개의 지식 중 ${from} - ${to} `,
          // `총 ${count}개의 지식`,
        },
        footerRowSelected: (count) => `${count}개의 지식 선택`,
      }}
      isRowSelectable={(item) => {
        return item.row.isRowSelectable;
      }}
      // checkBox 사용 제한
      // isRowSelectable={(item) => item.row.type !== "Folder"}
    />
  );
}

function CustomNoRowsOverlay() {
  return (
    <EmptyDataWrapper>
      <ErrorOutlineIcon color="warning" fontSize="medium" sx={{ mr: 1 }} />
      지식 데이터를 찾을 수 없습니다.
    </EmptyDataWrapper>
  );
}

const Container = styled.div`
  .css-1dimb5e-singleValue {
    overflow: unset;
  }

  width: 100%;
  margin-bottom: 15px;
`;

const SelectCt = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  /* 700임시 */
  @media (max-width: 700px) {
    margin: 10px auto;
  }
`;

export const Box = styled("div")`
  position: relative;
`;

export const Height100Box = styled(Box)`
  height: 100%;
`;

export const FlexBox = styled(Box)`
  display: flex;
`;

const KnowledgeContainer = styled.div`
  /* max-width: 1200px; */
  justify-content: center;
  gap: 20px 10px;
  padding-bottom: 50px;

  .MuiDataGrid-root {
    border-radius: 0 0 4px 4px;
  }

  .MuiDataGrid-virtualScrollerContent,
  .css-axafay-MuiDataGrid-virtualScroller {
    min-height: 100px !important;
  }

  .MuiDataGrid-cell:focus {
    outline: none;
  }
  .css-6xugel,
  .css-1d6wzja-MuiButton-startIcon {
    margin: 0;
  }

  button.nameBtn {
    color: #333333;
    display: flex;
    & svg {
      margin-right: 5px;
    }
  }

  button.folderViewBtn {
    &:hover {
      color: #2a8cff;
      svg {
        color: #2a8cff !important;
      }
    }
  }

  div.knowledge {
    /* height: 250px; */
    margin-top: 5px;
    margin-bottom: 10px;
    border: 1px solid rgba(0, 0, 0, 0.05);
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.16);
    padding: 20px 10px;
    border-radius: 10px;

    display: flex;
    flex-direction: column;
  }

  .title {
    margin-bottom: 25px;
    text-align: center;
    font-size: 16px;
    line-height: 21px;
    word-break: break-all;
    letter-spacing: -0.5px;

    .type {
      font-weight: 600;
      margin-top: 15px;
    }
  }

  .descBox {
    /* margin-top: 20px; */
    margin-top: auto;
    margin-bottom: 20px;

    &.restrict {
      margin-top: 15px;

      & p.point {
        color: #ff892a;
      }
    }

    .descTitle {
      color: #555454;
      font-size: 15px;
      font-weight: 500;
      margin-bottom: 4px;
      word-break: break-all;
    }

    .descNumber {
      color: #888;
      font-size: 15px;
      font-weight: 400;
      word-break: break-all;
    }

    /* margin-bottom: 20%; */
    text-align: center;
  }

  & .buttonContainer {
    margin-top: 10px;
    /* margin-top: auto; */

    & button {
      /* margin: 5px 2.5px; */
      height: 40px;
      line-height: normal;

      &:hover {
        opacity: 0.8;
      }

      &.removeDefault {
        width: 65px;
        padding: 10px;
        font-size: 16px;
      }

      &.knowActive,
      &.knowNone {
        margin-top: 0px;
        width: 64px;
      }

      @media (max-width: 1060px) {
        font-size: 14px !important;
        height: 40px;
      }
    }
  }

  & button.knowledge {
    height: 100%;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    display: flex;
    flex-direction: column;
    padding: 0;
    border: 0;

    & i {
      width: 100%;
      color: #ff892a;
      font-size: 4rem;
      margin-bottom: 30px;
    }

    & span {
      width: 100%;
      color: #fff;
      padding: 15px 10px;
      background: #ff892a;
      border-radius: 100px;
    }
  }
`;

const KnowTopButtonCt = styled.div`
  display: flex;
  padding: 5px;
  font-size: 16px;

  margin-bottom: 0;
  border: 1px solid #dcdcdc;
  border-radius: 4px 4px 0 0;
  border-bottom: 0;

  @media (max-width: 480px) {
    font-size: 14px;
  }
`;

const KnowCountText = styled.button`
  color: #333;
  font-size: 14px;
  font-weight: 350;
  padding: 5px 17px;
  border-radius: 5px;

  line-height: normal;
  margin-left: auto;

  &:hover {
    opacity: 0.8;
    color: #fff;
    background: #2a8cff;
  }
`;
const KnowCountNum = styled.span`
  font-weight: 500;
  font-size: 15px;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
`;

const EmptyDataWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -1;
  position: relative;
`;
