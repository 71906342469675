import { useQuery, useQueryClient } from "@tanstack/react-query";
import botApi from "../../api/botApi.js";
import { useMemo } from "react";

/**
 * props
 * @typedef {Object} prop_1
 * @property {string} botUid - chatBotData의 botUid
 * @property {boolean} showKnowledgeManage - 채팅 부근에서 지식관리 창을 보여줄지 말지 여부 ( 다른 곳에서 사용하려 할 시 true를 보내면 된다. )
 * */

/**
 * function returnValue
 * @typedef {Object} returnValue
 * @property {Array<Object>} folderList - api에서 가져온 폴더 목록
 * @property {Array<Object>} activedFolderUidList - 활성화된 폴더 목록
 * @property {Function} updateActiveYnInFolderList - 활성화 여부 조정 함수
 * */

/**
 * @param {prop_1} props
 *
 * @description
 * - botUid에 해당되는 지식 관리의 폴더 목록을 조회.
 * - 활성화된 파일의 개수가 1개라도 있으면 active 값이 true 아니면 false.
 * - 기본 폴더 목록과, 활성화된 폴더 목록, 활성화/비활성화 여부를 수정할 수 있는 update 함수를 반환.
 * */
export default function useViewFolderListFromKnowledge(props) {
  const { botUid, showKnowledgeManage } = props;
  const queryClient = useQueryClient();

  // 폴더 목록을 가져온다. botUid가 있고, 지식 관리 목록을 펼쳤을 때만 쿼리가 실행되게 조치 ( enabled )
  const { data: { folderList, description } = { folderList: [], description: "" } } = useQuery({
    queryKey: ["viewFolderListFromKnowledge", botUid],
    queryFn: () =>
      botApi.viewFolderListFromKnowledge(botUid).then((json) => {
        const list = JSON.parse(json.data.folderList) ?? [];
        // console.log("list", list);
        const description = json.data.description;
        return {
          description,
          folderList: list.map((item) => ({
            ...item,
            active: false,
          })),
        };
      }),
    enabled: !!botUid && showKnowledgeManage,
    staleTime: Infinity,
  });

  // chatApi.sendQ에서 보낼 때 사용할 변수, 활성화 된 폴더만 필터링된 상태로 유지한다.
  const activedFolderUidList = useMemo(() => {
    if (!folderList) return [];
    return folderList.filter((item) => item.active).map((item) => item.dataUid);
  }, [folderList]);

  /**@description
   * - 지식관리에서 사용할 함수. 한 개의 폴더를 클릭하면 해당 폴더의 활성화 -> 비활성화 or 비활성화 -> 활성화로 변경해준다.
   * */
  const updateActiveYnInFolderList = (targetItem) => {
    queryClient.setQueryData(["viewFolderListFromKnowledge", botUid], (oldData) => {
      // 변경 대상과 일치하는 데이터의 idx를 찾는다.
      const idx = oldData.folderList.findIndex((item) => item.dataUid === targetItem.dataUid);
      // 없으면 기존값을 내보내서 렌더링 되지 않게 한다.

      // 전체 활성화 비활성화 추가
      if (targetItem === "all") {
        let firstData = oldData.folderList[0].active;
        return {
          ...oldData,
          folderList: [
            ...oldData.folderList.map((item, subIdx) => ({
              ...item,
              active: false,
            })),
          ],
        };
      }

      if (idx === -1) return oldData;

      // const newItem = JSON.parse(JSON.stringify(oldData[idx]));
      // newItem.active = !newItem.active;
      // oldData.splice(1, idx);
      return {
        ...oldData,
        folderList: [
          ...oldData.folderList.map((item, subIdx) => ({
            ...item,
            active: subIdx === idx ? !item.active : item.active,
          })),
        ],
      };
    });
  };

  return {
    description, // 해당 폴더 on/off에 대한 설명 ( 챗봇 관리에서 작성한 텍스트 )
    folderList, // 서버에서 가져온 폴더 목록 ( 수정은 클라이언트 내에서만 이루어짐 / 채팅 보낼 때만 사용할 임시 데이터이기 떄문 )
    activedFolderUidList, // 폴더 목록 중에서 활성화 된 애들만 filter로 거른 리스트 / 채티 보낼 때 사용할 변수
    updateActiveYnInFolderList, // 폴더 활성화 여부 단일 업데이트 함수. / 클릭한 폴더 목록의 원소를 그대로 보내면 됨.
  };
}
