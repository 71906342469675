import { useCallback, useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import iconUp from "../../assets/images/icon_topArrow.svg";
import ChatBotImage from "../../assets/images/chatBotImg_new.svg";
import iconClose from "../../assets/images/icon_close.svg";

import OwnChat from "components/pages/chat/OwnChat";

export default function SideNavButton({ setPreviewImg, previewImg }) {
  const [showSideNavigator, setShowSideNavigator] = useState(false);

  // ownbot 스테이트
  const [ownBotState, setOwnBotState] = useState(false);

  const handleScroll = useCallback((e) => {
    const scrollTop = document.scrollingElement.scrollTop;
    if (scrollTop > window.innerHeight / 2) setShowSideNavigator(true);
    else setShowSideNavigator(false);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, true);
    return () => window.removeEventListener("scroll", handleScroll, true);
  }, [handleScroll]);

  const pageScrollTop = (e) => {
    document.scrollingElement.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <SideNavContainer className={ownBotState ? "ownChatOepn" : ""}>
      {/* bot */}
      <BotCt>
        {!ownBotState && <BotText>궁금하신걸 문의하세요</BotText>}

        {/* 봇 Active button */}
        <BtnBotActive
          className={!ownBotState ? "open" : "close"}
          icon={!ownBotState ? ChatBotImage : iconClose}
          onClick={() => {
            setOwnBotState(!ownBotState);
          }}
        ></BtnBotActive>
        {ownBotState && (
          <OwnChat
            setShowOwnBot={setOwnBotState}
            showOwnBot={ownBotState}
            position={"RB"}
          />
        )}
      </BotCt>
      {/* <BannerBox>
        <a
          href="http://li-st.com/aivoucher"
          target="_blank"
          className="btc-ai-heading"
          rel="noreferrer"
        >
          AI바우처
          <br /> 상담문의
        </a>
      </BannerBox> */}

      {showSideNavigator && (
        <BtnTop icon={iconUp} onClick={pageScrollTop}></BtnTop>
      )}
    </SideNavContainer>
  );
}

const BannerBox = styled.div`
  position: relative;
  height: 55px;
  & a {
    position: absolute;
    left: -100%;

    display: inline-block;
    width: 120px;
    height: 55px;
    z-index: 1;
    background: rgb(47, 90, 169);
    background: linear-gradient(
      135deg,
      rgba(47, 90, 169, 1) 0%,
      rgba(239, 55, 65, 1) 100%
    );
    text-align: center;
    border-radius: 20px;
    padding: 8px 0;
    font-size: 16px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const SideNavContainer = styled.div`
  width: 60px;
  height: auto;

  position: fixed;
  right: 3%;
  bottom: 1.5%;
  z-index: 1;
  @media (max-width: 480px) {
    z-index: 0;
    &.ownChatOepn {
      z-index: 1;
    }
  }
`;

const BtnTop = styled.button`
  width: 55px;
  height: 55px;
  border-radius: 50%;
  border: none;
  display: block;
  margin-bottom: 10px;
  text-align: center;

  background: url(${({ icon }) => icon}) no-repeat;
  background-position: center;
  background-size: contain;
`;

const Bounce = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-4px);
  }
`;

const BotCt = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const BotText = styled.div`
  background: #ff892a;
  color: #fff;
  padding: 10px;
  position: absolute;
  top: -55%;
  right: 0;
  font-size: 12px;
  min-width: 130px;
  border-radius: 5px;
  letter-spacing: -1px;
  text-align: center;
  border: 1px solid #fff;

  animation: ${Bounce} 1s infinite linear;
  &:after {
    position: absolute;
    content: "";
    bottom: 0px;
    left: 75%;
    width: 5px;
    height: 5px;
    background: #ff892a;
    transform: rotate(45deg) translateY(50%);
  }
`;

const BtnBotActive = styled.button`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: none;
  display: block;
  margin-bottom: 10px;
  text-align: center;

  transition: 0.3s;
  background: #fff url(${({ icon }) => icon}) no-repeat;
  background-position: center;
  background-size: contain;
  box-shadow: 0px 5px 17px 0px rgba(0, 0, 0, 0.26);

  &.close {
    background-size: 20px;
  }
`;
