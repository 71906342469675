import { useEffect } from "react";
import styled from "styled-components";

export default function CommandBox({ recvComList, procCommand }) {
  return (
    <ChatInfo>
      <ChatInfoInner>
        <div className="btnGroup">
          {recvComList.map((data, idx) => {
            return (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  procCommand(data);
                }}
                className="commandBtn"
                key={idx}
              >
                {data.command}
              </button>
            );
          })}
        </div>
      </ChatInfoInner>
    </ChatInfo>
  );
}

const ChatInfo = styled.div`
  width: 100%;
`;

const ChatInfoInner = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  position: relative;
  color: 666;

  & div.btnGroup {
    display: flex;
    margin: 0px 0px 5px;
    padding: 5px 0px;
  }

  & button.commandBtn {
    margin: 5px 5px;
    padding: 3px 15px;

    word-break: keep-all;
    text-align: left;

    color: #4d4945;
    font-weight: 400;
    line-height: 25px;
    width: fit-content;

    background: #fff;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.38);
    border-radius: 10px 10px 0px 10px;

    @media (min-width: 481px) {
      &:hover {
        background: #ff8e2a;
        color: #fff;
      }
    }
  }
`;
