import React, { createContext, useState } from "react";
import { alertMessageUtil } from "../../utils/extractDataUtil";
import ToastMessage from "components/templates/popup/ToastMessage";
import { Fade } from "@mui/material";

/**
 * showTextPop = (보여줄 텍스트) = 텍스트만 넘기면 해당 함수에서 처리 후 AlertPop으로 보여준다.
 * */
const ToastMessageContext = createContext({
  toastState: {},
  toastMessage: "",
  handleToastOpen: () => {},
  handleToastClose: () => {},
  handleToastMessage: () => {},
  handleToastMessageReset: () => {},
});
export { ToastMessageContext };

export default function ToastMessageProvider(props) {
  const { children: Components } = props;

  const { captionMessages, htmlMessages, defaultMessages } = alertMessageUtil;

  /**
   * type: 'success' | 'info' | 'warning' | 'error'
   * */
  const [toastState, setToastState] = useState({ open: false, Transition: Fade, type: "info" });
  const [toastMessage, setToastMessage] = useState("");

  const handleToastMessage = (type, data, toastType) => {
    switch (type) {
      case "html":
        setToastMessage(htmlMessages[data]);
        break;
      case "caption":
        setToastMessage(captionMessages[data]);
        break;
      case "default":
        setToastMessage(defaultMessages[data]);
        break;
      case "none":
        setToastMessage(data);
        break;
      default:
        break;
    }
    handleToastOpen(toastType);
  };

  const handleToastClose = () => {
    setToastState({ ...toastState, open: false });
  };

  const handleToastOpen = (toastType) => {
    setToastState({ open: true, Transition: Fade, type: toastType });
  };

  const handleToastMessageReset = () => {
    setToastMessage("");
  };

  return (
    <ToastMessageContext.Provider
      value={{
        toastState,
        toastMessage,
        handleToastOpen,
        handleToastClose,
        handleToastMessage,
        handleToastMessageReset,
      }}
    >
      <ToastMessage handleToastClose={handleToastClose} toastMessage={toastMessage} toastState={toastState} />
      {Components}
    </ToastMessageContext.Provider>
  );
}
