import React from "react";
import "./pp.css";
import { createPortal } from "react-dom";
import styled, { keyframes } from "styled-components";

export default function MarketingPolicy({ setMarketingInfo }) {
  const handleCloseModal = () => {
    setMarketingInfo && setMarketingInfo((prev) => !prev);
  };

  return (
    <>
      {createPortal(
        <Wrapper>
          <Container>
            <Header>
              마케팅정보수집방침 <button className="ri-close-line" onClick={handleCloseModal}></button>
            </Header>
            <TextContents>
              <div className="policy-container__textBox">
                <h2 className="policy__main__title">마케팅 정보 수집 및 이용동의</h2>
                <p className="policy__title">
                  온라인 챗봇 생성 및 운영 서비스 "메스와이저(MESwiser)" 마케팅 관련 개인 정보 수집 및 이용 동의(선택)
                </p>
                <p className="policy__desc">
                  주식회사 리스트(이하 “회사”라고 합니다.)는 개인정보보호법 및 정보통신망 이용 촉진 및 정보보호 및
                  정보통신망 이용 촉진 및 정보보호 등에 관련 법률 등 관계 법령에 따라 마케팅 및 홍보 활동을 위해
                  이용자의 개인 정보(이하 “마케팅 정보”라고 합니다)활용 동의를 받고 있습니다. 아래의 내용을 충분히
                  숙지하신 후 해당 내용의 동의 여부를 결정하시기 바랍니다.
                </p>
              </div>

              <div className="policy-container__textBox">
                <h2 className="policy__title">제 1 조 마케팅 정보 수집 및 이용 목적</h2>
                <p className="policy__desc">
                  메스와이저(MESwiser) 서비스는 회원님에게 보다 다양한 정보를 제공하고, 서비스의 질을 향상시키거나 신규
                  기능 및 서비스 안내 및 이벤트 안내 등 영업목적으로 마케팅 관련 개인 정보를 수집 및 이용 할 수
                  있습니다.
                </p>
              </div>

              <div className="policy-container__textBox">
                <h2 className="policy__title">제 2 조 수집하려는 마케팅 정보의 항목</h2>
                <p className="policy__desc no">아이디(이메일주소), 이름, 회사명, 전화번호</p>
              </div>

              <div className="policy-container__textBox">
                <h2 className="policy__title">제 3 조 마케팅 정보의 보유 및 이용 기간</h2>
                <p className="policy__desc">
                  동의 일로부터 회원 탈퇴 혹은 마케팅 이용 동의 철회 시까지 마케팅과 관련된 정보 전달을 위해 보유 및
                  이용합니다.
                </p>
              </div>

              <div className="policy-container__textBox">
                <h2 className="policy__title">제 4 조 동의거부권 및 마케팅 정보 수집 및 이용 동의 변경 및 철회</h2>
                <p className="policy__desc no">
                  1. 회원은 마케팅 수집 및 활용에 대한 동의를 거부할 권리가 있으며, 활용 동의 이후라도 언제든지 챗봇
                  관리를 통해 마케팅 활용에 대하여 동의 하거나 또는 철회 할 수 있습니다.
                </p>
                <p className="policy__desc no">
                  2. 동의 거부 시 회원가입을 통한 기본 서비스 이용은 가능하나, 광고 및 이벤트 안내 등의 마케팅 관련
                  서비스 제공이 제한될 수 있습니다.
                </p>
                <p className="policy__desc no">
                  3. 해당 요청의 근거자료를 남기기 위해 해당 문의 내역은 별도로 보관될 수 있으며 회원은 이에 동의하는
                  것으로 간주합니다. 이미 제공된 회원정보를 철회하는 데는 최대 10일의 기간이 소요됩니다. 활용 동의
                  철회를 요청하시더라도 위와 같은 해당 철회 적용 이전까지 마케팅에 활용 될 수 있음을 알려드립니다.
                </p>
                <p className="policy__desc no">
                  4. 회사는 회원님의 소중한 정보를 보호하기 위하여 신속하게 처리되도록 최선의 노력을 다하겠습니다.
                </p>
              </div>
            </TextContents>
          </Container>
        </Wrapper>,
        document.getElementById("modal")
      )}
    </>
  );
}
/* 기존 애니메이션 */
const openModal = keyframes`
  0% {
    -webkit-transform: translateY(10%);
    transform: translateY(10%);
    opacity: 0;
  }

  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
    opacity: 1;
  }
`;
const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  height: 100%;

  background: rgba(0, 0, 0, 0.2);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
`;
const Container = styled.div`
  width: 50vw;
  max-height: 800px;

  animation: ${openModal} 0.2s ease-in-out;
  animation-fill-mode: both;
  display: inline-block;
  position: absolute;
  top: 10vh;
  left: 25vw;
  background: #fff;
  opacity: 0;
  border-radius: 15px;

  padding: 0 15px 30px 30px;

  @media (max-width: 480px) {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-height: none;
    padding: 0 30px 30px;
    border-radius: 0;
  }
`;
const TextContents = styled.div`
  max-height: 692px;
  height: fit-content;
  padding: 0 15px 0 0;
  overflow-y: auto;

  @media (max-width: 480px) {
    max-height: unset;
    height: calc(100% - 78px);
    padding: 0;
  }
`;
const Header = styled.div`
  display: flex !important;
  width: 100%;
  height: 78px;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  padding: 0 10px 0 15px;
  border-bottom: solid 1px #dcdcdc;
  background: #fff;

  button {
    padding: 0;
    font-size: 35px;
  }
`;
