import styled from "styled-components";

export const HeaderContainer = styled.div`
  width: 100%;
  height: 60px;

  background: #fff;

  display: flex;
  justify-content: space-between;
  align-items: center;

  /* 0426 */
  flex-wrap: wrap;
  height: 70px;

  /* 0426 */

  img.botImg {
    width: 34px;
    height: 34px;
    margin-left: 17px;
    /* margin-left: 10px; */
    margin-right: 10px;
    border-radius: 7px;
  }
  img.botImg {
    margin-left: 10px;
  }

  .chatName {
    width: 100%;
    padding: 14px 14px 14px 0;

    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: #1a1a1a;

    overflow: hidden;
    word-break: break-all;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .ri-close-line,
  .ri-arrow-left-s-line {
    font-size: 20px;
    margin: 0 5px;
  }

  button {
    width: 25px;
    height: 25px;
    margin-left: auto;
    margin-right: 15px;
  }

  @media (min-width: 481px) {
    &.wide {
      height: 85px;
      img.botImg {
        width: 40px;
        height: 40px;
      }

      .chatName {
        font-size: 20px;
        font-weight: 700;
        line-height: 29px;
      }
    }
  }

  &.widget {
    .chatName {
      font-size: 14px !important;
      font-weight: 600 !important;
      line-height: 20px !important;
    }
    height: 60px;
  }
`;
