import botApi from "api/botApi";
import { useContext, useState } from "react";
import { createPortal } from "react-dom";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { signAppContext } from "utils/providers/SignContextProvider";
import { useEffect } from "react";
import BaseChat from "../../pages/chat/BaseChat";
import { chatMiddle, refineToClientMessages } from "../../../utils/chatUtil.js";

export default function ChatViewDetailPop({ closePopup, mode, modBotUid }) {
  const history = useHistory();
  const { handleSignOutApp } = useContext(signAppContext);
  const [openChatRoom, setOpenChatRoom] = useState(null);
  const [messages, setMessages] = useState([]);
  const [chatbotData, setChatbotData] = useState({});
  useEffect(() => {
    (async () => {
      // apiName = botApi의 키값과 동일
      // 중간 에러 처리 로직 추가
      const { data, status, message } = await chatMiddle(
        { history, handleSignOutApp, toObjTargetName: "botInfo" },
        "getBotInfoByBotUid",
        modBotUid
      );
      if (status === 0) return;

      let { botName, dataName, greetingMessage, botImagePath } = data ?? {};

      setChatbotData({
        botName,
        dataName,
        greetingMessage,
        botImagePath,
      });

      setOpenChatRoom(() => true);

      const messagesResponse = await botApi.getChatMessagesInBotUid(modBotUid);
      const refineMessages = refineToClientMessages(messagesResponse.data);
      console.log("refineMessages", refineMessages);
      setMessages(() => refineMessages);
    })();
  }, []);

  useEffect(() => {
    if (!openChatRoom) {
      // closePopup();
    }
  }, [openChatRoom]);

  return (
    <>
      {createPortal(
        <Wrapper>
          <BaseChat
            showQuestionBox={true}
            messages={messages}
            setMessages={setMessages}
            popupInfo={{
              isPopup: true,
              openChatRoom,
              setOpenChatRoom,
              clickFunc: () => {
                setOpenChatRoom(false);
                closePopup();
              },
            }}
            chatBotData={chatbotData}
            chatRoomId={""}
            handleSignOutApp={handleSignOutApp}
            isHistoryBack={false}
          />
        </Wrapper>,
        document.getElementById("modal")
      )}
    </>
  );
}

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  height: 100%;

  background: rgba(0, 0, 0, 0.2);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
`;
