import NormalButton from "components/atomic/NormalButton2";
import { Fragment, useEffect, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";

export default function BotApiButtonAddPop( {
	                                            btnInfo,
	                                            addBtnInfoFunc,
	                                            modifyBtnInfoFunc,
	                                            setPopState,
                                            } ) {
	const isModify = !!btnInfo;
	const [ inputs, setInputs ] = useState({
		command: '',
		className: '',
		codeDetail: '',
		...btnInfo ?? {},
	});
	const [ errorMessage, setErrorMessage ] = useState("");
	
	const Cancel = () => {
		setPopState(false);
	};
	
	const onChangeInputs = ( e ) => {
		const {target: {name, value}} = e;
		
		setInputs(( prevState ) => ({
			...prevState,
			[name]: value,
		}))
	}
	
	const viewErrorMessage = (message, time = 2000) => {
		setErrorMessage(message);
		setTimeout(() => {setErrorMessage(null)}, time);
	}
	
	const changeFileHandle = ( e ) => {
		e.preventDefault();
		
		const files = Array.from(e.dataTransfer?.files ?? e.target?.files ?? []);
		
		// 혹시 데이터를 가져올 때 값이 없을 경우 대비
		if (!files || files.length === 0) {
			e.target.value = null;
			return viewErrorMessage("파일을 선택해 주세요.");
		}
		
		const file = files[0];
		
		// TODO - 확장자 체크하기 ( 임시로 자바 파일과 txt 파일만 되도록 )
		if (!file.name.match(/\.(java|txt)$/g)) {
			e.target.value = null;
			return viewErrorMessage("자바 또는 텍스트 파일을 선택해 주세요.");
		}
		
		// 파일 내용을 읽어서 데이터 뿌리기.
		const fileReader = new FileReader();
		fileReader.readAsText(file);
		fileReader.onload = function ( ev ) {
			onChangeInputs({
				target: {
					name: 'codeDetail',
					value: ev?.target?.result ?? ''
				}
			});
		}
	};
	
	const keyToName = (key) => {
		switch (key) {
			case 'command':
				return "버튼 이름";
			case 'className':
				return "클래스 이름";
			case 'codeDetail':
				return "클래스 내용";
			default:
				return key;
		}
	}
	
	const validateInputs = () => {
		const keys = Object.keys(inputs);
		let message = "";
		keys.some(key => {
			if (inputs[key].trim().length === 0) {
				message = `${keyToName(key)}의 값이 비어져 있습니다.`;
				return true;
			}
		})
		
		if (message.length > 0) {
			if (!errorMessage) viewErrorMessage(message);
			return false;
		}
		
		return true;
	}
	
	return (
		<ConfirmModal>
			<Wrpper className={""}>
				<Header>
					{!isModify ? (
						<>
							<i className="ri-add-circle-line"/>
							버튼 추가
						</>
					) : (
						<>
							<i className="ri-pencil-line"/>
							버튼 수정
						</>
					)}
				</Header>
				<Body>
					<BottomBtnInfoWrapper>
						<BottomBtnInfoCt>
							<BottomBtnTitle>버튼 이름</BottomBtnTitle>
							<input
								maxLength="15"
								onChange={onChangeInputs}
								placeholder="버튼 이름을 입력해 주세요(최대 15자)"
								name={"command"}
								value={inputs.command}
							></input>
						</BottomBtnInfoCt>
						<BottomBtnInfoCt>
							<BottomBtnTitle>클래스 이름</BottomBtnTitle>
							<input
								maxLength="15"
								onChange={onChangeInputs}
								placeholder="버튼 이름을 입력해 주세요(최대 15자)"
								name={"className"}
								value={inputs.className}
							></input>
						</BottomBtnInfoCt>
						<BottomBtnInfoCt>
							<BottomBtnTitle>클래스 파일 내용</BottomBtnTitle>
							<div style={{ flex: 1 }}>
								<textarea
									placeholder=""
									spellCheck="false"
									maxLength={1000}
									onChange={onChangeInputs}
									name={"codeDetail"}
									value={inputs.codeDetail}
								></textarea>
								<div style={{ marginTop:'25px' }}>
									<input
										type="file"
										id="fileUpload"
										// accept={allowedExtensions.join(", ")}
										style={{display: "none"}}
										onChange={changeFileHandle}
									></input>
									
									<FileUpLoadLabel htmlFor="fileUpload">
										<i className="ri-file-upload-fill"/>
										파일 업로드
									</FileUpLoadLabel>
								</div>
							</div>
						</BottomBtnInfoCt>
					</BottomBtnInfoWrapper>
					
					{errorMessage && (
						<p className="error">
							<i className="ri-alarm-warning-line"></i>
							{errorMessage}
						</p>
					)}
				</Body>
				
				<ButtonGroup>
					<NormalButton
						disabled={false}
						buttonId="cancel"
						buttonTitle="취소"
						buttonClass={"cancel cDel"}
						callBackFunc={Cancel}
					/>
					<NormalButton
						disabled={false}
						buttonTitle={
							isModify ? "버튼 수정" : "버튼 등록"
						}
						buttonClass={"cOrange confirm"}
						callBackFunc={() => {
							if (validateInputs()) {
								!isModify ? addBtnInfoFunc(inputs) : modifyBtnInfoFunc(inputs);
								Cancel();
							}
						}}
					/>
				</ButtonGroup>
			</Wrpper>
		</ConfirmModal>
	);
}

const FileUpLoadLabel = styled.label`
  background: #808080;
  color: #fff;
  padding: 10px 25px;
  font-size: 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;

  &:hover {
    opacity: 0.7;
  }

  & i {
    margin-right: 10px;
  }

  &.folder {
    /* background: #ffc931; */
    background: #111;
  }

  @media (max-width: 480px) {
    font-size: 20px;
    width: 240px;
    margin: 0 auto;
    border-radius: 100px;
    text-align: center;
  }
`;

const openModal = keyframes`
  0% {
    transform: translate(-0%, 5%);
    -webkit-transform: translate(-0%, 5%);
    opacity: 0;
  }

  100% {
    -webkit-transform: translate(-0%, -0%);
    transform: translate(-0%, -0%);
    opacity: 1;
  }
`;

const openModalWeb = keyframes`
  0% {
    transform: translate(-50%, -45%);
    -webkit-transform: translate(-50%, -45%);
    opacity: 0;
  }

  100% {
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    opacity: 1;
  }
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
    left: 45px;
  }
  100% {
    opacity: 1;
    top: 40px;
  }
`;

const ConfirmModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;

  animation: ${openModal} 0.2s ease-in-out;

  @media (min-width: 481px) {
    position: absolute;
    top: 50%;
    left: 40%;
    transform: translate(-50%, -50%);
    width: 45%;
    height: 70%;
    background: #fff;
    animation: ${openModalWeb} 0.2s ease-in-out;
  }
`;

const Wrpper = styled.div`
  width: 100%;
  height: 100%;

  /* transform: translate(-50%, -50%);
  top: 50%;
  left: 50%; */

  display: flex;
  flex-direction: column;

  top: 0;
  left: 0;

  border: 1px solid #dbdbdb;

  background: #fff;
  z-index: 99;
  box-shadow: 1px 1px 20px 0 rgba(0, 0, 0, 0.2);

  min-width: 82px;

  right: 10px;

  @media (min-width: 481px) {
    border-radius: 15px 15px 0 0;
  }

  &.own {
    left: 50%;
  }
`;

const Header = styled.p`
  background: #fff;

  padding: 15px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  & i {
    margin: 0 5px;
    padding: 7.5px;
    border-radius: 50%;

    &.ri-add-circle-line {
      background: #e6f9f5;
      color: #00c887;
    }

    &.ri-pencil-line {
      color: #363cee;
      background: #e3defb;
    }
  }
`;

const Body = styled.div`
  padding: 0px 20px 17px;

  & span.txt {
    font-size: 13px;
    letter-spacing: -0.5px;
    word-break: keep-all;
  }

  & p.error {
    font-size: 14px;
    letter-spacing: -0.5px;
    width: fit-content;
    height: fit-content;
    /* padding: 10px; */
    margin: 0 auto;
    color: tomato;
    text-align: center;
    border: 1px solid tomato;
    padding: 10px 10px 10px 0;
    background: #fff;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 1.3px 2.5px 5px hsl(0deg 0% 0% / 0.46);
    animation: ${fadeIn} 0.1s linear;

    & i {
      background-color: tomato;
      padding: 12px;

      margin-right: 15px;
      color: #fff;
    }
  }
`;

const ButtonGroup = styled.div`
  margin-top: auto;

  display: flex;
  align-items: center;

  button {
    flex: 1 0 50%;
    height: 45px;
    display: block;
    font-size: 13px;
    margin: 0;
    padding: 0;
    cursor: pointer;
    font-weight: 500;

    border-radius: 0;
    border: none !important;
    border-top: 1px solid #ff892a !important;

    &:hover {
      opacity: 0.8;
    }

    &.cancel {
      color: #ff892a;
    }

    &.confirm {
    }
  }
`;

const BottomBtnInfoWrapper = styled.div``;
const BottomBtnInfoCt = styled.div`
  display: flex;

  margin: 25px 0;

  & input,
  textarea {
    width: 100%;
    border-radius: 3px;
    border: 1px solid #dcdcdc;
    min-height: 32px;
    padding: 0 10px;

    &::placeholder {
      font-size: 11px;
      /* padding: 0 3px; */
    }

    &:focus {
      border: 1px solid #ff892a;
    }
  }

  & input[type="radio"] {
    min-height: auto;
    margin: 0 5px;
  }

  & textarea {
    resize: none;
    height: 100px;
    padding: 5px;
  }

  /* radio button container */

  & .typeCt {
    width: 100%;

    & .radioWrapper {
      display: flex;

      & .radioCt {
        display: flex;
        margin-bottom: 5px;
        margin-right: 5px;

        label {
          width: 150px;
          font-size: 12px;
          font-weight: 300;
          width: 100%;

          &.active {
            color: #ff892a;
            font-weight: 500;
          }
        }

        input {
          width: 12px;
          height: 12px;
        }
      }
    }
  }

  &.column {
    flex-direction: column;
  }

  & .imgBtnCt {
    display: flex;
    flex-wrap: wrap;
    @media (max-width: 480px) {
      justify-content: space-between;
    }
  }
`;
const BottomBtnTitle = styled.p`
  flex: 0 0 100px;
  padding: 0 5px;
  font-size: 13px;
  font-weight: 400;

  &.selectImgTitle {
    flex: 0 0 auto;
    margin-bottom: 10px;
  }
`;
const BottomBtnIconImg = styled.button`
  flex: 1 0 10%;
  max-width: 45px;
  height: 45px;

  margin: 3px;

  border: 1px solid #808080;
  border-radius: 50%;

  @media (max-width: 480px) {
    flex: 1 0 30%;
  }

  & .svg {
    display: inline-block;
    width: 30px;
    height: 30px;
    background: url(${( {icon} ) => icon}) no-repeat;
    background-position: center;
    background-size: contain;
  }

  transition: 0.2s;

  &.active {
    & .svg {
      filter: invert(100%) sepia(9%) saturate(4791%) hue-rotate(220deg) brightness(111%) contrast(96%);
    }

    border: 1px solid #70b0ff;
    background-color: #5698ea;
  }
`;
