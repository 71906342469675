import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import botApi from "../../api/botApi.js";
import { useEffect, useState } from "react";

export default function useKnowledgeInfiniteScroll(props) {
  const {
    botUid,
    pdataUid,
    useBookmarkCheck,
    searchValue,
    currentPage, // 최초 시작 위치를 지정 ( 다른 컴포넌트에서 이 값을 바꾸면 안됨/ 여기에서 자동으로 바뀌어짐 )
    lengthPerPage,
    mode,
    currentBotUid,
  } = props ?? {};
  // mode = scroll
  const currentMode = mode ?? "default";
  const [currentPageParam, setCurrentPageParam] = useState(currentPage);

  const queryKey = [
    "knowledgeInfiniteScroll",
    {
      lengthPerPage,
      param: {
        mode: currentMode,
        currentBotUid,
        botUid,
        pdataUid,
        searchValue,
        useBookmarkCheck:
          useBookmarkCheck === undefined ? "N" : useBookmarkCheck,
      },
    },
  ];

  const {
    data,
    fetchNextPage,
    fetchPreviousPage,
    hasNextPage,
    hasPreviousPage,
    isLoading,
    refetch,
    ...result
  } = useInfiniteQuery({
    queryKey,
    queryFn: ({ pageParam = currentPageParam, queryKey }) => {
      //pageParam = getNextPageParam, getPreviousPageParam를 통해 다음 번호를 지정되면, 위의 pageParam 값이 바뀌어져 나온다.
      setCurrentPageParam(() => pageParam); // 다른 컴포넌트에서 현재 스크롤된 위치가 어딘지를 알기 위해 지정.
      return botApi
        .getUseKnowDataSetFromBotUid_Pagination({
          ...queryKey[1],
          currentPage: pageParam ?? 0, // 여기에서 currentPage를 지정.
        })
        .then((res) => ({
          ...JSON.parse(
            res?.data?.botKnowDataSetInfo ??
              JSON.stringify({
                total: 0,
                data: [],
              })
          ),
          pageParam,
        }))
        .catch((err) => {
          console.log("err", err);
          return {
            total: 0,
            data: [],
            pageParam,
          };
        });
    },
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage.length === 0) {
        return undefined;
      } else {
        return lastPage.total !== 0 ? lastPage.pageParam + 1 : undefined;
      }
    },
    getPreviousPageParam: (lastPage, allPages) => {
      return lastPage.pageParam - 1 < 0 ? 0 : lastPage.pageParam - 1;
    },
  });

  const { pages, pageParams } = data ?? { pages: [] };

  return {
    currentPage: currentPageParam,
    lengthPerPage,
    list: pages,
    isLoading,
    refetch,
    queryKey,
    fetchNextPage,
    hasNextPage,
  };
}
