import moment from "moment/moment.js";

/**
 * @return "HH:mm"
 * */
export const getNow = () => {
	return String(moment()._d).slice(15, 21);
};

export const timestampToTime = ( timestamp ) => {
	timestamp = timestamp?.trim() ?? "";
	if (timestamp.length === 0) return getNow();
	if (timestamp.length >= 3 && timestamp.length <= 5) {
		return timestamp;
	}
	const momentObj = moment(timestamp, "YYYY-MM-DD HH:mm:ss.S");
	return momentObj.format("HH:mm");
};


export class DateUtil {
	
	/**
	 * @description
	 * 오늘 날짜를 "YYYY-MM-DD"의 string 형태로 가져온다.
	 * */
	static getToday() {
		return moment().format("YYYY-MM-DD");
	}
	
	/**
	 * @description
	 * 문자열 형식의 날짜를 js의 Date 형식으로 바꿔준다.
	 * */
	static toDate( date ) {
		return new Date(date);
	}
	
	/**
	 * @description
	 * 첫 번째 인자로 Date, string 형식의 날짜를, moment객체로 변환
	 * -> 그리고 fmtStr에 적혀있는 format에 맞게 날짜를 포맷 해준다.
	 * */
	static fmt( mnt, fmtStr = 'YYYY-MM-DD' ) {
		return moment(mnt).format(fmtStr);
	}
	
	
	/**
	 * @description
	 * date 변수의 담긴 날짜를 기준으로, day의 값 만큼 이전 날짜를 기져온다.
	 * */
	static getNumDayAgo( date, day ) {
		return DateUtil.fmt(moment(date).subtract(day, 'days'));
	}
	
	/**
	 * @description
	 * date 변수의 담긴 날짜를 기준으로, month의 값 만큼 이전 달의 날짜를 가져온다.
	 * */
	static getNumMonthAgo( date, month ) {
		return DateUtil.fmt(moment(date).subtract(month, 'months'));
	}
	
	/**
	 * @description
	 * date 변수의 담긴 날짜를 기준으로, 일주일 전 날짜를 가져온다.
	 * */
	static getOneWeekAgo( date = DateUtil.getToday() ) {
		return DateUtil.getNumDayAgo(date, 7);
	}
	
	/**
	 * @description
	 * date 변수의 담긴 날짜를 기준으로, 한 달전 날짜를 가져온다.
	 * */
	static getOneMonthAgo( date = DateUtil.getToday() ) {
		return DateUtil.getNumMonthAgo(date, 1);
	}
	
	/**
	 * @description
	 * date 변수의 담긴 날짜를 기준으로, 3달 전 날짜를 가져온다.
	 * */
	static getThreeMonthAgo( date = DateUtil.getToday() ) {
		return DateUtil.getNumMonthAgo(date, 3);
	}
	
	/**
	 * @description
	 * date 변수의 담긴 날짜를 기준으로, 6달 전 날짜를 가져온다.
	 * */
	static getSixMonthAgo( date = DateUtil.getToday() ) {
		return DateUtil.getNumMonthAgo(date, 6);
	}
}
