import { ErrorCatch } from "api/ErrorCatch";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import PersonalPolicy from "components/pages/docs/PersonalPolicy";
import TermOfUsePolicy from "components/pages/docs/TermOfUsePolicy";
import comApi from "api/comApi";
import styled, { keyframes } from "styled-components";
import MarketingPolicy from "../docs/MarketingPolicy";

export default function Advice({ setAdviceToggle, setPopState, setPopMessage }) {
  const history = useHistory();
  const CheckAllRef = useRef();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [phone, setPhone] = useState("");
  const [inquiry, setInquiry] = useState("");

  // 약관 동의 시리즈
  const [allCheck, setAllCheck] = useState(false);
  const [termsAgreement, setTermsAgreement] = useState(false);
  const [privacyAgreement, setPrivacyAgreement] = useState(false);
  const [marketingAgreement, setMarketingAgreement] = useState(false);
  //
  const [termsOfUse, setTermsOfUse] = useState(false);
  const [personalData, setPersonalData] = useState(false);
  const [marketingInfo, setMarketingInfo] = useState(false);

  // popupState
  // const [popState, setPopState] = useState(false);
  // const [popMessage, setPopMessage] = useState("");

  const qnaLinkFunc = () => {
    sessionStorage.setItem("DISPLAY_MENU", "QNA");
    history.push({
      pathname: `/dashboard`,
    });
  };

  const insertInquiry = async () => {
    try {
      const { status } = await comApi.insertInquiry(
        name,
        email,
        company,
        phone,
        inquiry,
        marketingAgreement ? "Y" : "N"
      );
      if (status === 200) {
        // alert("정상적으로 문의내용을 등록했습니다.");
        setPopMessage("정상적으로 문의내용을 등록했습니다.");
        setPopState((prev) => !prev);

        setAdviceToggle && setAdviceToggle((prev) => !prev);
        // history.goBack();
      }
    } catch (error) {
      ErrorCatch(error);
    }
  };

  // input 시리즈.
  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleCompanyChange = (e) => {
    setCompany(e.target.value);
  };

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };

  const handleInquiryChange = (e) => {
    setInquiry(e.target.value);
  };

  // 동의 시리즈.
  useEffect(() => {
    setTermsAgreement(allCheck);
    setPrivacyAgreement(allCheck);
    setMarketingAgreement(allCheck);
  }, [allCheck]);

  useEffect(() => {
    if (termsAgreement && privacyAgreement && marketingAgreement) {
      setAllCheck(true);
    } else {
      CheckAllRef.current.checked = false;
    }
  }, [termsAgreement, privacyAgreement, marketingAgreement]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!termsAgreement || !privacyAgreement) {
      setPopMessage("동의 필수 항목을 모두 체크해주세요.");
      setPopState((prev) => !prev);
      return;
    }

    if (name.trim(" ") === "") {
      // alert("이름을 입력해주세요");

      setPopMessage("이름을 입력해주세요");
      setPopState((prev) => !prev);
    } else if (email.trim(" ") === "") {
      // alert("업무용 이메일을 입력해주세요");
      setPopMessage("업무용 이메일을 입력해주세요");
      setPopState((prev) => !prev);
    } else if (inquiry.trim(" ") === "") {
      // alert("문의 내용을 입력해주세요");
      setPopMessage("문의 내용을 입력해주세요");
      setPopState((prev) => !prev);
    } else {
      insertInquiry();
    }

    console.log("문의 내용:", name, email, company, phone, inquiry);
  };

  return (
    <Wrapper>
      <Header>
        <Title>
          문의하기
          <button
            className="closeBtn"
            onClick={() => {
              // alert("1234");
              setAdviceToggle((prev) => !prev);
            }}
          ></button>
        </Title>
        <Desc>
          아래 양식은 신규 고객용 입니다.
          <br />
          기존 고객의 경우{" "}
          <a
            href="#!"
            onClick={(e) => {
              qnaLinkFunc();
            }}
          >
            여기
          </a>
          를 통해 문의 주시기 바랍니다.
          <br />
          직접 문의를 원하시면 <a href="tel:070-4140-7725"> 010-4140-7725</a>를 통해 담당자와 직접 상담이 가능합니다
        </Desc>
        <Desc className=""></Desc>
      </Header>

      <InputForm onSubmit={handleSubmit} style={{ width: "100%" }}>
        <InputCt>
          <InputText>
            이름<span style={{ color: "#ff6161" }}>*</span>
          </InputText>
          <InputTag type="text" value={name} onChange={handleNameChange} placeholder="이름을 입력해 주세요." />
        </InputCt>
        <InputCt>
          <InputText>
            업무용 이메일<span style={{ color: "#ff6161" }}>*</span>
          </InputText>
          <InputTag type="email" value={email} onChange={handleEmailChange} placeholder="메일 주소를 입력해 주세요." />
        </InputCt>

        <InputCt>
          <InputText>회사명</InputText>
          <InputTag type="text" value={company} onChange={handleCompanyChange} placeholder="회사명을 입력해 주세요." />
        </InputCt>
        <InputCt>
          <InputText>전화번호</InputText>
          <InputTag type="tel" value={phone} onChange={handlePhoneChange} placeholder="전화번호를 입력해 주세요." />
        </InputCt>
        <InputCt>
          <InputText>
            문의 내용<span style={{ color: "#ff6161" }}>*</span>
          </InputText>
          <InputTextArea
            value={inquiry}
            onChange={handleInquiryChange}
            cols="88"
            rows="4"
            placeholder="문의 내용을 입력해 주세요."
          />
        </InputCt>

        <CheckCt>
          <CheckAllCt>
            <input
              ref={CheckAllRef}
              id="checkAll"
              type="checkbox"
              checked={allCheck}
              onChange={(e) => setAllCheck((current) => !current)}
            />
            <label htmlFor="checkAll">모든 항목에 동의</label>
          </CheckAllCt>
          <div className="each">
            <input
              id="checkTermsOfUse"
              type="checkbox"
              className="checkbox"
              checked={termsAgreement}
              onChange={(e) => setTermsAgreement((current) => !current)}
            />{" "}
            <label htmlFor="checkTermsOfUse">
              {" "}
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setTermsOfUse((prev) => !prev);
                }}
              >
                이용약관
              </button>{" "}
              동의(필수)
            </label>
          </div>
          <div className="each">
            <input
              id="checkPersonalData"
              type="checkbox"
              className="checkbox"
              checked={privacyAgreement}
              onChange={(e) => setPrivacyAgreement((current) => !current)}
            />{" "}
            <label htmlFor="checkPersonalData">
              {" "}
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setPersonalData((prev) => !prev);
                }}
              >
                개인정보 수집 및 이용
              </button>{" "}
              동의(필수)
            </label>
          </div>
          <div className="each">
            <input
              id="checkMarketingINfo"
              type="checkbox"
              className="checkbox"
              checked={marketingAgreement}
              onChange={(e) => setMarketingAgreement((current) => !current)}
            />{" "}
            <label htmlFor="checkMarketingINfo">
              {" "}
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setMarketingInfo((prev) => !prev);
                }}
              >
                마케팅 메일 수신
              </button>{" "}
              동의(선택)
            </label>
          </div>
        </CheckCt>
        <SubmitBtn type="submit">문의 남기기</SubmitBtn>
      </InputForm>

      {personalData && <PersonalPolicy setPersonalData={setPersonalData} />}
      {termsOfUse && <TermOfUsePolicy setTermsOfUse={setTermsOfUse} />}
      {marketingInfo && <MarketingPolicy setMarketingInfo={setMarketingInfo} />}
    </Wrapper>
  );
}

const openModal = keyframes`
  0% {
    transform: translate(-50%,-45%);
    -webkit-transform: translate(-50%,-45%);
    opacity: 0;
  }

  100% {
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    opacity: 1;
  }
`;

const Wrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* width: 800px; */
  width: 60vw;

  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  padding: 30px 40px;
  z-index: 1;

  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */

  overflow-y: scroll;

  @media (max-width: 1000px) {
    width: 80vw;
  }

  @media (min-width: 481px) {
    max-height: 70vh;
  }
  @media (max-width: 480px) {
    width: 100%;
    height: calc(100% - 64px);
    padding: 20px 30px;
    top: 64px;
    transform: translate(-50%, 0);
    overflow-y: scroll;
    justify-content: flex-start;
    border-radius: unset;
  }

  @media (min-width: 481px) {
    animation: ${openModal} 0.2s ease-in-out;
  }
`;

const Header = styled.div`
  width: 100%;
`;

const Title = styled.p`
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 20px;

  display: flex;
  align-items: center;

  & button.closeBtn {
    margin: 0 10px 0 auto;
  }
`;

const Desc = styled.p`
  margin-bottom: 30px;
  font-size: 20px;
  line-height: 24px;
  font-weight: 300;
  letter-spacing: -1px;
  word-break: keep-all;
  color: #484848;
  & a {
    color: #ff892a;
    margin-left: 4px;
    margin-right: 3px;
  }

  @media (max-width: 480px) {
    font-size: 16px;
  }
`;

const InputForm = styled.form`
  display: flex;
  justify-content: center;
  height: 100%;
  flex-wrap: wrap;

  margin-bottom: 50px;

  @media (max-width: 480px) {
    height: unset;
  }
`;

const InputCt = styled.div`
  flex: 1 0 48%;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  @media (max-width: 480px) {
    flex: 1 0 100%;
  }
`;

const InputText = styled.p`
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
  letter-spacing: -1px;
`;

const InputTag = styled.input`
  width: 80%;
  height: 52px;
  padding: 15px;
  border: 1px solid #dcdcdc;
  border-radius: 50px;
  font-size: 16px;

  @media (max-width: 480px) {
    width: 100%;
    height: 40px;
    padding: 10px;
    font-size: 14px;
  }
`;
const InputTextArea = styled.textarea`
  width: 100%;
  height: 200px;
  padding: 10px 15px;
  border: 1px solid #dcdcdc;
  border-radius: 0;
  font-size: 16px;
  resize: none;

  @media (max-width: 480px) {
    padding: 5px 10px;
    font-size: 14px;
  }
`;

const CheckCt = styled.div`
  width: 100%;
  margin-bottom: 30px;

  & div.each {
    display: inline-block;
  }

  @media (max-width: 480px) {
    & div.each {
      display: block;
    }
  }
  button {
    padding: 0 3px 0 0;
    font-size: 16px;
    color: #ff892a;
    letter-spacing: -1px;
  }
  label {
    margin-right: 25px;
    word-break: keep-all;
    letter-spacing: -1px;
  }
`;

const CheckAllCt = styled.div`
  margin: 10px 0;
  border-bottom: 1px solid #dcdcdc;
  padding-bottom: 10px;
  text-align: right;
  label {
    margin: 0 0 0 3px;
    font-size: 18px;
    font-weight: 500;
  }
`;

const SubmitBtn = styled.button`
  border-radius: 30px;
  font-weight: 500;
  font-size: 20px;
  padding: 9px 35px;
  color: #fff;
  letter-spacing: -0.01em;
  background: #ff892a;
`;
