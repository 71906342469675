/* eslint-disable */
import styled from "styled-components";
import ProfilePop from "components/templates/popup/ProfilePop";
import camera_icon from "assets/images/icons/icon_camera.svg";
import user_icon from "assets/images/icons/user_icon.svg";
import { useContext, useEffect, useRef, useState } from "react";
import InputBox from "components/atomic/InputBox";
import Select from "react-select";
import TextArea from "components/atomic/TextArea";
import { isMobileContext } from "utils/providers/BrowserEnvContextProvider";
import RangeSlider from "react-range-slider-input";
import iconWraning from "../../../assets/images/icon_warning.svg";
import { popupContext } from "../../../utils/providers/PopupProvider.js";
import DescriptionIcon from "@mui/icons-material/Description";
import { Button } from "@mui/material";
import { ModalContext } from "utils/providers/ModalProvider";
import ModalDefault from "components/templates/modal/ModalDefault";
import { baseSettingUtil } from "utils/baseSettingUtil";
import NormalButton from "../../atomic/NormalButton.js";
import { chatContext } from "../../../utils/providers/ChatContextProvider.js";

export const updateTooltipInfoLogic = (type, isEnabled, prevState) => ({
  ...prevState,
  [type]: {
    ...(prevState[type] ?? {}),
    isEnabled,
  },
});

const { exampleContent } = baseSettingUtil;

export default function BaseSettingTab({ baseProps }) {
  const {
    botName,
    setBotName,
    greetingMessage,
    setGreetingMessage,
    useAddPrompt,
    setUseAddPrompt,
    useRsearch,
    setUseRsearch,
    showRsearch,
    botRole,
    setBotRole,
    botImagePath,
    setBotImagePath,
    botRatioVal,
    setBotRatioVal,
    sliderMaxVal,
    setSliderMaxVal,
    sliderMinVal,
    setSliderMinVal,
    useKnowledgeManage,
    setUseKnowledgeManage,
    knowledgeManageDescription,
    setKnowledgeManageDescription,
    useChatMarkdown,
    setUseChatMarkdown,
    shortUrl,
    setShortUrl,
    validateShortUrl,
    isTypedShortUrl,
    setIsTypedShortUrl
  } = baseProps;
  const { isMobile } = useContext(isMobileContext);
  const { userPlan } = useContext(chatContext);

  // slider
  const RefRange = useRef("");
  const RefMin01 = useRef();
  const RefMax01 = useRef();

  const [tooltipInfo, setTooltipInfo] = useState({
    responseConditions: {
      type: "responseConditions",
      tooltipText: "시맨틱 비율을 높일수록 단어와 구문의 의미를 해석해 답변합니다",
      isEnabled: false,
    },
    relatedQuestions: {
      type: "relatedQuestions",
      tooltipText: "질문의 키워드를 분석해 유사하거나 관련 있는 질문을 추천합니다",
      isEnabled: false,
    },
    deepResponse: {
      type: "deepResponse",
      tooltipText: "질문에 대해 더 상세하고 구체적인 답변이 제공되지만 불필요한 정보도 포함될 수 있습니다",
      isEnabled: false,
    },
    knowledgeManage: {
      type: "knowledgeManage",
      tooltipText: "원하는 질문 범위를 지정할 수 있습니다.",
      isEnabled: false,
    },
    useChatMarkdown: {
      type: "useChatMarkdown",
      tooltipText: "지식 답변 방식을 markdown 형식으로 나타냅니다.",
      isEnabled: false,
    },
    shortUrl: {
      type: "shortUrl",
      tooltipText: "사용자가 지정한 이름을 사용하여 더 기억하기 쉬운 URL을 생성하세요.",
      isEnabled: false,
    },
  });
  const updateTooltipInfo = (type, isEnabled) => {
    setTooltipInfo((prevState) => updateTooltipInfoLogic(type, isEnabled, prevState));
  };

  const { showModal, openModal } = useContext(ModalContext);
  const [modalTitle, setModalTitle] = useState("사용 예시");
  const [modalContent, setModalContent] = useState([{}]);

  console.log("botRatioVal", botRatioVal);
  console.log('shortUrl', shortUrl)

  return (
    <TableCt>
      <BotModifyCt>
        <BotinfoCt>
          <BotInfoTitle>
            챗봇이름<span>*</span>
          </BotInfoTitle>
          <BotInfoRow>
            <InputForm target="img" value={botImagePath} setValue={setBotImagePath} />
            <InputForm inputclass={"modify"} target="name" value={botName} setValue={setBotName} />
          </BotInfoRow>
          <BotInfoDesc>
            이미지 형식: jpg, png{isMobile && <br />} 최대 1MB, 가로 최대 500px, 세로 최대 500px
          </BotInfoDesc>
        </BotinfoCt>

        <BotinfoCt>
          <BotInfoTitle>
            챗봇 인사말<span>*</span>
          </BotInfoTitle>
          <BotInfoRow>
            <InputForm inputclass={"modify"} target="greet" value={greetingMessage} setValue={setGreetingMessage} />
          </BotInfoRow>
        </BotinfoCt>
        
        {userPlan.isPremiumUser && (
          <BotinfoCt>
          <ShowTooltipInfo
            title={"사용자 URL 설정"}
            titleClass={"notSub"}
            updateTooltipInfo={updateTooltipInfo}
            {...tooltipInfo.shortUrl}
          />
          <BotInfoRow>
            <InputForm inputclass={"modify"} target="shortUrl" value={shortUrl} setValue={setShortUrl} onKeyDown={() => {
              if (!isTypedShortUrl) setIsTypedShortUrl(true)
            }} />
            <NormalButton
              disabled={false}
              buttonTitle="중복검사"
              buttonClass="cOrange submit_btn"
              callBackFunc={() => validateShortUrl()}
              style={{
                margin:"0 0 0 10px",
                padding:"6.5px 32.5px",
                fontSize:"16px"
              }}
            />
          </BotInfoRow>
        </BotinfoCt>
        )}

        <BotInfoTitle>응답 설정</BotInfoTitle>
        <BotinfoCt className="flexrow">
          <BotInfoRow className="responseStyle">
            <BotInfoTitle className="sub">
              응답 스타일<span>*</span>
            </BotInfoTitle>
            <InputForm style={{ zIndex: "2" }} target="role" value={botRole} setValue={setBotRole} />
          </BotInfoRow>

          <BotInfoRow className="searchOtp">
            <ShowTooltipInfo
              title={"응답 조건"}
              updateTooltipInfo={updateTooltipInfo}
              {...tooltipInfo.responseConditions}
            />
            <SearchOpCt style={{ width: "100%", textAlign: "left" }}>
              <div className="optionValueCt">
                <p className="minValueTitle">
                  키워드{" "}
                  <span ref={RefMin01} className="minValue">
                    {sliderMinVal}
                  </span>
                </p>

                <p className="maxValueTitle">
                  시맨틱{" "}
                  <span ref={RefMax01} className="maxValue">
                    {sliderMaxVal}
                  </span>
                </p>
              </div>
              <RangeSlider
                ref={RefRange}
                onInput={(value) => {
                  const refRangeMaxValue = RefRange.current.value.max;
                  const min = 100 - refRangeMaxValue;

                  RefMin01.current.innerText = min;
                  RefMax01.current.innerText = refRangeMaxValue;

                  setSliderMinVal(() => min);
                  setSliderMaxVal(() => refRangeMaxValue);
                  setBotRatioVal(() => [0, refRangeMaxValue]);
                }}
                thumbsDisabled={[true, false]}
                rangeSlideDisabled={true}
                defaultValue={[0, 20]}
                value={botRatioVal}
                min={0}
                max={100}
                step={10}
              />
            </SearchOpCt>
          </BotInfoRow>
          {/*
             참고자료는 현재 방향에서는 사용성이 좋지 않기에 주석처리 상태.
          <BotInfoRow className="toggleStyle">
            <BotInfoTitle className="sub sub2">답변 참고자료 표기</BotInfoTitle>
            <div
              id="showSource"
              className={showSource ? "toggle active" : "toggle"}
              onClick={() => {
                setShowSource((prev) => !prev);
              }}
            >
              <div className="circle" id="showSource"></div>
            </div>
          </BotInfoRow> */}
          {showRsearch && (
            <BotInfoRow className="toggleStyle">
              <ShowTooltipInfo
                title={"연관 질문"}
                titleClass={"sub sub2"}
                updateTooltipInfo={updateTooltipInfo}
                {...tooltipInfo.relatedQuestions}
              />
              <div
                id="showSource"
                className={useRsearch ? "toggle active" : "toggle"}
                onClick={() => {
                  setUseRsearch((prev) => !prev);
                }}
              >
                <div className="circle" id="showSource"></div>
              </div>
            </BotInfoRow>
          )}
          {/* */}
          <BotInfoRow className="toggleStyle">
            <ShowTooltipInfo
              title={"심층 응답"}
              titleClass={"sub sub2"}
              additionalActiveClass={"width250"}
              updateTooltipInfo={updateTooltipInfo}
              {...tooltipInfo.deepResponse}
            />
            <div
              id="showSource"
              className={useAddPrompt ? "toggle active" : "toggle"}
              onClick={() => {
                setUseAddPrompt((prev) => !prev);
              }}
            >
              <div className="circle" id="showSource"></div>
            </div>
          </BotInfoRow>
          {/* */}
          <BotInfoRow className="toggleStyle col">
            <div className="toggleStyleBox">
              {/* <ShowTooltipInfo
                title={"지식 필터"}
                titleClass={"sub sub2"}
                additionalActiveClass={"width250"}
                updateTooltipInfo={updateTooltipInfo}
                {...tooltipInfo.knowledgeManage}
              />
              */}

              <p>지식 필터</p>
              <DetailButton
                startIcon={<DescriptionIcon className="icon" />}
                title="설명 자세히 보기"
                onClick={() => {
                  setModalTitle("지식 필터 설명 😀");
                  setModalContent(exampleContent[0]);
                  openModal();
                }}
                variant="text"
                color="primary"
              >
                예시
              </DetailButton>
              <div
                id="showSource"
                className={useKnowledgeManage ? "toggle active" : "toggle"}
                onClick={() => {
                  setUseKnowledgeManage((prev) => !prev);
                }}
              >
                <div className="circle" id="showSource"></div>
              </div>
            </div>

            <InputForm
              inputclass={"modify"}
              target="knowledgeManage"
              value={knowledgeManageDescription}
              setValue={setKnowledgeManageDescription}
            />
          </BotInfoRow>

          {/* */}
          <BotInfoRow className="toggleStyle col">
            <div className="toggleStyleBox">
              {/* <ShowTooltipInfo
                title={"채팅 응답 방식"}
                titleClass={"sub sub2"}
                additionalActiveClass={"width250"}
                updateTooltipInfo={updateTooltipInfo}
                {...tooltipInfo.useChatMarkdown}
              /> */}
              <p>채팅 응답 방식</p>
              <DetailButton
                startIcon={<DescriptionIcon className="icon" />}
                title="설명 자세히 보기"
                LinkComponent={""}
                onClick={() => {
                  setModalTitle("채팅 응답 방식 설명 😀");
                  setModalContent(exampleContent[1]);
                  openModal();
                }}
                variant="text"
                color="primary"
                sx={{ mr: 0 }}
              >
                예시
              </DetailButton>
              <div
                id="showSource"
                className={useChatMarkdown ? "toggle active" : "toggle"}
                onClick={() => {
                  setUseChatMarkdown((prev) => !prev);
                }}
              >
                <div className="circle" id="showSource"></div>
              </div>
            </div>
          </BotInfoRow>
        </BotinfoCt>
      </BotModifyCt>

      {showModal && <ModalDefault modalTitle={modalTitle} modalContent={modalContent} />}
    </TableCt>
  );
}

export function ShowTooltipInfo(props) {
  const { title, titleClass, additionalActiveClass, type, tooltipText, isEnabled, updateTooltipInfo } = props;
  const { isMobile } = useContext(isMobileContext);
  const { showTextPop } = useContext(popupContext);

  return (
    <BotInfoTitle className={titleClass ?? "sub"} icon={iconWraning}>
      {title + " "}
      {!isMobile ? (
        <span className="toolTipCt">
          <span
            onMouseOver={() => {
              console.log("mouseover!");
              updateTooltipInfo(type, !isEnabled);
            }}
            onMouseOut={() => {
              updateTooltipInfo(type, !isEnabled);
            }}
            className="toolTipIcon"
          ></span>
          <span className={isEnabled ? "toolTipTxt active " + additionalActiveClass ?? "" : "toolTipTxt"}>
            {tooltipText}
          </span>
        </span>
      ) : (
        <span className="toolTipCt">
          <span
            onClick={() => {
              showTextPop(tooltipText);
            }}
            className="toolTipIcon"
          ></span>
        </span>
      )}
    </BotInfoTitle>
  );
}

export function InputForm({ target, value, setValue, onKeyDown }) {
  if (target === "img") {
    const [showProfilePop, setShowProfilePop] = useState(false);
    const [previewImage, setPreviewImage] = useState("");

    useEffect(() => {
      setShowProfilePop(false);
    }, [value]);

    const IMAGE_PATH = process.env.REACT_APP_RESOURCE_HOST + "/images/bot/";
    return (
      <Profile>
        <Thumb>
          <img src={previewImage ? previewImage : value ? IMAGE_PATH + value : user_icon} alt="" />
        </Thumb>
        {showProfilePop && (
          <ProfilePop
            setShowProfilePop={setShowProfilePop}
            setPreviewImage={setPreviewImage}
            setBotImagePath={setValue}
          />
        )}
        <Modify imrSrc={camera_icon} onClick={(e) => setShowProfilePop(!showProfilePop)}></Modify>
      </Profile>
    );
  } else if (target === "name") {
    return (
      <InputBox
        inputId={`manage_${target}`}
        value={value}
        setValue={setValue}
        placeholderText={"최대20자까지 입력 가능합니다."}
        style={{ width: "100%" }}
        inputclass={"botTable"}
      />
    );
  } else if (target === "greet") {
    return (
      <TextArea
        textId={`manage_${target}`}
        value={value}
        setValue={setValue}
        style={{ width: "80%", borderRadius: "10px" }}
        disabled={false}
        placeholderText={"채팅방 시작 시 환영 문구 입니다. (최대 200자)"}
        textClass={"manage"}
      />
    );
  } else if (target === "role") {
    const select = {
      label: value === 0 ? "친절" : value === 1 ? "귀여움" : value === 2 ? "유머러스" : "친절",
      value: value ? value : 0,
    };

    const selectList = [
      { label: "친절", value: 0 },
      { label: "귀여움", value: 1 },
      { label: "유머러스", value: 2 },
    ];

    const handleChange = (event) => {
      setValue(event.value);
    };

    return (
      <div style={{ width: "100%", maxWidth: "488px" }}>
        <Select
          value={select}
          // defaultInputValue={select}
          defaultValue={select}
          onChange={handleChange}
          options={selectList}
        />
      </div>
    );
  } else if (target === "excUrl") {
    // 해당은 제외URL인데 초기버전에서는 사용하지 않기에 따로 구현하지 않음. 23.07.25.codelua.
  } else if (target === "formIntroIn") {
    return (
      <TextArea
        textId={`manage_${target}`}
        value={value}
        setValue={setValue}
        style={{ width: "80%", borderRadius: "10px" }}
        disabled={false}
        placeholderText={"봇에 대한 소개를 작성해주세요. (최대 1000자)"}
        textClass={"manage"}
      />
    );
  } else if (target === "knowledgeManage") {
    return (
      <TextArea
        textId={`manage_${target}`}
        value={value}
        setValue={setValue}
        style={{ width: "80%", borderRadius: "10px" }}
        disabled={false}
        placeholderText={"지식 필터에 대한 설명을 작성해주세요. (최대 100자)"}
        textClass={"manage"}
      />
    );
  } else if (target === "formIntroOut") {
    return (
      <InputBox
        inputId={`manage_${target}`}
        value={value}
        setValue={setValue}
        placeholderText={"URL을 작성해주세요."}
        style={{ width: "100%" }}
        inputclass={"botTable"}
      />
    );
  } else if (target === "formExampleInput") {
    return (
      <InputBox
        inputId={`manage_${target}`}
        value={value}
        setValue={setValue}
        placeholderText={"예시 질문을 입력해주세요"}
        style={{ width: "35%", flexGrow: "0" }}
        inputclass={"botTable addUser"}
      />
    );
  } else if (target === "formExampleExtInput") {
    return (
      <InputBox
        inputId={`manage_${target}`}
        value={value}
        setValue={setValue}
        placeholderText={"URL을 작성해주세요."}
        style={{ width: "100%" }}
        inputclass={"botTable"}
      />
    );
  } else if (target === "shortUrl") {
    return (
      <InputBox
        inputId={`manage_${target}`}
        value={value}
        setValue={setValue}
        placeholderText={"원하는 URL 이름을 입력하세요. ( 50자 제한 )"}
        style={{ width: "100%" }}
        inputclass={"botTable"}
        inputStyle={{ borderRadius: 0 }}
        onKeyDown={onKeyDown}
      />
    );
  }
}

const BotModifyCt = styled.div`
  border-radius: 10px;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.16);
  background: #fff;
  padding: 22.5px;
  padding-top: 33px;
  text-align: center;
  margin-top: 15px;

  button {
    &.change {
      & span {
        display: inline-block;
        width: 28px;
        @media (max-width: 480px) {
          width: 16px;
        }
      }
    }
  }
`;

const BotinfoCt = styled.div`
  margin-bottom: 36px;

  &.flexrow {
    display: flex;
    flex-wrap: wrap;

    @media (max-width: 1000px) {
      flex-direction: column;
    }
  }

  .css-1nmdiq5-menu {
    z-index: 11;
  }
`;

const BotInfoTitle = styled.p`
  color: #363636;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 28.5px;
  text-align: left;
  z-index: 10;

  &.sub {
    font-size: 15px;
    font-weight: 500;
  }

  &.sub2 {
    margin-bottom: 0;
  }

  & .inputCt {
    margin-top: 10px;
    display: block;
    color: #888;

    & label {
      font-weight: 400;
      font-size: 14px;
      margin-right: 5px;
    }
  }

  & span {
    color: #ff6161;
    font-size: 16px;
  }

  &.exQ {
    font-weight: 400;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
  }

  & span.toolTipCt {
    display: inline-block;
    position: relative;
    transform: translateY(2px);
    @media (max-width: 480px) {
      transform: translateY(3px);
    }

    .toolTipIcon {
      display: inline-block;
      width: 16px;
      height: 16px;
      background: url(${({ icon }) => icon}) no-repeat;
      background-position: center;
      background-size: contain;

      &:hover {
        cursor: pointer;
      }
    }

    .toolTipTxt {
      display: none;
      position: absolute;

      padding: 10px 5px;

      top: -8px;
      left: 20px;

      font-size: 12px;
      line-height: 15px;
      word-break: keep-all;
      font-weight: 500;
      letter-spacing: -1px;
      text-align: left;
      color: #888;

      background: #f7f9ff;
      border-radius: 5px;
      width: 220px;

      &.width250 {
        width: 250px;
      }

      &.active {
        display: block;
      }
    }
  }

  & button.moreBtn {
    display: inline-flex;
    margin-left: auto;
    align-items: center;

    & img {
      transform: rotate(180deg);
      width: 20px;
      height: 20px;
      margin-right: 5px;
      transition: 0.3s;
    }

    &.active {
      & img {
        transform: rotate(0deg);
      }
    }
  }

  @media (max-width: 480px) {
    font-size: 14px;
    margin-bottom: 14px;
    & span {
      font-size: 14px;
    }
  }
`;
const BotInfoDesc = styled.p`
  color: #a6a6a6;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.14px;
  text-align: left;

  @media (max-width: 480px) {
    color: #a6a6a6;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.12px;
  }
`;

export const BotInfoRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  &.introTab {
    display: none;

    &.active {
      display: block;
    }
  }

  &.searchOtp {
    flex-direction: column;
    align-items: start;
    flex: 1 0 50%;
    @media (max-width: 480px) {
      flex: 1 0 90%;
    }
  }

  &.responseStyle {
    justify-content: space-between;
    align-items: start;
    flex-direction: column;
    flex: 1 0 50%;
    margin-bottom: 30px;
    padding-right: 30px;
    @media (max-width: 480px) {
      flex-direction: column;
      align-items: start;
      margin-bottom: 20px;
      flex: 1 0 100%;
      padding-right: 0px;
    }

    & p {
      margin: 0;
      margin-bottom: 28.5px;
      @media (max-width: 480px) {
        margin-bottom: 14px;
      }
    }
  }

  &.toggleStyle {
    flex: 0 0 50%;
    align-items: baseline;
    justify-content: start;

    &.col {
      flex-direction: column;
    }

    .toggleStyleBox {
      display: flex;
      align-items: center;
      width: 100%;
      margin: 7px 0 5px;
    }

    @media (max-width: 480px) {
      flex: 1 0 100%;
    }

    .sub span {
      /* display: inline-block;
												color: #363636;
												font-size: 15px; */
    }
  }

  &.question {
    justify-content: space-around;

    border: 1px solid #dcdcdc;
    padding: 10px;

    /* margin-right: 10px; */
  }

  & p.exText {
    word-break: break-all;
    margin-right: 10px;
    text-align: left;
    width: 100%;
  }

  & form.inputCt,
  div.inputCt {
    /* &.under {
									visibility: hidden;
									opacity: 0;
									height: 0px;
									overflow-y: auto;
								} */
    display: flex;
    width: 100%;
    margin-bottom: 25px;
    transition: 0.3s;

    input {
      margin-right: 10px;
      flex: 0 1 auto;
    }

    button {
    }
  }

  & input {
    border-radius: 100px;

    &::placeholder {
      color: #a6a6a6;
      font-size: 16px;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.16px;
      @media (max-width: 480px) {
        font-size: 13px;
      }
    }
  }

  & input,
  textarea {
    background: #f3f3f3;

    &::placeholder {
      color: #a6a6a6;
      font-size: 16px;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.16px;

      @media (max-width: 480px) {
        font-size: 13px;
      }
    }
  }

  & div.btnCt {
    display: flex;
    width: fit-content;

    button {
      margin-left: 5px;
      font-size: 14px;
      line-height: normal;
      height: 30px;
      padding: 5px 10px;
      width: 50px;
    }
  }

  .toggle {
    display: inline-block;
    width: 40px;
    height: 20px;
    background: #ffe7ca;
    border-radius: 20px;
    position: relative;
    cursor: pointer;
    transition: 0.3s;
    margin-left: 10px;

    transform: translateY(2px);

    &.active {
      background-color: #b9f4bb;
    }
  }

  .circle {
    width: 20px;
    height: 20px;
    background: #f8b400;
    border-radius: 50%;
    position: absolute;
    /* top: 50%; */
    top: 0%;
    transition:
      transform 0.3s,
      background 0.3s;
  }

  /* 활성화 스타일 */

  .active .circle {
    /* top: 50%; */
    transform: translateX(25px) translateY(0%);
    background: #4caf50; /* 색상 변경 */
    box-shadow: 0px 1px 9px 1px rgba(0, 0, 0, 0.11);
  }
`;

const TableCt = styled.div`
  @media (max-width: 700px) {
    width: 100%;
  }
  display: block;
  width: 70%;

  & table {
    width: 80%;
    min-height: 350px;
    height: auto;
    width: 100%;
    margin-top: 64px;
    border-radius: 20px;
    background: #fff;
    box-shadow: 0px 1px 9px 0px rgba(0, 0, 0, 0.22);

    & tbody.modify {
      & td {
        padding-right: 20px !important;
      }
    }

    & td {
      height: 80px;
      padding-right: 5px !important;
      word-break: keep-all;
      letter-spacing: -1px;

      &.crawlTd {
        & button.crawlUrlBtn {
          margin: 0 !important;
          width: auto !important;
          font-size: 14px;
          padding: 5px 10px !important;
        }
      }

      .chargeBtnCt {
        display: flex;
        align-items: center;
      }

      .chargeConsultBtn {
        border-radius: 15px;
        margin: 0;
        width: auto;
        font-size: 14px;
        padding: 5px 5px;
        color: #fff;
        background: #ffca36;
        margin-left: 20px;
        margin-right: 10px;
        font-weight: 500;

        &:hover {
          background: #ffe618;
          color: #6a6a6a;
        }
      }
    }

    @media (max-width: 480px) {
      min-height: auto;

      td {
        padding: 30px 15px;

        &.number {
          font-weight: 600;
        }
      }
    }

    & tbody.modify {
      tr {
      }

      .textTd {
        color: #363636;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        padding-top: 50px;
        padding-bottom: 20px;
      }

      .inputTd.avata {
        display: flex;
        align-items: center;

        .desc {
          margin-left: 55px;

          p {
            color: #a6a6a6;
            font-size: 14px;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.14px;
          }
        }
      }

      .inputTd.avata.mobileType {
        .desc {
          margin-top: 15px;
          margin-left: 0;
          padding-right: 15px;
          letter-spacing: -1px;
          word-break: keep-all;
        }
      }
    }

    & tr {
      height: auto;

      & td {
        padding: 0 20px;
        vertical-align: middle;

        :last-child {
          &input {
            margin-left: 20px;
          }
        }
      }
    }
  }
`;

const BotDataCt = styled.div`
  display: flex;
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.18);
  margin-bottom: 20px;

  flex-wrap: wrap;

  @media (max-width: 480px) {
    border-radius: 10px;
  }
`;

const BotDataTag = styled.div`
  flex: 1 0 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-height: 150px;
  padding: 15px 1vw;

  &.expire {
    position: relative;

    p.title {
      position: relative;
    }
  }

  &:not(:last-child) {
    border-right: 1px solid #dcdcdc;
  }

  p {
    text-align: center;

    &.title {
      font-weight: 500;
      line-height: 15px;
      letter-spacing: -0.5px;
      font-size: 13px;
      margin: auto 0;
      word-break: keep-all;

      & .icon {
        position: absolute;
        bottom: 0;
        color: tomato;
        font-size: 16px;
        cursor: pointer;

        & .expireTooltip {
          display: none;
        }

        &.active {
          display: inline-block;

          & .expireTooltip {
            position: absolute;
            /* background: linear-gradient(135deg, #ff892a, #ffc51e); */
            /* background: tomato; */
            background: rgba(25, 195, 125, 0.9);
            border-radius: 5px;
            top: 0%;
            right: 130%;
            display: block;
            width: 220px;
            font-size: 13px;
            letter-spacing: -1px;
            line-height: 15px;
            word-break: keep-all;
            padding: 10px;
            color: #000;
            /* color: tomato; */
            font-weight: 600;

            &:after {
              content: "";
              width: 10px;
              height: 10px;
              position: absolute;
              top: 0;
              right: 0;
              /* border: 1px solid tomato; */
              /* background: tomato; */
              background: #19c37d;
              transform: rotate(45deg) translateX(50%);
              /* border-left: 1px solid #777; */
              /* border-right: 1px solid #777; */
              /* border-bottom: 1px solid #777; */
            }
          }
        }
      }
    }
  }

  div.number,
  p.number {
    color: #232323;
    font-size: 14px;
    letter-spacing: -0.5px;
    font-weight: 700;
    height: 50px;
    max-width: 115px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 480px) {
      word-break: keep-all;
      padding: 0 15px;
    }

    &.usesSize {
      max-width: 100%;
    }

    &.text {
      color: #ff892a;
    }

    &.wide {
      color: #ff892a;
      max-width: 145px;
    }
  }

  .expireCt {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    .text {
      word-break: keep-all;
    }
  }

  .expireBtn {
    width: 40px;
    height: 20px;

    background: tomato;
    color: #fff;
    font-weight: 700;
    border-radius: 30px;
    font-size: 12px;
    letter-spacing: -1px;
    margin-left: 5px;
    line-height: 10px;

    &:hover {
      background: #19c37d;
      color: #fff;
    }

    @media (max-width: 480px) {
      position: unset;
      margin-left: 10px;
    }
  }

  @media (max-width: 480px) {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    /* order: 2; */
    flex: 1 0 55%;
    padding: 7.5px 10px;

    p.title {
      width: 40%;
    }

    div.number,
    p.number {
      flex: 0 0 auto;
      height: 15px;
      font-size: 13px !important;
      max-width: unset;
    }

    &:not(:last-child) {
      border-right: none;
    }

    &.Volume {
      /* order: 1; */

      div.number,
      p.number {
      }
    }
  }
`;

const BtnCt = styled.div`
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: center;
  /* width: 400px; */
  margin: 0 auto;

  & button {
    margin: 5px 10px !important;
    flex-shrink: 0 !important;
    font-size: 16px;
    min-width: 140px;
    font-weight: 700;
    line-height: normal;
    padding: 22.5px 52px !important;
    height: 68px !important;
    border-radius: 100px !important;

    &.start {
      width: 35%;
      min-width: 250px;
      @media (max-width: 480px) {
        flex: 0 1 auto;
        min-width: 100px;
        width: 180px;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        padding: 10px 10px !important;
      }

      & span {
        display: inline-block;
        width: 28px;
        @media (max-width: 480px) {
          width: 16px;
        }
      }
    }
  }

  @media (max-width: 700px) {
    flex: 1 1 100%;
    margin: 15px 10px 15px !important;
    height: auto !important;
    word-break: keep-all;
  }
  @media (max-width: 480px) {
    width: auto;
    & button {
    }
  }
`;

const Profile = styled.div`
  display: inline-block;
  width: 85px;
  height: 85px;
  position: relative;
  margin-right: 15px;

  @media (max-width: 480px) {
    width: 65px;
    height: 65px;
  }
`;

const Thumb = styled.span`
  display: inline-block;
  width: 81px;
  height: 81px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;

  img {
    width: 100%;
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  @media (max-width: 480px) {
    width: 60px;
    height: 60px;
  }
`;

const Modify = styled.button`
  width: 30px;
  height: 30px;
  position: absolute;
  bottom: 2px;
  right: 2px;
  z-index: 0;
  border-radius: 50%;
  border: none;
  background: url(${({ imrSrc }) => imrSrc}) no-repeat;
  background-size: 15px 12px;
  background-position: 50%;
  background-color: #e9e9e9;
`;

const SearchOpCt = styled.div`
  margin: 0px 0 20px;

  & p.title {
    color: #777;
    font-weight: 600;
    margin: 10px 0;
  }

  & .optionValueCt {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;

    & p {
      color: #888;

      & span {
        font-weight: 500;
      }
    }
  }
`;

const DetailButton = styled(Button)`
  font-size: 14px !important;
  padding: 0px !important;

  & span {
    margin: 0;
  }
`;
