import ChatMain from "components/organisms/ChatMain";
import MainContent from "components/organisms/MainContent";
import { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import qs from "query-string";
import SideNavButton from "../../organisms/SideNavButton";
// import cookie from "react-cookies";
// import banner1 from "assets/images/banner/AIVoucher_banner.png";

export default function HomeMain({ setLoginPopState }) {
  const location = useLocation();
  const { crawlRestricted } = qs.parse(location.search);
  const mainContentRef = useRef();

  /* 배너처리 */
  // const [isPop, setIsPop] = useState(false);

  // const closeBanner = (closeType) => {
  //   // 그냥 닫기
  //   if (closeType === 2) {
  //     setIsPop(false);
  //   } else {
  //     // 1일간 안열리게 쿠키 작업
  //     const expires = new Date();
  //     expires.setDate(expires.getDate() + 1);
  //     cookie.save("closeChatBotPop", "true", { path: "/", expires });
  //     setIsPop(false);
  //   }
  // };

  // useEffect(() => {
  //   // cookie.remove("closePop");
  //   const closePop = cookie.load("closeChatBotPop");
  //   if (!closePop) {
  //     setIsPop(true);
  //   }
  // }, []);
  /* 배너처리 끝 */

  return (
    <Wrapper>
      <Content className="content">
        <ChatMain
          crawlRestricted={crawlRestricted}
          setLoginPopState={setLoginPopState}
          mainContentRef={mainContentRef}
        />
        <MainContent contentRef={mainContentRef} />

        <SideNavButton />
        {/* {isPop && (
          <BannerBox>
            <a
              href="http://li-st.com/aivoucher"
              target="_blank"
              rel="noreferrer"
            >
              <img alt="AI바우쳐이미지" src={banner1} />
            </a>
            <ClosseArea>
              <input
                type="checkbox"
                onClick={(e) => {
                  closeBanner(1);
                }}
              />
              &nbsp;&nbsp;오늘 부터 1일 동안 보지 않기&nbsp;&nbsp;
              <CloseBtn
                onClick={(e) => {
                  closeBanner(2);
                }}
              >
                [닫기]
              </CloseBtn>
            </ClosseArea>
          </BannerBox>
        )} */}
      </Content>
    </Wrapper>
  );
}

const CloseBtn = styled.button`
  font-size: 14px;
  font-weight: 900;
  color: #fff;
`;

const ClosseArea = styled.div`
  display: flex;
  margin: 5px 0;
  height: 26px;
  line-height: 26px;
  color: #fff;
`;

const BannerBox = styled.div`
  position: absolute;
  width: fit-content;
  height: fit-content;
  background-color: #000;
  border-width: 0px;
  border-style: solid;
  border-color: #ccc;
  top: 90px;
  right: 180px;
  & img {
    width: 400px;
    height: 560px;
  }

  @media screen and (max-width: 480px) {
    width: 85%;
    right: 20px;
    height: fit-content;
    & img {
      width: 100%;
      height: auto;
    }
  }
`;

const Wrapper = styled.div`
  /* max-width: 1140px; */

  width: 100%;
  height: 100%;

  /* display: flex;
  justify-content: center;
  text-align: center; */
`;

const Content = styled.div``;
