import { ErrorCatch } from "api/ErrorCatch.js";
import InputBox from "components/atomic/InputBox.js";
import TextArea from "components/atomic/TextArea.js";
import React, { memo, useContext, useEffect, useMemo, useState, useCallback } from "react";
import styled from "styled-components";
import { chatMiddle } from "../../../utils/chatUtil.js";
import { signAppContext } from "../../../utils/providers/SignContextProvider.js";
import NormalButton from "../../atomic/NormalButton2.js";
import { DASH_QNA } from "./dashboardStyle.js";
//import IconToTop from "../../../assets/images/icon_topArrow.svg";
import { PagingBtnComponent } from "./ChatHistory.js";
import { PaginationContainer } from "utils/Pagination.js";
import BoardItemCt from "../../templates/dashboard/BoardItemCt.js";
import ChatBotSelect from "../../../utils/ChatBotSelect.js";
import { chatContext } from "../../../utils/providers/ChatContextProvider.js";
import botApi from "../../../api/botApi.js";
import { isMobileContext } from "utils/providers/BrowserEnvContextProvider.js";
import { useHistory } from "react-router-dom";
import { AlertPop2 } from "components/templates/popup/AlertPop.js";

export default function ChatQNA() {
  const { handleSignOutApp } = useContext(signAppContext);
  const { chatBotData, setChatBotData } = useContext(chatContext);
  const externalOptions = useMemo(
    () => [
      {
        botUid: "general",
        value: "qna",
        label: "일반문의",
      },
    ],
    []
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [qnaList, setQnaList] = useState(
    Array(20)
      .fill(1)
      .map((_, idx) => ({
        idx,
        botUid: `test_${idx}`,
        subject: `subject_${idx}`,
        detail: `detail_${idx}`,
        answer: `answer_${idx}`,
      }))
  );
  const [listCount, setListCount] = useState(0);
  const [newSubject, setNewSubject] = useState("");
  const [newDetail, setNewDetail] = useState("");

  const history = useHistory();
  const [addArrayInClient, setAddArrayInClient] = useState([]);

  const [selectedOption, setSelectedOption] = useState(
    history?.location?.state?.type === "QNA" && history?.location?.state?.chatType === "general"
      ? externalOptions[0]
      : chatBotData
        ? {
            ...chatBotData,
            value: chatBotData?.botUid,
            label: chatBotData?.botName,
          }
        : externalOptions[0]
  );

  const asyncFuncArgs = useMemo(() => {
    if (selectedOption?.botUid) {
      return [selectedOption?.botUid];
    } else {
      return [];
    }
  }, [selectedOption]);

  const isAsync = true;

  const PagingBtn = memo((props) => {
    const { text, ...otherProps } = props ?? {};
    return <PagingBtnComponent {...otherProps}>{text}</PagingBtnComponent>;
  });

  const [toggleInput, setToggleInput] = useState(true);

  // popupState
  const [popState, setPopState] = useState(false);
  const [popMessage, setPopMessage] = useState("");

  const addQna = async () => {
    if (newSubject?.trim().length === 0) {
      // return alert("제목을 입력해 주세요.");
      setPopMessage("제목을 입력해 주세요.");
      setPopState((prev) => !prev);
      return;
    }

    if (newSubject?.trim().length > 200) {
      // return alert("제목을 입력해 주세요.");
      setPopMessage("제목란의 최대 글자 수는 200자 입니다.");
      setPopState((prev) => !prev);
      return;
    }

    if (newDetail?.trim().length === 0) {
      // return alert("내용을 입력해 주세요.");
      setPopMessage("내용을 입력해 주세요.");
      setPopState((prev) => !prev);
      return;
    }

    if (newDetail?.trim().length > 500) {
      // return alert("제목을 입력해 주세요.");
      setPopMessage("내용란의 최대 글자 수는 500자 입니다.");
      setPopState((prev) => !prev);
      return;
    }

    // api logic...
    const result = await chatMiddle({}, "addQna", selectedOption.botUid, newSubject, newDetail);

    if (isAsync) {
      if (result?.data?.qnaInfo) {
        const newData = JSON.parse(result?.data?.qnaInfo);
        setAddArrayInClient((prev) => [{ ...newData, idx: 0 }, ...prev]);
      } else {
        setAddArrayInClient((prev) => [{ subject: newSubject, detail: newDetail, answer: "", idx: 0 }, ...prev]);
      }
    }

    setNewSubject("");
    setNewDetail("");
    setToggleInput((prevState) => !prevState);
  };

  const { isMobile } = useContext(isMobileContext);

  const selectHandleChange = (option) => {
    setSelectedOption(option);
  };

  const getQnaListCountByBotUid = useCallback(async () => {
    if (!selectedOption) return;

    try {
      const { status, data } = await botApi.getQnaListCountByBotUid(selectedOption.botUid);
      if (status === 200) {
        const tmpData = JSON.parse(data.totalCount);
        setListCount(tmpData.totalCount);
      }
    } catch (err) {
      setListCount(0);
      ErrorCatch(err, handleSignOutApp);
    }
  }, [selectedOption]);

  useEffect(() => {
    if (history.location?.state) {
      const state = JSON.parse(JSON.stringify(history.location?.state));
      if (state?.type) {
        delete state.type;
      }

      if (state?.chatType) {
        delete state.chatType;
      }
      history.replace({ ...history.location, state });
    }
  }, []);

  // 기존 문의 내역이 있는지 먼저 체크 STEP1.
  useEffect(() => {
    if (!selectedOption) return;
    getQnaListCountByBotUid();
  }, [getQnaListCountByBotUid, selectedOption]);

  useEffect(() => {
    if (listCount > 0) {
      setToggleInput(false);
    } else {
      setToggleInput(true);
    }
  }, [listCount]);

  return (
    <DASH_QNA.Container>
      <DASH_QNA.ChildContainer
        style={
          !isMobile
            ? {
                width: "70%",
                padding: "20px",
              }
            : {
                overflowX: "hidden",
              }
        }
      >
        <Title>궁금한 점이 있으신가요?</Title>

        <Desc>아래 내용을 작성해 문의 글을 남겨주시면 {isMobile && <br />} 담당자가 빠르게 연락 드리겠습니다.</Desc>

        <ChatBotSelect
          externalHandleChange={selectHandleChange}
          externalOptions={externalOptions}
          setSelectedOption={setSelectedOption}
          initialOption={selectedOption}
        />

        <InputWrapper className={toggleInput ? "" : "hide"}>
          <InputContainer>
            <InputTitle>
              제목<span>*</span>
            </InputTitle>
            <InputBox value={newSubject} setValue={setNewSubject} />
          </InputContainer>
          <InputContainer>
            <InputTitle>
              내용<span>*</span>
            </InputTitle>
            <TextArea value={newDetail} setValue={setNewDetail} textClass={"qna"} />
          </InputContainer>
          {listCount > 0 && (
            <NormalButton
              style={{ marginRight: "10px" }}
              buttonTitle={"취소"}
              buttonClass={isMobile ? "cDel small" : "cDel mid"}
              callBackFunc={() => {
                setToggleInput(false);
              }}
            />
          )}

          <NormalButton
            buttonTitle={"등록"}
            buttonClass={isMobile ? "cOrange small" : "cOrange mid"}
            callBackFunc={addQna}
          />
        </InputWrapper>

        <BoardWrapper className={toggleInput ? "hide" : ""}>
          <BoardHeader>
            <BoardHeadTitle className="subject">제목</BoardHeadTitle>
            <BoardHeadTitle className="date">작성일</BoardHeadTitle>
            <BoardHeadTitle className="state">상태</BoardHeadTitle>
          </BoardHeader>
          {/* 테이블 */}
          <PaginationContainer
            data={qnaList}
            opt={{
              isAsync,
              asyncFuncName: "getQnaListByBotUid",
              asyncFuncArgs: asyncFuncArgs,
              addArrayInClient: addArrayInClient,
            }}
            pageLength={5}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            DataWrapComponent={BoardContainer}
            DataItemComponent={(props) => (
              <BoardItemCt
                chatbotData={selectedOption}
                setPopState={setPopState}
                setPopMessage={setPopMessage}
                {...props}
              />
            )}
            ItemComponent={PagingBtnComponent}
            itemActiveClassName={"current"}
            PrevComponent={(props) => <PagingBtn className={"prev"} text={"이전"} {...props} />}
            NextComponent={(props) => <PagingBtn className={"next"} text={"다음"} {...props} />}
            PaginationNumWrap={PagingCt}
          />

          <NormalButton
            buttonTitle={"문의하기"}
            style={{ float: "right" }}
            buttonClass={"cOrange mid"}
            callBackFunc={() => {
              setToggleInput(true);
            }}
          />
        </BoardWrapper>

        {popState && <AlertPop2 text={popMessage} popState={popState} setPopState={setPopState} />}
      </DASH_QNA.ChildContainer>
    </DASH_QNA.Container>
  );
}

const Title = styled.p`
  color: #363636;
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.2px;

  margin-bottom: 10px;

  @media (max-width: 480px) {
    text-align: center;
    margin: 20px 0 0px;
  }
`;

const Desc = styled.p`
  color: #363636;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.16px;
  margin-bottom: 30px;
  word-break: keep-all;
  @media (max-width: 480px) {
    text-align: center;
    margin: 10px 0 20px;
  }
`;

const InputWrapper = styled.div`
  margin-top: 20px;
  /* height: fit-content; */
  height: 40%;
  position: relative;

  transition: 0.5s;

  text-align: center;

  &.hide {
    margin-top: 0px;
    height: 0px;
    overflow: hidden;
    height: 0%;
  }

  & button.regist {
    font-size: 15px;
    line-height: 18px;
    padding: 9.5px 30.5px;
  }
`;

const InputContainer = styled.div`
  & input {
    border: 1px solid #dcdcdc;
    width: 100%;
  }

  margin-bottom: 23.5px;
`;

const InputTitle = styled.p`
  color: #676666;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.16px;
  margin-bottom: 23px;

  text-align: left;

  & span {
    color: #ff6161;
  }
`;

const BoardWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;

  &.hide {
    margin-top: 0px;
    margin-bottom: 0px;
    overflow: hidden;
    height: 0%;
  }

  & button.regist {
    font-size: 15px;
    line-height: 18px;
    padding: 9.5px 30.5px;
    float: right;
  }
`;

const BoardHeader = styled.div`
  display: flex;
  padding: 20px 10px;
  background: rgba(192, 192, 192, 0.1);

  @media (max-width: 480px) {
    padding: 10px 15px;
  }
`;

const BoardHeadTitle = styled.p`
  color: #555454;
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.12px;

  @media (min-width: 481px) {
    &.subject {
      width: 500px;
      min-width: 300px;
      margin-right: 100px;

      overflow: hidden;
      word-break: break-all;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &.date {
      width: 80px;
      min-width: 60px;
      margin-right: auto;
    }

    &.state {
      margin-right: 75px;
      flex: 0 0 auto;
    }
  }

  @media (max-width: 480px) {
    margin-right: 45px;
    font-size: 12px;
    flex-shrink: 0;
    &.subject {
      margin-right: 140px;
    }

    &.date {
    }
  }
`;

const BoardContainer = styled.div`
  border-top: 2px solid #b9b9b9;
  border-bottom: 2px solid #b9b9b9;
`;

const PagingCt = styled.div`
  width: 100%;
  text-align: center;

  margin-top: auto;
  margin-bottom: 30px;
`;
