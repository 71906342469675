import { useEffect } from "react";

const useOuterClick = (ref, callback) => {
  useEffect(() => {
    const onClick = (e) => {
      if (callback && ref.current && ref.current === e.target) {
        console.log(">>>>> OK callback() call!!!! ");
        callback();
      }
    };
    window.addEventListener("click", onClick);
    return () => {
      window.removeEventListener("click", onClick);
    };
  }, [ref, callback]);
};

export default useOuterClick;
