export class ThemeUtil {
  static chatTheme = [
    {
      theme: null,
      title: "기본 테마",
      desc: "메스와이저에서 제공하는 챗봇 기본 테마입니다",
      botText:
        "안녕하세요, 메스와이저(MESwiser) 입니다 궁금한 점이 있으시다면,  AI가 보다 풍부하게 답변 드릴 수 있게 조금 더 자세히 질문해 주시겠어요?",
      userText: "메스와이저를 도입할때 고려해야할 부분이 있나요?",
    },
    {
      theme: "big",
      title: "확장 테마",
      desc: "메스와이저에서 제공하는 챗봇 확장 테마입니다",
      botText:
        "안녕하세요, 메스와이저(MESwiser) 입니다 궁금한 점이 있으시다면,  AI가 보다 풍부하게 답변 드릴 수 있게 조금 더 자세히 질문해 주시겠어요?",
      userText: "메스와이저를 도입할때 고려해야할 부분이 있나요?",
    },
    {
      theme: "left",
      title: "왼쪽 테마",
      desc: "메스와이저에서 제공하는 챗봇 왼쪽 테마입니다",
      botText:
        "안녕하세요, 메스와이저(MESwiser) 입니다 궁금한 점이 있으시다면,  AI가 보다 풍부하게 답변 드릴 수 있게 조금 더 자세히 질문해 주시겠어요?",
      userText: "메스와이저를 도입할때 고려해야할 부분이 있나요?",
    },
  ];
}
