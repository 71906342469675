import { useState, useEffect, useContext } from "react";
import styled, { keyframes } from "styled-components";
import Logo_LiST from "../../assets/images/ListLogo.svg";
import alvis_logo from "../../assets/images/alvis_logo.svg";
import { isMobileContext } from "utils/providers/BrowserEnvContextProvider";
import PersonalPolicy from "components/pages/docs/PersonalPolicy";
import TermOfUsePolicy from "components/pages/docs/TermOfUsePolicy";
import insta from "../../assets/images/sns_insta.svg";
import facebook from "../../assets/images/sns_facebook.svg";
import FaqPage from "components/pages/docs/FaqPage";

export default function Footer() {
  const { isMobile } = useContext(isMobileContext);

  const [termsOfUse, setTermsOfUse] = useState(false);
  const [personalData, setPersonalData] = useState(false);
  const [faqState, setFaqState] = useState(false);

  return (
    <FooterBack>
      <FooterSection>
        {!isMobile ? (
          <FooterNav>
            {/*<button
              onClick={(e) => {
                window.open("http://li-st.com");
              }}
            >
              회사소개
            </button>*/}
            <button
              onClick={(e) => {
                setTermsOfUse((prev) => !prev);
              }}
            >
              서비스이용약관
            </button>
            <button
              onClick={(e) => {
                setPersonalData((prev) => !prev);
              }}
            >
              개인정보처리방침
            </button>
            <button
              onClick={(e) => {
                setFaqState((prev) => !prev);
              }}
            >
              자주 묻는 질문
            </button>

            <SnsButton
              className="sns insta first"
              icon={insta}
              onClick={(e) => {
                window.open("https://www.instagram.com/meswiser.ai/");
              }}
            />
            <SnsButton
              className="sns facebook"
              icon={facebook}
              onClick={(e) => {
                window.open("https://www.facebook.com/meswiser/");
              }}
            />
          </FooterNav>
        ) : (
          <FooterNav>
            <div className="snsBox">
              <SnsButton
                className="sns insta first"
                icon={insta}
                onClick={(e) => {
                  window.open("https://www.instagram.com/meswiser.ai/");
                }}
              />
              <SnsButton
                className="sns facebook"
                icon={facebook}
                onClick={(e) => {
                  window.open("https://www.facebook.com/meswiser/");
                }}
              />
            </div>
            <div className="policyBox">
              {/*<button
                onClick={(e) => {
                  window.open("http://li-st.com");
                }}
              >
                회사소개
              </button>*/}
              <button
                onClick={(e) => {
                  setTermsOfUse((prev) => !prev);
                }}
              >
                서비스이용약관
              </button>
              <button
                onClick={(e) => {
                  setPersonalData((prev) => !prev);
                }}
              >
                개인정보처리방침
              </button>
              <button
                onClick={(e) => {
                  setFaqState((prev) => !prev);
                }}
              >
                자주 묻는 질문
              </button>
            </div>
          </FooterNav>
        )}
        {!isMobile ? (
          <FooterInfoCt>
            <div className="divFlex">
              <FooterLogo src={alvis_logo} alt="LiST로고" />
            </div>

            <div className="divFlex">
              <FooterTextCt className="first">
                <FooterDefaultText>
                  <span>대표번호 : </span>
                  070-4140-7725
                </FooterDefaultText>
                <FooterDefaultText className="lineLeft">
                  <span>문의메일 : </span>
                  help@meswiser.ai
                </FooterDefaultText>
                <FooterDefaultText className="lineLeft">
                  <span>영업문의 : </span>
                  sales@meswiser.ai
                </FooterDefaultText>
                <FooterDefaultText className="lineLeft">
                  <span>대표이사 : </span>
                  오원석
                </FooterDefaultText>
              </FooterTextCt>

              <FooterTextCt className="second">
                <FooterDefaultText>
                  <span>본사 : </span>
                  서울특별시 강서구 화곡로 416
                </FooterDefaultText>
                <FooterDefaultText className="lineLeft">
                  <span>사업자등록번호 : </span>
                  107-87-85562
                </FooterDefaultText>
                <FooterDefaultText className="lineLeft">
                  <span>통신판매업신고 : </span>제 2022-서울강서-2195호
                </FooterDefaultText>
              </FooterTextCt>
            </div>
          </FooterInfoCt>
        ) : (
          <FooterInfoCt>
            <div
              style={{
                display: "flex",
                width: "100%",
                marginBottom: 30,
                padding: "0 5px",
              }}
            >
              <FooterLogo src={alvis_logo} alt="LiST로고" style={{ margin: "0px auto" }} />
            </div>

            <FooterTextCt className="mobile">
              <FooterDefaultText className="mobile">
                <span>대표이사 : </span>
                오원석
              </FooterDefaultText>
              <FooterDefaultText className="mobile">
                <span>본사 : </span>
                서울특별시 강서구 화곡로 416
              </FooterDefaultText>
              <FooterDefaultText className="mobile">
                <span>대표번호 : </span>
                070-4140-7725
              </FooterDefaultText>
              <FooterDefaultText className="mobile">
                <span>문의메일 : </span>
                help@meswiser.ai
              </FooterDefaultText>
              <FooterDefaultText className="mobile">
                <span>영업문의 : </span>
                sales@meswiser.ai
              </FooterDefaultText>
              <FooterDefaultText className="mobile">
                <span>사업자등록번호 : </span>
                107-87-85562
              </FooterDefaultText>
              <FooterDefaultText className="mobile">
                <span>통신판매업신고 : </span>제 2022-서울강서-2195호
              </FooterDefaultText>
            </FooterTextCt>
            {/* <FooterTextCt className="mobile"></FooterTextCt> */}
          </FooterInfoCt>
        )}
      </FooterSection>

      {personalData && <PersonalPolicy setPersonalData={setPersonalData} />}
      {termsOfUse && <TermOfUsePolicy setTermsOfUse={setTermsOfUse} />}
      {faqState && <FaqPage setFaqState={setFaqState} />}
    </FooterBack>
  );
}

const FooterBack = styled.div`
  background-color: #f7f9ff;
`;

const FooterSection = styled.div`
  max-width: 1000px;
  min-height: 200px;
  color: #484848;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media (max-width: 1000px) {
    padding: 0 30px 30px;
  }
  @media (max-width: 480px) {
    padding: 0 10px 20px;
  }
`;

const FooterNav = styled.div`
  display: flex;
  margin: 30px 0;

  @media (max-width: 480px) {
    margin: 20px 0;
    flex-direction: column;

    & .snsBox {
      text-align: right;
      margin-bottom: 20px;
    }

    & .policyBox {
      width: 100%;
      padding-bottom: 20px;
      display: flex;
      justify-content: space-between;
      //border-bottom: 1px solid #000;

      button {
        border-right: 1px solid #000;
        color: #000;

        &:last-child {
          border-right: none;
        }
      }
    }
  }

  button {
    width: 150px;
    font-size: 14px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.12px;
    margin-right: 50px;
    @media (max-width: 1000px) {
      letter-spacing: -1px;
    }
    @media (max-width: 480px) {
      flex: 1 1 auto;
      padding: 0;
      margin: 0 !important;
      width: unset;
    }

    &.first {
      margin-left: auto;
    }
  }
`;

const SnsButton = styled.button`
  margin-right: 7px !important;
  width: 35px !important;
  height: 35px;
  border-radius: 100%;
  background: url(${({ icon }) => icon}) no-repeat;
  background-position: center;
  background-size: 35px;
`;

const FooterInfoCt = styled.div`
  display: flex;
  align-items: center;

  & .divFlex {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media (min-width: 1000px) {
    margin-bottom: 5px;
  }

  @media (max-width: 1000px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

const FooterLogo = styled.img`
  width: 173px;
  margin-right: 18px;
  //margin-bottom: 10px;
`;

const FooterTextCt = styled.div`
  //margin-right: 25px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  &.mobile {
    flex-direction: column;
    margin-right: auto;
    padding-left: 5px;
  }
`;

const FooterDefaultText = styled.div`
  color: #484848;
  font-size: 14px;

  line-height: normal;
  letter-spacing: -0.14px;
  margin: 5px 0;
  padding-right: 10px;

  & span {
    font-weight: 700;
  }

  &.companyName {
    font-weight: 500;
    letter-spacing: 1px;
  }

  &.mobile {
    font-size: 12px;
    margin: 5px 0;
  }

  &.lineLeft {
    border-left: 1px solid #484848;
    padding-left: 10px;
    @media (max-width: 1000px) {
      border-left: none;
    }
  }
`;
