import { useState, useContext, useRef } from "react";
import styled from "styled-components";
import NormalButton from "components/atomic/NormalButton2";
import { saveAs } from "file-saver";

import contentWeb from "../../assets/images/content_web.png";
import contentMobile from "../../assets/images/content_mobile.png";
import AlvisLogoCol from "../../assets/images/alvis_logoCol.svg";

import ArrowRight from "../../assets/images/icon_arrowRight.svg";
import ArrowDown from "../../assets/images/icon_arrowDown.svg";
import ArrowLeft from "../../assets/images/icon_arrowLeft.svg";
import ArrowRightOrange from "../../assets/images/icon_arrowRightOrange.svg";

import ArrowLeftOrange from "../../assets/images/icon_arrowLeftOrange.svg";

import BotImg1 from "../../assets/images/botIllustration.svg";
import BotImg2 from "../../assets/images/botIllustration2.svg";
import BotImg3 from "../../assets/images/botIllustration3.svg";

import iconCheckOrange from "../../assets/images/icon_checkOrange.svg";
import iconCheckWhite from "../../assets/images/icon_checkWhite.svg";

import BackImg from "../../assets/images/mainSectionBackImg.svg";
import BackImgMobile from "../../assets/images/mainSectionBackImg_mobile.svg";
import { isMobileContext } from "utils/providers/BrowserEnvContextProvider";
import Advice from "components/pages/consult/Advice";
import { AlertPop2 } from "components/templates/popup/AlertPop";

import MainContentAdded from "./MainContentAdded";
import MainContentPrice from "./MainContentPrice";
import { useMediaQuery } from "react-responsive";
import MainContentPriceMobile from "./MainContentPriceMobile";

export default function MainContent({ contentRef }) {
  const [infoState, setInfoState] = useState("01");
  const [adviceToggle, setAdviceToggle] = useState(null);
  const { isMobile } = useContext(isMobileContext);

  const [popState, setPopState] = useState(false);
  const [popMessage, setPopMessage] = useState("");

  const webWidthCheck = useMediaQuery({ query: "(min-width: 1024px)" });

  const downloadIntroFile = () => {
    const fileUrl = "/introfile.pdf";
    const fileName = "MESwiser_Chatbot소개서.pdf";

    fetch(fileUrl)
      .then((response) => response.blob())
      .then((blob) => {
        saveAs(blob, fileName);
      })
      .catch((error) => {
        console.log("Error While donwloading the file:", error);
      });
  };

  return (
    <>
      {/* 컨텐츠 1번 */}
      <SectionCt className="type01" ref={contentRef}>
        <Section className="type01">
          {!isMobile ? <img src={contentWeb} alt="MESwiser"></img> : <img src={contentMobile} alt="MESwiser"></img>}
          <FelxColumnCt className="type01">
            <p className="Title">손쉽게, 무료로!</p>
            {!isMobile ? (
              <p className="Title2">GPT기반 인공지능 챗봇을 지금 바로 적용해 보세요</p>
            ) : (
              <p className="Title2">
                GPT기반 인공지능 챗봇을
                <br />
                지금 바로 적용해 보세요
              </p>
            )}
            {!isMobile ? (
              <p className="Desc">
                비즈니스에 필요한 챗봇, 필요하지만 도입 비용 때문에 망설이셨죠?
                <br />
                이제 MESwiser로 GPT기반 인공지능 챗봇을 무료로 경험해 보세요
              </p>
            ) : (
              <p className="Desc">
                비즈니스에 필요한 챗봇,
                <br />
                필요하지만 도입 비용 때문에 망설이셨죠?
                <br />
                이제 MESwiser로 GPT기반 인공지능 챗봇을
                <br />
                무료로 경험해 보세요
              </p>
            )}
            <NormalButton
              buttonClass={"big round cOrange"}
              buttonTitle={"상담 신청"}
              callBackFunc={(e) => {
                e.preventDefault();
                setAdviceToggle((prev) => !prev);
              }}
            ></NormalButton>
          </FelxColumnCt>
        </Section>
      </SectionCt>
      {/* 추가 콘텐츠 */}
      <MainContentAdded />
      {/* 컨텐츠 2번 */}
      <SectionCt className="type02">
        <Section className="type02">
          <FelxColumnCt>
            <Title className="title highlight">
              MESwiser를 <span className="orange">선택</span>해야 하는 이유!
            </Title>

            {/* web */}
            <Type2DataCt className="web">
              <DataTextRowCt>
                <DataTextCircle className="first">
                  타사
                  <br />
                  챗봇
                </DataTextCircle>
                <DataTextSquare className="center right" icon={ArrowRight}>
                  챗봇 제작
                  <br />
                  내용 협의
                </DataTextSquare>
                <DataTextSquare className="center right" icon={ArrowRight}>
                  프로젝트
                  <br />
                  계약
                </DataTextSquare>
                <DataTextSquare className="center right" icon={ArrowRight}>
                  데이터
                  <br />
                  자료 제공
                </DataTextSquare>
                <DataTextSquare className="center right" icon={ArrowRight}>
                  챗봇
                  <br />
                  기획
                </DataTextSquare>
                <DataTextSquare className="center right" icon={ArrowRight}>
                  기획 검수 /
                  <br />
                  완료
                </DataTextSquare>
                <DataTextSquare className="center dark">
                  챗봇 검수 /
                  <br />
                  런칭
                </DataTextSquare>
              </DataTextRowCt>
              <DataTextRowCt className="alvisFlow">
                <img src={AlvisLogoCol} alt="alvisLogo" />
                <DataTextSquare className="center right alvisFlow" icon={ArrowRightOrange}>
                  URL 입력 / <br /> 챗봇 생성
                </DataTextSquare>
                <DataTextSquare className="orange">챗봇 도입</DataTextSquare>
                <DataTextSquare className="center Dleft alvisFlow" icon={ArrowLeftOrange}>
                  지속적 기능 및<br />
                  학습 확대
                </DataTextSquare>
              </DataTextRowCt>
            </Type2DataCt>

            {/* mobile */}
            <Type2DataCt className="mobile">
              <DataTextRowCt className="mobile type02">
                <div>
                  <DataTextCircle className="first mobile">
                    타사
                    <br />
                    챗봇
                  </DataTextCircle>
                </div>
                <MobileContainer>
                  <div style={{ display: "flex" }}>
                    <DataTextSquare className="center right mobile" icon={ArrowRight}>
                      챗봇 제작
                      <br />
                      내용 협의
                    </DataTextSquare>
                    <DataTextSquare className="center right mobile" icon={ArrowRight}>
                      프로젝트
                      <br />
                      계약
                    </DataTextSquare>
                    <DataTextSquare className="center down mobile last" icon={ArrowDown}>
                      데이터
                      <br />
                      자료 제공
                    </DataTextSquare>
                  </div>

                  <div style={{ display: "flex" }}>
                    <DataTextSquare className="center dark mobile">
                      챗봇 검수 /
                      <br />
                      런칭
                    </DataTextSquare>
                    <DataTextSquare className="center left mobile" icon={ArrowLeft}>
                      기획 검수 /
                      <br />
                      완료
                    </DataTextSquare>

                    <DataTextSquare className="center left mobile last" icon={ArrowLeft}>
                      챗봇
                      <br />
                      기획
                    </DataTextSquare>
                  </div>
                </MobileContainer>
              </DataTextRowCt>
              <DataTextRowCt className="mobile type02">
                <img className="alvisImg" src={AlvisLogoCol} alt="MESwiserLogo" />
                <MobileContainer className="under">
                  <DataTextSquare className="center right mobile" icon={ArrowRightOrange}>
                    URL 입력 /
                    <br /> 챗봇 생성
                  </DataTextSquare>

                  <DataTextSquare className="orange mobile">챗봇 도입</DataTextSquare>

                  <DataTextSquare className="center Dleft mobile" icon={ArrowLeftOrange}>
                    지속적 기능
                    <br />및 학습 확대
                  </DataTextSquare>
                </MobileContainer>
              </DataTextRowCt>
            </Type2DataCt>
            {!isMobile ? (
              <Title className="type02">
                5분만에 생성되는 GPT기반 인공지능 챗봇 MESwiser,
                <br />
                이제 더 이상 챗봇 도입에 많은 시간과 비용을 쓰지 않아도 됩니다
              </Title>
            ) : (
              <Title className="type02">
                <span>
                  <span className="strong">5</span>분만에
                  <br /> 생성되는 GPT기반
                  <br /> 인공지능 챗봇 MESwiser,
                </span>
                <br />
                <span className="desc">
                  이제 더 이상 챗봇 도입에
                  <br />
                  많은 시간과 비용을
                  <br />
                  쓰지 않아도 됩니다
                </span>
              </Title>
            )}
          </FelxColumnCt>
        </Section>
      </SectionCt>
      {/* 컨텐츠 3번 */}
      <SectionCt backImg={!isMobile ? BackImg : BackImgMobile} className="type03">
        <Section className="type03">
          {!isMobile ? (
            <p className="title">성공적인 비즈니스, 지금 MESwiser와 함께 하세요!</p>
          ) : (
            <p className="title">
              성공적인 비즈니스,
              <br /> 지금 MESwiser와 함께 하세요!
            </p>
          )}

          <DataTextRowCt className="type03">
            <div
              onClick={(e) => {
                setInfoState("01");
              }}
              className={infoState === "01" ? "infoCt active" : "infoCt"}
            >
              <p className="title">
                기업 내 업무 환경 및<br />
                비용 절감에 기여
              </p>
              {!isMobile ? (
                <p className="desc">
                  한번에 여러 고객을
                  <br />
                  동시 응대 할 수 있어 기업 차원에서
                  <br />
                  인적 비용의 절감을 이룰 수 있습니다
                </p>
              ) : (
                <p className="desc">
                  한번에 여러 고객을 동시 응대 할 수
                  <br />
                  있어 기업 차원에서 인적 비용의 절감을
                  <br />
                  이룰 수 있습니다
                </p>
              )}
            </div>
            <div
              onClick={(e) => {
                setInfoState("02");
              }}
              className={infoState === "02" ? "infoCt active" : "infoCt"}
            >
              <p className="title">커스텀 확장 가능</p>
              {!isMobile ? (
                <p className="desc">
                  기본 기능은 물론 브랜드에서
                  <br />
                  필요한 기능을 추가로 접목해
                  <br />
                  커스텀 인공지능 챗봇을
                  <br />
                  구현할 수 있습니다
                </p>
              ) : (
                <p className="desc">
                  기본 기능은 물론 브랜드에서 필요한
                  <br />
                  기능을 추가로 접목해 커스텀
                  <br />
                  인공지능 챗봇을 구현할 수 있습니다
                </p>
              )}
            </div>
            <div
              onClick={(e) => {
                setInfoState("03");
              }}
              className={infoState === "03" ? "infoCt active" : "infoCt"}
            >
              <p className="title">데이터 확보 가능</p>
              <p className="desc">
                고객의 질문 데이터를 통해
                <br />
                비즈니스에서 활용할 수 있는
                <br />
                인사이트를 도출할 수 있습니다
              </p>
            </div>
          </DataTextRowCt>
        </Section>
      </SectionCt>
      {/* 컨텐츠 4번 */}
      <SectionCt className="type04">
        <Section className="type04">
          <Title className="type04">
            GPT기반 인공지능 챗봇이 {isMobile && <br />}
            <span className="orange">고객 만족 경험을 제공</span>
            합니다
          </Title>
          <DataTextRowCt className="type04">
            {!isMobile ? (
              <FelxColumnCt className="type04">
                <img src={BotImg1} alt="content" />
                <p className="title">
                  자동화된 <br />
                  GPT기반 인공지능 챗봇 <br />
                  실시간 대기모드
                </p>
                <p className="desc">
                  MESwiser가 있으면 우리 사이트에서
                  <br />
                  더이상 고객이 기다릴 필요가
                  <br />
                  없습니다. 고객이 원하는 시간에
                  <br />
                  실시간으로 고객과의
                  <br />
                  커뮤니케이션이 가능합니다.
                  <br />
                  뛰어난 응대 능력을 보유한
                  <br />
                  MESwiser를 만나보세요
                </p>
              </FelxColumnCt>
            ) : (
              <FelxColumnCt className="type04">
                <div className="flexbox">
                  <img src={BotImg1} alt="content" />
                  <p className="title">
                    자동화된 GPT기반
                    <br />
                    인공지능 챗봇 <br />
                    실시간 대기모드
                  </p>
                </div>
                <p className="desc">
                  MESwiser가 있으면 우리 사이트에서
                  <br />
                  더이상 고객이 기다릴 필요가 없습니다.
                  <br />
                  고객이 원하는 시간에 실시간으로 고객과의
                  <br />
                  커뮤니케이션이 가능합니다. 뛰어난 응대 능력을
                  <br />
                  보유한 MESwiser를 만나보세요
                </p>
              </FelxColumnCt>
            )}
            {!isMobile ? (
              <FelxColumnCt className="type04 down">
                <img src={BotImg2} alt="content" />
                <p className="title">
                  개인화된
                  <br />
                  맞춤 정보 제공
                </p>
                <p className="desc">
                  다양한 사용자의 요구사항을 대화형
                  <br />
                  인터페이스를 통해 보다 직관적으로
                  <br />
                  전달합니다. 특정 사용자가 원하는
                  <br />
                  정보를 GPT기반 인공지능이
                  <br />
                  정확히 파악해 빠르게 대응합니다
                </p>
              </FelxColumnCt>
            ) : (
              <FelxColumnCt className="type04 down">
                <div className="flexbox">
                  <img src={BotImg2} alt="content" />
                  <p className="title">
                    개인화된
                    <br />
                    맞춤 정보 제공
                  </p>
                </div>
                <p className="desc">
                  다양한 사용자의 요구사항을 대화형
                  <br />
                  인터페이스를 통해 보다 직관적으로
                  <br />
                  전달합니다. 특정 사용자가 원하는 정보를
                  <br />
                  GPT기반 인공지능이 정확히 파악해
                  <br />
                  빠르게 대응합니다
                </p>
              </FelxColumnCt>
            )}

            {!isMobile ? (
              <FelxColumnCt className="type04">
                <img src={BotImg3} alt="content" />
                <p className="title">
                  GPT기반 <br />
                  인공지능 기술
                </p>
                <p className="desc">
                  MESwiser는 최신 GPT 모델을 기반으로
                  <br />
                  광범위한 언어패턴과 스타일을 학습하고
                  <br />
                  기존과는 차원이 다른 정확한 응답을
                  <br />
                  생성합니다. 다른 모델에 비해
                  <br />
                  더 넓은 범위의 언어 패턴을 적용해
                  <br />
                  고객의 질문에 대해 정확하고
                  <br />
                  자연스러운 답변을
                  <br />
                  제공할 수 있습니다
                </p>
              </FelxColumnCt>
            ) : (
              <FelxColumnCt className="type04">
                <div className="flexbox">
                  <img src={BotImg3} alt="content" />
                  <p className="title">
                    GPT기반 <br />
                    인공지능 기술
                  </p>
                </div>
                <p className="desc">
                  MESwiser는 최신 GPT 모델을 기반으로
                  <br />
                  광범위한 언어패턴과 스타일을 학습하고
                  <br />
                  기존과는 차원이 다른 정확한 응답을 생성합니다.
                  <br />
                  다른 모델에 비해 더 넓은 범위의 언어 패턴을
                  <br />
                  적용해 고객의 질문에 대해 정확하고
                  <br />
                  자연스러운 답변을 제공할 수 있습니다
                </p>
              </FelxColumnCt>
            )}
          </DataTextRowCt>
        </Section>
      </SectionCt>
      {/* 컨텐츠 5번 */}
      {/*<SectionCt className="type05">
        <Section className="type05">
          <p className="line"></p>
          <p className="title">
            MESwiser와 함께하고 있는 {isMobile && <br />} 파트너사
          </p>
          <p className="desc">
            다양한 분야의 기업들이 {isMobile && <br />} MESwiser와 함께하고
            있습니다.
          </p>
          <DataTextRowCt className="type05">
            <img className="part" src={part01} alt="파트너사이미지" />
            <img className="part" src={part02} alt="파트너사이미지" />
            <img className="part" src={part03} alt="파트너사이미지" />
            <img className="part" src={part04} alt="파트너사이미지" />
          </DataTextRowCt>
        </Section>
      </SectionCt>*/}

      {/* 가격 정책 콘텐츠 */}
      {webWidthCheck ? <MainContentPrice /> : <MainContentPriceMobile />}

      {/* 컨텐츠 6번 */}
      {/* <SectionCt className="type06">
        <Section className="type06">
          <p className="title">
            지금 필요한 플랜을 비교해 보고
            <br />
            <span className="strong">최신 GPT가 탑재된 {isMobile && <br />} MESwiser를 경험해 보세요</span>
          </p>

          <div className="tabBtnCt">
            <button
              className={tabState === "01" ? "active" : ""}
              onClick={(e) => {
                setTabState("01");
              }}
            >
              무료 버전
            </button>
            <button
              className={tabState === "02" ? "active" : ""}
              onClick={(e) => {
                setTabState("02");
              }}
            >
              유료 버전
            </button>
          </div>
          <div className="tabContent">
            <div className={tabState === "01" ? "free contentCt active" : "free contentCt"}>
              <p className="header ">무료 버전</p>
              <p className="unit">[계정 단위]</p>
              <p className="desc">최대 5개의 챗봇 생성 가능</p>
              <p className="unit">[챗봇 단위]</p>
              <p className="desc">지식관리 기능을 통한 다수의 학습 지식 관리</p>
              <p className="desc">
                학습지식 용량 제한 <span>(1M)</span>
              </p>
              <p className="desc">
                하루 질의 사용 횟수 제한 <span>(50개 이내)</span>
              </p>
              <p className={isMobile ? "desc last hasSpan" : "desc  last"}>
                운영 홈페이지에 챗봇 적용 불가
                <span>(챗봇 관리 내부에서만 가능)</span>
              </p>
              <p className="descSpecial">※ 상담하기를 통해 유료버전 체험을 문의해 주세요.</p>
            </div>
            <div className={tabState === "02" ? "charge contentCt active" : "charge contentCt"}>
              <p className="header ">유료 버전</p>
              <p className="unit">[계정 단위]</p>
              <p className="desc">유료 버전 이외의 무료 버전 5개 생성 가능</p>
              <p className="unit">[챗봇 단위]</p>
              <p className="desc">지식관리 기능을 통한 다수의 학습 지식 관리</p>
              <p className={isMobile ? "desc hasSpan" : "desc "}>
                학습지식 용량 확대
                <span>(20M 이상 - 비용에 따라 변동)</span>
              </p>
              <p className={isMobile ? "desc hasSpan" : "desc "}>
                월 질의 사용 횟수 확대 <span> (1만개 이상 - 비용에 따라 변동)</span>
              </p>
              <p className="desc">운영 홈페이지에 챗봇 적용 지원</p>
              <p className="desc ">문장 단위의 응답 관리 지원</p>
              <p className="desc ">학습지식 관리 및 정제 지원</p>
              <p className="desc ">운영 지원</p>
              <p className="desc ">로그 관리 및 분석 지원</p>
            </div>
          </div>
        </Section>
      </SectionCt> */}

      {/* 컨텐츠 7번 */}
      <SectionCt className="type07">
        <Section className="type07">
          <div className="infoCt first">
            <p className="text">
              우리 회사에 딱 맞는
              <br />
              <strong>인공지능 챗봇이 필요하다면?</strong>
            </p>
            <button
              onClick={(e) => {
                e.preventDefault();
                setAdviceToggle((prev) => !prev);
              }}
            >
              문의하기
            </button>
          </div>

          <div className="infoCt last">
            <p className="text">
              MESwiser 서비스에 대해
              <br />
              <strong>더 궁금하다면?</strong>
            </p>
            <button
              onClick={(e) => {
                e.preventDefault();
                downloadIntroFile();
                // alert("소개서를 열심히 준비중입니다.");
              }}
            >
              소개서 받기
            </button>
          </div>
        </Section>
      </SectionCt>
      {adviceToggle && (
        <Advice setAdviceToggle={setAdviceToggle} setPopState={setPopState} setPopMessage={setPopMessage} />
      )}
      {popState && <AlertPop2 text={popMessage} popState={popState} setPopState={setPopState} />}
      {/* <Route path={`${match.path}Advice`} component={Advice} /> */}
    </>
  );
}

const SectionCt = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  @media (max-width: 1000px) {
    padding: 0 30px;
  }
  &.type01 {
    background: #fff;
  }
  &.type02 {
    background: #fff;
  }
  &.type03 {
    background: url(${({ backImg }) => backImg}) no-repeat;
    background-size: cover;
  }
  &.type04 {
    background: #fff;
  }
  &.type05 {
    background: #fff;
  }
  &.type06 {
    padding-top: 90px;
    background: #f7f9ff;
  }
  &.type07 {
    background: linear-gradient(0deg, #ffca36 0%, #ffca36 100%), #fff;

    @media (max-width: 480px) {
      padding: 0 20px;
    }
  }
`;

const Section = styled.section`
  max-width: 1200px;
  min-height: 742px;

  display: flex;
  align-items: center;
  justify-content: center;
  color: #0d0d0d;

  @media (max-width: 1000px) {
    flex: 0 1 auto;
    margin-top: 100px;
  }

  &.type01 {
    flex-wrap: wrap;
    min-height: auto;

    & img {
      //width: 407px;
      width: auto;
      height: 608px;
      margin-right: 70px;
      @media (max-width: 1000px) {
        margin-bottom: 100px;
      }
      @media (max-width: 900px) {
        margin: 0 auto;
      }
      @media (max-width: 480px) {
        width: 100%;
        height: auto;
        //padding: 0 5px;
      }
    }
    .Title {
      font-size: 40px;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.4px;
    }
    .Title2 {
      font-size: 25px;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.4px;
      margin-bottom: 16px;
      word-break: keep-all;
    }
    .Desc {
      color: #1a1a1a;
      font-size: 20px;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.18px;
      margin-bottom: 32px;
    }

    @media (max-width: 480px) {
      .Title {
        color: #1a1a1a;
        font-size: 26px;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.28px;
        text-align: center;
      }
      .Title2 {
        color: #1a1a1a;
        font-size: 18px;
        line-height: normal;
        letter-spacing: -0.28px;
        margin-bottom: 20px;
        text-align: center;
      }
      .Desc {
        color: #1a1a1a;
        font-size: 16px;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.16px;
        text-align: center;
      }
    }

    button {
      width: 232px;
      height: 56px;
      padding: 10px;
      border-radius: 50px;
      font-size: 30px;

      @media (max-width: 1000px) {
        flex: 1 0 auto;
        margin: 0 auto;
      }
      @media (max-width: 480px) {
        min-width: 280px;
        height: 56px;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
      }
    }
  }
  &.type02 {
    flex-wrap: wrap;
    img {
      width: 120px;
      margin: 0 36px;
    }
    .title {
      &.highlight {
        z-index: 0;
        position: relative;
        &::after {
          z-index: -1;
          content: "";
          width: 60%;
          height: 53%;
          position: absolute;
          background: rgba(255, 206, 70, 0.81);
          bottom: 0;
          left: 50%;
          transform: translate(-50%);
        }

        @media (max-width: 480px) {
          font-size: 24.5px;
          width: 105%;
          &::after {
            width: 100%;
          }
        }

        @media (max-width: 559px) and (min-width: 481px) {
          font-size: 30px;
          letter-spacing: -0.5px;
          &::after {
            width: 100%;
          }
        }
        @media (max-width: 629px) and (min-width: 560px) {
          font-size: 36px;
          letter-spacing: -0.5px;
          &::after {
            width: 100%;
          }
        }
        @media (max-width: 800px) and (min-width: 630px) {
          &::after {
            width: 90%;

            letter-spacing: -0.5px;
          }
        }

        @media (max-width: 1000px) and (min-width: 801px) {
          &::after {
            width: 80%;
            height: 53%;
          }
        }
      }
    }
    /* 추가 콘텐츠 */
    &.Added {
      flex-direction: column;
      & img.contentImg {
        width: 80%;
        margin-bottom: 50px;
      }
    }

    @media (max-width: 480px) {
      img.alvisImg {
        margin: 0 0 30px 0;
      }
      .title {
        color: #1a1a1a;
        font-size: 26px;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -1px;
        margin-bottom: 50px;
      }
    }
  }
  &.type03 {
    flex-wrap: wrap;
    flex-direction: column;
    min-height: fit-content;
    margin: 80px 0 120px;

    @media (max-width: 1000px) {
      align-items: center;
      justify-content: center;
      .title {
        text-align: center;
        word-break: break-word;
        width: 70%;
      }
    }

    @media (max-width: 480px) {
      margin: 50px 0 70px;
    }

    .title {
      color: #000;
      font-size: 40px;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.4px;
      margin-top: 50px;
      margin-bottom: 80px;

      @media (max-width: 480px) {
        font-size: 25px;
        margin-bottom: 50px;
        width: 100%;
        letter-spacing: -0.8px;
      }
      @media (max-width: 375px) {
        font-size: 24px;
        letter-spacing: -1px;
      }
    }

    .infoCt {
      transition: 0.2s;
      display: flex;
      width: 340px;
      height: 320px;
      padding: 35px 25px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 47px;

      margin: 0 15px 15px;
      background: #fff6cf;

      cursor: pointer;
      &:hover {
        opacity: 0.9;
      }
      &.active {
        background: #fff;
        box-shadow: 0px 5px 17px 0px rgba(0, 0, 0, 0.26);
      }

      .title {
        color: #1a1a1a;
        text-align: center;
        font-size: 30px;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.34px;
        word-break: keep-all;
        margin: 0 0 auto;

        min-height: 100px;

        display: flex;
        align-items: center;
        justify-content: center;
      }
      .desc {
        color: #1a1a1a;
        text-align: center;
        font-size: 20px;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.5px;

        min-height: 100px;
      }

      @media (max-width: 1000px) {
        margin-bottom: 30px;
        padding: 10px 20px;
        width: 300px;
        height: 320px;
        & .title {
          margin-top: 10px;
        }
      }

      @media (max-width: 480px) {
        width: 280px;
        height: 244px;
        padding: 20px 15px;

        .title {
          color: #1a1a1a;
          font-size: 22px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          letter-spacing: -0.26px;
        }
        .desc {
          font-size: 16px;
        }
      }
    }
  }
  &.type04 {
    min-height: 900px;
    //margin-top: 100px;
    margin: 100px 0;
    flex-wrap: wrap;
    @media (max-width: 1000px) {
      height: auto;
    }
    @media (max-width: 480px) {
      flex-direction: column;
    }
  }
  &.type05 {
    height: 600px;
    min-height: unset;
    padding-top: 60px;
    flex-direction: column;
    flex-wrap: wrap;

    @media (max-width: 480px) {
      padding-top: 10px;
      margin: 80px 0;
    }

    & p.line {
      width: 533px;
      height: 1px;
      background: #000;
      margin-bottom: 38px;
    }
    & p.title {
      color: #1a1a1a;
      text-align: center;
      font-size: 40px;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.4px;
      margin-bottom: 18px;
    }
    & p.desc {
      color: #969595;
      text-align: center;
      font-size: 30px;
      font-weight: 350;
      line-height: normal;
      letter-spacing: 0.75px;
      margin-bottom: 120px;
    }

    @media (max-width: 1000px) {
      height: auto;
      & p.line {
        width: 80%;
      }

      & p.title,
      & p.desc {
        word-break: keep-all;
      }
      & p.title {
        font-size: 36px;
        width: 70%;
      }

      & p.desc {
        font-size: 24px;
        width: 55%;
      }
    }
    @media (max-width: 480px) {
      & p.title {
        width: 100%;
        color: #232323;
        font-size: 28px;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.28px;
      }
      & p.desc {
        width: 100%;
        font-size: 20px;
        font-weight: 350;
        line-height: normal;
        letter-spacing: 0.5px;
        margin-bottom: 40px;
      }
    }
  }
  &.type06 {
    flex-direction: column;
    margin-bottom: 125px;
    flex-wrap: wrap;

    @media (max-width: 1000px) {
      margin-top: 0;
      min-height: unset;
    }

    .title {
      word-break: keep-all;
      color: #1a1a1a;
      text-align: center;
      font-size: 40px;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.4px;
      margin-bottom: 41px;
      .strong {
        font-weight: bold;
      }
      @media (max-width: 1000px) {
        width: 85vw;
        font-size: 34px;
      }
      @media (max-width: 480px) {
        font-size: 26px;
        letter-spacing: -2px;
        .strong {
        }
      }
    }
    .tabBtnCt {
      margin-bottom: 61px;
      /* width: 539px; */
      border-radius: 87px;
      background: #e7ebf9;
      display: flex;
      justify-content: space-between;
      @media (max-width: 1000px) {
        font-size: 32px;
        /* width: 40%; */
      }
      @media (max-width: 480px) {
        width: 335px;
        height: 79px;
        margin-bottom: 40px;
      }
      button {
        transition: 0.2s;
        font-size: 30px;

        line-height: normal;
        letter-spacing: -0.34px;
        margin: 9px 13px 10px;

        color: rgba(0, 0, 0, 0.4);
        padding: 8px 57px;
        border-radius: 54px;
        @media (max-width: 1000px) {
          padding: 8px 20px;
        }
        @media (max-width: 480px) {
          font-size: 26px;
          flex: 0 0 auto;
          margin: 9px;
          width: 149px;
          height: 61px;
        }
      }
      button.active {
        padding: 8px 57px;
        color: #1a1a1a;
        border-radius: 54px;
        background: #fff;
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
        font-weight: 700;
        @media (max-width: 1000px) {
          padding: 8px 20px;
        }
        @media (max-width: 480px) {
        }
      }
    }
    .tabContent {
      display: flex;
      .contentCt {
        transition: 0.2s;
        width: 463px;
        /* height: 530px; */
        margin: 0 15.5px;

        position: relative;

        border-radius: 20px;
        background: #e7ebf9;

        .header {
          border-bottom: 1px solid #fff;
        }

        &.active {
          border-radius: 20px;
          border: 1px solid #6d96ff;
          background: #fff;
          box-shadow: 0px 5px 17px 0px rgba(0, 0, 0, 0.26);
          .header {
            border-bottom: 1px solid #6d96ff;
            position: relative;
            &::after {
              position: absolute;
              content: "";
              width: 24px;
              height: 24px;
              top: 50%;
              left: 32%;
              transform: translate(0, -50%);
              border-radius: 100%;
              background: url(${iconCheckWhite}) no-repeat;
              background-position: center;
              background-size: 12px;
              background-color: #ff892a;
            }
          }
        }

        .header {
          height: 80px;
          color: #1a1a1a;
          font-size: 25px;
          font-weight: 700;
          line-height: normal;
          letter-spacing: -0.24px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .unit {
          margin: 20px 0px 0px 40px;
          color: #1a1a1a;
          font-size: 18px;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -1px;
          height: 20px;
          display: flex;
          align-items: center;
        }
        .desc.hasSpan {
          flex-direction: column;
          align-items: start;
          @media (max-width: 480px) {
            position: relative;
          }
        }
        .desc {
          margin: 6px 45px 6px 70px;
          color: #1a1a1a;
          font-size: 18px;
          font-weight: 400;
          line-height: normal;
          letter-spacing: -1px;
          height: 40px;
          display: flex;
          align-items: center;

          position: relative;
          &.last {
            letter-spacing: -2px;
          }

          &.letterSpacing {
            letter-spacing: -0.5px;
          }

          @media (max-width: 480px) {
            margin-left: 45px;
            margin-right: 15px;
          }

          & span {
            font-size: 13px;
          }
          &::after {
            position: absolute;
            background: url(${iconCheckOrange}) no-repeat;
            background-position: center;
            background-size: contain;
            content: "";
            width: 24px;
            height: 24px;
            top: 50%;
            left: -44px;
            transform: translate(50%, -50%);
          }

          &.lineTwo {
            line-height: inherit;
          }

          &.last {
            margin-bottom: 35px;
          }
        }
        .descSpecial {
          position: absolute;
          bottom: 15px;
          font-size: 16px;
          color: #ff893a;
          width: 100%;
          text-align: center;
        }

        @media (max-width: 1000px) {
          display: none;
          &.active {
            display: block;
          }
        }
      }
      @media (max-width: 480px) {
        .contentCt {
          width: 335px;

          &.free {
            /* height: 260px; */
            & p.desc.last {
              margin-bottom: 35px;
              letter-spacing: -2px;
            }
            & .descSpecial {
              bottom: 10px;
              font-size: 14px;
            }
          }
        }
        .header {
          font-size: 24px !important;
          padding-left: 32px;
        }
        .desc {
          font-size: 15px !important;
          word-break: keep-all;
          letter-spacing: -1px !important;
        }
      }
    }
  }
  &.type07 {
    min-height: 245px;
    text-align: center;
    background: #ffca36;
    display: flex;

    @media (max-width: 1000px) {
      width: 100%;
      height: auto;
      min-height: 512px;
      margin-top: 0;
      flex-direction: column;
      justify-content: space-around;
    }

    @media (max-width: 480px) {
      justify-content: space-around;
    }

    @media (max-width: 1100px) and (min-width: 1000px) {
      flex-wrap: wrap;
    }

    & .infoCt {
      display: flex;
      align-items: center;
      justify-content: space-between;

      margin-bottom: 0 !important;

      height: 150px;
      width: 540px;

      @media (max-width: 1090px) {
        &.first {
          padding-right: 0 !important;
        }
      }
      @media (min-width: 481px) and (max-width: 1000px) {
        &.first {
          padding-right: 20px !important;
        }
      }

      @media (max-width: 1080px) and (min-width: 1000px) {
        &.first {
          border-bottom: 1px solid #000;
          border-right: none !important;
          padding-bottom: 25px;
          margin-bottom: 30px;
          position: relative;
        }

        &.last {
          padding-left: 0px !important;
          margin-bottom: 30px;
        }
      }
      @media (max-width: 1000px) {
        width: 100%;
        height: 200px;
        flex-direction: column;
        padding: 0 20px;
        & .text {
          margin-bottom: 32px;
          margin-left: 20px;
          width: 100%;
        }
        & button {
          align-self: end;
        }
      }

      @media (max-width: 480px) {
        padding: 0;
      }

      & .text {
        color: #1a1a1a;
        font-size: 24px;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.24px;
        text-align: left;
        margin-right: 10px;
        strong {
          font-weight: bold;
        }

        @media (max-width: 480px) {
          font-size: 22px;
        }
      }
      &.first {
        border-right: 1px solid #000;
        padding-right: 25px;
        margin-bottom: 30px;
        position: relative;

        @media (max-width: 1000px) {
          border-right: none;
          &::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 1px;
            background: #000;
            bottom: -30px;
            left: 50%;
            transform: translate(-50%, 0);
          }
        }
      }
      &.last {
        padding-left: 25px;
        margin-bottom: 30px;
      }
      &.first,
      &.last {
        @media (max-width: 1000px) {
          justify-content: center;
        }

        @media (max-width: 480px) {
          padding: 0;

          button {
            width: 220px;
            height: 60px;
            font-size: 22px;
          }
        }
      }
      button {
        transition: 0.2s;
        color: #1a1a1a;
        text-align: center;
        font-size: 25px;
        font-weight: 700;
        line-height: normal;
        border-radius: 52px;
        background: #fff;
        letter-spacing: -0.22px;
        width: 200px;
        height: 60px;
        padding: 10px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex-shrink: 0;
        &:hover {
          background: #fda055;
          color: #ffffff;
        }
      }
    }
  }
`;

const FelxColumnCt = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;

  &.type01 {
    margin-top: 20px;
  }

  &.type04 {
    width: 325px;

    padding: 17.5px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 20px;
    @media (max-width: 1000px) {
      margin-bottom: 30px;
    }

    @media (max-width: 480px) {
      padding: 10px 0;

      & .flexbox {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;

        & img {
          width: 45%;
        }

        & .title {
          margin-left: 40px;
        }
      }
    }

    &.down {
      transform: translateY(30px);
      @media (max-width: 1000px) {
        transform: translateY(0);
      }
    }

    & img {
      width: 170px;
      height: 170px;
      margin-bottom: 25px;
    }
    & .title {
      color: #1a1a1a;
      text-align: center;
      font-size: 25px;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.24px;
      margin-bottom: 37px;

      @media (max-width: 480px) {
        font-size: 16px;
        text-align: left;
      }
    }

    & .desc {
      color: #6a6a6a;
      text-align: center;
      font-size: 20px;
      font-weight: 350;
      line-height: 160%;
      letter-spacing: -0.01em;
      word-break: keep-all;

      @media (max-width: 480px) {
        font-size: 14px;
      }
    }
  }
`;

const DataTextRowCt = styled.div`
  margin-bottom: 82px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1000px) {
    flex-wrap: wrap;
  }
  @media (max-width: 480px) {
    &.type02.mobile {
      margin-bottom: 0;
    }
  }

  &.alvisFlow {
    justify-content: flex-start;
  }

  &.type03 {
    flex-wrap: wrap;
    margin-bottom: 0;
  }

  &.type04 {
    margin-bottom: 0;

    @media (max-width: 1100px) and (min-width: 1000px) {
      flex-wrap: wrap;
    }
  }

  &.type05 {
    width: 1000px;
    text-align: center;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 480px) {
      width: unset;
      margin-bottom: 0;
      padding: 0;

      & img.part {
        margin: 10px;
        height: 25px;
      }
    }
    & img.part {
      margin: 15px;
    }
  }
  & img.part {
    margin-right: 94px;
    height: 35px;
  }

  &.mobile {
    flex-direction: column;
    align-items: center;
  }
`;

const DataTextSquareCt = styled.div`
  display: flex;
  & img.middleImg {
    margin: 30px 0;
  }
  @media (max-width: 950px) {
    flex-wrap: wrap;
    justify-content: center;
  }
  @media (min-width: 700px) {
    &.onlyMobile {
      display: none;
    }
  }
  @media (max-width: 480px) {
    margin: 20px 0;
  }
`;

const DataTextCircle = styled.p`
  padding: 10px;

  color: #fff;
  font-size: 20px;
  font-weight: 400;

  width: 72px;
  height: 72px;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  background: #656565;
  position: relative;
  z-index: 0;

  &::after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 100px;
    background: rgba(101, 101, 101, 0.2);
    position: absolute;
    scale: 1.3;
    top: 0;
    left: 0;
  }

  &.mobile {
    width: 56px;
    height: 56px;
    margin: 15px 0 !important;
    font-size: 14px;
    line-height: 18.38px;
  }
  &.first {
    margin: 0 60px;
  }
`;
const DataTextSquare = styled.p`
  display: flex;
  width: 120px;
  height: 80px;
  padding: 10px;
  justify-content: center;
  align-items: center;

  color: #000;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.18px;

  margin-right: 29px;
  border-radius: 10px;
  background: #f2f2f2;
  position: relative;

  @media (max-width: 1100px) and (min-width: 1000px) {
    width: 110px;
    height: 80px;
    padding: 10px 5px;

    font-size: 18px;
    letter-spacing: -0.5px;

    margin-right: 25px;
  }

  &.anima {
    opacity: 0;
    animation: none;
    ${(props) => (props.animate ? "animation: fadeInAndOut 5s linear infinite;" : "none")};
    animation-delay: ${(props) => props.delay}s;

    @keyframes fadeInAndOut {
      0%,
      100% {
        opacity: 0;
      }
      10%,
      90% {
        opacity: 1;
      }
    }
  }

  &:last-child {
    margin-right: 0;
  }
  &.mobile {
    margin-bottom: 50px;
    font-size: 14px;
    flex: 0 0 auto;
    padding: 10px;

    letter-spacing: -0.14px;

    @media (max-width: 480px) {
      width: 92px;
      height: 58px;
      color: #000;
      font-size: 14px;
      font-weight: 400;
      line-height: 142.8%;
      letter-spacing: -0.14px;
      &.orange {
        width: 92px;
        height: 58px;
        line-height: 142.8%;
        letter-spacing: -0.14px;
        margin-right: 0;
      }
    }
  }
  &.center {
    text-align: center;
  }
  &.right {
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      right: -22px;
      transform: translateY(-50%);
      width: 14px;
      height: 15.288px;
      background: url(${({ icon }) => icon}) no-repeat;
      background-size: contain;
      background-position: center;
    }
  }
  &.down {
    &::after {
      content: "";
      position: absolute;
      left: 50%;
      bottom: -35px;
      transform: translateX(-50%);
      width: 14px;
      height: 15.288px;
      background: url(${({ icon }) => icon}) no-repeat;
      background-size: contain;
      background-position: center;
      &.orange {
      }
    }
  }
  &.left {
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: -22px;
      transform: translateY(-50%);
      width: 14px;
      height: 15.288px;
      background: url(${({ icon }) => icon}) no-repeat;
      background-size: contain;
      background-position: center;
      &.orange {
      }
    }
  }
  &.Dleft {
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: -22px;
      transform: translateY(-50%);
      width: 14px;
      height: 15.288px;
      background: url(${({ icon }) => icon}) no-repeat;
      background-size: contain;
      background-position: center;
      &.orange {
      }
    }
    background-color: #ffe49e;
  }

  &.orange {
    color: #fff;
    font-weight: 700;

    width: 226px;
    height: 80px;
    padding: 10px;

    align-items: center;
    background: #ff892a;
  }
  &.dark {
    color: #fff;
    background: #656565;
  }
  &.alvisFlow {
    width: 146px;
  }
`;

const DataTextSquareAsstType = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  padding: 60px 20px;
  width: 300px;
  height: 380px;
  border-radius: 40px;

  color: #000;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.18px;

  background: #fff;
  margin: 0 10.5px;

  position: relative;
  transition: 0.2s;
  border: 1px solid transparent;

  &.added {
    justify-content: center !important;
    border: 1px solid #ff892a;
    & img {
      width: 215px;
      height: 22px;
      margin: 10px 0;
    }
    & p {
      text-align: center;
      font-size: 16px;
      font-weight: 300 !important;
      line-height: 24px;
      margin: 5px 0 10px !important;
      .strong {
        font-weight: 700;
      }
    }
  }

  &:hover {
    cursor: pointer;
    opacity: 0.7;
    transform: translateY(-5%);
  }
  &.active {
    border: 1px solid #ff892a;
    box-shadow: 0px 1px 23px 0px rgba(0, 0, 0, 0.42);
    transform: translateY(-5%);
    @media (max-width: 480px) {
      transform: translateY(-2%);
    }

    p.desc {
      color: #222;
    }
  }

  & img {
    width: 60px;
    height: 60px;
    margin-bottom: 20px;
  }
  p.title {
    color: #333;
    font-size: 22px;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 40px;
    word-break: keep-all;
    letter-spacing: -1px;
    text-align: center;
  }
  p.desc {
    color: #999;
    font-size: 16px;
    font-weight: 300;
    line-height: normal;
    word-break: keep-all;
    text-align: center;
  }

  @media (max-width: 950px) {
    margin: 10px;
    padding: 20px;
    height: auto;
    width: 30%;

    p.title {
      font-size: 22px;
      letter-spacing: -1px;
      margin-bottom: 10px;
    }
    p.desc {
      font-size: 16px;
      letter-spacing: -1px;
    }
  }

  @media (max-width: 700px) {
    margin: 10px 5px;
    height: auto;

    flex: 1 0 100%;
    align-items: center;

    &.type03 {
      box-shadow: 0px 1px 23px 0px rgba(0, 0, 0, 0.42);
      flex: 0 1 45%;
      padding: 15px 10px;
      height: 50%;

      background: rgb(255, 246, 215);
      justify-content: flex-start;

      &.active {
        background: #fff;
        p.desc {
          color: #111;
          font-weight: 400;
        }
      }
      p.title {
        height: 40px;
        margin-top: 10px;
        margin-bottom: 5px;
        text-align: center;
      }
      p.desc {
        word-break: break-word;
      }
    }
    img {
      margin-bottom: 10px;
    }

    p.title {
      font-size: 18px;
      letter-spacing: -1px;
      margin-bottom: 25px;
      line-height: 20px;
    }
    p.desc {
      font-size: 14px;
      line-height: 17px;
      letter-spacing: -1px;
      padding: 10px;

      word-break: keep-all;
    }
  }
`;

const Title = styled.p`
  font-size: 40px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.4px;
  text-align: center;
  margin-bottom: 79px;
  word-break: keep-all;

  &.hasImg {
    & img {
      width: 240px;
    }
    margin: 30px 0px;
    @media (max-width: 480px) {
      margin-bottom: 0;
    }
  }

  &.type02 {
    letter-spacing: -0.4px;
    font-size: 30px;
    line-height: 45px;

    &.Added {
      /* margin-top: 50px; */
      color: #333;
      text-align: center;
      font-size: 26px;
      font-weight: 300;
      .strong {
        font-weight: 700;
      }

      &.orange {
        color: #ff892a;
        font-size: 16px;
        font-weight: 500;
        line-height: normal;
      }

      &.Desc {
        color: #333;
        text-align: center;
        font-weight: 300;
        font-size: 32px;
        line-height: 38px;
        & span {
          font-weight: 700;
        }
        @media (max-width: 480px) {
          font-size: 18px !important;
          line-height: 24px;
        }
      }
    }

    @media (max-width: 480px) {
      font-size: 24px;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.28px;

      .strong {
        font-size: 40px;
      }
      .desc {
        display: inline-block;
        margin-top: 8px;
        font-size: 26px;
        font-weight: 400;
        letter-spacing: -1.5px;
      }
    }
  }
  &.type04 {
    @media (max-width: 480px) {
      font-size: 26px;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.28px;
      margin-bottom: 30px;
    }
  }
  & span.orange {
    color: #ff892a;
  }
`;

const Type2DataCt = styled.div`
  &.web {
    @media (max-width: 1000px) {
      display: none;
    }
    @media (min-width: 1000px) {
      display: block;
    }
  }

  &.mobile {
    @media (max-width: 1000px) {
      display: block;
    }
    @media (min-width: 1000px) {
      display: none;
    }
  }
`;

const MobileContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 70vw;
  padding: 20px;

  &.under {
    width: auto !important;
  }

  @media (max-width: 480px) {
    &.under {
      flex-wrap: nowrap;
      width: 100%;
      padding: 0 !important;
      & p:not(:last-child) {
        margin-right: 25px;
      }
      & p:last-child {
        margin-right: 0 !important;
      }
    }
  }
`;

const ChartWrap = styled.div`
  display: flex;

  align-items: streth;
  justify-content: space-between;
  width: 100%;
  margin: 20px 0 20px;

  &.noneBtmMargin {
    margin: 20px 0 0px;
  }
`;

const ChartDivedCt = styled(ChartWrap)`
  flex: 0 0 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ChartDivedTitle = styled.div`
  color: #1a1a1a;
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  text-align: center;
  margin-bottom: auto;
`;

const ChartLeftPart = styled.div`
  position: relative;
  margin-top: 15px;
  & p.item {
    padding: 10px;
    text-align: center;
    color: #333;
    font-size: 14px;
    font-weight: 300;
    line-height: 21px;
    margin: 12px 0;
    letter-spacing: -1px;
    border-radius: 100px;
    background: #e7e8ea;

    &.color {
      font-weight: 600;
      background: #ffedca;
    }
  }

  & div.orangePoint {
    border: 1.5px dashed #ff892a;
    position: relative;
    &.hasArrow {
      img {
        position: absolute;
        content: "";
        width: 25px;
        height: 15px;
        background: url(${({ icon }) => icon}) no-repeat;
        background-position: center;
        background-size: cover;
        top: 50%;
        right: -32px;
        transform: translateY(-50%);
      }
    }
  }
`;
const ChartRightPart = styled(ChartLeftPart)`
  & div.orangePoint {
    border: 1.5px dashed #ff892a;
    position: relative;
    &.hasArrow {
      img {
        position: absolute;
        content: "";
        width: 25px;
        height: 15px;
        background: url(${({ icon }) => icon}) no-repeat;
        background-position: center;
        background-size: cover;
        top: 50%;
        left: -32px;
        transform: translateY(-50%);
      }
    }
  }
`;
