import { useEffect, useState, memo, useCallback, useMemo } from "react";
import styled from "styled-components";
import {
  Box,
  FlexAll_C_Box,
  FlexBox,
} from "../components/pages/dashboard/dashboardStyle.js";
import { chatMiddle } from "./chatUtil.js";

/**
 * @function
 * Pagination Container로, 데이터 영역과, 아래 페이지 번호까지 공통으로 사용할 수 있도록 만든 컴포넌트
 *
 * @property
 * - props
 *   - opt: Object; -> isAsync 와 같이 조건부 변수 담는 곳
 *     - isAsync: boolean; -> 데이터 가져오는 방식을 직접 넣을지 / 비동기로 할지 설정. ( true 일 경우 비동기로 서버에서 가져오도록 ) ( 필수 )
 *     - setRecordCount: number; -> 페이지 컴포넌트를 부르는곳에 실제 쿼리 된 총 개수를 주고 싶은 경우 사용 .
 *   - data: Array<anything>; -> 페이지네이션에서 사용할 데이터를 배열 형식으로 전달. isAsync 옵션이 true일 경우 안 넣어도 됨. ( isAsunc가 false일 경우 필수 )
 *   - botUid: string; -> 선택된 봇의 uid ( 필수 아님 )
 *   - pageLength: number; -> 한 페이지당 보여줄 데이터의 개수 ( 필수 )
 *   - pageCount: number -> 한 페이지당 보여줄 페이지 카운트의 개수 ( 필수 아님 / 디폴트 값 3 )
 *   - currentPage: number; -> 현재 페이지 번호 ( 필수 )
 *   - setCurrentPage: Function; -> 현재 페이지 번호 설정 ( 필수 )
 *   - ItemComponent: Component; -> 개발자가 임의로 입힌 숫자 버튼의 컴포넌트 ( 필수 아님 )
 *   - PrevComponent: Component; -> 개발자가 임의로 입힌 이전 버튼의 컴포넌트 ( 필수 아님 )
 *   - NextComponent: Component; -> 개발자가 임의로 입힌 다음 버튼의 컴포넌트 ( 필수 아님 )
 *   - DataWrapComponent: Component; -> 개발자가 임의로 입힌 데이터 영역 wrapper ( 필수 아님 )
 *   - DataItemComponent: Component; -> 개발자가 임의로 입힌 데이터 영역 wrapper 안의 item ( 필수 아님 )
 *   - reLoad: boolean ; -> 상위에서 강제로 데이터를 갱신 하고 싶을때 사용 true, false에 의해 동작이 아닌 state변경을 위한 신호임. ( 필수 아님 )
 * */
export const PaginationContainer = memo((props) => {
  const {
    opt,
    data,
    pageLength,
    pageCount,
    currentPage,
    setCurrentPage,
    dataNullMessage,
    PaginationNumWrap,
    ItemComponent,
    PrevComponent,
    NextComponent,
    DataWrapComponent,
    DataItemComponent,
    itemActiveClassName,
    reLoad,
  } = props ?? {};
  const {
    isAsync,
    asyncFuncName,
    asyncFuncArgs,
    addArrayInClient,
    setRecordCount,
  } = opt ?? {};
  const [dataTotalLength, setDataTotalLength] = useState(data?.length ?? 0);
  // const [pageTotalCnt] = useState(Math.ceil(dataTotalLength / pageLength));
  const [viewData, setViewData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const setFilderDataByNoAsync = useCallback(() => {
    if (!isAsync && data.length > 0) {
      console.log(">>>>>>>>>>>>>>>>> 아 이거 나오면 나가린데...");
      setViewData(
        data?.filter(
          ({ idx }) =>
            currentPage * pageLength > idx &&
            (currentPage - 1) * pageLength <= idx
        )
      );
    }
  }, [isAsync, data, currentPage, pageLength]);

  const refetch = async () => {
    await setFilderDataByAsync();
  };

  const removeItem = (compareObjKey, targetValue) => {
    const idx = viewData.findIndex((d) => d[compareObjKey] === targetValue);
    if (idx !== -1) {
      setViewData((prev) => {
        prev.splice(idx, 1);
        return prev;
      });
      setDataTotalLength((prev) => prev - 1);
    }
  };

  const setFilderDataByAsync = async () => {
    if (isAsync) {
      setIsLoading(true);
      try {
        const { data } = await chatMiddle(
          { isAlert: false },
          asyncFuncName,
          currentPage,
          pageLength,
          ...asyncFuncArgs
        );

        console.log(JSON.parse(data?.items ?? "[]"));

        setViewData(JSON.parse(data?.items ?? "[]")); // 2023.07.13.codelua 페이징 처리를 공통 컴포넌트를 사용하기 위해 API서버쪽에서 반드시 동일명칭 사용 약속.
        setDataTotalLength(
          JSON.parse(data?.totalCount ?? '{"totalCount":0}').totalCount
        );
      } catch (err) {
        console.log("err", err);
        setViewData([]);
        setDataTotalLength(0);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    setRecordCount && setRecordCount(dataTotalLength);
  }, [dataTotalLength, setRecordCount]);

  useEffect(() => {
    if (!asyncFuncArgs) return;
    if (isAsync) {
      setFilderDataByAsync();
    } else {
      setFilderDataByNoAsync();
    }
  }, [asyncFuncArgs, currentPage, reLoad]);

  useEffect(() => {
    if (!isAsync) {
      setFilderDataByNoAsync(); // <<< 이거때문에 어쨋든 setFilderDataByNoAsync는  2번 타겠네 위에서 if / else 로 나눴으니깐
      setDataTotalLength(data?.length ?? 0);
    }
  }, [data]);

  useEffect(() => {
    if (addArrayInClient.length > 0) {
      setViewData((prev) =>
        [addArrayInClient[0], ...prev].filter((d, idx) => idx <= 4)
      );

      setDataTotalLength((prev) => prev + 1);
    }
  }, [addArrayInClient]);

  const Component = useMemo(
    () => ({
      DataWrap: DataWrapComponent ?? CustomDataWrapComponent,
      DataItem: DataItemComponent ?? CustomDataItem,
    }),
    []
  );

  if (viewData.length === 0 || !viewData) {
    return (
      <Component.DataWrap>
        <p className="empty">{dataNullMessage ?? "데이터가 없습니다."}</p>
      </Component.DataWrap>
    );
  }

  return (
    <>
      {/* TotalTextCt 있을 경우 그려주기*/}
      <Component.DataWrap>
        {viewData?.map((itemData, index) => {
          return (
            <Component.DataItem
              data={itemData}
              refetch={refetch}
              removeItem={removeItem}
              key={"pagination_" + index}
              keyName={"pagination_" + index}
            />
          );
        })}
      </Component.DataWrap>
      {viewData.length > 0 && (
        <Pagination
          pageLength={pageLength}
          pageCount={pageCount ?? 3}
          dataTotalLength={dataTotalLength}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          ItemComponent={ItemComponent}
          itemActiveClassName={itemActiveClassName}
          PrevComponent={PrevComponent}
          NextComponent={NextComponent}
          PaginationNumWrap={PaginationNumWrap}
        />
      )}
    </>
  );
});

const CustomDataWrapComponent = styled.ul`
  max-width: 450px;
  width: 100%;
`;

const CustomDataItem = memo((props) => {
  const { data, keyName, ...otherProps } = props ?? {};
  return (
    <CustomDataItemComponent key={keyName} {...otherProps}>
      <p>제목: {data.title}</p>
      <p>내용: {data.content}</p>
      <p>답변: {data.receive}</p>
    </CustomDataItemComponent>
  );
});

const CustomDataItemComponent = styled.li`
  width: 100%;
  margin: 10px 0;
  padding: 10px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);

  p {
    margin: 3px 0;
    word-break: break-all;
    white-space: break-spaces;
  }
`;

/**
 * @function
 * - 한 페이지당 보여줄 개수, 전체 데이터 게수에 따라 숫자 버튼이 생성
 * - 이전 / 다음 버튼을 누르면 선택된 숫자 버튼에서 이전 / 다음 숫자 버튼으로 이동
 *   - ( 처음 버튼에서 이전 버튼 클릭 시, 마지막 버튼으로 이동 / 반대 상황도 마찬가지 )
 *
 * @property
 * - props
 *   - pageLength: number; -> 한 페이지에 최대 몇 개의 데이터를 표시할건지 ( 필수 )
 *   - pageCount: number -> 한 페이지당 보여줄 페이지 카운트의 개수 ( 필수 아님 / 디폴트 값 3 )
 *   - dataTotalLength: number; -> 총 데이터 개수 ( 필수 )
 *   - currentPage: number; -> 현재 페이지 ( 필수 )
 *   - setCurrentPage: Function; -> 현재 페이지(currentPage) 위치 변경 함수 ( 필수 )
 *
 *   - ItemComponent: Component; -> 개발자가 임의로 입힌 숫자 버튼의 컴포넌트 ( 필수 아님 )
 *   - PrevComponent: Component; -> 개발자가 임의로 입힌 이전 버튼의 컴포넌트 ( 필수 아님 )
 *   - NextComponent: Component; -> 개발자가 임의로 입힌 다음 버튼의 컴포넌트 ( 필수 아님 )
 *
 * */
const Pagination = memo((props) => {
  const {
    pageLength,
    dataTotalLength,
    currentPage,
    setCurrentPage,
    ItemComponent,
    itemActiveClassName,
    PrevComponent,
    NextComponent,
    PaginationNumWrap,
  } = props ?? {};
  let { pageCount } = props;
  pageCount = pageCount ?? 3;
  const pageTotalCnt = Math.ceil(dataTotalLength / pageLength);

  const clickItem = (cnt) => {
    setCurrentPage(cnt);
  };

  const movePrevPage = () => {
    setCurrentPage((prev) => (prev - 1 < 1 ? pageTotalCnt : prev - 1));
  };

  const moveNextPage = () => {
    setCurrentPage((next) => (next + 1 > pageTotalCnt ? 1 : next + 1));
  };

  // 혹시 몰라 다양한 곳에서 사용할 수 있도록 여기서 임의로 만든 defaultComponent 혹은
  // 사용자가 커스텀 컴포넌트들 ( 이전 버튼, 숫자 버튼, 다음 버튼 등의 컴포넌트 ) 등을 변경할 수 있도록 개발
  // ( 필요에 따라 감싸는 부모 컴포넌트도 커스텀으로 제작할 수 도 있음 / 이 부분은 구현 안해둔 상태 )
  const Component = {
    Item: ItemComponent ?? CustomItem,
    Prev: PrevComponent ?? CustomPrev,
    Next: NextComponent ?? CustomNext,
    Wrap: PaginationNumWrap ?? Wrapper,
  };
  const activeClassName = itemActiveClassName ?? "active";

  const array = useMemo(
    () =>
      Array(pageTotalCnt)
        .fill(1)
        .map((_, idx) => idx + 1),
    [pageTotalCnt]
  );

  return (
    <Component.Wrap>
      <Component.Prev onClick={() => movePrevPage()} />
      {array
        .slice(
          Math.floor((currentPage - 1) / pageCount) * pageCount,
          Math.floor((currentPage - 1) / pageCount) * pageCount + pageCount
        )
        .map((_) => {
          return (
            <Component.Item
              key={`pagination_${_}`}
              className={_ === currentPage ? activeClassName : ""}
              onClick={() => clickItem(_)}
            >
              {_}
            </Component.Item>
          );
        })}
      <Component.Next onClick={() => moveNextPage()} />
    </Component.Wrap>
  );
});

export default memo(Pagination);

const CustomPrev = (props) => {
  const { children, ...otherProps } = props ?? {};
  return (
    <Box {...otherProps}>
      <i className="ri-arrow-left-s-line"></i>
    </Box>
  );
};

const CustomNext = (props) => {
  const { children, ...otherProps } = props ?? {};
  return (
    <Box {...otherProps}>
      <i className="ri-arrow-right-s-line"></i>
    </Box>
  );
};

const Wrapper = styled(FlexBox)`
  max-width: 100%;
  height: 30px;
  justify-content: center;
  align-items: center;
`;

const CustomNextPrevGroup = styled(FlexBox)`
  width: 15%;
  height: 100%;
  align-items: center;

  &.prev--group {
    justify-content: flex-end;
  }

  &.next--group {
    justify-content: flex-start;
  }

  .btn {
    cursor: pointer;
    user-select: none;
  }
`;

const CustomPageGroup = styled(FlexBox)`
  max-width: 70%;
  height: 100%;
`;

const CustomItem = styled(FlexAll_C_Box)`
  width: 30px;
  margin: 0 5px;
  cursor: pointer;
  user-select: none;

  &.active {
    box-shadow: rgb(0 0 0 / 30%) 0px 12px 60px 5px;
  }
`;
