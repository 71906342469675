import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import "../components/pages/chat/css/github-markdown-light.css";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { okaidia } from 'react-syntax-highlighter/dist/esm/styles/prism';

export default function ViewMarkdownTextToHtml(props) {
  const { text } = props ?? {}

  return (
    <ReactMarkdown
      className={"markdown-body"}
      // rehypePlugins={[rehypeHighlight]}
      remarkPlugins={[remarkGfm]}
      children={text}
          components={{
      code({ node, inline, className, children, ...props }) {
        const match = /language-(\w+)/.exec(className || '');
        return !inline && match ? (
          <SyntaxHighlighter
            children={String(children).replace(/\n$/, '')}
            style={okaidia}
            language={match[1]}
            PreTag="div"
            {...props}
            
          />
        ) : (
          <code className={className} {...props}>
            {children}
          </code>
        );
      },
    }}
    />
  );
}
