import { existMemberYn, signInToEmail, signInToSocial } from "api/memberApi";
import GlobalHeader from "components/organisms/GlobalHeader";
import HomeMain from "components/templates/home/HomeMain";
import LoginPop from "components/templates/login/LoginPop";
import { Base64 } from "js-base64";
import { signAppContext } from "utils/providers/SignContextProvider";
import { useCallback, useContext, useEffect, useState } from "react";
import { local, session } from "utils/storage";
import SignUpPop from "components/templates/login/SignUpPop";
import { Route, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import AlertPop from "components/templates/popup/AlertPop";
import { AlertPop2 } from "components/templates/popup/AlertPop";

import { ErrorCatch } from "api/ErrorCatch";
import Footer from "components/organisms/Footer";
import SignUpAgreeLayer from "components/templates/login/SignUpAgreeLayer";

export default function Home({ loginPop }) {
  const [loginSignTabState, setLoginSignTabState] = useState(true);
  const [loginPopState, setLoginPopState] = useState(false);
  const [openSignUp, setOpenSignUp] = useState(false);
  const [tabState, setTabState] = useState(loginSignTabState);
  const [openTermsOfAlvis, setOpenTermsOfAlvis] = useState(false);
  const [infoStruct, setInfoStruct] = useState(null);

  // 경고 메시지 State
  const [popState, setPopState] = useState(false);

  const { handleSignInApp } = useContext(signAppContext);

  const [isAutoLogin, setIsAutoLogin] = useState(false);
  const [keepId, setKeepId] = useState(false);

  const [mobileMenu, setMobileMenu] = useState(false);

  const naver = window.naver;
  const kakao = window.Kakao;

  const history = useHistory();

  const [popState2, setPopState2] = useState(false);
  const [popMessage, setPopMessage] = useState("");

  useEffect(() => {
    setOpenTermsOfAlvis(infoStruct ? true : false);
  }, [infoStruct]);

  const socialSignIn = useCallback(
    async (id, email, name, socialType, agreeItems) => {
      try {
        const { headers, status } = await signInToSocial(
          id,
          email,
          name,
          socialType,
          agreeItems
        );

        if (status === 200) {
          if (isAutoLogin) {
            console.log(
              ">>>>> 하하하하 이건 공통 소셜인데 다 되나? 자동로그인을 하기위한 토큰 체크라네! ",
              headers.refresh_token
            );

            headers.refresh_token &&
              local.setAutoLoginToken(headers.refresh_token);
            headers.member_email && local.setKeepId(headers.member_email);
          }

          handleSignInApp(
            headers.authorization,
            headers.refresh_token,
            headers.member_email,
            headers.member_uid,
            headers.social_yn,
            socialType
          );
        } else {
          setPopState2((prev) => !prev);
          setPopMessage("잠시 후 다시 시도 해 주세요.");
          // alert("잠시 후 다시 시도 해 주세요.");
        }
      } catch (error) {
        console.log(">>> error : ", error);
      } finally {
        setLoginPopState(false);
      }
    },
    [handleSignInApp, isAutoLogin]
  );

  //Google 로그인
  const getGoogleUserInfo = async (userInfo) => {
    const id = userInfo.data.sub;
    const email = userInfo.data.email;

    const { data } = await existMemberYn(id, "google", email);

    if (data.existMemberYn === "N") {
      setInfoStruct({
        id: id,
        email: email,
        socialType: "google",
      });
    } else {
      socialSignIn(id, email, "google");
    }
  };

  const naverInit = useCallback(() => {
    const naverLogin = new naver.LoginWithNaverId({
      clientId: process.env.REACT_APP_NAVER_CLIENT_KEY,
      callbackUrl: `${process.env.REACT_APP_HOST}/naverLoginSuccess`,
      isPopup: false,
      loginButton: { color: "green", type: 1, height: 30 },
    });

    naverLogin.init();
    naver.successCallback = socialSignIn;
  }, [naver, socialSignIn]);

  const kakaoInit = useCallback(() => {
    !kakao.isInitialized() && kakao.init(process.env.REACT_APP_KAKAO_APP_KEY);
  }, [kakao]);

  useEffect(() => {
    naverInit();
    kakaoInit();
  }, [naverInit, kakaoInit]);

  //Naver 로그인
  const handleNaverLogin = () => {
    const naverLoginButton = document.querySelector("#naverIdLogin a");
    naverLoginButton.click();
  };

  //E-mail 로그인
  const signIn = async (email, password) => {
    try {
      if (!email || (email?.trim() ?? "").length === 0) {
        setPopState2((prev) => !prev);
        setPopMessage("이메일 정보를 입력 해주세요.");
        return;
        // return alert("이메일 정보를 입력 해주세요.");
      }

      if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)) {
        setPopState2((prev) => !prev);
        setPopMessage("이메일 형식으로 작성해 주세요.");
        return;
        // return alert("이메일 형식으로 작성해 주세요.");
      }

      console.log(">>>>>>>>> login Param");
      const { headers } = await signInToEmail(email, password);

      if (keepId) headers.member_email && local.setKeepId(headers.member_email);
      else {
        local.removeAutoLoginToken();
        local.removeKeepId();
      }

      handleSignInApp(
        headers.authorization,
        headers.refresh_token,
        headers.member_email,
        headers.member_uid,
        headers.social_yn,
        "email"
      );

      openSignUp && setOpenSignUp(false);
      loginPopState && setLoginPopState(false);
    } catch (error) {
      console.log(">>>! error : ", error);
      ErrorCatch(error);
    }
  };

  useEffect(() => {
    //로그인 요청으로 home에 왔을 때 url replace
    if (loginPop) {
      history.replace("/");
      setLoginPopState(true);
    }
  }, []);

  return (
    <>
      <div className="container home">
        <GlobalHeader
          loginSignTabState={loginSignTabState}
          setLoginPopState={setLoginPopState}
          setMobileMenu={setMobileMenu}
          loginPopState={loginPopState}
          mobileMenu={mobileMenu}
          headerClass={"home"}
        />
        <HomeMain setLoginPopState={setLoginPopState} />

        {loginPopState && (
          <LoginPop
            loginSignTabState={loginSignTabState}
            setLoginPopState={setLoginPopState}
            tabState={tabState}
            setTabState={setTabState}
            getGoogleUserInfo={getGoogleUserInfo}
            kakao={kakao}
            handleNaverLogin={handleNaverLogin}
            setOpenSignUp={setOpenSignUp}
            signIn={signIn}
            keepId={keepId}
            setKeepId={setKeepId}
            isAutoLogin={isAutoLogin}
            setIsAutoLogin={setIsAutoLogin}
          />
        )}
        {openSignUp && (
          <SignUpPop setOpenSignUp={setOpenSignUp} signIn={signIn} />
        )}

        {/* 경고 메시지 */}
        {popState && <AlertPop popState={popState} setPopState={setPopState} />}
        {popState2 && (
          <AlertPop2
            popState={popState2}
            setPopState={setPopState2}
            text={popMessage}
          />
        )}
      </div>
      <Footer />
      {openTermsOfAlvis ? (
        <SignUpAgreeLayer
          socialSignIn={socialSignIn}
          infoStruct={infoStruct}
          setInfoStruct={setInfoStruct}
          setOpenTermsOfAlvis={setOpenTermsOfAlvis}
        />
      ) : null}
    </>
  );
}
