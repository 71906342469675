import React from "react";
import App from "./App";
import { render } from "react-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
// import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

const rootElement = document.getElementById("root");

// 운영에서는 console 로그 제한.
// if (process.env.REACT_APP_MODE === "production") {
//   console.log = () => {};
//   console.error = () => {};
//   console.debug = () => {};
// }

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 20, // 서버에서 받아온 값을 지정된 시간까지 활용
      cacheTime: 1000 * 60 * 5, // 서버에서 받아온 값을 캐시로써 얼마나 저장할지 ( 지정된 시간 이후 GC에 의해 제거됨 )
      refetchOnWindowFocus: false, // 데이터 상태가 stale 상태일 경우, 윈도우에서 포커싱이 될 때마다 refetch를 실행할지에 대한 설정
      refetchOnReconnect: true, // 서버가 다시 연결 되 었을 때 자동으로 refetch할건지에 대한 설정
    },
  },
});
render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <QueryClientProvider client={queryClient}>
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
        <App />
      </QueryClientProvider>
    </GoogleOAuthProvider>
  </React.StrictMode>,
  rootElement
);
