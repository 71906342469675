import { createContext, useState } from "react";

const ModalContext = createContext({
  openModal: () => {},
  closeModal: () => {},
  showModal: false,
});

export { ModalContext };

export default function ModalProvider(props) {
  const { children } = props;

  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };

  return <ModalContext.Provider value={{ showModal, openModal, closeModal }}>{children}</ModalContext.Provider>;
}
