import { DASH_HOME, FlexColumnBox } from "./dashboardStyle.js";
import NormalButton from "../../atomic/NormalButton2.js";
import BaseChat from "../chat/BaseChat";
import { useContext, useEffect, useState, memo, useMemo } from "react";
import botApi from "../../../api/botApi.js";
import { refineToClientMessages } from "../../../utils/chatUtil.js";
import styled from "styled-components";
import { PaginationContainer } from "utils/Pagination.js";
import ChatBotSelect from "../../../utils/ChatBotSelect.js";
import { chatContext } from "../../../utils/providers/ChatContextProvider.js";
import { signAppContext } from "utils/providers/SignContextProvider";
import { ErrorCatch } from "api/ErrorCatch";
import { isMobileContext } from "utils/providers/BrowserEnvContextProvider.js";
import ChatEmpty from "./ChatEmpty.js";
import { AlertPop2 } from "components/templates/popup/AlertPop.js";

export default function ChatHistory() {
  const { handleSignOutApp } = useContext(signAppContext);
  const [selectedChatRoom, setSelectedChatRoom] = useState(null);
  const { chatBotData } = useContext(chatContext);
  const [messages, setMessages] = useState([]);
  const [openChatRoom, setOpenChatRoom] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const asyncFuncArgs = useMemo(() => {
    if (chatBotData?.botUid) {
      return [chatBotData?.botUid];
    } else {
      return [];
    }
  }, [chatBotData?.botUid]);

  const getChatHistoryMessage = async (chatRoomId, botUid) => {
    async function getChatHistoryMessage(chatRoomId, botUid) {
      try {
        const { status, data } = await botApi.getChatHistoryMessage(chatRoomId, botUid);
        if (status === 200) {
          const chatMessages = data.chatMessages ? JSON.parse(data.chatMessages) : "";
          if (chatMessages) {
            console.log("getChatHistoryMessage: ", chatMessages);
          }
          if (!Array.isArray(chatMessages)) {
            chatMessages = [chatMessages]; // 배열로 변환
          }
          const refineMessages = refineToClientMessages(chatMessages);
          setMessages(refineMessages);
        } else {
          console.log("getChatHistoryMessage Not Loaded...");
        }
      } catch (error) {
        ErrorCatch(error, handleSignOutApp);
      }
    }

    await getChatHistoryMessage(chatRoomId, botUid);
  };

  const funcSelectChatInfo = (data) => {
    getChatHistoryMessage(data.chatRoomId, chatBotData.botUid);
    setSelectedChatRoom(data);
  };

  useEffect(() => {
    if (!chatBotData) return;
    setSelectedChatRoom(null);
  }, [chatBotData]);

  const { isMobile } = useContext(isMobileContext);

  const [popState, setPopState] = useState(false);
  const [popMessage, setPopMessage] = useState("");

  if (!chatBotData) {
    return (
      <DASH_HOME.Container>
        <ChatEmpty>아직 생성 된 챗봇이 없습니다.</ChatEmpty>
      </DASH_HOME.Container>
    );
  }

  return (
    <DASH_HOME.Container>
      <FlexColumnBox
        style={
          !isMobile
            ? {
                marginBottom: "20px",
                width: "70%",
              }
            : {
                marginBottom: "20px",
                width: "100%",
              }
        }
      >
        <ChatBotSelect />
      </FlexColumnBox>

      <DASH_HOME.ContentCol
        style={
          !isMobile
            ? {
                width: "70%",
                height: "auto",
              }
            : {
                width: "100%",
                height: "auto",
              }
        }
      >
        {selectedChatRoom ? (
          <div style={{ width: "100%" }}>
            <div>
              <BaseChat
                popupInfo={{ isPopup: false, setOpenChatRoom, openChatRoom }}
                showResponsePop={true}
                showQuestionBox={false}
                showDesc={true}
                messages={messages}
                setMessages={setMessages}
                chatBotData={chatBotData}
                chatRoomId={selectedChatRoom.chatRoomId}
                handleSignOutApp={handleSignOutApp}
                isHistoryBack={false}
                isHistory={true}
                isHeader={false}
                botClass="HISTORY"
                setPopMessage={setPopMessage}
                setPopState={setPopState}
              />
              <ChatDetailInfoFooter>
                {/* <BotHistoryList>
                  <BotHistory className="active">
                    <InfoIp>{`IP:${selectedChatRoom.ip}`}</InfoIp>
                    <InfoCreateAt>
                      {`${selectedChatRoom.createAt} 생성`}
                    </InfoCreateAt>
                  </BotHistory>
                </BotHistoryList> */}

                <NormalButton
                  style={{ margin: "0 auto" }}
                  buttonClass={"cOrange mid round"}
                  buttonTitle={"목록으로"}
                  callBackFunc={(e) => {
                    e.preventDefault();
                    setSelectedChatRoom(false);
                  }}
                  // onClick={(e) => {
                  //   e.preventDefault();
                  //   setSelectedChatRoom(false);
                  // }}
                ></NormalButton>
              </ChatDetailInfoFooter>
            </div>
          </div>
        ) : (
          <HistoryTableCt>
            {chatBotData?.botUid && (
              <></>
              // <HistoryTableHeader>{chatBotData.botName}</HistoryTableHeader>
            )}
            {chatBotData?.botUid && (
              <PaginationContainer
                opt={{
                  isAsync: true,
                  asyncFuncName: "getChatRoomList",
                  asyncFuncArgs: asyncFuncArgs,
                  addArrayInClient: [],
                }}
                pageLength={5}
                pageCount={3}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                botUid={chatBotData.botUid}
                dataNullMessage={
                  <>
                    해당 챗봇은 대화 기록이
                    <br /> 아직 작성되지 않았습니다.
                  </>
                }
                DataWrapComponent={BotHistoryList}
                DataItemComponent={(props) => <CustomBotHistory funcSelectChatInfo={funcSelectChatInfo} {...props} />}
                ItemComponent={PagingBtnComponent}
                itemActiveClassName={"current"}
                PrevComponent={(props) => <PagingBtn className={"prev"} text={"이전"} {...props} />}
                NextComponent={(props) => <PagingBtn className={"next"} text={"다음"} {...props} />}
                PaginationNumWrap={PagingCt}
              />
            )}
          </HistoryTableCt>
        )}
        {/* <NormalButton
          buttonClass={"history"}
          buttonTitle={
            <span>
              <i className="ri-download-2-fill"></i> 대화기록 다운로드 CSV
            </span>
          }
          onClick={() => {
            alert(
              "스토리보드 그림이라 그리긴했는데 전체 대화내역을 1 csv로 내려주는게 목적인가요?"
            );
          }}
        /> */}

        {popState && <AlertPop2 text={popMessage} popState={popState} setPopState={setPopState} />}
      </DASH_HOME.ContentCol>
    </DASH_HOME.Container>
  );
}

const BotHistoryList = styled.div`
  width: 100%;
  max-height: 330px;
  overflow: hidden;
  /* 모바일/ 웹이서 하단이 잘려보여서 15px로 통일  */
  /* border-radius: 15px 15px 0 0; */
  /* border-radius: 15px; */

  /* 23.07.25.codelua pagination 으로 던진 스타일이 이쪽에서 처리되도록 된 굉장히 짜증나는 코드 레거시임! */
  & p.empty {
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #ffffff;
    color: tomato;
    line-height: 1.8;
  }
`;

const BotHistory = styled.div`
  //margin: 10px 0;
  padding: 15px;
  border-radius: 15px;

  display: flex;
  justify-content: space-around;
  width: 100%;

  &:hover {
    background: #f2f3f8;
    border-radius: 0;
    cursor: pointer;
  }

  & .item.content,
  .item.date {
    /* border: 1px solid red; */
  }

  &.active {
    width: 350px;
    padding: 0;
    justify-content: start;

    p {
      margin-right: 10px;
    }
  }
`;

const CustomBotHistory = (props) => {
  const { data, keyName, funcSelectChatInfo, ...otherProps } = props;
  return (
    <>
      <InfoHeader>
        <p className="title content">대화 기록</p>
        <p className="title date">작성일</p>
      </InfoHeader>

      <BotHistory
        key={keyName}
        onClick={(e) => {
          e.preventDefault();
          funcSelectChatInfo(data);
        }}
      >
        {/* className={`chat--bot2 ${openChatRoom ? "active" : "none"} */}
        <InfoIp className="item content">{`${data.ip}`}</InfoIp>
        <InfoCreateAt className="item date">{`${data.createAt}`}</InfoCreateAt>
      </BotHistory>
    </>
  );
};

const ChatDetailInfoFooter = styled.div`
  width: 100%;
  margin: 9.5px 3px;
  display: flex;
  align-items: center;

  @media (max-width: 480px) {
    font-size: 12px;
    flex-direction: column;
  }
`;

const InfoHeader = styled.div`
  width: 100%;
  display: none;

  p {
    color: #000;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.12px;
    width: 120px;
    text-align: center;

    /* border: 1px solid red; */
    @media (max-width: 480px) {
      font-size: 12px;
    }
  }

  & p.title.content {
    width: 400px;
    margin-right: 50px;
    @media (max-width: 480px) {
      width: 180px;
    }
  }

  & p.title.date {
    width: 200px;
  }

  &:first-child {
    background: #f2f3f8;
    border-bottom: 1px solid #ccc;
    display: flex;
    padding: 10px 15px;
    display: flex;
    justify-content: space-around;
    width: 100%;

    /* @media (max-width: 480px) {
      justify-content: unset;
      padding: 15px 30px;
      & p:last-child {
        margin-left: 35%;
      }
    } */
  }
`;
const InfoIp = styled.p`
  color: #7a7a7a;
  font-size: 16px;
  font-weight: 350;
  line-height: normal;
  letter-spacing: -0.16px;

  text-align: left;
  width: 400px;
  margin-right: 50px;

  overflow: hidden;
  word-break: break-all;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media (max-width: 480px) {
    width: 300px;
    margin-right: 0;
  }
`;
const InfoCreateAt = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #7a7a7a;
  font-size: 16px;
  font-weight: 350;
  line-height: normal;
  letter-spacing: -0.16px;

  width: 200px;
  text-align: center;
`;

const PagingCt = styled.div`
  width: 100%;
  text-align: center;

  margin: 30px 0;
`;

const PagingBtn = memo((props) => {
  const { text, ...otherProps } = props ?? {};
  return <PagingBtnComponent {...otherProps}>{text}</PagingBtnComponent>;
});

export const PagingBtnComponent = styled.button`
  //border: 1px solid #ff892a;
  border-radius: 5px;
  margin: 5px;
  padding: 5px 8px;

  color: #888;
  border: 1px solid #888;

  width: 45px;
  height: 35px;
  font-size: 13px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  &.current {
    border: 1px solid #ff892a;
    background-color: #ff892a;
    color: #fff;
  }

  &.next {
  }

  &.prev {
  }
`;

const HistoryTableCt = styled.div`
  width: 100%;
  height: fit-content;
  margin: 9.5px 3px;
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 15px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.16);

  @media (max-width: 480px) {
    min-height: auto;
  }
`;

const HistoryTableHeader = styled.div`
  width: 100%;

  padding: 10px 20px;

  border-radius: 10px 10px 0 0;
  border-bottom: 1px solid #ccc;
  background: rgba(255, 137, 42, 0.14);

  color: #1a1a1a;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.14px;
`;
