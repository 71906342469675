import { existMemberYn, signInToSocial } from "api/memberApi";
import { Base64 } from "js-base64";
import { signAppContext } from "./providers/SignContextProvider";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { isMobileContext } from "./providers/BrowserEnvContextProvider";
import SignUpAgreeLayer from "components/templates/login/SignUpAgreeLayer";

export default function NaverLoginSuccess(setPopState, setPopMessage) {
  const naver = window.naver;

  const history = useHistory();
  const { handleSignInApp } = useContext(signAppContext);
  const { isMobile } = useContext(isMobileContext);

  const [openTermsOfAlvis, setOpenTermsOfAlvis] = useState(false);
  const [infoStruct, setInfoStruct] = useState(null);

  const [openComplete, setOpenComplete] = useState(false);
  const [memberYn, setMemberYn] = useState("");

  useEffect(() => {
    setOpenTermsOfAlvis(infoStruct ? true : false);
  }, [infoStruct]);

  const naverLogin = useMemo(
    () =>
      new naver.LoginWithNaverId({
        clientId: process.env.REACT_APP_NAVER_CLIENT_KEY,
        callbackUrl: `${process.env.REACT_APP_HOST}/naverLoginSuccess`,
        callbackHandle: true,
        isPopup: false,
      }),
    [naver]
  );

  const socialSignIn = useCallback(
    async (id, email, socialType, agreeItems) => {
      try {
        const { headers } = await signInToSocial(
          id,
          email,
          socialType,
          agreeItems
        );

        handleSignInApp(
          headers.authorization,
          headers.refresh_token,
          headers.member_email,
          headers.member_uid,
          headers.social_yn,
          "naver"
        );
      } catch (error) {
        console.error("error : ", error);
      } finally {
        memberYn === "N"
          ? setOpenComplete(true)
          : history.replace({ pathname: "/" });
      }
    },
    [history, memberYn, handleSignInApp]
  );

  const success = useCallback(() => {
    naverLogin.getLoginStatus(async (status) => {
      if (status) {
        const email = naverLogin.user.getEmail();
        const id = naverLogin.user.getId();

        if (!email) {
          // setPopState((prev) => !prev);
          // setPopMessage("이메일은 필수 정보 입니다. 정보제공을 동의해주세요.");
          alert("이메일은 필수 정보 입니다. 정보제공을 동의해주세요.");
          naverLogin.reprompt();
          return;
        }
        // 회원여부 질의
        const { data } = await existMemberYn(id, "naver", email);
        setMemberYn(data.existMemberYn);
        if (data.existMemberYn === "N") {
          setInfoStruct({
            id: id,
            email: email,
            socialType: "naver",
          });
        } else {
          socialSignIn(id, email, "naver");
        }
      } else {
        console.log("callback 처리 실패. error code : ", status);
        history.replace({ pathname: "/" });
      }
    });
  }, [naverLogin, socialSignIn]);

  useEffect(() => {
    naverLogin.init();

    success();
  }, [naverLogin, success]);

  return openTermsOfAlvis ? (
    <SignUpAgreeLayer
      socialSignIn={socialSignIn}
      infoStruct={infoStruct}
      setInfoStruct={setInfoStruct}
      setOpenTermsOfAlvis={setOpenTermsOfAlvis}
    />
  ) : (
    <></>
    // <LoadingComponent />
  );
}
