import NormalButton from "components/atomic/NormalButton2";
import { useContext } from "react";
import styled, { keyframes } from "styled-components";
import logoAssistant from "../../assets/images/LOGO_Assistant.svg";
import logoChat from "../../assets/images/LOGO_Chat.svg";
import Content04 from "../../assets/images/content04.svg";
import Content05 from "../../assets/images/content05.svg";
import Content06 from "../../assets/images/content06.svg";
import icondotArrowLeft from "../../assets/images/icon_dotArrowLeft.svg";
import icondotArrowRight from "../../assets/images/icon_dotArrowRight.svg";
import logoDefault from "../../assets/images/meswiser_LogoDefault.svg";
import icondotArrowDown from "../../assets/images/icon_dotArrowDown.svg";

import { isMobileContext } from "utils/providers/BrowserEnvContextProvider";

export default function MainContentAdded() {
  const { isMobile } = useContext(isMobileContext);

  return (
    <>
      <SectionCt className="type02 white">
        <Section className="type02">
          <FelxColumnCt className="type02">
            <Title style={{ marginBottom: "30px" }} className={isMobile ? "Title type02 " : "Title center"}>
              유기적인 협업이 필요하신가요?
              <br />
              <span className="orange">MESwiser 서비스 하나로{isMobile && <br />} 업무 통합 환경을 구축해 보세요</span>
            </Title>
            <Title className={"hasImg"} style={{ textAlign: "center" }}>
              <img className="" src={logoDefault} alt={"logo"}></img>
            </Title>
          </FelxColumnCt>

          {isMobile ? (
            <DataTextSquareCt>
              <DataTextSquare className="added">
                <img src={logoAssistant} alt={"아이콘1"} />
                <p className="title">
                  <span className="strong">내부 업무</span>에 적합한 <br /> 업무 생산성 향상 지원 도구
                </p>
              </DataTextSquare>
              <img className="middleImg" src={Content06} alt={"아이콘1"} />
              <DataTextSquare className="added">
                <img src={logoChat} alt={"아이콘1"} />
                <p className="title">
                  <span className="strong">대외 서비스</span>에 적합한 <br /> GPT 기반의 인공지능 챗봇
                </p>
              </DataTextSquare>
            </DataTextSquareCt>
          ) : (
            <img className="contentImg" src={Content04} alt="콘텐츠 이미지" />
          )}

          <Title className="Added type02 Desc">
            MESwiser는 Chatbot과 Assistant 간<br />
            <span className="">지식 불러오기 기능</span>을 지원합니다
          </Title>

          {isMobile ? (
            <>
              <ChartWrap style={{ flexWrap: "wrap" }}>
                <ChartDivedCt>
                  <ChartDivedTitle>Assistant</ChartDivedTitle>
                  <ChartLeftPart>
                    <p className="item">업무 생산성 향상</p>
                    <p className="item">업무 협업</p>
                    <div className="orangePoint hasArrow">
                      <img src={icondotArrowRight} alt="arrowImg" />
                      <p className="item">지식 관리</p>
                      <p className="item">지식 검증</p>
                    </div>
                  </ChartLeftPart>
                </ChartDivedCt>

                <ChartDivedCt>
                  <ChartDivedTitle>Chatbot</ChartDivedTitle>
                  <ChartRightPart>
                    <div className="orangePoint">
                      <p className="item">질의 응답 서비스</p>
                      <p className="item">민원 응대 서비스</p>
                      <p className="item">상담 서비스</p>
                      <p className="item">시나리오 기반 서비스</p>
                    </div>
                  </ChartRightPart>
                </ChartDivedCt>
                <ChartDivedCt icon={icondotArrowDown} className="onlyImg"></ChartDivedCt>
              </ChartWrap>

              <Title className="type02 Added orange">
                Assistant에서 검증된 지식으로 <br />
                Chatbot에 바로 적용 및 관리 - 불러오기
              </Title>
              <Title className="type02 Added Desc">*할루시네이션(환각현상)제거</Title>
              <ChartWrap icon className="noneBtmMargin">
                <ChartDivedCt>
                  <ChartLeftPart>
                    <div className="orangePoint">
                      <p className="item color">신규 지식 반영</p>
                      <p className="item color">분석 지식 반영</p>
                    </div>
                  </ChartLeftPart>
                </ChartDivedCt>
                <ChartDivedCt>
                  <ChartRightPart>
                    <div className="orangePoint hasArrow">
                      <img src={icondotArrowLeft} alt="arrowRight" />
                      <p className="item color">질의 및 응답 로그</p>
                      <p className="item color">사용자 분석</p>
                    </div>
                  </ChartRightPart>
                </ChartDivedCt>
                <ChartDivedCt icon={icondotArrowDown} className="onlyImg" />
              </ChartWrap>
              <Title className="type02 Added orange">
                Chatbot 운영으로 생산된 지식을
                <br />
                Assistant 신규 지식, 분석 지식으로 활용
              </Title>
            </>
          ) : (
            <img className="contentImg" src={Content05} alt="콘텐츠 이미지" />
          )}

          <NormalButton
            buttonTitle={
              <>
                MESwiser Assistant 바로가기
                <i className="ri-external-link-line"></i>{" "}
              </>
            }
            buttonClass={"big round cOrange"}
            callBackFunc={() => {
              window.open("https://assistant.meswiser.ai", "_blank");
            }}
          ></NormalButton>
        </Section>
      </SectionCt>
    </>
  );
}

const SectionCt = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  @media (max-width: 1000px) {
    padding: 0 30px;
  }

  &.type02 {
    background: #f7f9ff;
    &.white {
      background: #fff;
    }

    & img.contentImg {
      width: 80%;
      margin-bottom: 50px;
    }
  }
`;

const Section = styled.section`
  max-width: 1200px;
  min-height: 742px;

  display: flex;
  align-items: center;
  justify-content: center;
  color: #0d0d0d;

  @media (max-width: 1000px) {
    flex: 0 1 auto;
    margin-top: 100px;
  }

  &.type02 {
    flex-direction: column;
    margin: 80px 0 120px;
    min-height: 600px;
    & p.titleTop {
      color: #333;
      text-align: center;
      font-size: 50px;
      font-weight: 700;
      line-height: 65px;
      margin-bottom: 74px;

      @media (max-width: 480px) {
        font-size: 36px;
        margin-bottom: 20px;
      }
    }
    & p.descTop {
      color: #333;
      text-align: center;
      font-size: 18px;
      font-weight: 400;
      line-height: 23.5px;
      letter-spacing: -0.18px;
      margin-bottom: 20px;
    }

    @media (max-width: 480px) {
      margin: 80px 0;
    }

    & button.round.big.cOrange {
      font-size: 17px;
      padding: 10px 45px;
    }
  }

  @media (max-width: 480px) {
    img.alvisImg {
      margin: 0 0 30px 0;
    }
    .title {
      color: #1a1a1a;
      font-size: 26px;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -1px;
      margin-bottom: 50px;
    }
  }
`;

const Up = keyframes`
    0% {
      transform: translateY(-10%) scale(1.2);

    }
    100% {
      transform: translateY(0%) scale(1);
      opacity: 1;
    }
`;

const FelxColumnCt = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;

  &.scActive {
    animation: ${Up} 0.7s ease-in-out;
    animation-fill-mode: forwards;
  }
  &.Ani01 {
    animation-delay: 0.3s;
  }
  &.Ani02 {
    animation-delay: 0.6s;
  }
  &.Ani03 {
    animation-delay: 0.9s;
  }
  &.Ani04 {
    animation-delay: 1.2s;
  }

  &.type02 {
    img.content {
      margin: 0 auto;
      width: 80%;
    }
  }
`;

const DataTextSquareCt = styled.div`
  display: flex;

  & img.middleImg {
    margin: 30px 0;
  }
  @media (max-width: 950px) {
    flex-wrap: wrap;
    justify-content: center;
  }
  @media (min-width: 700px) {
    &.onlyMobile {
      display: none;
    }
  }
  @media (max-width: 480px) {
    margin: 20px 0;
  }
`;

const DataTextSquare = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  padding: 60px 20px;
  width: 300px;
  height: 380px;
  border-radius: 40px;

  color: #000;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.18px;

  background: #fff;
  margin: 0 10.5px;

  position: relative;
  transition: 0.2s;
  border: 1px solid transparent;

  &.added {
    justify-content: center !important;
    border: 1px solid #ff892a;
    & img {
      width: 215px;
      height: 22px;
      margin: 10px 0;
    }
    & p {
      text-align: center;
      font-size: 16px;
      font-weight: 300 !important;
      line-height: 24px;
      margin: 5px 0 10px !important;
      .strong {
        font-weight: 700;
      }
    }
  }

  &:hover {
    cursor: pointer;
    opacity: 0.7;
    transform: translateY(-5%);
  }
  &.active {
    border: 1px solid #ff892a;
    box-shadow: 0px 1px 23px 0px rgba(0, 0, 0, 0.42);
    transform: translateY(-5%);
    @media (max-width: 480px) {
      transform: translateY(-2%);
    }

    p.desc {
      color: #222;
    }
  }

  & img {
    width: 60px;
    height: 60px;
    margin-bottom: 20px;
  }
  p.title {
    color: #333;
    font-size: 22px;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 40px;
    word-break: keep-all;
    letter-spacing: -1px;
    text-align: center;
  }
  p.desc {
    color: #999;
    font-size: 16px;
    font-weight: 300;
    line-height: normal;
    word-break: keep-all;
    text-align: center;
  }

  @media (max-width: 950px) {
    margin: 10px;
    padding: 20px;
    height: auto;
    width: 30%;

    p.title {
      font-size: 22px;
      letter-spacing: -1px;
      margin-bottom: 10px;
    }
    p.desc {
      font-size: 16px;
      letter-spacing: -1px;
    }
  }

  @media (max-width: 700px) {
    margin: 10px 5px;
    height: auto;

    flex: 1 0 100%;
    align-items: center;

    img {
      margin-bottom: 10px;
    }

    p.title {
      font-size: 18px;
      letter-spacing: -1px;
      margin-bottom: 25px;
      line-height: 20px;
    }
    p.desc {
      font-size: 14px;
      line-height: 17px;
      letter-spacing: -1px;
      padding: 10px;

      word-break: keep-all;
    }
  }
`;

const Title = styled.p`
  font-size: 40px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.4px;
  /* text-align: center; */
  margin-bottom: 79px;
  word-break: keep-all;

  &.hasImg {
    margin: 30px 0px;
    @media (max-width: 480px) {
      margin-bottom: 0;
    }
  }
  &.center {
    text-align: center;
  }
  & .desc {
    font-size: 24px;
    font-weight: 400;
  }
  & span.orange {
    color: #ff892a;
  }

  &.type02 {
    letter-spacing: -0.4px;
    font-size: 30px;
    line-height: 45px;

    &.Added {
      /* margin-top: 50px; */
      color: #333;
      text-align: center;
      font-size: 26px;
      font-weight: 300;
      .strong {
        font-weight: 700;
      }

      &.orange {
        color: #ff892a;
        font-size: 16px;
        font-weight: 500;
        line-height: normal;
      }

      &.Desc {
        color: #333;
        text-align: center;
        font-weight: 300;
        font-size: 32px;
        line-height: 38px;
        & span {
          font-weight: 700;
        }
        @media (max-width: 480px) {
          font-size: 18px !important;
          line-height: 24px;
        }
      }
    }

    @media (max-width: 480px) {
      font-size: 24px;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -1px;
      margin-bottom: 20px;
      text-align: center;

      .strong {
        font-size: 40px;
      }
      .desc {
        display: inline-block;
        margin-top: 8px;
        font-size: 26px;
        font-weight: 400;
        letter-spacing: -1.5px;
      }
    }
  }
`;

const ChartWrap = styled.div`
  display: flex;

  align-items: streth;
  justify-content: space-between;
  width: 100%;
  margin: 20px 0 0px;

  flex-wrap: wrap;

  &.noneBtmMargin {
    margin: 0;
  }
`;

const ChartDivedCt = styled(ChartWrap)`
  flex: 0 0 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &.onlyImg {
    flex: 1 0 100%;
    height: 30px;

    margin-top: 0;
    background: url(${({ icon }) => icon}) no-repeat;
    background-position: center;
    background-size: contain;
  }
`;

const ChartDivedTitle = styled.div`
  color: #1a1a1a;
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  text-align: center;
  margin-bottom: auto;
`;

const ChartLeftPart = styled.div`
  position: relative;
  margin-top: 15px;
  & p.item {
    padding: 10px;
    text-align: center;
    color: #333;
    font-size: 14px;
    font-weight: 300;
    line-height: 21px;
    margin: 12px 0;
    letter-spacing: -1px;
    border-radius: 100px;
    background: #e7e8ea;

    &.color {
      font-weight: 600;
      background: #ffedca;
    }
  }

  & div.orangePoint {
    border: 1.5px dashed #ff892a;
    position: relative;
    &.hasArrow {
      img {
        position: absolute;
        content: "";
        width: 25px;
        height: 15px;
        background: url(${({ icon }) => icon}) no-repeat;
        background-position: center;
        background-size: cover;
        top: 50%;
        right: -32px;
        transform: translateY(-50%);
      }
    }
  }
`;
const ChartRightPart = styled(ChartLeftPart)`
  & div.orangePoint {
    border: 1.5px dashed #ff892a;
    position: relative;
    &.hasArrow {
      img {
        position: absolute;
        content: "";
        width: 25px;
        height: 15px;
        background: url(${({ icon }) => icon}) no-repeat;
        background-position: center;
        background-size: cover;
        top: 50%;
        left: -32px;
        transform: translateY(-50%);
      }
    }
  }
`;
