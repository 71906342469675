export class baseSettingUtil {
  static exampleContent = [
    {
      title: "지식 필터",
      subTitle: "📌 지식 필터 기능이란?",
      desc: "챗봇 응답 지식 범위를 채팅 내에서 간편하게 선택하여 사용할 수 있는 기능입니다!",
      use: "지식 필터 기능을 on으로 설정한 후 챗봇을 실행하여 챗봇 화면 왼쪽 상단에 생성된 햄버거 메뉴를 클릭해보세요! 원하는 질문 범위를 폴더 단위로 지정하여 응답을 받을 수 있습니다.",
      comparison: false,
    },
    {
      title: "채팅 응답 방식 (markDown)",
      subTitle: "📌 채팅 응답 방식이란?",
      desc: "챗봇을 통해 받는 응답의 방식을 변경할 수 있습니다.",
      use: "채팅 응답 방식 기능을 on으로 설정한 후 챗봇의 응답을 확인해보세요!",
      comparison: true,
      beforeTitle: "일반 응답",
      before: "마트에서 많은 짐을 드는 방법에는 2가지가 있습니다. 1. 카트를 이용한다. 2. 바구니를 이용한다.",
      afterTitle: "마크다운 응답",
      after:
        "<h2 class=title>마트에서 많은 짐을 드는 방법에는 2가지가 있습니다.</h2><p class=desc>1. 카트를 이용한다.</p><p class=desc>2. 바구니를 이용한다.</p>",
    },
  ];
}
