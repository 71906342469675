import { useEffect, useState, useContext, useMemo } from "react";
import { chatContext } from "utils/providers/ChatContextProvider";
import { signAppContext } from "../../../utils/providers/SignContextProvider.js";
import uuid from "react-uuid";
import { getNow } from "../../../utils/dateUtil.js";
import { useLocation, useParams } from "react-router-dom";
import botApi from "api/botApi.js";
import LoadingComponent from "utils/LoadingComponent.js";
import qs from "querystring";
import BaseChat from "./BaseChat";

export default function ExternalChat() {
  const location = useLocation();
  const referer = document.referrer; // ex) http://dev.alvis.kr/dashboard, 로컬에서 바로 띄우면 빈문자열이다.
  // const { s } = qs.parse(location.search, { ignoreQueryPrefix: true });
  const s = useMemo(() => {
    const tmpO = new URLSearchParams(location.search);
    return tmpO.get("bot");
  }, [location.search]);

  const { botUid } = useParams();
  // state
  const { chatBotData, setChatBotData, chatRoomId, setChatRoomId, myIP } = useContext(chatContext);
  const { handleSignOutApp } = useContext(signAppContext);

  const [openChatRoom, setOpenChatRoom] = useState(true);
  const [messages, setMessages] = useState([]);
  const [freeBot, setFreeBot] = useState(null);
  const [exList, setExList] = useState([]);
  const [bottomData, setBottomData] = useState([]);
  const [loading, setLoading] = useState(false);

  const getAdditionalData = async (botUid) => {
    setLoading(true);
    try {
      const exqListnResponse = await botApi.selectBotExQuestion(botUid);
      if (exqListnResponse.status === 200 && exqListnResponse.data?.infoList) {
        setExList(JSON.parse(exqListnResponse.data.infoList));
      } else {
        setExList([]);
      }
      // 하단메뉴를 사용 안한다면 굳이 보여주지 않는다.
      if (["Y", "A"].includes(chatBotData?.useBottomMenu)) {
        const bottomMenuResponse = await botApi.selectBottomMenuList(botUid);
        if (bottomMenuResponse.status === 200 && bottomMenuResponse.data?.bottomMenuList) {
          setBottomData(JSON.parse(bottomMenuResponse.data.bottomMenuList));
        } else {
          setBottomData([]);
        }
      } else {
        setBottomData([]);
      }
    } catch (error) {
      //
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!chatBotData) return;
    if (chatBotData.serviceModel === "1") {
      setFreeBot(false);
    } else {
      if (referer.includes("http://localhost:3000") || "meswiser.ai") {
        setFreeBot(false);
      } else {
        setFreeBot(true);
      }
    }

    if (messages.length === 0 && chatBotData) {
      // 브라우저 타이틀을 변경해준다.
      document.title = chatBotData.botName;
      let message = "";
      if (chatBotData.greetingMessage) message = chatBotData.greetingMessage;
      else message = "입력하신 URL주소에 포함 된 내용을 기반으로 답변드립니다.";

      setMessages([
        {
          message: message,
          type: "bot",
          messageType: "text",
          time: getNow(),
          id: uuid(),
        },
      ]);

      // 예시질문, 하단메뉴에 대해서 쿼리한다.
      getAdditionalData(chatBotData.botUid);
    }
    console.log(chatBotData);
  }, [chatBotData]);

  const getChatBotData = async () => {
    try {
      if (!s) {
        const { status, data } = await botApi.getBotInfoByBotUid(botUid);
        if (status === 200) {
          console.log("유/무료 체크");
          // referrer 를 체크 해서 alvis를 통한게 아니라면 튕겨주자. 유료봇이라면 상관없다.
          const resultData = JSON.parse(data.botInfo);
          if (resultData.serviceModel === "1") {
            console.log("유료모델체크! ", referer);
            setFreeBot(false);
            setChatBotData(resultData);
          } else {
            console.log("유료모델이 아니므로  레퍼러 체크: ", referer);
            if (referer.includes("http://localhost:3000") || referer.includes("meswiser.ai")) {
              console.log(">>> 레퍼러 체크 OK");
              setFreeBot(false);
              setChatBotData(resultData);
            } else {
              setFreeBot(true);
            }
          }
        }
      } else {
        // 2023.07.13.codelua  s 는 shortUrl 주소를 설정 한경우 해당의 경우 다른 쿼리를 날려본다.
        const { status, data } = await botApi.getBotInfoByShortUrl(s);
        if (status === 200) {
          const resultData = JSON.parse(data.botInfo);
          setChatBotData(resultData);
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    // chatBotData를 로딩하고 session에 담아준다.
    if (!chatBotData) {
      const botData = JSON.parse(sessionStorage.getItem("botData"));
      if (botData) setChatBotData(botData);
      else {
        console.log("채팅을 하기위해 챗봇데이터를 로딩함.");
        getChatBotData();
      }
    }
  }, []);

  useEffect(() => {
    const tmpData = sessionStorage.getItem("botData");
    if (tmpData) {
      const oop = JSON.parse(tmpData);
      // 23.07.05.codelua DEMO의 경우 session값과 넘어온 bot이 같을때만 동작한다.
      if (oop.botUid === botUid) {
        setChatBotData(JSON.parse(tmpData));
        // sessionStorage.removeItem("botData");
      }
    }
  }, []);

  useEffect(() => {
    setChatRoomId(uuid());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (freeBot) {
    return (
      <div>
        무료버전은 외부에서의 접근이 제한됩니다.
        <br />
        외부에서의 접근을 통한 테스트가 필요하시면
        <br />
        문의하기로 상담 신청 해주시면
        <br />
        상담해 드리도록 하겠습니다
      </div>
    );
  }

  if (!chatBotData) {
    return (
      <div>
        챗봇정보를 확인 할 수 없습니다.
        <br />
        잘못된 접근입니다.
      </div>
    );
  }

  return (
    <>
      {loading && <LoadingComponent />}
      {chatBotData ? (
        <BaseChat
          popupInfo={{ isPopup: false, setOpenChatRoom, openChatRoom }}
          showQuestionBox={true}
          messages={messages}
          setMessages={setMessages}
          chatBotData={chatBotData}
          chatRoomId={chatRoomId}
          handleSignOutApp={handleSignOutApp}
          isHistoryBack={false}
          IP={myIP}
          exList={exList}
          BottomData={bottomData}
          botClass="EXTERNAL"
        />
      ) : (
        <div>잘못 된 접근입니다.</div>
      )}
    </>
  );
}
