import React, { memo, useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import ChatBotSelect from "utils/ChatBotSelect.js";
import { DASH_HOME, FlexColumnBox } from "./dashboardStyle.js";
import { signAppContext } from "../../../utils/providers/SignContextProvider.js";
import { chatContext } from "../../../utils/providers/ChatContextProvider.js";
import { isMobileContext } from "utils/providers/BrowserEnvContextProvider.js";
import ChatEmpty from "./ChatEmpty.js";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import NormalButton from "../../atomic/NormalButton2.js";
import moment from "moment";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
// import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
//import "react-calendar/dist/Calendar.css";
import { DateUtil } from "../../../utils/dateUtil.js";

const {
  getOneWeekAgo,
  getToday,
  getOneMonthAgo,
  getThreeMonthAgo,
  getSixMonthAgo,
  toDate,
} = DateUtil;

export default function Statistics2() {
  const { chatBotData, setChatBotData } = useContext(chatContext);
  const { handleSignOutApp } = useContext(signAppContext);
  const { isMobile } = useContext(isMobileContext);

  const dateChangeWithoutEffect = useRef(false);

  const [statsData, setStatsData] = useState(null);

  /*
   * all: pc 단에서 그래프와 표를 전부 보여줄 때
   * graph: mobile단에서 그래프만 보여줄 때
   * table: mobile단에서 표만 보여줄 때
   * */
  const [statTab, setStatTab] = useState(isMobile ? "all" : "graph"); // graph or table or all

  // 일주일, 1개월, 3개월, 전체 버튼을 state로 관리. ( 변동 사항에 따라 수정이 가능하도록 구현 )
  const [daysInfo, setDaysInfo] = useState([
    {
      value: "1_week",
      label: "일주일",
      checked: true,
    },
    {
      value: "1_month",
      label: "1개월",
      checked: false,
    },
    {
      value: "3_month",
      label: "3개월",
      checked: false,
    },
    {
      value: "all",
      label: "전체",
      checked: false,
    },
  ]);

  // [시작 날짜, 끝 날짜]
  const [dateRange, setDateRange] = useState([
    toDate(getOneWeekAgo()),
    new Date(),
  ]);

  //상단 4개 ( 일주일, 1개월 ... ) 등 버튼 누를 때 실행되는 함수, 날짜 범위 변경.
  // ( 최대 6개월까지 조회가 가능하다 하여, 전체를 누를 경우 6개월 전이 선택되로록 구현 )
  const changeDays = (value) => {
    switch (value) {
      case "1_week":
        setDateRange((item) => [getOneWeekAgo(), getToday()]);
        break;
      case "1_month":
        setDateRange((item) => [getOneMonthAgo(), getToday()]);
        break;
      case "3_month":
        setDateRange((item) => [getThreeMonthAgo(), getToday()]);
        break;
      case "all":
        setDateRange((item) => [getSixMonthAgo(), getToday()]);
        break;
    }

    setDaysInfo((args) =>
      args.map((arg) => ({ ...arg, checked: arg.value === value }))
    );
    getChatStat();
  };

  // 선택된 날짜 범위에 대해 자료 가져오는 함수
  const getChatStat = async (botUid) => {
    // try {
    // 	const {status, data} = await botApi.getChatStat(botUid);
    // 	if (status === 200) {
    // 		const statInfo = JSON.parse(data.statInfo);
    // 		console.log('statInfo', statInfo)
    // 		setStatsData(statInfo);
    // 	}
    // } catch (error) {
    // 	console.log(error);
    // 	ErrorCatch(error, handleSignOutApp);
    // }

    // TODO - 임시 데이터 지우고 api 연동하기 ( dateRange에 있는 날짜에 맞춰서. )
    const tempData = Array(9)
      .fill(9)
      .map((item, idx) => {
        if (idx === 2)
          return {
            name: `2023-12-0${idx + 1}`,
            qst: 0,
            user: 0,
          };

        return {
          name: `2023-12-0${idx + 1}`,
          qst: Math.floor(Math.random() * 2000) + 20,
          user: Math.floor(Math.random() * 2000) + 20,
        };
      });

    console.log("tempData", tempData);

    setStatsData(tempData);
  };

  // chatbotData 변경 될 때마다 데이터 조회
  useEffect(() => {
    if (!chatBotData) return;
    getChatStat(chatBotData.botUid);
    // 해당 챗봇의 통계를 받아오자.
  }, [chatBotData]);

  // mobile or pc로 변경 시 마다 표 보여주는 방식 변경
  useEffect(() => {
    console.log("change mobile...", isMobile);
    setStatTab(isMobile ? "graph" : "all");
  }, [isMobile]);

  // dateRange의 값이 바뀔 때 마다 최대로 가져올 수 있는 범위가 넘었는지 등등을 비교
  useEffect(() => {
    if (dateChangeWithoutEffect.current) {
      dateChangeWithoutEffect.current = false;
      return;
    }
    const [startDate, endDate] = dateRange.map((date) => moment(date));

    const monthsDiff = endDate.diff(startDate) / (1000 * 60 * 60 * 24 * 30.44);
    const totalMonths = Math.round(monthsDiff);

    console.log(">>>>>>>>>>>>>>>> 총 몇 달이 지났는지:", totalMonths, "달");
    if (totalMonths > 6 || monthsDiff > 6.044) {
      alert("최대 6개월 전까지 자료를 확인할 수 있습니다.");

      dateChangeWithoutEffect.current = true;
      setDateRange([toDate(getSixMonthAgo(endDate)), dateRange[1]]);
      return;
    }

    // (async () => {
    // 	console.log('result', result)
    // 	// TODO - api 호출하기
    // })();
  }, [dateRange]);

  if (!chatBotData) {
    return (
      <DASH_HOME.Container>
        <ChatEmpty>아직 생성 된 챗봇이 없습니다.</ChatEmpty>
      </DASH_HOME.Container>
    );
  }

  return (
    <DASH_HOME.Container>
      <FlexColumnBox
        style={
          !isMobile
            ? {
                marginBottom: "20px",
                width: "70%",
              }
            : {
                marginBottom: "20px",
                width: "100%",
              }
        }
      >
        <ChatBotSelect
          chatBotData={chatBotData}
          setChatBotData={setChatBotData}
        />
      </FlexColumnBox>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {daysInfo.map((item, idx) => {
          return (
            <NormalButton
              callBackFunc={() => {
                changeDays(item.value);
              }}
              key={`daysBtn_${idx}`}
              buttonClass={"cOrange mid"}
              buttonTitle={item.label}
              style={{
                margin: "10px 5px",
                backgroundColor: item.checked ? "skyblue" : "",
              }}
            />
          );
        })}
        <DateRangePicker onChange={setDateRange} value={dateRange} />
      </div>
      {statsData && (
        <StatWrapper style={{ width: "100%", height: "300px" }}>
          {isMobile && (
            <div>
              <button
                key={"stat_btn_1"}
                style={{
                  background: statTab === "graph" ? "skyblue" : "white",
                }}
                onClick={() => setStatTab("graph")}
              >
                그래프
              </button>
              <button
                key={"stat_btn_2"}
                style={{
                  background: statTab === "table" ? "skyblue" : "white",
                }}
                onClick={() => setStatTab("table")}
              >
                표
              </button>
            </div>
          )}
          {/*  그래프 테스트	*/}
          {/* reference: https://recharts.org/en-US/examples/SimpleBarChart */}
          {/* reference: https://www.npmjs.com/package/recharts */}
          {["graph", "all"].includes(statTab) && (
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                width={500}
                height={300}
                data={statsData}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                {/* activeBar: 막대에 마우스 갖다댈 시 어떻게 표시할지. 색상 등 */}
                <Bar
                  dataKey="qst"
                  fill="#ffc000"
                  name={"질문"}
                  activeBar={<Rectangle fill="#b28600" />}
                />
                <Bar
                  dataKey="user"
                  fill="#ed7d31"
                  name={"이용자"}
                  activeBar={<Rectangle fill="#ed7d31" />}
                />
              </BarChart>
            </ResponsiveContainer>
          )}

          {["table", "all"].includes(statTab) && (
            <table className={"dashboard--table"}>
              <colgroup>
                <col width="40%" />
                <col width="10%" />
                <col width="20%" />
                <col width="20%" />
                <col width="10%" />
              </colgroup>

              <thead>
                <tr>
                  <th>날짜</th>
                  <th>질문</th>
                  <th>이용자</th>
                  <th>평균 사용시간</th>
                </tr>
              </thead>
              <tbody>
                {statsData.map((item) => (
                  <tr key={item.name}>
                    <td>{item.name}</td>
                    <td>{item.qst}</td>
                    <td>{item.user}</td>
                    <td>...</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </StatWrapper>
      )}
    </DASH_HOME.Container>
  );
}

const StatWrapper = styled.div`
  /* border: 1px solid red; */

  @media (min-width: 481px) {
    width: 70%;
  }
`;

const StatList = styled.div`
  /* border: 1px dashed blue; */

  display: flex;
  flex-wrap: wrap;
  width: 100%;

  justify-content: center;
  align-items: center;

  @media (max-width: 480px) {
    border-radius: 30px;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.11);
    background: #fff;
    flex-direction: column;
    padding: 10px 20px;
  }

  & div.dateManage {
    flex: 1 0 100%;
    color: #ff892a;
    text-align: right;
    font-weight: 600;
    padding: 10px 20px;
    width: 100%;
    margin-bottom: 10px;
    font-size: 15px;
  }
`;

const StatCT = styled.div`
  background-color: #fff;
  flex: 1 0 47%;
  /* border: 1px solid black; */
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.11);
  border-radius: 10px;

  margin: 9.5px 3px;

  &.today {
    flex: 1 0 100%;
    width: 100%;
  }

  @media (max-width: 480px) {
    /* flex: 1 0 80% !important; */
    /* width: 80%; */
    width: 100%;
    margin: 0;
    margin-bottom: 10px;

    box-shadow: none;

    /* &:nth-child(odd) {
      background: #ffe7ca;
    }
    &:nth-child(even) {
      background: #caecff;
    } */
  }
`;

const StatInfoCt = styled.div`
  /* border: 1px solid red; */
  display: flex;
`;

const StatData = styled.p`
  display: flex;
  padding: 12.5px 29px;

  flex: 1 1 50%;
  justify-content: center;

  @media (max-width: 480px) {
    text-align: left;
    justify-content: start;
    font-size: 14px;
    color: #696969;
  }

  &.title {
    font-size: 14px;

    width: 100%;
    border-bottom: 1px solid #dcdcdc;

    color: #ff892a;
    font-weight: 700;
    line-height: normal;
    justify-content: center;
    padding: 12.5px 10px;
    @media (max-width: 480px) {
      text-align: left;
      justify-content: start;
    }
  }

  &.value {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
    @media (max-width: 480px) {
      justify-content: end;
    }

    &.active {
      color: #ff892a;
    }
  }

  &.date {
    color: #888;
    font-size: 14px;
    margin-top: auto;
    align-self: end;
  }

  @media (max-width: 480px) {
    &.title {
      font-size: 12px;
    }

    &.value {
      font-size: 16px;
    }
  }
`;
