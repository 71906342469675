import { useContext, useEffect, useRef, useState } from "react";
import ViewMarkdownTextToHtml from "../../utils/ViewMarkdownTextToHtml.js";
import { chatContext } from "../../utils/providers/ChatContextProvider.js";

export default function ChatTypeWriter({ data, isLoading, setScSignal }) {
  const { chatBotData } = useContext(chatContext);
  const useChatMarkdown = !!chatBotData?.useChatMarkdown && chatBotData?.useChatMarkdown === "Y";
  const [newText, setNewText] = useState("");

  useEffect(() => {
    const regex = /(\n)/g;
    const message = data?.message ?? "";
    const strArray = message.split(regex).reduce((acc, data, idx) => {
      // 문자열 중에서 ```javascript, ```[text] 등의 문자가 들어올 경우를 찾아 값 추출하기.
      const codeRegex = new RegExp("```([a-zA-Z0-9]+)\n?", "gi");
      const codeMatch = data.match(codeRegex);
      let excludeWord = null;
      if (!!codeMatch) {
        excludeWord = codeMatch[0].replace(/```|\n/g, "");
      }

      // 문자열을 abcd를 => ['a', 'b', 'c', 'd'] 등으로 나누는데,
      // excludeWord 값이 'bc'면 ['a', 'bc', 'd'] 등으로 나누도록 정규식 작성
      const subRegex = new RegExp(`(${excludeWord ? `(${excludeWord})|` : ""}(\s)|(\n))|.`, "gi");
      let dataSplit = Array.from(data.match(subRegex) ?? []);
      acc = [...acc, ...dataSplit];
      return acc;
    }, []);
    
    let idx = 0;
    const interval = setInterval(() => {
      if (idx > strArray.length - 1) {
        clearInterval(interval);
        data.isEnd = true;
        setScSignal && setScSignal("off");
        return;
      }

      setNewText((prevState) => prevState + strArray[idx]);

      idx++;
    }, 20);
    
    return () => {
      if (interval != null) clearInterval(interval);
    };
  }, []);

  return (
    <>
      {useChatMarkdown && <ViewMarkdownTextToHtml text={newText} />}
      {!useChatMarkdown && <pre>{newText}</pre>}
      {/*<Box style={{ display: "none" }}>*/}
    </>
  );
}
