import { ErrorCatch } from "api/ErrorCatch.js";
import BaseSettingTab from "components/pages/settingTabs/BaseSettingTab.js";
import GuideSettingTab from "components/pages/settingTabs/GuideSettingTab.js";
import BottomBtnTab from "../settingTabs/BottomBtnTab.js";
import ApiCommandSetting from "../settingTabs/ApiCommandSetting.js";

import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { chatContext } from "utils/providers/ChatContextProvider.js";
import { signAppContext } from "utils/providers/SignContextProvider.js";
import { userChatBotContext } from "utils/providers/UserChatBotContextProvider.js";
import botApi from "../../../api/botApi.js";
import { DASH_HOME, FlexColumnBox } from "./dashboardStyle.js";
import { isMobileContext } from "utils/providers/BrowserEnvContextProvider.js";
import ChatBotSelect from "../../../utils/ChatBotSelect.js";
import ChatEmpty from "./ChatEmpty.js";
import NormalButton from "components/atomic/NormalButton2";
import LoadingComponent from "utils/LoadingComponent.js";
import { popupContext } from "../../../utils/providers/PopupProvider.js";
import ThemeSettingTab from "../settingTabs/ThemeSettingTab.js";
import { PriceUtil as priceUtil } from "../../../utils/priceUtil.js";

export default function ChatManage() {
  const { handleSignOutApp } = useContext(signAppContext);
  const { userChatBotList, setUserChatBotList, getUserChatBotList } = useContext(userChatBotContext);
  const { chatBotData, setChatBotData, userPlan } = useContext(chatContext);

  const { isMobile } = useContext(isMobileContext);
  const { showExpirePop, showTextPop, showConfirmPopCommonCallback } = useContext(popupContext);
  const [toolTip, setTooltip] = useState(false);
  // tab 처리
  const [tabState, setTabState] = useState("baseSet");

  const [loading, setLoading] = useState(false);

  // 기본설정 시작
  // 0. 봇 테이블
  const [expireDateState, setExpireDateState] = useState(false); // 유료기한 버튼 여부
  // 1. 챗봇이름
  const [botName, setBotName] = useState("");
  const [botImagePath, setBotImagePath] = useState("");
  // 2. 챗봇 인사말
  const [greetingMessage, setGreetingMessage] = useState("");
  // 3. 검색 조건
  const [sliderMaxVal, setSliderMaxVal] = useState(80); // 시맨틱
  const [sliderMinVal, setSliderMinVal] = useState(20); // 키워드
  const [botRatioVal, setBotRatioVal] = useState([0, 20]);
  // 4. 응답스타일
  const [botRole, setBotRole] = useState("");
  // 5. 답변출처 사용 여부
  const [showSource, setShowSource] = useState(false);
  // 6. 연관질문 사용 여부
  const [useRsearch, setUseRsearch] = useState(false);
  const [showRsearch, setShowRsearch] = useState(false);
  // 7. 상세응답 사용 여부
  const [useAddPrompt, setUseAddPrompt] = useState(false);
  // 8. 상세응답 사용 여부
  const [useKnowledgeManage, setUseKnowledgeManage] = useState(false);
  const [knowledgeManageDescription, setKnowledgeManageDescription] = useState("");
  // 기본설정 끝

  // 소개&예시질문 세팅 시작
  // 소개
  const [introInName, setIntroInName] = useState("");
  const [introIn, setIntroIn] = useState("");
  const [introOutName, setIntroOutName] = useState("");
  const [introOut, setIntroOut] = useState("");

  // 예시
  const [exqInName, setExqInName] = useState("");
  const [exqOutName, setExqOutName] = useState("");
  const [exqText, setExqText] = useState("");
  const [exqOut, setExqOut] = useState("");
  const [exList, setExList] = useState([]);
  // 소개&예시질문 세팅 끝

  // 하단 버튼 세팅 시작
  const [useBottomMenu, setUseBottomMenu] = useState("N");
  const [bottomMenuList, setBottomMenuList] = useState([]);
  // 하단 버튼 세팅 끝

  // api command 세팅 시작
  // 8. api 버튼 사용 여부
  const [useComMenu, setUseComMenu] = useState(false);
  const [apiCommandList, setApiCommandList] = useState([]);

  // 9. 응답 방식 markdown
  const [useChatMarkdown, setUseChatMarkdown] = useState(false);

  // 10. 챗봇 테마 타입
  const [chatThemeType, setChatThemeType] = useState(null);

  // 11. 사용자 URL 설정
  const [shortUrl, setShortUrl] = useState(null);
  const [orgnalShortUrl, setOrgnalShortUrl] = useState(null);
  const [availableShortUrl, setAvailableShortUrl] = useState(false);
  const [isTypedShortUrl, setIsTypedShortUrl] = useState(false);
  
  useEffect(() => {
  }, [chatBotData]);
  

  // api command 세팅 끝
  //챗봇 새창열기
  const openChatbot = () => {
    if (chatBotData.dataType === 10) {
      showTextPop("지식이 없습니다. 지식관리를 이용해주세요");
      return;
    }

    window.open(`/chat/${chatBotData.botUid}`);
  };

  const modifyBotInfo = async (botInfoParams, exList, bottomMenuList, apiCommandList) => {
    // console.log(">>>> modify exList list:", exList);
    // console.log(">>>> modify bottomMenuList:", bottomMenuList);
    console.log("apiCommandList", apiCommandList);
    try {
      const { status, data } = await botApi.botInfoModify(botInfoParams, exList, bottomMenuList, apiCommandList);

      if (status === 200) {
        const botInfo = JSON.parse(data.botInfo);
        setUserChatBotList((prev) =>
          prev.map((item) => {
            if (item.botUid === botInfo.botUid) {
              botInfo.label = item.botName;
              return Object.assign({}, item, botInfo);
            }
            return item;
          })
        );
        setChatBotData(botInfo);
        showTextPop("수정 되었습니다.");
      }
    } catch (error) {
      ErrorCatch(error, handleSignOutApp);
    }
  };

  const disposalBot = async () => {
    try {
      const { status } = await botApi.disposalBot(chatBotData.botUid, chatBotData.indexName);
      if (status === 200) {
        showTextPop("정상적으로 삭제 했습니다.");
        setChatBotData(null);
        sessionStorage.removeItem("botData"); // 당연하게도 현재 세션의 봇은 삭제한 봇일거니..
        getUserChatBotList();
      }
    } catch (error) {
      ErrorCatch(error, handleSignOutApp);
    }
  };

  const getAdditionalData = async (botUid) => {
    // 예시질문, 하단버튼 메뉴 불러오기.
    setLoading(true);
    try {
      const exqListnResponse = await botApi.selectBotExQuestion(botUid);
      if (exqListnResponse.status === 200 && exqListnResponse.data?.infoList) {
        setExList(JSON.parse(exqListnResponse.data.infoList));
      } else {
        setExList([]);
      }
      const bottomMenuResponse = await botApi.selectBottomMenuList(botUid);
      if (bottomMenuResponse.status === 200 && bottomMenuResponse.data?.bottomMenuList) {
        setBottomMenuList(JSON.parse(bottomMenuResponse.data.bottomMenuList));
      } else {
        setBottomMenuList([]);
      }
    } catch (error) {
      ErrorCatch(error);
    } finally {
      setLoading(false);
    }
  };

  //
  const beforeDeleteFunc = () => {
    if (window.confirm("삭제 하시겠습니까?")) disposalBot();
  };
  // 모든 탭에 대한 저장 전 체크 일괄
  const beforeModifyFunc = () => {
    // 챗봇명 200자 제한
    const checkBotName = (type) => {
      if (type === 0) return !((botName?.trim()?.length ?? 0) === 0);
      else return !((botName?.trim()?.length ?? 0) > 200);
    };

    // 챗봇 환영인사 200자 제한
    const checkGreetingMessage = (type) => {
      if (type === 0) return !((greetingMessage?.trim()?.length ?? 0) === 0);
      else return !((greetingMessage?.trim()?.length ?? 0) > 200);
    };

    // 챗봇 내부 소개 1000자 제한
    const checkIntroInText = (type) => {
      if (type === 0) return !((introIn?.trim()?.length ?? 0) === 0);
      else return !((introIn?.length ?? 0) > 1000);
    };

    /*
			더보기 메뉴명 20자 제한 처리
			1.소개 introInName
			2.예시질문 exqInName
			3. 외부URL 팝업1 introOutName
			4. 외부URL 팝업2 exqOutName
		*/
    const checkMenuNameLength = (type) => {
      // 추가처리가 필요할 지 몰라 그냥 4개 모두 작성 .
      if (type === 1) {
        return (introInName?.length ?? 0) > 20;
      } else if (type === 2) {
        return (exqInName?.length ?? 0) > 20;
      } else if (type === 3) {
        return (introOutName?.length ?? 0) > 20;
      } else if (type === 4) {
        return (exqOutName?.length ?? 0) > 20;
      }
    };

    // 챗봇 외부 소개 페이지 URL 255자 제한
    const checkIntroOutText = (type) => {
      if (type === 0) return !((introOut?.trim()?.length ?? 0) === 0);
      else return !((introOut?.length ?? 0) > 255);
    };

    // 챗봇 예시 질문 내부 ()

    // 챗봇 예시 질문 외부 페이지 ()
    const checkExqOut = (type) => {
      if (type === 0) return !((exqOut?.trim()?.length ?? 0) === 0);
      else return !((exqOut?.length ?? 0) > 255);
    };

    // api command 체크
    // api command 사용 여부를 true 로 했을 때, 버튼 배열의 길이가 0이면 막아야 하나?
    // 다른 조건은 필요 없는지 애매하다.
    const checkApiCommand = () => {};

    const urlRegex = /^(?:(http:\/\/|https:\/\/))?((?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,})(\/.*)?$/;

    // 실제 체크 시작.
    if (!checkBotName(0)) {
      showTextPop("챗봇 명칭을 입력해 주세요.");
      return;
    }

    if (!checkBotName(1)) {
      showTextPop("챗봇이름은 200자까지 입력 가능합니다.");
      return;
    }

    if (!checkGreetingMessage(0)) {
      showTextPop("환영인사 메세지를 작성해주세요.");
      return;
    }

    if (!checkGreetingMessage(1)) {
      showTextPop("환영인사 메세지는 최대 200자 입니다.");
      return;
    }

    if (introInName.length > 0) {
      if (checkMenuNameLength(1)) {
        showTextPop("내부 팝업 버튼 이름은 20자 제한입니다.");
        return;
      }
    }

    if (introIn.length > 0) {
      // 작성을했다면 1000자를 못넘게 하자.
      if (!checkIntroInText(1)) {
        showTextPop("내부 팝업 상세 내용은 1000자 제한입니다.");
        return;
      }
    }

    if (exqInName.length > 0) {
      if (checkMenuNameLength(2)) {
        showTextPop("예시 질문 버튼 이름은 20자 제한입니다.");
        return;
      }
    }

    if (introOutName.length > 0) {
      if (checkMenuNameLength(3)) {
        showTextPop("외부URL 버튼1 이름은 20자 제한입니다.");
        return;
      }
    }

    if (exqOutName.length > 0) {
      if (checkMenuNameLength(4)) {
        showTextPop("외부URL 버튼2 이름은 20자 제한입니다.");
        return;
      }
    }

    if (introOut.length > 0) {
      if (!urlRegex.test(introOut)) {
        showTextPop("외부URL 연결1은 URL형식만 작성가능합니다!");
        return;
      }

      if (!checkIntroOutText(1)) {
        showTextPop("외부URL 연결1은 255자 제한입니다.");
        return;
      }
    }

    //예시 질문에 대한 처리
    if (exqOut.length > 0) {
      if (!urlRegex.test(exqOut)) {
        showTextPop("외부URL 연결2는 URL형식만 작성가능합니다!");
        return;
      }

      if (!checkExqOut(1)) {
        showTextPop("외부URL 연결2는 255자 제한입니다.");
        return;
      }
    }

    // 응답 설정 -> 지식 관리 수정
    console.log("knowledgeManageDescription", knowledgeManageDescription);
    if (knowledgeManageDescription.trim().length > 100) {
      showTextPop("지식 관리 설명문은 100자 제한입니다.");
      return;
    }

    // 240809.janghyuck shortUrl 글자 수 제한 ( 임의로 50자 제한 limit )
    if (!!shortUrl && shortUrl.trim().length > 50) {
      return showTextPop("사용자 URL은 50자 제한입니다.");
    }
    
    // 중복검사가 된 상태에서, 타이핑을 통해 추가 수정을 하고 저장하는것을 방지하기 위한 코드. and false인 상태에서 저장 방지
    if (shortUrl !== orgnalShortUrl && (!availableShortUrl || isTypedShortUrl)) {
      return showTextPop(isTypedShortUrl ? "중복검사를 진행 후 다시 시도해 주세요." : "현재 사용중인 사용자 URL이 존재합니다. 다른 사용자 URL을 입력해 주세요.");
    }

    showConfirmPopCommonCallback("수정 하시겠습니까?", "", () => {
      const ratio = (100 - sliderMaxVal) / 100;
      console.log("useChatMarkdown", useChatMarkdown, useChatMarkdown ? "Y" : "N", chatBotData.botUid);

      let botInfoParams = {
        botUid: chatBotData.botUid,
        botName,
        greetingMessage,
        botRatio: ratio,
        botRole,
        showSource: showSource ? "Y" : "N",
        useRsearch: useRsearch ? "Y" : "N",
        useAddPrompt: useAddPrompt ? "Y" : "N",
        useComMenu: useComMenu ? "Y" : "N",
        useKnowledgeManage: useKnowledgeManage ? "Y" : "N",
        useChatMarkdown: useChatMarkdown ? "Y" : "N",
        knowledgeManageDescription,
        useBottomMenu,
        botImagePath,
        introInName,
        introIn,
        introOutName,
        introOut,
        exqInName,
        exqOutName,
        exqOut,
        setExqOut,
        chatThemeType,
        shortUrl: availableShortUrl ? shortUrl : orgnalShortUrl,
      };

      modifyBotInfo(botInfoParams, exList, bottomMenuList, apiCommandList);
    });
  };

  const remaining = () => {
    // let num = botData.totalCount - botData.useCount;
    let num = chatBotData.useCount;

    if (isNaN(num)) return 0;

    if (num.toString().length > 3) {
      num = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return num < 0 ? 0 : num;
  };

  const usesSizeInfo = () => {
    if (!chatBotData.totalUsageValue) return `새로고침 필요`;
    var totalUsageString = chatBotData.totalUsageValue.toString();
    var strSizeInfo = `${totalUsageString.replace(/\B(?=(\d{3})+(?!\d))/g, ",")} byte`;

    // Byte to KB
    if (chatBotData.totalUsageValue >= 1024) {
      const totalUsageKB = (chatBotData.totalUsageValue / 1024).toFixed(2);
      strSizeInfo = `${totalUsageKB.replace(/\B(?=(\d{3})+(?!\d))/g, ",")} KB`;
    }

    // Byte to MB
    if (chatBotData.totalUsageValue >= 1024 * 1024) {
      const totalUsageMB = (chatBotData.totalUsageValue / (1024 * 1024)).toFixed(2);
      strSizeInfo = `${totalUsageMB.replace(/\B(?=(\d{3})+(?!\d))/g, ",")} MB`;
    }

    if (chatBotData.serviceSize) {
      const maxSize = chatBotData.serviceSize * 1024 * 1024;
      const currSize = Number(chatBotData.totalUsageValue);
      const avg = (currSize / maxSize) * 100;

      if (!isMobile) {
        strSizeInfo = strSizeInfo + `<br/> (${avg.toFixed(2)}%)`;
      } else {
        strSizeInfo = strSizeInfo + ` (${avg.toFixed(2)}%)`;
      }
    }
    return strSizeInfo;
  };

  const handleExpireBtn = () => {
    showExpirePop(
      !isMobile
        ? `요금제 만료일 이후에 유료 모델 사용을 유지하시려면<br/> 아래의 연장하기 버튼을 눌러주시기 바랍니다.<br/> 연장 신청 후 담당자가 연장 관련 안내를 드릴 예정입니다.<br/> 나의 연장 문의는 [문의하기]로 자동 등록돼<br/> 좌측의 문의하기 메뉴에서도 확인하실 수 있습니다.`
        : `요금제 만료일 이후에 유료 모델 사용을 유지하시려면 아래의 연장하기 버튼을 눌러주시기 바랍니다. 연장 신청 후 담당자가 연장 관련 안내를 드릴 예정입니다. 나의 연장 문의는 [문의하기]로 자동 등록돼 좌측의 문의하기 메뉴에서도 확인하실 수 있습니다.`
    );
  };

  useEffect(() => {
    if (!chatBotData) return;

    console.log("chatBotData", chatBotData);
    //수정 모드에서 셀렉트 박스로 봇 변경 시 정보 변경을 위함
    setBotName(chatBotData.botName);
    setGreetingMessage(chatBotData.greetingMessage);

    const MaxVal = 100 - (chatBotData.botRatio ?? 0.2) * 100;
    const MinVal = 100 - MaxVal;
    setSliderMaxVal(MaxVal);
    setSliderMinVal(MinVal);
    setBotRatioVal([0, MaxVal]);
    setBotRole(chatBotData.botRole);
    setUseBottomMenu(chatBotData.useBottomMenu);
    if (chatBotData.showSource) {
      setShowSource(chatBotData.showSource === "Y" ? true : false);
    } else {
      setShowSource(false);
    }
    if (chatBotData.useAddPrompt) {
      setUseAddPrompt(chatBotData.useAddPrompt === "Y" ? true : false);
    } else {
      setUseAddPrompt(false);
    }

    if (chatBotData.useRsearch) {
      setUseRsearch(chatBotData.useRsearch === "Y" ? true : false);
    } else {
      setUseRsearch(false);
    }

    if (chatBotData.useChatMarkdown) {
      setUseChatMarkdown(chatBotData.useChatMarkdown === "Y");
    }

    setUseKnowledgeManage(chatBotData?.useKnowledgeManage === "Y");
    if (!!chatBotData?.knowledgeManageDescription)
      setKnowledgeManageDescription(chatBotData.knowledgeManageDescription);

    if (chatBotData.createAt) {
      // botData.createAt : 23-11-08 09:49 그러니 20을 일단 더해준다.
      const strTmp = "20" + chatBotData.createAt; // ex) 2023-11-08 09:49
      // 7일 이내의 경우 연장 버튼을 활성화 시켜준다.
      const strDate = new Date(strTmp);
      const baseDate = new Date("2023-11-20 00:00");
      setShowRsearch(strDate > baseDate);
    } else {
      setShowRsearch(false);
    }
    
    if (!!chatBotData?.shortUrl) {
      setShortUrl(chatBotData.shortUrl);
      setOrgnalShortUrl(chatBotData.shortUrl);
    }

    setBotImagePath(chatBotData.botImagePath);
    if (chatBotData.expireDate) {
      // botData.expireDate : 23-11-08 그러니 20을 일단 더해준다.
      const strTmp = "20" + chatBotData.expireDate; // ex) 2023-11-08
      // 7일 이내의 경우 연장 버튼을 활성화 시켜준다.
      const strDate = new Date(strTmp);
      const today = new Date();
      const timeDiff = strDate - today;
      const daysRemain = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
      if (daysRemain <= 7) {
        setExpireDateState(true);
      } else {
        setExpireDateState(false);
      }
    } else {
      setExpireDateState(false);
    }
    //
    setIntroInName(chatBotData.introInName ?? "");
    setIntroIn(chatBotData.introIn ?? "");
    setIntroOutName(chatBotData.introOutName ?? "");
    setIntroOut(chatBotData.introOut ?? "");

    setExqInName(chatBotData.exqInName ?? "");
    // 예시질문 목록은 별도로 리스트로 관리 되고 있음에 주의.
    setExqOut(chatBotData.exqOut ?? "");
    setExqOutName(chatBotData.exqOutName ?? "");

    if (chatBotData?.useComMenu) {
      setUseComMenu(chatBotData.useComMenu === "Y");
    }

    // 내부 예시질문, 하단 버튼 메뉴에 대해서 불러온다.
    getAdditionalData(chatBotData.botUid);
    // eslint-disable-next-line react-hooks/exhaustive-deps

    // theme설정
    setChatThemeType(chatBotData.chatThemeType);
    
    if (!userPlan.isPremiumUser && tabState === "themeSet") {
     showTextPop("해당 기능은 프리미엄 유저만 이용 가능합니다.", () => {
       setTabState("baseSet");
     });
    }
  }, [chatBotData]);
  
  const validateShortUrl = async () => {
    if (!shortUrl || (!!shortUrl && shortUrl.trim().length === 0)) return showTextPop("사용자 URL을 입력해 주세요.");
    if (shortUrl === orgnalShortUrl) return showTextPop("기존에 입력된 사용자 URL을 수정 후 다시 시도해 주세요.");
    
    try {
      const result = await botApi.validateShortUrl(shortUrl);
      setAvailableShortUrl(true);
      setIsTypedShortUrl(false);
      return showTextPop("사용 가능한 사용자 URL 입니다.");
    } catch (err) {
      console.log('err', err);
      setAvailableShortUrl(false);
      return showTextPop("현재 사용중인 사용자 URL이 존재합니다. 다른 사용자 URL을 입력해 주세요.");
    }
  }

  if (!chatBotData || !userChatBotList) {
    return (
      <DASH_HOME.Container>
        <ChatEmpty>아직 생성 된 챗봇이 없습니다.</ChatEmpty>
      </DASH_HOME.Container>
    );
  }

  return (
    <DASH_HOME.Container>
      {loading && <LoadingComponent />}
      {/* <Title>챗봇 관리</Title> */}
      <DASH_HOME.ContentCol style={{ height: "auto" }}>
        <SelectCt>
          <FlexColumnBox
            style={
              !isMobile
                ? {
                    marginBottom: "10px",
                    width: "70%",
                  }
                : {
                    marginBottom: "10px",
                    width: "100%",
                  }
            }
          >
            <ChatBotSelect />
          </FlexColumnBox>
        </SelectCt>
        {/* 탭이 들어간다  */}
        <TabBtnCt>
          <button
            className={tabState === "baseSet" ? "active" : ""}
            onClick={() => {
              setTabState("baseSet");
            }}
          >
            기본설정
          </button>
          <button
            className={tabState === "menuSet" ? "active" : ""}
            onClick={() => {
              setTabState("menuSet");
            }}
          >
            더보기설정
          </button>
          <button
            className={tabState === "botBtnSet" ? "active" : ""}
            onClick={() => {
              setTabState("botBtnSet");
            }}
          >
            메뉴설정
          </button>
          <button
            className={tabState === "themeSet" ? "active" : ""}
            onClick={() => {
              if (!userPlan.isPremiumUser)
                return showTextPop("해당 기능은 프리미엄 요금제로 변경한 유저만 가능한 기능입니다.");
              setTabState("themeSet");
            }}
          >
            챗봇테마
            {!userPlan?.isPremiumUser && <i className="ri-information-line"></i>}
          </button>
          {/* API 연동 설정은 일단 완성까지는 비공개. */}
          {/* {!isMobile && (
            <button
              className={tabState === "apiCommandSet" ? "active" : ""}
              onClick={() => {
                setTabState("apiCommandSet");
              }}
            >
              API연동 설정
            </button>
          )} */}
        </TabBtnCt>
        {/* 데이터 컨테이너 */}
        <div style={{ width: "100%" }}>
          <InfoTableCt>
            <div style={{ textAlign: "center" }}>
              <BotDataCt>
                <BotDataTag>
                  <p className="title">질문 사용개수</p>
                  <p className="number">
                    {remaining()} / {String(chatBotData.totalCount).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </p>
                </BotDataTag>
                <BotDataTag className="Volume">
                  <p className="title">지식 사용량</p>
                  <div className="number usesSize">
                    <div dangerouslySetInnerHTML={{ __html: usesSizeInfo() }}></div>
                  </div>
                </BotDataTag>
                <BotDataTag>
                  <p className="title">봇 생성일</p>
                  <p className="number">{chatBotData.createAt}</p>
                </BotDataTag>

                <BotDataTag>
                  <p className="title">멤버십</p>
                  <p className="number text">{priceUtil.botServiceModel(chatBotData.serviceModel)}</p>
                </BotDataTag>

                <BotDataTag className="expire">
                  <p className="title">
                    요금제 만료일
                    {expireDateState && (
                      <i
                        className={toolTip ? "ri-error-warning-line icon active" : "ri-error-warning-line icon "}
                        onMouseOver={() => {
                          setTooltip((prev) => !prev);
                        }}
                        onMouseOut={() => {
                          setTooltip((prev) => !prev);
                        }}
                      >
                        {!isMobile && (
                          <span className="expireTooltip">
                            요금제 만료일 경과 시, <br /> 유료 모델의 기능 사용이 제한됩니다.
                          </span>
                        )}
                      </i>
                    )}
                  </p>
                  <div className="expireCt">
                    <p className="number text">
                      {chatBotData.serviceModel === "0"
                        ? "유료버전 미사용"
                        : chatBotData.expireDate
                          ? chatBotData.expireDate
                          : "무기한"}
                    </p>
                    {expireDateState && (
                      <button
                        className="expireBtn"
                        onClick={() => {
                          handleExpireBtn();
                        }}
                      >
                        연장
                      </button>
                    )}
                  </div>
                </BotDataTag>
              </BotDataCt>

              {/* 버튼컨테이너 */}
              <BtnCt>
                <NormalButton
                  style={{ marginLeft: 0 }}
                  buttonId="loginBtn"
                  buttonTitle={"챗봇 삭제"}
                  buttonClass={isMobile ? "small cDel round" : "mid cDel round"}
                  callBackFunc={beforeDeleteFunc}
                />
                <NormalButton
                  buttonId="loginBtn"
                  buttonTitle={"설정 저장"}
                  buttonClass={isMobile ? "small cGreen round" : "mid cGreen round"}
                  callBackFunc={beforeModifyFunc}
                />
                <NormalButton
                  buttonId="loginBtn"
                  buttonTitle={"챗봇 실행"}
                  buttonClass={isMobile ? "small cOrange round" : "mid cOrange round"}
                  callBackFunc={openChatbot}
                />
              </BtnCt>
            </div>
          </InfoTableCt>
        </div>
        {/* 챗봇 설정 컨테이너 */}

        {/* 기본설정 변경 부분  */}
        {tabState === "baseSet" && (
          <div style={{ width: "100%" }}>
            <BaseSettingTab
              baseProps={{
                botName,
                setBotName,
                greetingMessage,
                setGreetingMessage,
                useAddPrompt,
                setUseAddPrompt,
                useRsearch,
                setUseRsearch,
                showRsearch,
                expireDateState,
                botRole,
                setBotRole,
                botImagePath,
                setBotImagePath,
                botRatioVal,
                setBotRatioVal,
                sliderMaxVal,
                setSliderMaxVal,
                sliderMinVal,
                setSliderMinVal,
                useKnowledgeManage,
                setUseKnowledgeManage,
                knowledgeManageDescription,
                setKnowledgeManageDescription,
                useChatMarkdown,
                setUseChatMarkdown,
                shortUrl,
                setShortUrl,
                validateShortUrl,
                isTypedShortUrl,
                setIsTypedShortUrl
              }}
            />
          </div>
        )}

        {/* 소개 & 예시질문 변경 부분 */}
        {tabState === "menuSet" && (
          <div style={{ width: "100%" }}>
            <GuideSettingTab
              guideFuncs={{
                botData: chatBotData,
                exList,
                setExList,
              }}
              guideProps={{
                introInName,
                setIntroInName,
                introOutName,
                setIntroOutName,
                introIn,
                setIntroIn,
                introOut,
                setIntroOut,
                exqInName,
                setExqInName,
                exqOutName,
                setExqOutName,
                exqText,
                setExqText,
                exqOut,
                setExqOut,
                expireDateState,
              }}
            />
          </div>
        )}

        {/* 하단버튼에 대한 변경 부분 */}
        {tabState === "botBtnSet" && (
          <div style={{ width: "100%" }}>
            <BottomBtnTab
              menuProps={{
                botData: chatBotData,
                bottomMenuList,
                setBottomMenuList,
                useBottomMenu,
                setUseBottomMenu,
              }}
            />
          </div>
        )}

        {tabState === "themeSet" && (
          <div style={{ width: "100%" }}>
            <ThemeSettingTab
              chatThemeType={chatThemeType}
              setChatThemeType={setChatThemeType}
              menuProps={{
                botData: chatBotData,
              }}
            />
          </div>
        )}

        {/* 하단버튼에 대한 변경 부분 */}
        {tabState === "apiCommandSet" && !isMobile && (
          <div style={{ width: "100%" }}>
            <ApiCommandSetting
              menuProps={{
                botData: chatBotData,
                useComMenu,
                setUseComMenu,
                apiCommandList,
                setApiCommandList,
              }}
            />
          </div>
        )}
      </DASH_HOME.ContentCol>
    </DASH_HOME.Container>
  );
}

const SelectCt = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  /* margin-bottom: 15px; */

  /* 700임시 */
  @media (max-width: 700px) {
    width: 100%;
    margin: 10px auto 50px;
  }

  @media (max-width: 480px) {
    margin: 10px auto 5px;
  }
`;

const InfoTableCt = styled.div`
  @media (max-width: 700px) {
    width: 100%;
  }
  display: block;
  width: 70%;
`;

const TabBtnCt = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 5px 0px;
  margin-bottom: 10px;
  justify-content: center;
  width: 100%;

  @media (max-width: 480px) {
    padding-top: 0;
    margin-bottom: 5px;
  }

  @media (min-width: 481px) {
    width: fit-content;
    align-self: start;
    justify-content: unset;
  }

  & button {
    padding: 10px 15px 5px;
    margin-left: 5px;
    color: #888;
    position: relative;

    font-size: 15px;

    @media (max-width: 480px) {
      padding-top: 0;
      font-size: 14px;
      word-break: keep-all;
    }
    @media (max-width: 390px) {
      letter-spacing: -1px;
    }

    &:not(:last-child):after {
      content: "";
      position: absolute;

      width: 1.5px;
      height: 100%;
      right: 0;
      background: #dcdcdc;

      @media (max-width: 480px) {
        top: 0;
      }
      @media (min-width: 481px) {
        height: 60%;
      }
    }

    &.active {
      color: #ff892a;
      font-weight: 500;
      text-decoration: underline;
      text-underline-position: under;
      /* text-underline-offset: 5px; */
      text-underline-offset: 1.5px;
      text-decoration-thickness: 3px;

      @media (max-width: 480px) {
        text-decoration-thickness: 2px;
      }
    }
  }
`;

const BotDataCt = styled.div`
  display: flex;
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.18);
  margin-bottom: 20px;

  flex-wrap: wrap;

  @media (max-width: 480px) {
    border-radius: 10px;
  }
`;

const BotDataTag = styled.div`
  flex: 1 0 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-height: 150px;
  padding: 15px 1vw;

  &.expire {
    position: relative;

    p.title {
      position: relative;
    }
  }

  &:not(:last-child) {
    border-right: 1px solid #dcdcdc;
  }

  p {
    text-align: center;

    &.title {
      font-weight: 500;
      line-height: 15px;
      letter-spacing: -0.5px;
      font-size: 13px;
      margin: auto 0;
      word-break: keep-all;

      & .icon {
        position: absolute;
        bottom: 0;
        color: tomato;
        font-size: 16px;
        cursor: pointer;

        & .expireTooltip {
          display: none;
        }

        &.active {
          display: inline-block;

          & .expireTooltip {
            position: absolute;
            /* background: linear-gradient(135deg, #ff892a, #ffc51e); */
            /* background: tomato; */
            background: rgba(25, 195, 125, 0.9);
            border-radius: 5px;
            top: 0%;
            right: 130%;
            display: block;
            width: 220px;
            font-size: 13px;
            letter-spacing: -1px;
            line-height: 15px;
            word-break: keep-all;
            padding: 10px;
            color: #000;
            /* color: tomato; */
            font-weight: 600;

            &:after {
              content: "";
              width: 10px;
              height: 10px;
              position: absolute;
              top: 0;
              right: 0;
              /* border: 1px solid tomato; */
              /* background: tomato; */
              background: #19c37d;
              transform: rotate(45deg) translateX(50%);
              /* border-left: 1px solid #777; */
              /* border-right: 1px solid #777; */
              /* border-bottom: 1px solid #777; */
            }
          }
        }
      }
    }
  }

  div.number,
  p.number {
    color: #232323;
    font-size: 14px;
    letter-spacing: -0.5px;
    font-weight: 700;
    height: 50px;
    max-width: 115px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 480px) {
      word-break: keep-all;
      padding: 0 15px;
    }

    &.usesSize {
      max-width: 100%;
    }

    &.text {
      color: #ff892a;
    }

    &.wide {
      color: #ff892a;
      max-width: 145px;
    }
  }

  .expireCt {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    .text {
      word-break: keep-all;
    }
  }

  .expireBtn {
    width: 40px;
    height: 20px;

    background: tomato;
    color: #fff;
    font-weight: 700;
    border-radius: 30px;
    font-size: 12px;
    letter-spacing: -1px;
    margin-left: 5px;
    line-height: 10px;

    &:hover {
      background: #19c37d;
      color: #fff;
    }

    @media (max-width: 480px) {
      position: unset;
      margin-left: 10px;
    }
  }

  @media (max-width: 480px) {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    /* order: 2; */
    flex: 1 0 55%;
    padding: 7.5px 10px;

    p.title {
      width: 40%;
    }

    div.number,
    p.number {
      flex: 0 0 auto;
      height: 15px;
      font-size: 13px !important;
      max-width: unset;
    }

    &:not(:last-child) {
      border-right: none;
    }

    &.Volume {
      /* order: 1; */

      div.number,
      p.number {
      }
    }
  }
`;

const BtnCt = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 auto;

  & button {
    font-size: 14px;
    min-width: 120px;
    margin: 0 5px;
    @media (max-width: 480px) {
      flex: 0 1 auto;
      min-width: 95px;
    }
  }

  @media (max-width: 700px) {
    flex: 1 1 100%;
    margin: 15px 10px 15px !important;
    height: auto !important;
    word-break: keep-all;
  }
  @media (max-width: 480px) {
    width: auto;
  }
`;
