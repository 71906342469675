/* eslint-disable */
import styled from "styled-components";
import { useContext, useEffect, useRef, useState } from "react";
import uuid from "react-uuid";
import NormalButton from "components/atomic/NormalButton2";
import BotBottomMenuAddPop from "components/templates/popup/BotBottomMenuAddPop";

import icon1 from "../../../assets/images/icon_chat01.svg";
import icon2 from "../../../assets/images/icon_chat02.svg";
import icon3 from "../../../assets/images/icon_chat03.svg";
import icon4 from "../../../assets/images/icon_chat04.svg";
import icon5 from "../../../assets/images/icon_chat05.svg";
import icon6 from "../../../assets/images/icon_chat06.svg";
import ConfirmPopDefault from "components/templates/popup/ConfirmPopDefault";

import iconCheck from "../../../assets/images/icon_checkWhite.svg";
import { isMobileContext } from "utils/providers/BrowserEnvContextProvider";

export default function BottomBtnTab({ menuProps }) {
  const {
    botData,
    bottomMenuList,
    setBottomMenuList,
    useBottomMenu,
    setUseBottomMenu,
  } = menuProps;

  const [editBtnInfo, setEditBtnInfo] = useState();

  const { isMobile } = useContext(isMobileContext);
  // pop
  const [bottomBtnPop, setBottomBtnPop] = useState(false);
  const [bottomBtnPopType, setBottomBtnPopType] = useState("add");
  const [popCallback, setPopCallback] = useState({
    normal: () => {},
    cancel: () => {},
  });

  const [callback, setCallback] = useState(() => {});

  const [showConfirmDefault, setShowConfirmDefault] = useState(false);

  const [updateInfo, setUpdateInfo] = useState(null);

  useEffect(() => {
    if (bottomBtnPopType === "modify") {
      setPopCallback({
        normal: (editBtnInfo, updateInfo) => {
          handleEditBtn(editBtnInfo, updateInfo);
        },
        cancel: () => {},
      });
    } else {
      setPopCallback({
        normal: (editBtnInfo, updateInfo) => {
          handleAddBtn(null, updateInfo);
        },
        cancel: () => {},
      });
    }
  }, [bottomBtnPopType]);

  // 하단메뉴 사용관련 라디오 버튼 핸들링
  const handleRadioChange = (e) => {
    setUseBottomMenu(e.target.value);
  };

  // List 추가
  const handleAddBtn = (seqNum, updatedInfo) => {
    updatedInfo.bmUid = uuid();
    updatedInfo.botUid = botData.botUid;
    setBottomMenuList((prevList) => [...prevList, updatedInfo]);
  };
  // List 수정
  const handleEditBtn = (seqNum, updatedInfo) => {
    setBottomMenuList((prevList) => {
      const updatedList = prevList.map((item) =>
        item.seqNum === seqNum ? { ...item, ...updatedInfo } : item
      );
      return updatedList;
    });
  };

  // List 삭제
  const handleDeleteBtn = (seqNum) => {
    let updatedList = bottomMenuList.filter((item) => item.seqNum !== seqNum);
    setBottomMenuList(updatedList);

    setBottomMenuList((prevList) =>
      prevList.map((item, idx) =>
        item.seqNum !== idx ? { ...item, seqNum: idx + 1 } : item
      )
    );
  };

  const handleBottomBtnPop = (item, e) => {
    const { type } = e.target.dataset;

    if (type === "delete") {
      handleDeleteBtn(item.seqNum);
    } else if (type === "modify") {
      setEditBtnInfo(item.seqNum);
      setBottomBtnPopType(type);
      setBottomBtnPop((prev) => !prev);
    } else {
      setBottomBtnPopType(type);
      setBottomBtnPop((prev) => !prev);
    }
  };

  // 버튼에 맞는 icon선택 처리
  const setButtonImage = (imgNo) => {
    if (imgNo === 1) return icon1;
    else if (imgNo === 2) return icon2;
    else if (imgNo === 3) return icon3;
    else if (imgNo === 4) return icon4;
    else if (imgNo === 5) return icon5;
    else if (imgNo === 6) return icon6;
    else return icon1;
  };

  return (
    <TableCt>
      {bottomBtnPop && (
        <BotBottomMenuAddPop
          bottomMenuList={bottomMenuList}
          bottomBtnPopType={bottomBtnPopType}
          setBottomBtnPop={setBottomBtnPop}
          updateInfo={updateInfo}
          setUpdateInfo={setUpdateInfo}
          editBtnInfo={editBtnInfo}
          callback={popCallback?.normal}
          cancelCallback={popCallback?.cancel}
        />
      )}
      {/* 챗봇 하단메뉴 사용 토글 시작 */}
      <BotModifyCt>
        <BotinfoCt>
          <BotInfoRow className="column">
            <BotInfoTitle>하단메뉴</BotInfoTitle>
            <RadioBtnCt icon={iconCheck} calssName="">
              <label
                className={useBottomMenu === "N" ? "toggle active" : "toggle"}
              >
                <span
                  className={useBottomMenu === "N" ? "check active" : "check"}
                ></span>
                <input
                  type="radio"
                  name="useBotMenu"
                  value="N"
                  defaultChecked={useBottomMenu === "N"}
                  onChange={handleRadioChange}
                />
                사용 안함
              </label>

              <label
                className={useBottomMenu === "Y" ? "toggle active" : "toggle"}
              >
                <span
                  className={useBottomMenu === "Y" ? "check active" : "check"}
                ></span>
                <input
                  type="radio"
                  name="useBotMenu"
                  value="Y"
                  defaultChecked={useBottomMenu === "Y"}
                  onChange={handleRadioChange}
                />
                사용함
              </label>
              <label
                className={useBottomMenu === "A" ? "toggle active" : "toggle"}
              >
                <span
                  className={useBottomMenu === "A" ? "check active" : "check "}
                ></span>
                <input
                  type="radio"
                  name="useBotMenu"
                  value="A"
                  defaultChecked={useBottomMenu === "A"}
                  onChange={handleRadioChange}
                />
                항상 표시
              </label>
            </RadioBtnCt>
            {/* {useBottomMenu ? (
              <BotInfoTitle className="subTitle active">활성화</BotInfoTitle>
            ) : (
              <BotInfoTitle className="subTitle">비활성화</BotInfoTitle>
            )} */}
          </BotInfoRow>
        </BotinfoCt>
      </BotModifyCt>

      {/* 하단메뉴 추가 버튼 영역 */}
      <BtnCt>
        <NormalButton
          callBackFunc={(e) => {
            handleBottomBtnPop(null, e);
          }}
          style={{ marginLeft: "0" }}
          type={"add"}
          buttonClass={"small cOrange round"}
          buttonTitle={"버튼 추가"}
        ></NormalButton>
      </BtnCt>
      {/* 하단메뉴 추가 버튼 영역 */}
      {/* 챗봇 하단메뉴 사용 토글 끝 */}

      {/* 챗봇 하단메뉴 List 시작 */}
      <BotModifyCt>
        <BotinfoCt>
          <BotInfoTitle>하단메뉴 목록</BotInfoTitle>

          {bottomMenuList.length < 1 ? (
            <BotInfoTitle className="subTitle center">
              추가된 하단메뉴 버튼이 없습니다.
            </BotInfoTitle>
          ) : (
            <BotInfoRow>
              <table className="btnListTable">
                <colgroup>
                  {isMobile ? (
                    <>
                      <col width="13%"></col>
                      <col width="13%"></col>
                      <col width="40%"></col>
                      <col width="15%"></col>
                      <col width="15%"></col>
                    </>
                  ) : (
                    <>
                      <col width="7%"></col>
                      <col width="5%"></col>
                      <col width="40%"></col>
                      <col width="15%"></col>
                      <col width="20%"></col>
                    </>
                  )}
                </colgroup>
                <thead>
                  <tr>
                    <td>순서</td>
                    <td>Icon</td>
                    <td>이름</td>
                    <td>타입</td>
                    <td>설정</td>
                  </tr>
                </thead>
                <tbody>
                  {bottomMenuList.map((item, idx) => {
                    return (
                      <tr key={idx}>
                        <td>{item.seqNum}</td>
                        <td className="iconTd">
                          {
                            <img
                              src={setButtonImage(Number(item.imgNo))}
                              alt=""
                            />
                          }
                        </td>
                        <td>{item.name}</td>
                        <td>
                          {Number(item.operType) === 1
                            ? "텍스트"
                            : Number(item.operType) === 2
                            ? "URL"
                            : "부모URL"}
                        </td>
                        <td>
                          <button
                            onClick={(e) => {
                              setShowConfirmDefault((prev) => !prev);
                              setCallback(() => {
                                return () => handleBottomBtnPop(item, e);
                              });
                            }}
                            data-type={"delete"}
                            className="delete"
                          >
                            삭제
                          </button>
                          <button
                            onClick={(e) => {
                              handleBottomBtnPop(item, e);
                            }}
                            data-type={"modify"}
                            className="modify"
                          >
                            수정
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </BotInfoRow>
          )}
        </BotinfoCt>
      </BotModifyCt>

      {showConfirmDefault && (
        <ConfirmPopDefault
          setShowConfirmDefault={setShowConfirmDefault}
          title={"삭제하시겠습니까?"}
          callback={callback}
        />
      )}

      {/* 챗봇 하단메뉴 List 끝 */}
    </TableCt>
  );
}

const BotModifyCt = styled.div`
  border-radius: 10px;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.16);
  background: #fff;
  padding: 15px;
  padding-top: 20px;
  text-align: center;
  margin-top: 15px;

  button {
    &.change {
      & span {
        display: inline-block;
        width: 28px;
        @media (max-width: 480px) {
          width: 16px;
        }
      }
    }
  }
`;

const BotinfoCt = styled.div`
  .circle {
    width: 20px;
    height: 20px;
    background: #f8b400;
    border-radius: 50%;
    position: absolute;
    /* top: 50%; */
    top: 0%;
    transition: transform 0.3s, background 0.3s;
  }

  /* 활성화 스타일 */
  .active .circle {
    /* top: 50%; */
    transform: translateX(25px) translateY(0%);
    background: #4caf50; /* 색상 변경 */
    box-shadow: 0px 1px 9px 1px rgba(0, 0, 0, 0.11);
  }
`;

const BotInfoTitle = styled.p`
  color: #363636;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 12.5px;
  text-align: left;

  &.subTitle {
    transition: 0.3s;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    margin-left: 10px;
    color: #f8b400;
    &.active {
      color: #4caf50;
    }
    &.center {
      color: #ddd;
      font-size: 16px;
      text-align: center;
    }
  }

  & span {
    color: #ff6161;
    font-size: 16px;
  }

  &.exQ {
    font-weight: 400;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
  }

  & button.moreBtn {
    display: inline-flex;
    margin-left: auto;
    align-items: center;
    & img {
      transform: rotate(0deg);
      width: 20px;
      height: 20px;
      margin-right: 5px;
      transition: 0.3s;
    }

    &.active {
      & img {
        transform: rotate(180deg);
      }
    }
  }

  @media (max-width: 480px) {
    font-size: 14px;
    margin-bottom: 14px;
    & span {
      font-size: 14px;
    }
  }
`;

const BotInfoRow = styled.div`
  display: flex;
  align-items: center;

  &.column {
    flex-direction: column;
    align-items: start;
  }

  & div.inputCt {
    display: flex;
    width: 100%;
    margin-bottom: 5px;
    transition: 0.3s;

    input {
      margin-right: 10px;
      flex: 0 1 auto;
    }
    button {
    }
  }

  & input {
    border-radius: 100px;

    &::placeholder {
      color: #a6a6a6;
      font-size: 16px;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.16px;
      @media (max-width: 480px) {
        font-size: 13px;
      }
    }
  }

  & input,
  textarea {
    background: #f3f3f3;

    &::placeholder {
      color: #a6a6a6;
      font-size: 16px;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.16px;

      @media (max-width: 480px) {
        font-size: 13px;
      }
    }
  }

  & div.btnCt {
    display: flex;
    width: fit-content;

    button {
      margin-left: 5px;
      font-size: 14px;
      line-height: normal;
      height: 30px;
      padding: 5px 10px;
      width: 50px;
    }
  }

  .circle {
    width: 20px;
    height: 20px;
    background: #f8b400;
    border-radius: 50%;
    position: absolute;
    /* top: 50%; */
    top: 0%;
    transition: transform 0.3s;
  }

  /* 활성화 스타일 */
  .active .circle {
    /* top: 50%; */
    transform: translateX(25px) translateY(0%);
    background: #4caf50; /* 색상 변경 */
    box-shadow: 0px 1px 9px 1px rgba(0, 0, 0, 0.11);
  }

  & table.btnListTable {
    width: 100%;

    thead {
      td {
        padding: 5px;
        color: #fff;
        font-weight: 500;
        border: 1px solid #fff;
        border-bottom: 1px splid #dcdcdc;
        background: #ff892a;
        vertical-align: middle;
      }

      text-align: center;
      font-size: 13px;
    }
    tbody {
      font-size: 12px;
      font-weight: 500;
      tr {
        &:nth-child(even) {
          background: #ffe1c8;
          color: #ff892a;
        }
      }
      td {
        vertical-align: middle;
        button {
          border-radius: 5px;
          margin: 2px;
          width: 45px;
          height: 25px;
          letter-spacing: -1px;
          &.modify {
            /* background: #19c37d;
            color: #fff; */
            background: #ff892a;
            color: #fff;
          }
          &.delete {
            /* background: darkgray;
            color: #fff; */
            color: #808080;
            background: #fff;
            border: 1px solid #808080;
          }
          &:first-child {
          }
          font-size: 11px;
        }

        &.iconTd {
          img {
            width: 25px;
            height: 25px;
          }
        }
      }
    }
  }
`;

const TableCt = styled.div`
  @media (max-width: 700px) {
    width: 100%;
  }
  display: block;
  width: 70%;
`;

const BtnCt = styled.div`
  display: flex;
  justify-content: center;
  margin: 15px 10px 15px !important;

  & button {
    line-height: normal;
    border-radius: 30px;
    font-size: 14px;
    min-width: 120px;
    font-weight: 500;
    margin-left: 20px;
    @media (max-width: 480px) {
      flex: 0 1 auto;
      margin: 0 5px;
      min-width: 100px;
    }
    &.run {
    }
  }
`;

const RadioBtnCt = styled.div`
  flex: 1 0 100%;

  .toggle {
    display: inline-flex;
    margin: 3px 7px;
    cursor: pointer;

    color: #888;
    &.active {
      color: #000;
      font-weight: 500;
    }

    & input {
      display: none;
    }

    & span.check {
      margin-right: 5px;
      display: inline-block;
      border: 1px solid darkgray;
      border-radius: 3px;
      width: 18px;
      height: 18px;

      transition: 0.2s;
      &.active {
        background: url(${({ icon }) => icon}) no-repeat;
        background-size: contain;
        background-position: center;
        background-color: #19c37d;
        border: 1px solid #19c37d;
      }
    }
  }
`;
