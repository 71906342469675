// 자체 채팅창 관련 api.. 1곳의 봇정보를 가져오기위함.
import axios from "axios";
const URL = `${process.env.REACT_APP_CHATBOTHOST}/api/bot/`;
const CHATURL = `${process.env.REACT_APP_CHATBOTHOST}/api/chatbot/`;
const botUid = `${process.env.REACT_APP_CHATBOTUID}`;
const api = axios.create({ baseURL: URL });
const chatapi = axios.create({ baseURL: CHATURL });

const ownBotApi = {
  selectBotExQuestion: () => {
    // console.log("Call ownBot selectBotExQuestion");
    return api.post("selectBotExQuestion", { botUid });
  },
  getBotInfoByBotUid: () => {
    if (!botUid) {
      console.log("getBotInfoByBotUid Not Found botUid ERROR! return");
      return;
    }
    // console.log("call ownBot getBotInfoByBotUid, param: ", botUid);
    return api.post("getBotInfoByBotUid", { botUid });
  },
  selectBottomMenuList: () => {
    // console.log("Call selectBottomMenuList");
    return api.post("selectBottomMenuList", { botUid });
  },
  sendQuestion: (
    indexName,
    chatRoomId,
    messageId,
    sendMessage,
    isFirst,
    role,
    ratio,
    useHistory,
    useRsearch,
    useAddPrompt
  ) => {
    console.log(">>>>> ownBotApi Call SendQuesiton");
    const chatType = 0;
    let params = {
      indexName,
      botUid,
      chatRoomId,
      messageId,
      sendMessage,
      isFirst,
      role,
      chatType,
      ratio,
      useHistory,
      useRsearch,
      useAddPrompt,
    };
    return chatapi.post("sendQuestion", params);
  },
};

export default ownBotApi;
