import React, { useState } from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

import { Button, ButtonGroup } from "@mui/material";

export default function ThemeSettingItem({ chatThemeType, handleThemeCheck, item }) {
  const { theme, title, desc, botText, userText } = item;

  const [displayBtn, setDisplayBtn] = useState("web");

  const handleDisplayBtn = (display) => {
    setDisplayBtn(display);
  };

  return (
    <CardContainer>
      <div className="checkCt">
        <input
          onChange={(e) => {
            handleThemeCheck(theme);
          }}
          name="theme"
          id={theme === null ? "default" : theme}
          type="radio"
          checked={chatThemeType === theme ? true : false}
        />
        <label className={chatThemeType === theme ? "active" : ""} htmlFor={theme === null ? "default" : theme}>
          {title}
        </label>
      </div>
      <MyCard className={chatThemeType === theme ? "active" : ""} variant="outlined">
        {/* baseChat내용 */}
        <MyCardOverflow className={displayBtn + " " + theme + " chat--bot2  chat--cover"}>
          <div className={"chatBox"}>
            <div className="chatBody  ">
              <div className="bot chatObj">
                <div style={{ flexDirection: "row" }}>
                  <div style={{ flexDirection: "column" }}>
                    <div className="chatText">
                      <div>{botText}</div>
                    </div>
                    <p className="time"> 11:24</p>
                  </div>
                  <div style={{ minWidth: "80px", marginLeft: "10px" }}></div>
                </div>
              </div>
              <div className="user chatObj">
                <div className="textBox" style={{ flexDirection: "none", justifyContent: "flex-end" }}>
                  <div style={{ display: "flex", flexDirection: "column", alignItems: "end" }}>
                    <div className="chatText">
                      <div>{userText}</div>
                    </div>
                    <p className="time"> 11:24</p>
                  </div>
                  <div style={{ minWidth: "0", marginLeft: "10px" }}></div>
                </div>
              </div>
            </div>
          </div>
        </MyCardOverflow>
        {/* baseChat내용 */}

        <CardContent>
          <MyTitle
            className="title"
            level="title-md"
            color={chatThemeType === theme ? "ActiveCaption" : "GrayText"}
            fontSize={"sm"}
          >
            {chatThemeType === theme ? (
              <CheckCircleIcon fontSize="small" color={"success"} />
            ) : (
              <CheckCircleOutlineIcon fontSize="small" color={"disabled"} />
            )}
            {title}
          </MyTitle>
          <Typography level="body-sm">
            <span style={{ wordBreak: "keep-all" }}>{desc}</span>
          </Typography>

          <MyButtonGroup>
            <Button
              onClick={() => {
                handleDisplayBtn("web");
              }}
              color={displayBtn === "web" ? "primary" : "inherit"}
              variant={displayBtn === "web" ? "contained" : "outlined"}
              size="sm"
            >
              Web
            </Button>
            <Button
              onClick={() => {
                handleDisplayBtn("mobile");
              }}
              color={displayBtn === "mobile" ? "primary" : "inherit"}
              variant={displayBtn === "mobile" ? "contained" : "outlined"}
              size="sm"
            >
              Mobile
            </Button>
          </MyButtonGroup>
        </CardContent>
      </MyCard>
    </CardContainer>
  );
}

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5px 0 10px;

  .checkCt {
    label {
      margin: 0 5px;
      cursor: pointer;
      &.active {
        color: #ff892a;
        font-weight: 600;
      }
      &:hover {
        color: #ff892a;
        font-weight: 500;
      }
    }
  }
`;

const MyCard = styled(Card)`
  width: 370px;
  height: 450px;
  overflow-y: unset !important;
  margin: 5px 2.5px;
  border: 3px solid #cccccc !important;
  &.active {
    border: 3px solid #ff892a !important;
    .title {
      color: #fff !important;
      background: #ff892a;
      border: 1px solid transparent;
      svg {
        color: #fff;
      }
    }
  }
  @media (max-width: 480px) {
    width: 100%;
    height: 470px;
    overflow: hidden;
  }
`;

const MyCardOverflow = styled.div`
  height: 300px;
  display: inline-block;
  padding: 0;
  width: auto;

  & .chatBox {
    border-radius: 0;
  }
`;

const MyButtonGroup = styled(ButtonGroup)`
  margin: 10px 0 0;
`;

const MyTitle = styled(Typography)`
  display: flex !important;
  align-items: center;

  width: fit-content;
  padding: 2.5px 7px;
  border-radius: 5px;
  border: 1px solid #dde7ee;

  cursor: pointer;
  & svg {
    margin-right: 5px;
  }
`;
