import styled from "styled-components";

export default function NormalButton({
  disabled,
  buttonId,
  buttonTitle,
  buttonClass,
  callBackFunc,
  type,
  style,
  icon,
}) {
  return (
    <NormalBtn
      type={type}
      id={buttonId ? buttonId : "submit_btn"}
      disabled={disabled}
      className={buttonClass ? buttonClass : ""}
      style={{ ...style }}
      onClick={(e) => {
        e.preventDefault();
        callBackFunc && callBackFunc(e);
      }}
      icon={icon}
    >
      {buttonTitle}
    </NormalBtn>
  );
}

export const NormalBtn = styled.button`
  min-width: fit-content;
  transition: 0.2s;

  letter-spacing: -1px;
  font-weight: 500;
  border-radius: 5px;
  border: 1px solid #999;
  background: #fff;

  &.small {
    padding: 6px 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    .mobile {
      font-size: 12px;
    }
  }
  &.mid {
    padding: 8px 20px;
    font-size: 16px;
    line-height: 28px;
    .mobile {
      font-size: 14px;
    }
  }
  &.big {
    padding: 15px 80px;
    font-size: 22px;
    line-height: 28px;
    .mobile {
      font-size: 16px;
      max-width: 240px;
    }
  }

  &.round {
    border-radius: 100px;
  }

  &.withIcon {
    display: flex;
    align-items: center;
    justify-content: center;

    & svg {
      margin-right: 5px;
    }
  }

  &.cOrange {
    color: #fff;
    background: #ff892a;
    border: 1px solid transparent;
  }
  &.cDel {
    color: #808080;
    background: #fff;
    border: 1px solid #808080;
  }
  &.cGreen {
    color: #fff;
    background: #19c37d;
    border: 1px solid transparent;
  }
  &.cIcon {
    color: #808080;
    background: #fff;
    border: none !important;
    padding: 0;
  }

  &.disable {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:hover:not(.noOpacity) {
    opacity: 0.8;
  }
  &.hoverNone {
    &:hover {
      cursor: default;
      opacity: 1;
    }
  }
  /* 지식 추가 관련해서 따로 버튼 스타일 */
  &.addKnowledge {
    width: 250px;
    height: 185px;
    border: 1px solid #cccccc;
    border-radius: 15px;

    &:hover {
      border: 2px solid #ff892a;
    }

    .iconWrap {
      border-radius: 150px;
      display: block;
      width: 38px;
      height: 38px;
      background: #ffe1c8;
      margin-bottom: 20px;
      & svg {
        color: #ff892a;
      }
    }
    & span {
      color: #1a1a1a;
      letter-spacing: 0px;
      font-size: 16px;
    }

    @media (max-width: 480px) {
      width: 200px;
      height: 135px;
    }
  }

  &.createEmptyFolder {
    background: #2a8cff;
    color: #fff;
    border: none;
    width: 180px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: auto;

    &.notMarginRight {
      margin-right: 0;
    }

    &.noM &.float {
      z-index: 1;
      border-radius: 4px;
      background: #ff892a;
      min-width: 60%;
      position: fixed;
      bottom: 30px;
      left: 50%;
      transform: translateX(-50%);

      & svg {
        margin-right: 5px;
      }

      &:hover {
        background: #b3601d;
      }
    }
    &.small {
      width: 150px;
    }
    &:hover {
      background: #1b59a3;
    }
    & svg {
      margin-right: 5px;
    }

    @media (max-width: 480px) {
      width: auto !important;
      margin-right: 0;
    }
  }

  &.createEmptyFolder2 {
    background: #ff892a;
    min-width: 280px;
    color: #fff;
    border: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0 auto;

    &.small {
      width: 150px;
    }
    &:hover {
      background: #b3601d;
    }
    & svg {
      margin-right: 5px;
    }
    @media (max-width: 480px) {
      width: auto !important;
      /* margin-right: 0; */
    }
  }

  /* 되돌아가기 */
  &.backIcon {
    max-height: 40px;
    padding: 8px !important;
    border-radius: 10px;
    background: #efefef;
    border: none;
    margin-right: 10px;

    @media (max-width: 480px) {
      max-height: 30px;
      padding: 3px 10px !important;
    }

    &:hover {
      opacity: 1;
      background: #e5f1ff;
      & svg {
        color: #2a8cff;
      }
    }
  }

  &.input {
    height: 100%;
    border: 1px solid #2a8cff;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    min-width: 90px !important;
    max-width: 150px;

    &:hover {
      color: #fff;
      background: #2a8cff;
    }
  }

  /* 지식 추가 관련해서 따로 버튼 스타일 */

  /* page Active main 페이지용 */
  &.pageActive {
    font-weight: 900;
    text-decoration: #ffd526 underline 4px;
    text-underline-offset: 21px;
  }
`;
