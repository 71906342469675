import { useContext, useState } from "react";
import { popupContext } from "../../providers/PopupProvider.js";
import botApi from "../../../api/botApi.js";

const countSlashes = (path) => {
  // 하위 폴더를 제외하기 위함 카운트 1인경우만 상위폴더임.
  let count = 0;
  for (let i = 0; i < path.length; i++) {
    if (path[i] === "/") {
      count++;
    }
  }
  // console.log(">>> countSlahses OK:", path, " --- ", count);
  return count;
};

const MAX_FILE_COUNT = 10;
const MAX_FILE_SIZE = {
  0: 100 * 1024 * 1024,
  1: 200 * 1024 * 1024,
  2: 1024 * 1024 * 1024,
};
// 이 확장자 목록을 컨트롤과 view 모두 사용함에 주의
const ALLOWED_EXTENSIONS = [
  // ".csv",
  // ".epub",
  // ".xml",
  // ".xls",
  // ".xlsx",
  ".pdf",
  ".hwp",
  ".hwpx",
  ".doc",
  ".docx",
  ".ppt",
  ".pptx",
  ".json",
  ".txt",
];

export const viewAllowedExtensions = ALLOWED_EXTENSIONS.join(", ").replaceAll("\.", "");

export const getMaxFileSize = (chatBotData) => !chatBotData?.maxFileSize ? (MAX_FILE_SIZE[chatBotData?.serviceModel] ?? MAX_FILE_SIZE[0]) : chatBotData?.maxFileSize;

export const getMaxServiceSize = (chatBotData) => (chatBotData?.serviceSize ? chatBotData.serviceSize : 1) * 1024 * 1024;  // 안되면 1mb라도 되도록
export const getUsageDiskSize = async (chatBotData) => {
    try {
      const { data } = await botApi.getUsageFileSize(chatBotData?.botUid);
      const obj = JSON.parse(data?.info ?? "{}");
      
      return obj?.usageDiskSize ?? getMaxFileSize(chatBotData);
    } catch (err) {
      console.log('err', err);
      
      return getMaxFileSize(chatBotData);
    }
}

const getAddUsageValue = (botList) => {
  if (!botList || !Array.isArray(botList) || Array.isArray(botList) && botList.length === 0) return 0;
  
  return botList.reduce((acc, data) => Number(data?.crawlSize ?? '0') + acc, 0);
}

export const checkCrawlSize = (chatBotData, botList) => {
  // chatBotData 없으면 검사 수행 X
  if (!chatBotData) return false;
  
  // 지식 추가 등에서 추가할 파일들 있다면, crawlSize값들을 모아 추가 비교
  let addUsageValue = getAddUsageValue(botList);
  
  return (Number(chatBotData?.totalUsageValue ?? "0") + addUsageValue) >= getMaxServiceSize(chatBotData);
}

export const checkStoreSize = (chatBotData) => {
  // chatBotData 없으면 검사 수행 X
  if (!chatBotData) return false;
  
  return (Number(chatBotData?.storeSize ?? "0")) >= getMaxServiceSize(chatBotData);
}


export const checkDiskSize = async (chatBotData) => {
  // chatBotData 없으면 검사 수행 X
  if (!chatBotData) return false;
  
  return (await getUsageDiskSize(chatBotData)) >= getMaxFileSize(chatBotData);
}

export const checkFullDiskAndStoreSizeAndCrawlSize = async (chatBotData, botList) => {
  return checkCrawlSize(chatBotData, botList) || checkStoreSize(chatBotData) || (await checkDiskSize(chatBotData));
}


export default function useFileHook({ knowledgeList, chatBotData }) {
  const maxFileSize = getMaxFileSize(chatBotData);
  const { showTextPop, showListPop } = useContext(popupContext);

  const [selectedFile, setSelectedFile] = useState([]);
  const [folderKey, setFolderKey] = useState(1);
  
  const showUploadError = (checkExceptList, callback) => {
    showListPop({
      title: "파일 업로드 제한",
      content: "파일 업로드는 1회 " + MAX_FILE_COUNT + "개 까지만 가능합니다.",
      list: checkExceptList,
      type: "except",
      callback,
    });
  };

  const showWaringUploadCnt = (checkExceptList, callback) => {
    showListPop({
      title: "파일 업로드 제한",
      content: "파일 업로드는 1회 " + MAX_FILE_COUNT + "개 까지만 가능합니다.",
      list: checkExceptList,
      type: "except",
      callback,
    });
  };

  const cancelUpdateFile = (file) => {
    setSelectedFile(selectedFile.filter((info) => info.name !== file.name));
  };

  const changeFolderHandle = async (e) => {
    const usageDiskSize = await getUsageDiskSize(chatBotData);
    e.preventDefault();

    const clean = () => {
      e.target.value = "";
    };

    setFolderKey((prev) => prev + 1);
    let curFileCount = selectedFile.length;
    if (curFileCount >= MAX_FILE_COUNT)
      return showTextPop("파일 업로드는 1회 " + MAX_FILE_COUNT + "개 까지만 가능합니다.", () => clean());
    // 기존에 파일 개수가 여유있는지 확인.
    // 1. 확장자로 골라내기.
    let [files, checkExceptList] = Array.from(e.target.files).reduce(
      (acc, file) => {
        const fileName = file.name.replace(/^.*[\\\/]/, "");
        const fileExtension = fileName.split(".").pop().toLowerCase();
        const isAlreadyInKnowledge = knowledgeList.some((item) => item.dataName === file.name);
        const isAlreadyInSelected = selectedFile.some((selFile) => selFile.name === file.name);
        const isAcceptExtensions = ALLOWED_EXTENSIONS.includes("." + fileExtension);
        const isOutOfMaxFileCount = curFileCount >= MAX_FILE_COUNT;

        // 지식 관리에서 불러온 데이터거나 / 이미 추가된 데이터거나 / 허용되지 않은 확장자거나 / 파일 개수가 limit값을 넘었을 때 제외 대상으로 지정.
        if (isAlreadyInKnowledge || isAlreadyInSelected || !isAcceptExtensions || isOutOfMaxFileCount) {
          let strNo = "[" + (acc[1].length + 1) + "]";
          let path = file.webkitRelativePath;
          const idx = path.indexOf("/");
          if (idx !== -1) {
            path = path.substring(idx + 1);
          }
          let strName = strNo + " " + path;
          let data = { name: strName };
          acc[1].push(data);
        } else if (!isAlreadyInKnowledge && !isAlreadyInSelected && countSlashes(file.webkitRelativePath) === 1) {
          curFileCount++;
          acc[0].push(file);
        }
        
        return acc;
      },
      [[], []]
    );

    // 2. 용량을 체크해서 100MB 이내로 리스트를 세팅해준다.
    let selFileSize = 0;
    for (const file of files) {
      selFileSize += Number(file.size);
      // console.log("Use Size:", selFileSize);

      if (selFileSize > (maxFileSize - usageDiskSize)) {
        return showTextPop(`파일의 용량은 총 ${convertToMbSize(maxFileSize - usageDiskSize)}MB를 넘을 수 없습니다.`, () => clean());
      }
      setSelectedFile((prev) => [...prev, file]);
    }
    if (curFileCount > MAX_FILE_COUNT && checkExceptList.length > 0) {
      showWaringUploadCnt(checkExceptList, () => clean());
    } else if (checkExceptList.length > 0) {
      showListPop({
        title: "파일 업로드 제한",
        content: "이미 등록됐거나 제한된 확장자 및 업로드 최대 개수가 넘어간 경우 일부 파일이 제외될 수 있습니다.",
        list: checkExceptList,
        type: "except",
        callback: () => clean(),
      });
    }
    
    console.log(">>>>>>>>>> files:", files);
  };

  const convertToMbSize = (size) => {
    size = size / 1024 / 1024;
    if (size > 1000) {
      return size / 1000;
    }

    return size;
  };

  const changeFileHandle = (e) => {
    e.preventDefault();
    let curFileCount = selectedFile.length;
    let allFiles = [];

    const clean = () => {
      e.target.value = "";
    };

    if (curFileCount >= MAX_FILE_COUNT)
      return showTextPop(`파일 업로드는 1회 ${MAX_FILE_COUNT}개 까지만 가능합니다.`, () => clean());

    // drag & drop인지 먼저 판단.
    const targetAddedFiles = e.dataTransfer?.files ? e.dataTransfer.files : e.target.files;
    allFiles = [...allFiles, ...Array.from(targetAddedFiles)];

    console.log("allFiles", allFiles);

    // 1. 이미 있는 지식명인지 + 가능한 확장자인지 체크
    let [files, checkExceptList] = allFiles.reduce(
      (acc, file) => {
        const fileName = file.name;
        const fileExtension = fileName.split(".").pop().toLowerCase();
        const isAlreadyInKnowledge = knowledgeList.some((item) => item.dataName === file.name);
        const isAlreadyInSelected = selectedFile.some((selFile) => selFile.name === file.name);
        const isAcceptExtensions = ALLOWED_EXTENSIONS.includes("." + fileExtension);
        const isOutOfMaxFileCount = curFileCount >= MAX_FILE_COUNT;

        // 지식 관리에서 불러온 데이터거나 / 이미 추가된 데이터거나 / 허용되지 않은 확장자거나 / 파일 개수가 limit값을 넘었을 때 제외 대상으로 지정.
        if (isAlreadyInKnowledge || isAlreadyInSelected || !isAcceptExtensions || isOutOfMaxFileCount) {
          let strNo = "[" + (acc[1].length + 1) + "]";
          let strName = strNo + " " + file.name;
          let data = { name: strName };

          acc[1].push(data);
        } else if (!isAlreadyInKnowledge && !isAlreadyInSelected) {
          curFileCount++;
          acc[0].push(file);
        }

        return acc;
      },
      [[], []]
    );

    // 2. 용량을 체크해서 요금제별 용량 한도 체크 리스트를 세팅해준다.
    let selFileSize = 0;
    for (const file of files) {
      selFileSize += Number(file.size);
      console.log("Use Size:", selFileSize, maxFileSize);
      // 용량제한에 대해서는 별도로 제한 목록 처리를 하지 않는다.
      if (selFileSize > maxFileSize)
        return showTextPop(`파일의 용량은 총 ${convertToMbSize(maxFileSize)}MB를 넘을 수 없습니다.`, () => clean());
      setSelectedFile((prev) => [...prev, file]);
    }

    if (curFileCount > MAX_FILE_COUNT && checkExceptList.length > 0) {
      showUploadError(checkExceptList, () => clean());
    } else if (checkExceptList.length > 0) {
      showListPop({
        title: "파일 업로드 제한",
        content: "이미 등록됐거나 제한된 확장자 및 업로드 최대 개수가 넘어간 경우 일부 파일이 제외될 수 있습니다.",
        list: checkExceptList,
        type: "except",
        callback: () => clean(),
      });
    }
    e.target.value = null;
    console.log(">>>>>>>>>> files:", files);
  };

  const emptySelectedFile = () => setSelectedFile([]);

  return { selectedFile, folderKey, cancelUpdateFile, changeFolderHandle, changeFileHandle, emptySelectedFile };
}

export { MAX_FILE_COUNT, MAX_FILE_SIZE, ALLOWED_EXTENSIONS };
