import icon_google from "assets/images/google.svg";
import icon_close from "assets/images/icon_close.svg";
import icon_kakao from "assets/images/kakao.svg";
import icon_naver from "assets/images/naver.svg";
import { useCallback, useContext, useEffect, useState } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import styled, { keyframes } from "styled-components";
import { local } from "utils/storage";
import axios from "axios";
import { isMobileContext } from "utils/providers/BrowserEnvContextProvider";
import FindPasswordLayer from "./FindPasswordLayer";

export default function LoginPop({
  setLoginPopState,
  tabState,
  setTabState,
  getGoogleUserInfo,
  kakao,
  handleNaverLogin,
  setOpenSignUp,
  signIn,
  keepId,
  setKeepId,
  isAutoLogin,
  setIsAutoLogin,
}) {
  const { isMobile } = useContext(isMobileContext);

  // email password state
  const [email, setEmail] = useState("");
  const [pwd, setPwd] = useState("");

  const [showFindPasswordLayer, setShowFindPasswordLayer] = useState(false);

  const googleSocialLogin = useGoogleLogin({
    onSuccess: async ({ access_token }) => {
      const userInfo = await axios.get(
        "https://www.googleapis.com/oauth2/v3/userinfo",
        {
          headers: { Authorization: `Bearer ${access_token}` },
        }
      );
      getGoogleUserInfo(userInfo);
    },
    onError: (errorResponse) => {
      console.error(errorResponse);
    },
    scope: "email",
    //flow: "auth-code",
  });

  useEffect(() => {
    const savedKeepId = local.getKeepId();

    if (savedKeepId !== null) {
      setKeepId(true);
      setEmail(savedKeepId);
    }
  }, []);

  return (
    <>
      <LoginWrapper>
        <CloseBtn
          icon={icon_close}
          onClick={(e) => {
            setLoginPopState((prev) => !prev);
          }}
        ></CloseBtn>
        <LoginTab id="loginTab">
          <LoginTabBtn
            id="log"
            className={tabState && "active"}
            onClick={(e) => {
              e.preventDefault();
              setTabState((prev) => !prev);
            }}
          >
            로그인
          </LoginTabBtn>
          <LoginTabBtn
            id="sign"
            className={!tabState && "active"}
            onClick={(e) => {
              e.preventDefault();
              setTabState((prev) => !prev);
            }}
          >
            회원가입
          </LoginTabBtn>
        </LoginTab>

        {tabState ? (
          <LoginCt id="tab_log">
            <LoginInputCt>
              <LoginInput
                type="email"
                placeholder="E-mail Address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <LoginInput
                type="password"
                placeholder="비밀번호(영문, 숫자, 특수문자 조합 6~20자 이내)"
                value={pwd}
                onChange={(e) => setPwd(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === "Enter") signIn(email, pwd);
                }}
              />
              <LoginChkCt>
                <LoginChk
                  id="saveId"
                  type="checkbox"
                  checked={keepId}
                  onChange={(e) => setKeepId(e.target.checked)}
                />
                <LoginChkLabel htmlFor="saveId" className="saveId">
                  아이디 저장
                </LoginChkLabel>
                {/* <LoginChk
                id="autoLogin"
                type="checkbox"
                onChange={(e) => setIsAutoLogin(e.target.checked)}
              />
              <LoginChkLabel htmlFor="autoLogin">자동 로그인</LoginChkLabel> */}
              </LoginChkCt>
            </LoginInputCt>
            {!isMobile ? (
              <LoginBtn onClick={() => signIn(email, pwd)}>로그인</LoginBtn>
            ) : (
              <LoginBtnInMobile>
                <LoginBtn onClick={() => signIn(email, pwd)}>로그인</LoginBtn>
              </LoginBtnInMobile>
            )}

            <LoginCtBottom>
              <p>아직 회원이 아니신가요?</p>
              <button onClick={(e) => setTabState(false)}>회원가입</button>
              <button
                onClick={(e) => setShowFindPasswordLayer((prev) => !prev)}
              >
                비밀번호를 잊으셨나요?
              </button>
            </LoginCtBottom>
            <LoginOtherAcc>
              <LoginOtherAccText>
                <span></span>
                <p>SNS 계정으로 로그인</p>
                <span></span>
              </LoginOtherAccText>
              <LoginOtherAccA
                icon_google={icon_google}
                className="google"
                onClick={() => googleSocialLogin()}
              />
              <LoginOtherAccA
                icon_kakao={icon_kakao}
                className="kakao"
                onClick={() =>
                  kakao.Auth.authorize({
                    redirectUri: `${process.env.REACT_APP_HOST}/kakaoLoginSuccess`,
                    throughTalk: true,
                  })
                }
              />
              <LoginOtherAccA
                href="#!"
                icon_naver={icon_naver}
                className="naver"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleNaverLogin();
                }}
              />
            </LoginOtherAcc>
          </LoginCt>
        ) : (
          <SignUpCt id="tab_sign">
            <DescText>
              SNS로 3초만에 가입하고
              <br />
              무료로 인공지능 챗봇을 만들어 보세요
            </DescText>

            <SignupOtherBtns>
              <SignupOtherBtn
                className="google"
                onClick={() => googleSocialLogin()}
              >
                구글 간편가입하기
              </SignupOtherBtn>
              <SignupOtherBtn
                className="kakao"
                onClick={() => {
                  kakao.Auth.authorize({
                    redirectUri: `${process.env.REACT_APP_HOST}/kakaoLoginSuccess`,
                    throughTalk: true,
                  });
                }}
              >
                카카오톡 간편가입하기
              </SignupOtherBtn>
              <SignupOtherBtn
                className="naver"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleNaverLogin();
                }}
              >
                네이버 간편가입하기
              </SignupOtherBtn>
              <span className="line"></span>
              <SignupOtherBtn
                className="email"
                onClick={(e) => setOpenSignUp(true)}
              >
                이메일로 가입하기
              </SignupOtherBtn>
            </SignupOtherBtns>
          </SignUpCt>
        )}
      </LoginWrapper>
      {showFindPasswordLayer && (
        <FindPasswordLayer
          setShowFindPasswordLayer={setShowFindPasswordLayer}
        />
      )}
    </>
  );
}

const openModal = keyframes`
0% {
-webkit-transform: translateX(5%);
transform: translateX(5%);
opacity: 0;
}

100% {
-webkit-transform: translate(0);
transform: translate(0);
opacity: 1;
}
`;

const LoginWrapper = styled.div`
  /* position: absolute; */
  position: fixed;
  top: 75px;
  right: 3vw;
  background-color: #fff;

  min-width: 500px;
  width: 50vw;
  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;
  border-radius: 10px;

  padding: 45px 0 30px;

  animation: ${openModal} 0.2s ease-in-out;
  animation-fill-mode: both;

  box-shadow: 0px 6px 22px 0px #00000040;
  z-index: 2;

  @media (max-width: 480px) {
    border-radius: 0;
    min-width: 100vw;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    padding: 80px 0 30px;
  }
`;

const LoginTab = styled.div`
  margin-bottom: 30px;
`;

const LoginTabBtn = styled.button`
  font-weight: 400;
  font-size: 20px;
  line-height: 29px;
  color: #9d9d9d;
  &.active {
    color: #ff892a;
    font-weight: 700;
    font-size: 26px;
    line-height: 38px;
  }
`;

const LoginCt = styled.div`
  /* display: none; */
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 440px;

  &.active {
    display: flex;
  }
`;

const LoginInputCt = styled.div`
  border: 2px solid transparent;
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: 480px) {
    width: 100%;
    align-items: center;
  }
`;

const LoginInput = styled.input`
  padding: 14px 28px;
  height: 51px;
  border: 1px solid #2d333a;
  border-radius: 4px;
  font-weight: 300;
  font-size: 16px;
  line-height: 23px;
  color: rgba(45, 51, 58, 0.8);
  margin-bottom: 20px;
  &-moz-placeholder {
    font-weight: 300;
    font-size: 16px;
    line-height: 23px;
    color: rgba(45, 51, 58, 0.8);
  }
  &::placeholder {
    font-weight: 300;
    font-size: 16px;
    line-height: 23px;
    color: rgba(45, 51, 58, 0.8);
  }

  @media (max-width: 480px) {
    width: 80%;
    &::placeholder {
      font-weight: 300;
      font-size: 12px;
      color: rgba(45, 51, 58, 0.8);
    }
  }
`;

const LoginChkCt = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
`;

const LoginChk = styled.input`
  width: 24px;
  height: 24px;
  background: #ffffff;
  border: 1px solid #2d333a;
  border-radius: 4px;
  margin: 0;
  @media (max-width: 480px) {
    // 23.07.25.codelua 잠시 자동로그인 기능 막음. (꼬임 들을 해결하기전까지 닫음.)
    margin-left: 240px;
  }
`;

const LoginChkLabel = styled.label`
  height: 100%;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  display: inline-block;
  margin-left: 6px;
  &.saveId {
    margin-right: auto;
  }
`;

const LoginBtnInMobile = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const LoginBtn = styled.button`
  text-align: center;
  padding: 14px;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #fff;
  width: 440px;
  height: 51px;
  background-color: #ff892a;
  /* background: linear-gradient(
    110.76deg,
    #00e8ff -28.02%,
    #10cbff -16.52%,
    #2c9bff 1.52%,
    #4175ff 22.65%,
    #515aff 38.02%,
    #5a49ff 53.54%,
    #731bff 69.79%,
    #7e07ff 88.48%,
    #8200ff 105.03%
  ); */
  border-radius: 4px;
  margin-bottom: 21px;

  @media (max-width: 480px) {
    flex: 0 0 auto;
    width: 80%;
  }
`;

const LoginCtBottom = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  color: #2d333a;
  margin-bottom: 52px;
  & p {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
  & button {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #611fd3;
    &:last-child {
      margin-left: auto;
    }
  }

  @media (max-width: 480px) {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    & p,
    button {
      font-size: 12px;
    }
  }
`;

const DescText = styled.p`
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #d31f55;
  margin-bottom: 69px;

  @media (max-width: 480px) {
    text-align: center;
  }
`;

const LoginOtherAcc = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #4d4d4d;

  text-align: center;
`;
const LoginOtherAccText = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 22px;

  & span {
    width: 142px;
    flex: 1 1 auto;
    height: 1px;
    border-top: 1px solid #9d9d9d;
  }

  & p {
    margin: 0 13px;
    flex: 0 0 auto;
  }
`;

const LoginOtherAccA = styled.a`
  /* width: 50px;
  height: 50px;
  border-radius: 100%;
  margin: 0 7px;
  color: transparent; */
  display: inline-block;
  margin: 0 7px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
  &.google {
    background: url(${({ icon_google }) => icon_google}) no-repeat;
    background-position: center;
    background-size: cover;
  }
  &.naver {
    background: url(${({ icon_naver }) => icon_naver}) no-repeat;
    background-position: center;
    background-size: cover;
  }
  &.kakao {
    background: url(${({ icon_kakao }) => icon_kakao}) no-repeat;
    background-position: center;
    background-size: cover;
  }
`;

const SignUpCt = styled.div`
  width: 440px;
`;

const SignupOtherBtns = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & span {
    margin: 16px 0 36px;
    width: 100%;
    height: 1px;
    display: block;
    border: 1px solid #9d9d9d;
  }
`;

const SignupOtherBtn = styled.button`
  font-weight: 700;
  font-size: 16px;
  line-height: 23px;
  border: 1px solid #dcdcdc;
  margin-bottom: 20px;
  width: 100%;
  height: 50px;
  border-radius: 2px;

  @media (max-width: 480px) {
    width: 70%;
    border-radius: 20px;
  }

  &.google {
    background: #e74234;
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    text-align: center;
    color: #ffffff;
  }
  &.naver {
    background: #1ace61;
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    text-align: center;
    color: #ffffff;
  }
  &.kakao {
    background: #fee101;
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    text-align: center;
    color: #3a1c1c;
  }
  &.email {
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    text-align: center;
    color: #ffffff;
    background: #40b3db;
  }
`;

const CloseBtn = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;
  width: 20px;
  height: 20px;

  background: url(${({ icon }) => icon}) no-repeat;
  background-size: cover;
  background-position: center;
`;
