import { useState } from "react";

import styled from "styled-components";

export default function RefBox(refProps) {
  const { sources, clickOpenOrDown } = refProps;

  const [openBox, setOpenBox] = useState(false);

  const splitSource = (sources) => {
    return sources.split(",");
  };

  const handleSourceType = (data) => {
    const webAddressRegex = /^(http|https):\/\//;
    return webAddressRegex.test(data.data);
  };

  return (
    <ChatInfo>
      <ChatInfoInner className={openBox ? "active" : ""}>
        <div className="sourceTop">
          <BtnOrigin
            onClick={() => {
              setOpenBox((prev) => !prev);
            }}
          >
            {openBox ? (
              <button className={openBox ? "refContent open" : "refContent"}>
                <i className="ri-skip-up-line"></i> 접기
              </button>
            ) : (
              <button className={openBox ? "refContent" : "refContent close"}>
                <i className="ri-file-list-2-fill"></i> 참고자료
              </button>
            )}
          </BtnOrigin>
          {openBox ? <p className="title">참고자료</p> : ""}
        </div>

        {openBox && (
          <>
            {splitSource(sources).map((data, idx) => {
              return (
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    clickOpenOrDown({ data });
                  }}
                  className="sourceBtn"
                  key={idx}
                >
                  {handleSourceType({ data }) ? (
                    <i className="ri-external-link-line"></i>
                  ) : (
                    <i className="ri-file-line"></i>
                  )}
                  {data}
                </button>
              );
            })}
          </>
        )}
      </ChatInfoInner>
    </ChatInfo>
  );
}

const ChatInfo = styled.div`
  width: 100%;
`;

const ChatInfoInner = styled.div`
  position: relative;
  /* width: 100%; */
  width: fit-content;

  display: flex;
  flex-direction: column;

  font-size: 12px;
  color: 666;

  transition: 0.1s;

  & div.sourceTop {
    display: flex;

    p.title {
      font-size: 10px;
      margin: 2px 5px;
      font-weight: 500;
      color: #5b7eff;
    }
  }

  & div.sourceCt {
    @media (min-width: 481px) {
      display: flex;
      & button.sourceBtn {
        width: auto;
        margin: 0 5px;
      }
    }
  }

  &.active {
    width: 100%;
    border-radius: 10px;
    padding: 5px 8px;
    background-color: #e8edff;
  }

  & button.sourceBtn {
    margin: 2px 0;
    padding: 2px 0;
    color: #555;
    font-size: 11px;
    width: 70%;
    word-break: break-all;
    text-align: left;
    & i {
      margin-right: 5px;
      font-size: 13px;
    }
    &:hover {
      opacity: 0.8;
      @media (min-width: 481px) {
        font-weight: 600;
        color: #005a9c;
      }
    }
  }
`;

const BtnOrigin = styled.button`
  margin-right: auto;
  margin-bottom: 5px;

  border-radius: 15px;

  padding-left: 0;
  background-color: #e8edff;

  & button.refContent {
    font-size: 11px;
    font-weight: 500;
    color: #5b7eff;
    padding: 3px 10px;
  }
  & button.refContent.open {
    border: 1px solid #5b7eff;
    background-color: #fff;
    padding: 0px 10px;
    border-radius: 20px;
  }
`;
