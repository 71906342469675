import React, { useEffect, useRef, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { createPortal } from "react-dom";
import uuid from "react-uuid";
import "remixicon/fonts/remixicon.css";
import "./chat2.css";
import "./chat2-ThemeBig.css";
import "./chat2-ThemeLeft.css";
import chatApi from "../../../api/chatApi.js";
import { HeaderContainer } from "./chatStyle.js";
import { getNow } from "../../../utils/dateUtil.js";
import { createMessageData } from "../../../utils/chatUtil.js";
import NormalButton from "../../atomic/NormalButton2.js";
import styled, { keyframes } from "styled-components";
import { Box, FlexBox, FlexColumnBox } from "../dashboard/dashboardStyle.js";
import botApi from "../../../api/botApi.js";
import ownBotApi from "api/ownBotApi";
import { isMobileContext } from "../../../utils/providers/BrowserEnvContextProvider.js";
import { pdfViewerContext } from "utils/providers/PdfViewerContextProvider";
import iconMenu from "../../../assets/images/icon_moblieMenu.svg";
import iconCloseRound from "../../../assets/images/icon_closeRound.svg";

import ChatIntroCt from "components/templates/chat/ChatIntroCt.js";
import ChatExQCt from "components/templates/chat/ChatExQCt";
import ChatTypeWriter from "../../organisms/ChatTypeWriter.js";
import ChatPlainWriter from "components/organisms/ChatPlainWriter";
import RefBox from "./RefBox";
import FaqBox from "./FaqBox";
import CommandBox from "./CommandBox";
import ConfirmPop from "components/templates/popup/ConfirmPop";
import { AlertPop2 } from "components/templates/popup/AlertPop";
import LoadingComponent from "utils/LoadingComponent.js";

import ChatIcon01 from "../../../assets/images/icon_chat01.svg";
import ChatIcon02 from "../../../assets/images/icon_chat02.svg";
import ChatIcon03 from "../../../assets/images/icon_chat03.svg";
import ChatIcon04 from "../../../assets/images/icon_chat04.svg";
import ChatIcon05 from "../../../assets/images/icon_chat05.svg";
import ChatIcon06 from "../../../assets/images/icon_chat06.svg";
import useViewFolderListFromKnowledge from "../../../utils/hooks/useVIewFolderListFromKnowledge.js";

import MenuIcon from "@mui/icons-material/Menu";
import FolderIcon from "@mui/icons-material/Folder";
import { Button, Drawer, ListItemAvatar, ListItemText } from "@mui/material";
import { Avatar, List, ListItem } from "@mui/material";
import ChecklistRtlIcon from "@mui/icons-material/ChecklistRtl";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

import InputLabel from "@mui/material/InputLabel";
import { MenuItem } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { popupContext } from "utils/providers/PopupProvider";

function KnowledgeBtnAndDrawer(props) {
  const { folderList, activedFolderUidList, knowledgeActiveDescription, updateActiveYnInFolderList } = props;
  const [selectedData, setSelectedData] = useState("");
  const [open, setOpen] = useState(false);

  const { showTextPop } = useContext(popupContext);

  // selectBox 함수
  const handleChange = (e) => {
    if (e.target.value === "none") return;
    let selectedDataUid = e.target.value;
    let targetItem = folderList.filter((item) => item.dataUid === selectedDataUid);

    let activeCheck = activedFolderUidList.filter((item) => {
      return item === selectedDataUid;
    });

    if (activeCheck.length >= 1) {
      showTextPop("이미 추가 되어 있습니다.");
      alert("이미 추가 되어 있습니다.");
    } else {
      setSelectedData(selectedDataUid);
      updateActiveYnInFolderList(targetItem[0]);
    }
  };

  const clickRadioBtn = (item) => {
    // item === "all" &&
    setSelectedData("");
    updateActiveYnInFolderList(item);
  };

  return (
    <>
      <Button sx={{ minWidth: "45px", marginRight: "0 !important" }} onClick={() => setOpen(true)}>
        <MenuIcon />
      </Button>
      <Drawer anchor={"left"} open={open} onClose={() => setOpen(false)}>
        <Box style={{ width: "250px" }}>
          <Typography sx={{ padding: "10px 15px", fontSize: "14px", lineHeight: "18px" }} component={"h3"}>
            {knowledgeActiveDescription}
          </Typography>
          <FormControl variant="standard" sx={{ m: 1, minWidth: 120, width: "80%" }}>
            <InputLabel id="knowledgeSelect">지식 선택</InputLabel>
            <Select labelId="knowledgeSelect" value={selectedData} onChange={handleChange} label="지식 선택">
              {folderList.map((item, index) => {
                return (
                  item.active === false && (
                    <MenuItem key={item.dataUid} value={item.dataUid}>
                      {item.dataName}
                    </MenuItem>
                  )
                );
              })}
              {activedFolderUidList.length === folderList.length && (
                <MenuItem key={"none"} value={"none"}>
                  추가 가능 지식 없음
                </MenuItem>
              )}
            </Select>
            {activedFolderUidList.length === folderList.length && (
              <Typography color="primary" variant="soft" fontSize={"12px"} sx={{ width: "fit-content", mt: 1 }}>
                모든 지식이 활성화 되어있습니다
              </Typography>
            )}
          </FormControl>

          <List>
            <MyListItem className="titleCt">
              <ListItemAvatar>
                <Avatar>
                  <ChecklistRtlIcon color={"primary"} />
                </Avatar>
              </ListItemAvatar>
              <MyListItemText className="title" primary={"선택 된 지식 리스트"} />
            </MyListItem>
            {folderList.length > 0 &&
              folderList?.map((item) =>
                item.active === true ? (
                  <MyListItem key={item.dataUid}>
                    <ListItemAvatar>
                      <Avatar size="sm">
                        <FolderIcon color={"primary"} />
                      </Avatar>
                    </ListItemAvatar>
                    <MyListItemText className="listItem" primary={item.dataName} />
                    <ListItem sx={{ padding: 0, width: "auto" }}>
                      <IconButton onClick={() => clickRadioBtn(item)}>
                        <RemoveCircleIcon color="warning" />
                      </IconButton>
                    </ListItem>
                  </MyListItem>
                ) : (
                  <></>
                )
              )}
            {activedFolderUidList.length > 0 && (
              <Button
                color="warning"
                onClick={() => {
                  clickRadioBtn("all");
                }}
              >
                전체 취소
              </Button>
            )}
          </List>
        </Box>
      </Drawer>
    </>
  );
}

export default function BaseChat(props) {
  const VIEWURL = `${process.env.REACT_APP_API_HOST}/`;
  const {
    showQuestionBox,
    showResponsePop,
    showDesc,
    messages,
    setMessages,
    popupInfo: { isPopup, setOpenChatRoom, openChatRoom, clickFunc },
    chatBotData,
    chatRoomId,
    handleSignOutApp,
    isHistoryBack,
    isHistory,
    IP,
    exList,
    BottomData,
    botClass,
    setShowOwnBot,
    setPopMessage,
    setPopState,
  } = props;
  let { isHeader } = props;
  if (isHeader === undefined) {
    isHeader = true;
  }

  const history = useHistory();
  const [firstMessage, setFirstMessage] = useState(true);
  const [newMessage, setNewMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [fullLoading, setFullLoading] = useState(false);
  const [responsePopInfo, setResponsePopInfo] = useState({
    isShow: false,
    data: null,
  });
  const [dropState, setDropState] = useState(false);
  const [descPopState, setDescPopState] = useState("none");
  const [textareaValue, setTextareaValue] = useState(false);

  //alertPop
  const [chatPopStae, setChatPopState] = useState(false);
  const [chatPopMessage, setChatPopMessage] = useState("");

  // confirmState
  const [showConfirmPop, setShowConfirmPop] = useState(false);
  const [description, setDescription] = useState("");
  const [popCallback, setPopCallback] = useState({
    normal: () => {},
    cancel: () => {},
  });

  // 채팅 메뉴 버튼
  const [chatMenuState, setChatMenuState] = useState(false);

  // textarae 텍스트 양에 따라서 높이 값이 조절될 수 있도록 수정
  const [textAreaHeight, setTextAreaHeight] = useState("33px");

  // 지식관리 관련 창 열지 말지에 대한 여부.
  const [showKnowledgeManage, setShowKnowledgeManage] = useState(true);

  const [scSignal, setScSignal] = useState("");
  const chatBodyRef = useRef(null);
  const textAreaRef = useRef();

  const IMAGE_PATH = process.env.REACT_APP_RESOURCE_HOST + "/images/bot/";
  const OWN_IMAGE_PATH = process.env.REACT_APP_CHATBOTHOST + "/images/bot/";
  const botImageUrl =
    botClass !== "own"
      ? IMAGE_PATH + (chatBotData?.botImagePath ?? "test.png")
      : OWN_IMAGE_PATH + (chatBotData?.botImagePath ?? "test.png");

  const isFreeUser = Number(chatBotData?.serviceModel ?? "0") === 0;

  const { isMobile } = useContext(isMobileContext);
  const { setUseViewer, setPdfUrl, setPdfPage } = useContext(pdfViewerContext);

  const {
    folderList,
    activedFolderUidList,
    description: knowledgeActiveDescription,
    updateActiveYnInFolderList,
  } = useViewFolderListFromKnowledge({
    botUid: chatBotData?.botUid,
    showKnowledgeManage,
  });

  // 버튼에 맞는 icon선택 처리
  const setButtonImage = (imgNo) => {
    if (imgNo === 1) return ChatIcon01;
    else if (imgNo === 2) return ChatIcon02;
    else if (imgNo === 3) return ChatIcon03;
    else if (imgNo === 4) return ChatIcon04;
    else if (imgNo === 5) return ChatIcon05;
    else if (imgNo === 6) return ChatIcon06;
    else return ChatIcon01;
  };

  // 테마 설정
  const chatTheme = chatBotData?.chatThemeType;

  /* ============== 실시간 API 정보 처리 시작  ============== */

  // 명령어의 모음을 서버로 부터 받는 함수 24.01.29 codelua 기준에서는 일단 하드코딩
  const fetchCommandsFromServer = () => {
    // 여기는 return으로 그냥 주는게 아닌 서버에서 확인을 하던가 메모리에 올려놓아야 하는데
    // 일단은 하드코딩으로 체크만 하는 코드임에 주의 필요.
    return ["명령", "도움말"];
  };

  const getCommandList = async (prefix) => {
    console.log(">>> getCommandHistroy, param:", prefix);
    const { status, data } = await chatApi.getCommandList(prefix, chatBotData.botUid);
    if (data?.commandList) {
      return JSON.parse(data.commandList);
    }
  };

  const procCommand = async (apiInfo) => {
    const { status, data } = await chatApi.getApiData(apiInfo.className);
    console.log(">>> data:", data);
    console.log(">>> status:", status);
    if (data.resultCode === "204") {
      alert("glkgklgkg");
    } else {
      alert("HAHAHAH");
    }
  };

  /* ============== 실시간 API 정보 처리 끝 ============== */

  // 참고자료 처리용 함수
  const downloadKnowledegeFile = async (fileName) => {
    setFullLoading(true);
    try {
      if (fileName.toLowerCase().includes(".pdf")) {
        // LiST_취업 규칙_v1.0 (1).pdf / 8 page 형태 일 경우 뒤 / 제외 처리
        const replaceName = fileName.split("/")[0].trim();
        let page = parseInt(fileName.split("/")[1].trim().replace(/\D/g, ""), 10);
        if (page) {
          // pdf viewer의 initial page가 0부터 시작하는것 같아 -1처리
          if (page > 0) page = page - 1;
        } else {
          page = 0;
        }

        const { status, data } = await botApi.getKnowDataUrlParam(chatBotData.botUid, replaceName);
        if (status === 200) {
          const retData = JSON.parse(data.knowledgeInfo);
          // 아래 URL 마지막에 .pdf 확장자 고정한거 나중에 확장 할 때 신경써야함 23.11.20.codelua 기준에서는 기능성 확인을 위한것이라 그냥 하드 코딩.
          const viwerURL = VIEWURL + "datafiles/file/" + retData.memberUid + "/" + retData.dataUid + ".pdf";
          console.log(">>>> viwerURL: ", viwerURL);
          setPdfUrl(viwerURL);
          setPdfPage(page);
          setUseViewer(true);
        }
      } else {
        // LiST_취업 규칙_v1.0 (1).pdf / 8 page 형태 일 경우 뒤 / 제외 처리
        const replaceName = fileName.split("/")[0].trim();

        const response = await botApi.downloadReferenceFile(chatBotData.botUid, replaceName);
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        console.log(">> blob:", blob);
        const downloadUrl = URL.createObjectURL(blob);
        console.log(">> downloadUrl:", downloadUrl);
        const link = document.createElement("a");
        console.log(">> link:", link);
        link.href = downloadUrl;
        link.download = fileName;
        link.click();
      }
    } catch (error) {
      setChatPopMessage("다운로드가 불가능합니다 관리자에게 문의하세요.");
      setChatPopState(true);
    } finally {
      setFullLoading(false);
    }
  };

  const clickFaqData = async (info) => {
    let isErr = false;
    if (!info.knowledgeId) {
      setChatPopMessage("예시질문의 메타정보가 없습니다. 관리자에게 문의가 필요합니다.");
      setChatPopState(true);
    }
    setFullLoading(true);
    try {
      const { status, data } = await chatApi.getKnowledgeAnswer(info.knowledgeId);
      if (status === 200) {
        const resData = JSON.parse(data.resData);

        const recvMessage = [
          createMessageData(resData.sendMessage, "user", "text", uuid(), uuid(), {}),
          createMessageData(resData.recvMessage, "bot", "text", uuid(), uuid(), {}),
        ];
        setMessages((prev) => [...prev, ...recvMessage]);
      }
    } catch (error) {
      console.log("clickFaqData error:", error);
      isErr = true;
    } finally {
      setFullLoading(false);
    }
    if (isErr) {
      setChatPopMessage("예시질문 사용이 불가능합니다 관리자에게 문의해주세요.");
      setChatPopState(true);
    }
  };

  const clickOpenOrDown = (data) => {
    const webAddressRegex = /^(http|https):\/\//;
    const urlToCheck = data.data.trim() + "";
    const isURL = webAddressRegex.test(urlToCheck);
    if (isURL) {
      //URL은 오픈 윈도우
      window.open(urlToCheck);
    } else {
      const fileName = data.data; // 여기 / page 들어온 부분 /이전까지 잘라서 제외하는 처리 해야 함.
      // 파일은 loading돌리고 다운로드 처리
      setPopCallback(() => ({
        cancel: () => {},
        normal: () => {
          downloadKnowledegeFile(fileName);
        },
      }));
      setDescription(data.data + "를 다운로드 받으시겠습니까?");
      setShowConfirmPop((prev) => !prev);
    }
  };

  let vh = 0;

  // drop menu 함수
  const handleDropMenu = (e) => {
    setDropState((prev) => !prev);
    setChatMenuState(false);
    setDescPopState("none");
    setNewMessage("");
    // url1
    //window.open(chatBotData.introOut);
    if (e.target.id === "intro") {
      setDescPopState("intro");
    } else if (e.target.id === "ex") {
      setDescPopState("ex");
    } else if (e.target.id === "url1") {
      window.open(chatBotData.introOut);
    } else if (e.target.id === "url2") {
      window.open(chatBotData.exqOut);
    }
  };

  const onMessageKeyDown = (e) => {
    const { key, shiftKey, type } = e;
    if ((key === "Enter" && !shiftKey) || (type === "click" && e.button === 0)) {
      e.preventDefault();
      if (textAreaRef.current.value.trim(" ") !== "") {
        sendMessage();
      }
      // 전송 되고 나면 질문 입력칸을 초기화 해준다.
      textAreaRef.current.value = "";
    }
  };

  // 봇 관련 메세지 생성 ( 특정 키워드 들어가면 이미지가 나타나도록 적용 )
  const createBotMessage = async (messageId, message) => {
    try {
      // recvSources는 출처 표기를 킨 경우 리턴값. // recvRsearch는 예시질문을 표기한 경우.
      const { recvData, recvSources, recvRsearch, recvComList } = await sendQ(messageId, message);
      // 일반 botMessage 전송 시 아래 코드 사용
      return createMessageData(recvData, "bot", "text", uuid(), messageId, {
        recvSources,
        recvRsearch,
        recvComList,
      });
    } catch (err) {
      const errorMsg = `죄송합니다. 다시 한번 질문해주세요.`;
      console.log(">>>>> " + errorMsg, err);
      return createMessageData(errorMsg, "bot", "text", uuid(), messageId);
    }
  };

  async function sendQ(messageId, message) {
    try {
      if (!chatBotData) {
        return {
          recvData: "챗봇의 정보가 정상 전달되지 않았습니다. 관리자에게 문의해주세요.",
          props: { recvSources: "", recvRsearch: "" },
        };
      }

      // if (true) {
      //   return {
      //     recvData:
      //       "안녕하세요, 메스와이저(MESwiser) 입니다 궁금한 점이 있으시다면,  \
      //     AI가 보다 풍부하게 답변 드릴 수 있게 조금 더 자세히 질문해 주시겠어요? \
      //     (예시) 메스와이저를 도입할 때 고려해야 할 부분이 있나요? \
      //     https://www.sejongpac.or.kr/portal/performance/performance/view.do?menuNo=200004&performIdx=34777에서 확인해보세요",
      //   };
      // }

      let indexName = chatBotData.indexName;
      let botUid = chatBotData.botUid;
      let role = chatBotData.botRole;
      const botRatio = chatBotData.botRatio ?? 0.2;
      const showSource = chatBotData.showSource ?? "N";
      const useHistory = chatBotData.useHistory ?? "N";
      const useRsearch = chatBotData.useRsearch ?? "N";
      const useAddPrompt = chatBotData.useAddPrompt ?? "N";
      const useChatMarkdown = chatBotData.useChatMarkdown ?? "N";

      /*
        /명령 삽입구간 테스트 24.01.24.codelua
        어떠한 설정을 만들기전에 먼저 가능성을 보기 위한 예졔 코드 작성 
      */
      // if (1 === 1) {
      //   var comList = [];
      //   comList.push({ className: "info2", command: "할인정보" });
      //   comList.push({ className: "info1", command: "공연정보" });
      //   return {
      //     recvData: "사용가능한 명령들입니다",
      //     recvComList: comList,
      //   };
      // }

      // if (message.startsWith("/")) {
      //   const command = fetchCommandsFromServer();
      //   const comPrefix = message.substring(1).split(" ")[0]; // /명령, /도움말등을 자른다.
      //   if (command.includes(comPrefix)) {
      //     // 서버로 부터 가능 명령어와 API코드를 받아온다.
      //     const comList = await getCommandList(comPrefix);
      //     if (comList) {
      //       console.log("comList:", comList);
      //       return {
      //         recvData: "사용가능한 명령들입니다",
      //         recvComList: comList,
      //       };
      //     } else {
      //       return {
      //         recvData: "사용 가능한 명령이 없습니다.",
      //       };
      //     }
      //   }
      // }

      // if (1 === 1) {
      //   return {
      //     recvData: "테스트중이라 답변이 고정되있습니다. ",
      //   };
      // }

      /* /명령 예제코드 작성 종료 부분 */

      // setShowOwnBot

      const { status, data } = setShowOwnBot // ownBot을 사용중이라면 api를 운영쪽으로
        ? await ownBotApi.sendQuestion(
            indexName,
            chatRoomId,
            messageId,
            message,
            firstMessage,
            role,
            botRatio,
            useHistory,
            useRsearch,
            useAddPrompt
          )
        : await chatApi.sendQuestion(
            indexName,
            botUid,
            chatRoomId,
            messageId,
            message,
            IP,
            firstMessage,
            role,
            botRatio,
            showSource,
            useHistory,
            useRsearch,
            useAddPrompt,
            activedFolderUidList,
            useChatMarkdown
          );
      if (status === 200) {
        const resultData = JSON.parse(data.resData);
        const sources = data.sourceList ? JSON.parse(data.sourceList) : "";
        const rsearchs = data.rsearchList ? JSON.parse(data.rsearchList) : "";
        // console.log(">>>> rsearchs:", rsearchs);
        return {
          recvData: resultData.recvMessage,
          recvSources: sources,
          recvRsearch: rsearchs,
        };
      } else {
        console.log(">>>>>>>", "FAIL");
        return "챗봇 응답에 문제가 있습니다. 관리자에게 문의해주세요.";
      }
    } catch (error) {
      console.log(">>>>>>> ERROR: ", error);
      if (error.response && error.response.status === 403) {
        setPopState((prev) => !prev);
        setPopMessage("세션이 만료되었습니다. 다시 로그인해주세요.");
        // alert("세션이 만료되었습니다. 다시 로그인해주세요.");
        handleSignOutApp();
        history.replace({ pathname: "/" });
      } else {
        if (error.response.data.resultMessage) {
          console.log(">>>>>>> sendQ ErrorMsg: ", error.response.data.resultMessage);
          return {
            recvData: error.response.data.resultMessage,
            recvSources: "",
            recvRsearch: "",
          };
        } else {
          return {
            recvData: "서버연결이 원할하지 않습니다. 관리자에게 문의하세요",
            recvSources: "",
            recvRsearch: "",
          };
        }
      }
    } finally {
      setFirstMessage(false);
    }
  }

  const sendMessage = async () => {
    if (isLoading) return;
    if (textAreaRef.current.value.trim("") === "") return;

    setTextAreaHeight("33px");

    const messageId = uuid();
    const userMessage = createMessageData(newMessage, "user", "text", uuid(), messageId);
    setMessages((prev) => [...prev, userMessage]);
    setNewMessage("");
    setIsLoading(() => true);
    const botRatio = chatBotData.botRatio ?? 0.2;

    try {
      const recvMessage = await createBotMessage(messageId, newMessage, botRatio);
      // console.log(">>>> recvMessage:", recvMessage);
      if (typeof recvMessage === "object" && Array.isArray(recvMessage)) {
        setMessages((prev) => [...prev, ...recvMessage]);
      } else {
        setMessages((prev) => [...prev, recvMessage]);
      }
    } catch (err) {
      console.log("err", err);
    } finally {
      setIsLoading(() => false);
    }

    setTextareaValue(false);
  };

  const closeChatbot = () => {
    setNewMessage("");
    setOpenChatRoom(() => false);
    if (isHistoryBack) {
      history.goBack();
    }
  };

  const getSendAndRecvMessageData = (messageId) => {
    const array = messages.filter((d) => d.messageId === messageId);
    return {
      messageId,
      send: array[0].message,
      recv: array[1].message,
    };
  };

  const visableResponsePop = (messageId) => {
    if (isFreeUser) {
      setPopState((prev) => !prev);
      setPopMessage("유료 버전으로 전환하시면 특정 질문에 대한 응답을 수정하실 수 있습니다.");
      // alert(
      //   "유료 버전으로 전환하시면 특정 질문에 대한 응답을 수정하실 수 있습니다."
      // );
      return;
    }
    setResponsePopInfo({
      isShow: true,
      data: getSendAndRecvMessageData(messageId),
    });
  };

  const cancelResponsePop = () => {
    setResponsePopInfo({
      isShow: false,
      data: null,
    });
  };

  const updateResponseMsgInPop = (type, value) => {
    setResponsePopInfo((prev) => ({
      ...prev,
      data: {
        ...prev.data,
        [type]: value,
      },
    }));
  };

  const submitResponsePop = async () => {
    if (responsePopInfo?.data?.send?.trim().length === 0) {
      setPopMessage("질문을 작성해 주세요.");
      setPopState((prev) => !prev);
      return;
      // return alert("제목을 입력해 주세요.");
    }

    if ((responsePopInfo?.data?.send ?? "").trim().length > 300) {
      setPopMessage("질문의 최대 글자수는 300자 입니다.");
      setPopState((prev) => !prev);
      return;
    }

    if (responsePopInfo?.data?.recv?.trim().length === 0) {
      setPopMessage("답변을 작성해 주세요.");
      setPopState((prev) => !prev);
      return;
      // return alert("내용을 입력해 주세요.");
    }

    if ((responsePopInfo?.data?.recv ?? "").trim().length > 1000) {
      setPopMessage("답변의 최대 글자수는 1000자 입니다.");
      setPopState((prev) => !prev);
      return;
    }

    try {
      const {
        tmp,
        data: { messageId, send, recv },
      } = responsePopInfo;
      if (!messageId) {
        console.log(">>>>>>>>>> 메시지 아이디???", messageId);
        return;
      }

      let indexName = chatBotData.indexName;
      let botUid = chatBotData.botUid;
      const { data, status } = await botApi.addKnowledge(botUid, indexName, messageId, send, recv);
      setMessages((prev) =>
        prev.map((prevData) => {
          if (prevData.messageId === messageId && prevData.type === "bot") {
            return { ...prevData, isResponsed: true };
          }
          return prevData;
        })
      );
    } catch (err) {
      console.log("err", err);
    }
    cancelResponsePop();
  };

  // textarea 사이즈 조절 함수
  const resizeTextarea = (e) => {
    setTextAreaHeight(`${e.target.scrollHeight}px`);
  };

  // textarea 값이 들어왔을때 button 색상 변경 함수
  const handleSendBtnColor = (e) => {
    if (e.target.value.trim() === "") {
      setTextareaValue(false);
    } else {
      setTextareaValue(true);
    }
  };

  // BottomBtn 클릭 이벤트
  const handleBottomBtnClick = (item) => {
    if (item.operType === 1) {
      if (scSignal === "on") {
        setChatPopState((prev) => !prev);
        setChatPopMessage("답변중인 내용이 끝나고 다시 눌러주세요");
        return;
      }
      const handleDetail = [
        createMessageData(item.name, "user", "text", uuid(), uuid(), {
          fixBot: true,
        }),
        createMessageData(item.detail, "bot", "text", uuid(), uuid(), {
          fixBot: true,
        }),
      ];
      setMessages((prev) => [...prev, ...handleDetail]);
      chatBotData?.useBottomMenu !== "A" && setChatMenuState((prev) => !prev);
    } else if (item.operType === 2) {
      window.open(item.detail);
      chatBotData?.useBottomMenu !== "A" && setChatMenuState((prev) => !prev);
    } else if (item.operType === 3) {
      if (window.opener.parent) {
        const messageData = {
          text: "changeURL",
          url: item.detail,
        };
        window.opener.parent.postMessage(messageData, "*");
      } else {
        window.open(item.detail);
      }
      chatBotData?.useBottomMenu !== "A" && setChatMenuState((prev) => !prev);
    }
  };

  useEffect(() => {
    vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }, []);

  useEffect(() => {
    // messages ( 채팅 기록 )이 변경될 때 마다 스크롤을 최 하단으로 이동
    chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;

    if (!isHistory && !messages[messages.length - 1]?.fixBot) {
      setScSignal("on");
    }
  }, [messages]);

  useEffect(() => {
    let id = 0;
    if (scSignal === "on") {
      id = setInterval(() => {
        // console.log("time processing... id:", id);
        chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
      }, 600);

      // console.log(">>. interval start id:", id);
    }

    return () => {
      if (id) {
        // console.log("interval free id:", id);
        clearInterval(id);
        if (chatBodyRef && chatBodyRef?.current) {
          chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
        }
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scSignal]);

  return (
    <WholeChatWrapper
      id="chat--bot2"
      className={`${chatTheme} ${botClass} chat--bot2 ${openChatRoom ? "active" : "none"}
      ${!isPopup ? `chat--cover ${botClass}` : ""}
      `}
    >
      {fullLoading && <LoadingComponent />}
      {createPortal(
        showResponsePop && (
          <PopWrapper className={""} isShow={responsePopInfo.isShow}>
            <Container>
              <Box className="box">
                <p className="title">질문</p>
                <input
                  type="text"
                  value={responsePopInfo?.data?.send ?? ""}
                  onChange={(e) => updateResponseMsgInPop("send", e.target.value)}
                />
              </Box>
              <Box className="box">
                <p className="title">응답</p>
                <textarea
                  spellCheck="false"
                  name=""
                  id=""
                  value={responsePopInfo?.data?.recv ?? ""}
                  onChange={(e) => updateResponseMsgInPop("recv", e.target.value)}
                />
              </Box>
              <FlexBox className="btnCt">
                <NormalButton
                  disabled={false}
                  buttonId="cancel"
                  buttonTitle="취소"
                  buttonClass={"cDel mid"}
                  callBackFunc={cancelResponsePop}
                />
                <NormalButton
                  type={"button"}
                  id={"btn"}
                  disabled={false}
                  buttonClass={"cOrange mid"}
                  callBackFunc={submitResponsePop}
                  buttonTitle={"추가"}
                />
              </FlexBox>
            </Container>
          </PopWrapper>
        ),
        document.getElementById("modal")
      )}

      {botClass !== "own" && isPopup && <div className={"background-cover"}></div>}

      <div className={`${!isPopup ? `chatBox ${botClass}` : "chatBox"} `}>
        {isHeader && (
          <HeaderContainer className={botClass}>
            <div className="webHeader">
              {!setShowOwnBot && chatBotData?.useKnowledgeManage === "Y" && (
                <KnowledgeBtnAndDrawer
                  folderList={folderList}
                  activedFolderUidList={activedFolderUidList}
                  knowledgeActiveDescription={knowledgeActiveDescription}
                  updateActiveYnInFolderList={updateActiveYnInFolderList}
                />
              )}
              {!isHistory && <img className="botImg botImg2" src={botImageUrl} alt="챗봇이미지02" />}

              <p className="chatName">{chatBotData && `${chatBotData?.botName ?? ""}`}</p>
              {isHistoryBack && (
                <button
                  className="ri-close-line"
                  type="button"
                  onClick={() => {
                    if (clickFunc) {
                      clickFunc();
                      botClass === "own" && setShowOwnBot((prev) => !prev);
                    } else {
                      setOpenChatRoom(() => false);
                    }
                    if (isHistoryBack) {
                      history.goBack();
                    }
                  }}
                ></button>
              )}

              {/* 소개, 예시질문이 세팅되어있지 않다면 햄버거메뉴는 그리지 않는다. */}
              {(((chatBotData?.introIn?.length ?? 0) > 0 && (chatBotData?.introInName?.length ?? 0) > 0) ||
                ((chatBotData?.introOut?.length ?? 0) > 0 && (chatBotData?.introOutName?.length ?? 0) > 0) ||
                (exList.length > 0 && (chatBotData?.exqInName?.length ?? 0) > 0) ||
                ((chatBotData?.exqOut?.length ?? 0) > 0 && (chatBotData?.exqOutName?.length ?? 0) > 0)) && (
                <MenuBtn
                  className={isMobile || botClass === "own" ? "small" : "wide"}
                  onClick={handleDropMenu}
                  icon={iconMenu}
                ></MenuBtn>
              )}

              {isMobile && (botClass === "own" || botClass === "widget") && (
                <button
                  className="ri-close-line"
                  type="button"
                  style={{ color: "#000" }}
                  onClick={() => {
                    if (clickFunc) {
                      clickFunc();
                      botClass === "own" && setShowOwnBot((prev) => !prev);
                    } else {
                      setOpenChatRoom(() => false);
                    }
                    if (isHistoryBack) {
                      history.goBack();
                    }
                  }}
                ></button>
              )}

              {dropState && (
                <DropMenuBg className={(!isMobile && botClass === "own") || botClass === " widget" ? "small" : "wide"}>
                  <DropMenu className={dropState ? `${botClass} drop active` : `${botClass} drop`}>
                    <div className="header">
                      <p className="">{/* 더보기 */}</p>
                      <button onClick={handleDropMenu}></button>
                    </div>
                    {/* 소개 */}
                    {(chatBotData?.introInName?.length ?? 0) > 0 && (chatBotData?.introIn?.length ?? 0) > 0 && (
                      <p id="intro" onClick={handleDropMenu}>
                        <i className="ri-megaphone-line"></i>

                        {chatBotData.introInName}
                      </p>
                    )}
                    {/* 예시질문  */}
                    {(chatBotData?.exqInName?.length ?? 0) > 0 && exList.length > 0 && (
                      <p id="ex" onClick={handleDropMenu}>
                        <i className="ri-chat-quote-line"></i>

                        {chatBotData.exqInName}
                      </p>
                    )}
                    {/* 외부URL 1  */}
                    {(chatBotData?.introOutName?.length ?? 0) > 0 && (chatBotData?.introOut?.length ?? 0) > 0 && (
                      <p id="url1" onClick={handleDropMenu}>
                        <i className="ri-external-link-fill"></i>
                        {chatBotData.introOutName}
                      </p>
                    )}
                    {/* 외부URL 2 */}
                    {(chatBotData?.exqOutName?.length ?? 0) > 0 && (chatBotData?.exqOut?.length ?? 0) > 0 && (
                      <p id="url2" onClick={handleDropMenu}>
                        <i className="ri-external-link-fill"></i>
                        {chatBotData.exqOutName}
                      </p>
                    )}
                  </DropMenu>
                </DropMenuBg>
              )}
            </div>
          </HeaderContainer>
        )}

        <div className={`chatBody ${botClass}`} ref={chatBodyRef}>
          {messages.map((data, index) => {
            const isBot = data.type === "bot";
            const isUser = data.type === "user";
            const isFixBot = data?.fixBot;
            const isEnd = data?.isEnd ?? false;

            return (
              <div key={data.id} className={`${data.type} chatObj`}>
                {isBot && !isHistory && <img className="botImg" src={botImageUrl} alt="챗봇이미지02" />}
                {chatTheme === "left" && isUser && (
                  <Avatar
                    sx={{ width: "28px", height: "28px", mr: 1, alignSelf: "flex-start" }}
                    variant="soft"
                    color="primary"
                    src="/broken-image.jpg"
                  />
                )}
                <FlexColumnBox
                  style={{
                    width: "100%",
                    flexDirection: isMobile ? "column" : isBot ? "row" : "none",
                    justifyContent: isUser ? "flex-end" : "flex-start",
                  }}
                >
                  {/* bot class가 external일때 응답관리 안보이게 style 인라인으로 수정 */}
                  <FlexBox
                    className=""
                    style={{
                      width: chatTheme === "left" ? "100%" : "",
                      flexDirection: "column",
                      alignItems: isBot ? "" : "end",
                    }}
                  >
                    <div className="chatText">
                      <div>
                        {isBot && !isFixBot && !isHistory && !isEnd ? (
                          <ChatTypeWriter data={data} setScSignal={setScSignal} isLoading={isLoading} />
                        ) : (
                          <ChatPlainWriter data={data} />
                        )}
                      </div>
                      {isBot && data.messageType === "action" && data?.action()}
                    </div>

                    <p className="time">{data.time}</p>

                    {!isMobile && isBot && !firstMessage && data.recvSources && (
                      <BotChatSourceBox>
                        <RefBox sources={data.recvSources} clickOpenOrDown={clickOpenOrDown} />
                      </BotChatSourceBox>
                    )}

                    {isBot && !firstMessage && data.recvRsearch && (
                      <BotChatSourceBox>
                        <FaqBox
                          setChatPopState={setChatPopState}
                          setChatPopMessage={setChatPopMessage}
                          scSignal={scSignal}
                          rsearchs={data.recvRsearch}
                          clickFaqData={clickFaqData}
                        />
                      </BotChatSourceBox>
                    )}
                    {/* API커멘드 연결 처리 */}
                    {isBot && !firstMessage && data.recvComList && (
                      <BotChatSourceBox>
                        <CommandBox recvComList={data.recvComList} procCommand={procCommand} />
                      </BotChatSourceBox>
                    )}
                  </FlexBox>

                  {/* bot class가 external일때 응답관리 안보이게 style 인라인으로 수정 */}
                  <FlexBox
                    style={{
                      minWidth: data?.type === "bot" ? "80px" : "0",
                      marginLeft: !isMobile ? 10 : 0,
                      display: botClass === "EXTERNAL" || botClass === "own" || botClass === "widget" ? "none" : "",
                    }}
                  >
                    {data?.type === "bot" && showResponsePop && (
                      <NormalButton
                        type={"button"}
                        id={"btn"}
                        disabled={data?.isResponsed}
                        buttonClass={`small cGreen round response ${isFreeUser ? "disable" : ""}`}
                        callBackFunc={() => visableResponsePop(data.messageId)}
                        buttonTitle={
                          data?.isResponsed ? (
                            <>
                              <i className="ri-checkbox-circle-line"></i>
                              응답완료
                            </>
                          ) : (
                            <>
                              <i className="ri-pencil-line"></i>
                              응답관리
                            </>
                          )
                        }
                      />
                    )}
                    {data?.type === "bot" && isFreeUser && showDesc && (
                      <i
                        className="ri-information-line"
                        onClick={visableResponsePop}
                        // style={!isMobile ? { alignSelf: "end" } : {}}
                      ></i>
                    )}
                  </FlexBox>
                </FlexColumnBox>
              </div>
            );
          })}

          {isLoading && (
            <div className="bot chatObj">
              <img className="botImg" src={botImageUrl} alt="봇이미지" />
              <div className="botText chatText" style={{ padding: "3px 20px 0 20px" }}>
                <LoadingSpinner className="spinner">
                  <React1 className="rect1"></React1>
                  <React1 className="rect2"></React1>
                  <React1 className="rect3"></React1>
                  <React1 className="rect4"></React1>
                  <React1 className="rect5"></React1>
                </LoadingSpinner>
                {/* <img src={LoadingSvg} alt="" /> */}
              </div>
              <p className="time">{getNow()}</p>
            </div>
          )}

          {descPopState === "intro" ? (
            <ChatIntroCt
              introInName={chatBotData?.introInName ?? ""}
              introText={chatBotData?.introIn ?? ""}
              setDescPopState={setDescPopState}
              botClass={botClass}
              botImageUrl={botImageUrl}
            />
          ) : descPopState === "ex" ? (
            <ChatExQCt
              exqInName={chatBotData?.exqInName ?? ""}
              setDescPopState={setDescPopState}
              exList={exList}
              setNewMessage={setNewMessage}
              newMessage={newMessage}
              sendMessage={sendMessage}
              botClass={botClass}
            />
          ) : (
            descPopState === "none" && <></>
          )}
          {chatMenuState && (
            <ChatMenuCt className={chatMenuState ? `active ${botClass}` : `hidden ${botClass}`}>
              <ChatMenuBtnCt className={botClass}>
                {BottomData.map((item, idx) => {
                  return (
                    <ChatMenuBtn
                      key={idx}
                      onClick={(e) => {
                        handleBottomBtnClick(item);
                      }}
                      icon={setButtonImage(item.imgNo)}
                      className={botClass}
                    >
                      <span className="icon" />
                      <br />
                      {item.name}
                    </ChatMenuBtn>
                  );
                })}
              </ChatMenuBtnCt>
            </ChatMenuCt>
          )}
        </div>
        {chatBotData?.useBottomMenu === "A" && (
          <div className="chatInput chatFooterSection chatAMenuSection">
            <div className="chatFooterMenuCt">
              <div className="chatFooterMenuCtInner">
                <ChatMenuBtnCt className={botClass}>
                  {BottomData.map((item, idx) => {
                    return (
                      <ChatMenuBtn
                        key={idx}
                        onClick={(e) => {
                          handleBottomBtnClick(item);
                        }}
                        icon={setButtonImage(item.imgNo)}
                        className={botClass}
                      >
                        <span className="icon" />
                        <br />
                        {item.name}
                      </ChatMenuBtn>
                    );
                  })}
                </ChatMenuBtnCt>
              </div>
            </div>
          </div>
        )}
        {showQuestionBox && (
          <div className="chatInput chatFooterSection">
            {/* chatInput === section */}
            <div className="chatFooterCt">
              <div className="chatFooterCtInner">
                {chatBotData?.useBottomMenu === "Y" && (BottomData?.length ?? 0) > 0 && (
                  <button
                    disabled={isLoading ? true : false}
                    onClick={() => {
                      setChatMenuState((prev) => !prev);
                    }}
                    className={chatMenuState ? "chatInfoBtn active" : "chatInfoBtn "}
                    icon={""}
                  ></button>
                )}
                <div className="chatTextAreaCt">
                  <textarea
                    spellCheck="false"
                    onKeyUp={resizeTextarea}
                    style={{ height: textAreaHeight }}
                    ref={textAreaRef}
                    name=""
                    id="userChatInput"
                    placeholder="궁금한 것을 물어보면 AI가 답변해 드려요!"
                    value={newMessage}
                    onChange={(e) => {
                      setNewMessage(e.target.value);
                      setDescPopState("none");
                      handleSendBtnColor(e);
                    }}
                    onKeyDown={onMessageKeyDown}
                  ></textarea>
                  <button
                    id="chatSendBtn"
                    className={textareaValue ? "chatSendBtn active" : "chatSendBtn"}
                    onClick={() => sendMessage()}
                  ></button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {showConfirmPop && (
        <ConfirmPop
          setShowConfirmPop={setShowConfirmPop}
          title={"참고파일 다운로드"}
          callback={popCallback?.normal}
          description={description}
          popClass={botClass === "own" ? "own" : ""}
        />
      )}
      {chatPopStae && <AlertPop2 popState={chatPopStae} setPopState={setChatPopState} text={chatPopMessage} />}
    </WholeChatWrapper>
  );
}

const FadeIn = keyframes`
  0% {
    /* top: -30%; */
    opacity: 0;
  }
  100% {
    /* top: 0px; */
    opacity: 1;

  }
`;
const FadeInUp = keyframes`
  0% {
    top: 35px;
    opacity: 0;
  }
  100% {
    top: 0%;
    opacity: 1;
  }
`;

const WholeChatWrapper = styled.div`
  & div.widget,
  & div.own {
    & div.chatInput {
      button {
        font-size: 14px;
      }
    }
    textarea {
      /* height: 20px !important; */

      font-size: 14px !important;
      line-height: normal !important;
      &::placeholder {
        font-size: 14px !important;
        line-height: normal !important;
      }
    }
  }

  &.widget {
    box-shadow: 0px 5px 17px 0px rgba(0, 0, 0, 0.26);
    border-radius: 25px;
    margin-right: 15px;
    animation: ${FadeInUp} 0.2s ease-in-out;
  }

  & div.chatObj {
    & button.response {
      height: fit-content;
      font-size: 14px;
      padding: 8px;
    }
  }
`;

const PopWrapper = styled.div`
  display: ${({ isShow }) => (isShow ? "flex" : "none")};
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
`;

const Container = styled.div`
  width: calc(50vw - 180px);
  min-height: fit-content;

  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);

  @media (max-width: 480px) {
    width: 90vw;
  }

  & p.title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  & .box {
    margin-bottom: 35px;

    &:nth-child(2) {
      flex-grow: 1;
      margin-bottom: 30px;
    }
  }

  & .btnCt {
    margin-top: auto;
    justify-content: center;

    & button {
      margin: 0 10px;
      padding: 5px 15px;
      flex: 1 0 40%;
    }
  }

  & > div {
    margin: 5px 0;
    width: 100%;

    input,
    textarea,
    button {
      width: 100%;
    }

    input,
    textarea {
      padding: 5px;
      border: 1px solid #b3b3b3;
      border-radius: 5px;
      font-size: 16px;

      @media (max-width: 480px) {
        font-size: 15px;
      }
    }

    textarea {
      height: calc(45vh - 110px);
      resize: none;
    }
  }
`;

const LoadingSpinner = styled.div``;

const React1 = styled.div``;
const React2 = styled.div``;
const React3 = styled.div``;
const React4 = styled.div``;
const React5 = styled.div``;

const MenuBtn = styled.button`
  margin-right: 10px;

  @media (min-width: 481px) {
    display: flex;
    align-items: center;
    justify-content: center;
    background: url(${({ icon }) => icon}) no-repeat;
    background-size: 50px;
    background-position: center;
    &:hover {
      opacity: 0.7;
    }
  }

  @media (max-width: 480px) {
    margin-right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url(${({ icon }) => icon}) no-repeat;
    background-size: 40px;
    background-position: center;
  }
`;

const UpDown = keyframes`
  0% {
    top: -30%;
    opacity: 0;
  }
  100% {
    top: 0px;
    opacity: 1;

  }
`;
const DropMenuBg = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.4);

  animation: ${FadeIn} 0.15s ease-in-out;

  border-radius: 25px;

  @media (max-width: 480px) {
    border-radius: 0;
  }
  @media (min-width: 481px) {
    &.wide {
      border-radius: 0;
    }
  }
`;

const DropMenu = styled.div`
  display: none;
  z-index: 2;

  &.active {
    display: block;
    animation: ${UpDown} 0.3s ease-in-out;
  }
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  z-index: 1;
  box-shadow: 0px 0.5px 0.5px 0px rgba(0, 0, 0, 0.3);
  background: #fff;
  border-radius: 0 0 36px 36px;

  overflow: hidden;

  & p {
    width: 100%;
    height: 45px;

    padding: 11px 20px;
    color: #5a5a5a;

    font-size: 14px;
    font-weight: 350;
    line-height: 20px;

    @media (min-width: 481px) {
      font-weight: 500;
    }
    cursor: pointer;
    & i {
      margin-right: 10px;
    }
    &:hover {
      color: #ff892a;
      font-weight: 500;
    }
  }
  & div.header {
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      padding: 14px;
      width: auto;
      line-height: 20px;
      font-size: 13px;
      font-weight: 500;
      /* font-size: 1.2rem; */
      color: #00000060;
      &:hover {
        font-weight: 400;
        pointer: default !important;
      }
    }
    button {
      width: 25px;
      height: 25px;
      margin-right: 8px;
      background: url(${iconCloseRound}) no-repeat;
      background-size: contain;
      background-position: center;
    }
  }

  @media (min-width: 481px) {
    border-radius: 0px 0px 30px 30px;

    &.own,
    &.widget {
      border-radius: 25px 25px 30px 30px;
    }

    width: 100%;
    max-width: 860px;

    left: 50%;
    transform: translateX(-50%);

    & p {
      margin: 2px 0;
      background: #fff;
      font-size: 16px;
    }
  }
`;

const ChatMenuUp = keyframes`
  0% {
    bottom: -30%;
    opacity: 0;
  }
  100% {
    bottom: 58px;
    opacity: 1;

  }
`;

const ChatMenuCt = styled.div`
  border: 1px solid transparent;
  width: calc(100% - 30px);

  border-radius: 10px 10px 0 0;

  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  color: #fff;
  transition: 0.3s;

  animation: ${ChatMenuUp} 0.2s ease-in-out;

  bottom: 60px;

  @media (min-width: 481px) {
    max-width: calc(860px - 30px);
    left: 50%;
    bottom: 74px;
    transform: translate(-50%);

    &.widget,
    &.own {
      max-width: calc(100% - 30px);
      bottom: 60px;
    }
  }

  @media (max-width: 480px) {
    &.widget,
    &.own {
      width: calc(100% - 30px);
    }
  }
`;

const ChatMenuBtnCt = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background: #fff;
`;

const ChatMenuBtn = styled.button`
  flex: 1 0 30%;
  height: 80px;
  font-size: 15px;
  color: #fff;
  border: 0.5px solid #fff;

  background: linear-gradient(0deg, #2a8cff 0%, #2a8cff 100%);

  cursor: pointer;

  &:hover {
    font-weight: 500;
    opacity: 0.9;
  }

  & .icon {
    display: inline-block;

    @media (max-width: 480px) {
      width: 25px;
      height: 25px;
    }
    &.own,
    &.widget {
      width: 25px;
      height: 25px;
    }
    width: 35px;
    height: 35px;

    filter: invert(100%) sepia(9%) saturate(4791%) hue-rotate(220deg) brightness(111%) contrast(96%);

    background: url(${({ icon }) => icon}) no-repeat;
    /* background-image: url(${(props) => props.icon}); */
    background-size: contain;
    background-position: center;
  }

  &.own,
  &.widget {
    font-size: 13px;
  }
  @media (max-width: 480px) {
    font-size: 13px;
    letter-spacing: -1px;
  }
`;

const BotChatSourceBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  /* margin-top: 3px; */
`;

// 폴더리스트 메뉴 아이템 스타일 정의

const MyListItem = styled(ListItem)`
  border-bottom: 1px solid #dcdcdc;
  &:hover {
    opacity: 0.8;
  }

  &.titleCt {
    box-shadow: 0px 3px 3px 0px #00000040;
    margin-bottom: 10px;
    &:hover {
      opacity: 1;
    }
  }
`;

const MyListItemText = styled(ListItemText)`
  word-break: break-all;
  .css-10hburv-MuiTypography-root {
    font-size: 14px !important;
  }

  @media (max-width: 480px) {
    font-size: 13px !important;
  }

  &.listItem {
    width: 100%;
  }

  &.title {
    word-break: keep-all;
    .css-10hburv-MuiTypography-root {
      font-size: 1rem !important;
    }
    @media (max-width: 480px) {
      font-size: 1rem !important;
    }
  }
`;
