import { memo } from "react";
import styled from "styled-components";

export const Box = styled("div")`
  position: relative;
`;

export const Footer = styled("footer")`
  position: relative;
`;

export const Width100Box = styled(Box)`
  width: 100%;
`;

export const Height100Box = styled(Box)`
  height: 100%;
`;

export const WidthHeight100Box = styled(Width100Box)`
  width: 100%;
  height: 100%;
`;

export const FlexFooter = styled(Footer)`
  display: flex;
`;

export const FlexBox = styled(Box)`
  display: flex;
`;

export const FlexAlignCenterBox = styled(FlexBox)`
  display: flex;
  align-items: center;
`;

export const FlexAll_C_Box = styled(FlexBox)`
  align-items: center;
  justify-content: center;
`;

export const FlexBetween_AlignCenterBox = styled(FlexBox)`
  justify-content: space-between;
  align-items: center;
`;

export const Section = styled("section")`
  position: relative;
`;

export const FlexSection = styled(Section)`
  display: flex;
`;

export const Article = styled("article")`
  position: relative;
`;

export const FlexArticle = styled(Article)`
  display: flex;
`;

export const FlexColumnBox = styled(FlexBox)`
  flex-direction: column;
`;

export const FlexColumnArticle = styled(FlexArticle)`
  flex-direction: column;
`;

export const FlexChildContainer = styled(Box)(({ flex }) => ({
  flex,
  padding: "0 5px",
  // width: "70%",
}));

export const DashboardContainer = styled(Width100Box)`
  height: 100vh;
`;

export const DashboardFlexContainer = styled(Width100Box)`
  height: calc(100% - 64px);
  display: flex;
  justify-content: center;
  align-items: stretch;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
  overflow: hidden;
`;

export const DashboardNavComponent = styled(FlexColumnBox)`
  @media (max-width: 480px) {
    display: none;
  }
  background-color: #f8f9fa;
  padding: 15px;

  flex: 0 0 auto;
  width: 180px;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
  z-index: 5;
  background-color: #e2e8f0;

  .dashboard--choose--chatbot {
    flex: 0.1;
    margin-bottom: 15px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;

    & > div {
      width: 100%;
      height: 90%;
      box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.15);
    }
  }

  .dashboard--menu {
    flex: 0 0 auto;
    display: flex;
    justify-content: center;

    ul {
      flex: 0 0 auto;
      width: 100%;
      padding: 0 2px;

      li {
        width: 100%;
        margin: 7px 0;
        padding: 10px 2px;
        transition: 0.2s;
        cursor: pointer;

        &.active {
          font-weight: bold;
          color: #fff;
          border-radius: 10px;
          padding: 10px;
          /* background: rgba(0, 0, 0, 0.05); */
          background-color: #ff892a;
        }

        &:hover {
          background-color: #ff892a;
          font-weight: bold;
          color: #fff;
          border-radius: 10px;
          padding: 10px;
        }

        a {
          display: block;
          color: #2a303a;
        }

        i {
          margin-right: 10px;
        }
      }
    }
  }
`;

export const DashboardSubContainer = styled(FlexColumnBox)`
  /* flex: 5.2; */
  width: 100%;
  position: relative;
  /* align-items: stretch; */

  @media (min-width: 481px) {
    width: calc(100% - 180px);
  }
`;

export const DashboardHeader = styled(FlexBox)`
  flex: 0.5;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 3px 10px;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.15);
  z-index: 10;

  .left {
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      margin-left: 10px;
      height: 30px;
      font-size: 14px;
      line-height: 10px;
    }
  }

  .right {
    flex: 0.2;
    text-align: right;
  }
`;
export const DashboardContent = styled(Box)`
  max-height: 100%;
  flex: 5;
  padding: 0 10px;
  background-color: #fbfcfd;

  overflow-y: auto;
`;

export const SettingSection = styled(FlexSection)`
  min-height: 300px;
  margin: 10px 0;
  padding: 5px 10px;

  //&:not(:last-child)::after {
  //  content: "";
  //  width: 100%;
  //  height: 0;
  //  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.1);
  //  position: absolute;
  //  bottom: 0;
  //  left: 50%;
  //  transform: translateX(-50%);
  //}
  article:first-child {
    flex: 3;
    position: sticky;

    .sticky {
      top: 0;
      position: sticky;

      h2 {
        font-size: 28px;
        font-weight: bold;
        margin-bottom: 15px;
      }

      p {
        color: #9b9797;
        word-break: break-all;
        white-space: break-spaces;
      }
    }
  }

  // right
  article:last-child {
    flex: 5;

    .box {
      margin: 0 0 30px 0;

      &.bot-box {
        align-items: flex-end;

        div:first-child {
          flex: 0.15;
          margin-right: 10px;

          img {
            width: 80px;
            height: 80px;
            object-fit: cover;
          }
        }

        div:last-child {
          flex: 1;
        }
      }

      h6 {
        margin: 10px 0 5px 0;
      }

      textarea,
      select,
      input {
        width: 100%;
      }

      textarea {
        resize: none;
      }

      select {
        min-height: 40px;
      }
    }
  }
`;

export const DashboardLine = styled(Width100Box)`
  width: 100%;
  height: 0;
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.1);
  position: relative;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
`;

//Dashboard Home 에서 사용 할 styled component
const homeContainer = styled(FlexColumnBox)`
  width: 100%;
  height: 100%;
  max-height: 100%;
  padding: 25px 25px;
`;

const homeContent = styled(Width100Box)`
  /* width: 100%; */
  flex: 0 0 auto;
`;

const homeBotImg = styled("img")`
  width: 55px;
  height: 55px;
  border-radius: 100%;
`;

const homeFooter = styled(FlexFooter)`
  width: 100%;
  margin-top: 5px;
  overflow-y: auto;
  overflow-x: hidden;
  justify-content: flex-end;
  align-items: center;

  padding-top: 10px;

  button {
    &.delete {
      background-color: #ec5656;
    }

    transform: scale(0.85);
  }
`;

const homeHeader = styled(Width100Box)`
  width: 100%;
  max-height: 12%;

  .top {
    justify-content: space-between;
    margin-bottom: 10px;

    .dashboard--home--title {
      font-weight: bold;
      font-size: 22px;
    }

    .dashboard--home--btn {
      height: 35px;
      font-size: 14px;
      line-height: 15px;
    }
  }

  .bottom {
    .tag--list {
      height: 50px;
      justify-content: flex-start;
      align-items: center;
      background: #e0e6ee;
      padding: 5px 5px;

      .tag {
        background-color: white;
        padding: 7px 15px;
        border-radius: 50px;
        margin: 0 5px;
      }
    }
  }
`;

const Tbody = styled.div`
  height: calc(100vh - 285px);
  width: calc(100% - 5px);
  overflow-y: scroll;
`;

// 0706 다시 작성
const MyPageContainer = styled.div`
  width: 100%;
  height: 100%;
  max-height: 100%;
  padding: 25px 20px;
  overflow-y: auto;
`;

const MyPageContent = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
`;

const MyPageContentColum = styled(MyPageContent)`
  flex-direction: column;
  align-items: center;

  width: 100%;

  @media (min-width: 481px) {
    /* width: calc(100% - 180px); */
  }

  @media (max-width: 480px) {
    margin-bottom: 5px;
    height: 0px;
  }
`;

export const DASH_HOME = {
  Container: MyPageContainer,
  Tbody: Tbody,
  Content: MyPageContent,
  ContentCol: MyPageContentColum,
  BotImg: homeBotImg,
  Footer: homeFooter,
  Header: homeHeader,
};

const DashHistoryContainer = styled(FlexBox)`
  width: 100%;
  /* width: calc(100% - 240px); */
  margin-left: auto;
  height: 100%;
`;

const DashHistoryList = styled("ul")`
  width: 100%;
  position: relative;
  margin-top: 10px;
`;

const DashHistoryListItemComponent = styled("li")`
  width: 100%;
  min-height: 50px;
  position: relative;
  display: flex;

  align-items: center;
  border-radius: 5px;
  padding: 8px 10px;
  margin: 10px 0;
  cursor: pointer;
  color: black;

  &.active {
    background-color: #6278fa;
    color: white;
  }

  p {
    margin: 4px 0;

    &:first-child {
      font-weight: bold;
    }
  }
`;

const DashHistoryListItem = memo((props) => {
  const { children, ...otherProps } = props;
  return (
    <DashHistoryListItemComponent {...otherProps}>
      <Box>{children}</Box>
    </DashHistoryListItemComponent>
  );
});

export const DASH_HISTORY = {
  Container: DashHistoryContainer,
  ChildContainer: FlexChildContainer,
  List: DashHistoryList,
  ListItem: DashHistoryListItem,
};

const DashQNAContainer = styled(FlexBox)`
  width: 100%;
  /* width: calc(100% - 240px); */
  margin-left: auto;
  height: 100%;
  flex-direction: column;
  overflow-y: auto;
`;

// vertical(수직) 값이 true일 경우 세로 슬라이드 / 아닐 경우 가로 슬라이드로 인식
const SlideWrap = styled(Box)`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;
const SlideContainer = styled(FlexBox)`
  width: ${({ vertical, count }) => (!vertical ? 100 * count + "%" : "100%")};
  height: ${({ vertical, count }) => (vertical ? 100 * count + "%" : "100%")};
  flex-direction: ${({ vertical }) => (!vertical ? "row" : "column")};
  bottom: ${({ vertical, current }) => (vertical ? current * 100 + "%" : "0px")};
  left: ${({ vertical, current }) => (!vertical ? current * 100 + "%" : "0px")};
`;

const SlideItem = styled(Box)`
  width: ${({ vertical, count }) => (!vertical ? 100 / count + "%" : "100%")};
  height: ${({ vertical, count }) => (vertical ? 100 / count + "%" : "100%")};

  border: 1px solid black;
`;

export const DASH_QNA = {
  Container: DashQNAContainer,
  ChildContainer: FlexChildContainer,
  List: DashHistoryList,
  ListItem: DashHistoryListItem,
};

export const DASH_RESPONSE = {
  Container: DashQNAContainer,
  ChildContainer: FlexChildContainer,
  List: DashHistoryList,
  ListItem: DashHistoryListItem,
};

export const Slide = {
  Wrap: SlideWrap,
  Container: SlideContainer,
  Item: SlideItem,
};
