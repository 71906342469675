import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import NormalButton from "components/atomic/NormalButton2";

import ConfirmPopDefault from "components/templates/popup/ConfirmPopDefault";

export default function ManageExQCt({
  data,
  idx,
  callbackFunc,
  exList,
  setExList,
}) {
  // 수정 버튼
  const [isModify, setIsModify] = useState(false);
  const [modifiedText, setModifiedText] = useState("");
  const [initText, setInitText] = useState(data.exData);

  const [callback, setCallback] = useState(() => {});
  const [showConfirmDefault, setShowConfirmDefault] = useState(false);

  // 수정된 text state 관리 함수
  const editText = (e) => {
    setModifiedText(e.target.value);
  };

  // 수정 state
  const modifyExQ = (type) => {
    if (type === "취소") {
      setModifiedText(initText);
      setIsModify((prev) => !prev);
    } else {
      setModifiedText(initText);
      setIsModify((prev) => !prev);
    }
  };

  const modifyConfirm = () => {
    data.exData = modifiedText;
    setInitText(modifiedText);
    setModifiedText(modifiedText);
    setIsModify((prev) => !prev);
  };

  const deleteExData = (data) => {
    // setExList(exList.filter((info) => info.exUid !== data.exUid));
    setExList((prevExList) => {
      const updatedExList = prevExList.filter(
        (info) => info.exUid !== data.exUid
      );
      return updatedExList.map((info, index) => ({ ...info, seq: index + 1 }));
    });
  };

  return (
    <BotInfoRow className="question" key={data.exUid}>
      {isModify ? (
        <input
          className="exText"
          value={modifiedText === "" ? data.exData : modifiedText}
          onChange={editText}
        ></input>
      ) : (
        <p className="exText">{data.exData}</p>
      )}

      <div className="btnCt">
        {isModify ? (
          <>
            <NormalButton
              type={"button"}
              disabled={false}
              buttonClass={"small cDel"}
              callBackFunc={() => modifyExQ("취소")}
              buttonTitle={"취소"}
            ></NormalButton>
            <NormalButton
              buttonClass={"small cOrange"}
              buttonTitle={"확인"}
              callBackFunc={() => {
                modifyConfirm(data.exUid);
              }}
            />
          </>
        ) : (
          <>
            <NormalButton
              buttonClass={"small cDel"}
              callBackFunc={() => {
                setShowConfirmDefault((prev) => !prev);
                setCallback(() => {
                  return () => deleteExData(data);
                });
              }}
              buttonTitle={"삭제"}
            ></NormalButton>

            <NormalButton
              buttonClass={"small cOrange"}
              buttonTitle={"수정"}
              callBackFunc={() => modifyExQ("수정")}
            />
          </>
        )}
      </div>

      {showConfirmDefault && (
        <ConfirmPopDefault
          setShowConfirmDefault={setShowConfirmDefault}
          title={"삭제하시겠습니까?"}
          callback={callback}
        />
      )}
    </BotInfoRow>
  );
}

const BotInfoRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  &.introTab {
    display: none;
    &.active {
      display: block;
    }
  }

  &.searchOtp {
    flex-direction: column;
    align-items: start;
  }
  &.responseStyle {
    justify-content: space-between;
    align-items: start;
    flex-direction: column;
    margin-bottom: 50px;
    @media (max-width: 480px) {
      flex-direction: column;
      align-items: start;
      margin-bottom: 50px;
    }

    & p {
      margin: 0;
      margin-bottom: 28.5px;
      @media (max-width: 480px) {
        margin-bottom: 14px;
      }
    }
  }

  &.question {
    justify-content: space-around;

    border: 1px solid #dcdcdc;
    padding: 10px;
  }

  & p.exText {
    word-break: break-all;
    margin-right: 10px;
    text-align: left;
    width: 100%;
    line-height: normal;

    @media (max-width: 480px) {
      font-size: 13px;
      letter-spacing: -1px;
    }
  }

  & form.inputCt,
  div.inputCt {
    display: flex;
    width: 100%;
    margin-bottom: 25px;
    transition: 0.3s;

    input {
      margin-right: 10px;
      flex: 0 1 auto;
    }
  }

  & input {
    border: 1px solid #dcdcdc;
    border-radius: 10px !important;
    padding: 5px 10px;
    width: 100%;

    &::placeholder {
      color: #a6a6a6;
      font-size: 16px;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.16px;
      @media (max-width: 480px) {
        font-size: 13px;
      }
    }
  }

  & input,
  textarea {
    background: #f3f3f3;

    &::placeholder {
      color: #a6a6a6;
      font-size: 16px;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.16px;

      @media (max-width: 480px) {
        font-size: 13px;
      }
    }
  }

  & div.btnCt {
    display: flex;
    width: fit-content;
  }

  @media (max-width: 480px) {
    flex-direction: column;
    & div.btnCt {
      width: 100% !important;
      justify-content: end;
      button {
        font-size: 12px;
        width: 45px;
        padding: 5px;
        margin: 0;
        &:first-child {
          margin: 0 5px 0 0;
        }
      }
    }
    & p.exText {
      margin-bottom: 10px;
      margin-right: 0 !important;
    }
  }
`;
