import styled from "styled-components";
import { useContext, useEffect, useState } from "react";
import botApi from "../../../api/botApi.js";
import NormalButton from "../../atomic/NormalButton2.js";
import BotApiButtonAddPop from "../../templates/popup/BotApiButtonAddPop.js";
import ConfirmPopDefault from "../../templates/popup/ConfirmPopDefault.js";
import uuid from "react-uuid";
import iconWraning from "../../../assets/images/icon_warning.svg";

export default function ApiCommandSetting({ menuProps }) {
  const {
    botData,
    useComMenu,
    setUseComMenu,
    apiCommandList,
    setApiCommandList,
  } = menuProps ?? {};

  const [inputs, setInputs] = useState(null);
  const [toolTip1, setTooltip1] = useState(false);

  // popup
  const [popDialogState, setPopDialogState] = useState(false);
  const [alertPopState, setAlertPopState] = useState(false);

  useEffect(() => {
    (async () => {
      if (apiCommandList.length === 0)
        setApiCommandList(await getapiCommandListInApi(botData.botUid));
    })();
  }, []);

  // 버튼 리스트 api에서 가져오기
  async function getapiCommandListInApi(botUid) {
    try {
      const data = await botApi.selectApiCommandList(botUid);

      return JSON.parse(data?.data?.apiCommandList ?? "[]");
    } catch (err) {
      console.log("err", err);
      return [];
    }
  }

  const addBtnInfoFunc = (item) => {
    setApiCommandList((prevState) => [
      ...prevState,
      { ...item, botUid: botData.botUid, id: uuid() },
    ]);
  };

  const modifyBtnInfoFunc = (item) => {
    setApiCommandList((prevState) => {
      const idx = prevState.findIndex(
        (btnInfo) => btnInfo.command === item.command
      );
      prevState[idx] = {
        ...{ ...item, botUid: botData.botUid, id: uuid() },
      };
      return [...prevState];
    });
  };

  return (
    <>
      {popDialogState && (
        <BotApiButtonAddPop
          btnInfo={inputs}
          addBtnInfoFunc={addBtnInfoFunc}
          modifyBtnInfoFunc={modifyBtnInfoFunc}
          setPopState={setPopDialogState}
        />
      )}
      {alertPopState && (
        <ConfirmPopDefault
          setShowConfirmDefault={setAlertPopState}
          title={"삭제하시겠습니까?"}
          callback={() => {
            setApiCommandList((prevState) =>
              prevState.filter((item) => inputs.command !== item.command)
            );
            setInputs(null);
          }}
          cancelCallback={() => {
            setInputs(null);
          }}
        />
      )}
      <TableCt>
        <BotModifyCt>
          <BotinfoCt>
            <BotInfoTitle icon={iconWraning}>API 연동 설정 </BotInfoTitle>
            <BotInfoRow className="toggleStyle">
              <BotInfoTitle icon={iconWraning} className="sub sub2">
                연관 질문{" "}
                <span className="toolTipCt">
                  <span
                    onMouseOver={() => {
                      setTooltip1((prev) => !prev);
                    }}
                    onMouseOut={() => {
                      setTooltip1((prev) => !prev);
                    }}
                    className="toolTipIcon"
                  ></span>
                  <span
                    className={toolTip1 ? "toolTipTxt active" : "toolTipTxt"}
                  >
                    질문의 키워드를 분석해 유사하거나 관련 있는 <br /> 질문을
                    추천합니다
                  </span>
                </span>
              </BotInfoTitle>
              <div
                style={toolTip1 ? { zIndex: -1 } : {}}
                id="useComMenu"
                className={useComMenu ? "toggle active" : "toggle"}
                onClick={() => {
                  setUseComMenu((prev) => !prev);
                }}
              >
                <div className="circle" id="useComMenu"></div>
              </div>
            </BotInfoRow>
            <BtnCt>
              <NormalButton
                type={"add"}
                buttonTitle={"API연동 추가"}
                buttonClass={"small cOrange round"}
                style={{ marginLeft: "0" }}
                callBackFunc={() => {
                  setInputs(null);
                  setPopDialogState(true);
                }}
              />
            </BtnCt>
          </BotinfoCt>
        </BotModifyCt>
        <BotModifyCt>
          <BotinfoCt>
            {apiCommandList.length < 1 ? (
              <BotInfoTitle className="subTitle center">
                추가된 버튼이 없습니다.
              </BotInfoTitle>
            ) : (
              <BotInfoRow>
                <table className="btnListTable">
                  <colgroup>
                    <>
                      <col width="7%"></col>
                      <col width="20%"></col>
                      <col width="20%"></col>
                      <col width="40%"></col>
                      <col width="15%"></col>
                    </>
                  </colgroup>
                  <thead>
                    <tr>
                      <td>순서</td>
                      <td>버튼 이름</td>
                      <td>클래스 이름</td>
                      <td>클래스 내용</td>
                      <td>설정</td>
                    </tr>
                  </thead>
                  <tbody>
                    {apiCommandList.map((item, idx) => {
                      return (
                        <tr key={idx}>
                          <td>{idx + 1}</td>
                          <td className="iconTd">{item.command}</td>
                          <td>{item.className}</td>
                          <td>
                            {item.codeDetail.length > 40
                              ? item.codeDetail.slice(0, 40) + "..."
                              : item.codeDetail}
                          </td>
                          <td>
                            <button
                              onClick={(e) => {
                                setInputs(() => ({ ...item }));
                                setAlertPopState(true);
                              }}
                              data-type={"delete"}
                              className="delete"
                            >
                              삭제
                            </button>
                            <button
                              onClick={(e) => {
                                setInputs(() => ({ ...item }));
                                setPopDialogState(true);
                              }}
                              data-type={"modify"}
                              className="modify"
                            >
                              수정
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </BotInfoRow>
            )}
          </BotinfoCt>
        </BotModifyCt>
      </TableCt>
    </>
  );
}

const TableCt = styled.div`
  position: relative;
  @media (max-width: 700px) {
    width: 100%;
  }
  display: block;
  width: 70%;
`;

const BotInfoRow = styled.div`
  display: flex;
  align-items: center;

  &.column {
    flex-direction: column;
    align-items: start;
  }

  & div.inputCt {
    display: flex;
    width: 100%;
    margin-bottom: 5px;
    transition: 0.3s;

    input {
      margin-right: 10px;
      flex: 0 1 auto;
    }

    button {
    }
  }

  & input {
    border-radius: 100px;

    &::placeholder {
      color: #a6a6a6;
      font-size: 16px;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.16px;
      @media (max-width: 480px) {
        font-size: 13px;
      }
    }
  }

  &.toggleStyle {
    flex: 0 0 50%;
    align-items: center;
    justify-content: start;
    @media (max-width: 480px) {
      flex: 1 0 100%;
    }
  }

  .toggle {
    display: inline-block;
    width: 40px;
    height: 20px;
    background: #ffe7ca;
    border-radius: 20px;
    position: relative;
    cursor: pointer;
    transition: 0.3s;
    margin-left: 10px;

    transform: translateY(2px);
    &.active {
      background-color: #b9f4bb;
    }
  }

  & input,
  textarea {
    background: #f3f3f3;

    &::placeholder {
      color: #a6a6a6;
      font-size: 16px;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.16px;

      @media (max-width: 480px) {
        font-size: 13px;
      }
    }
  }

  & div.btnCt {
    display: flex;
    width: fit-content;

    button {
      margin-left: 5px;
      font-size: 14px;
      line-height: normal;
      height: 30px;
      padding: 5px 10px;
      width: 50px;
    }
  }

  .circle {
    width: 20px;
    height: 20px;
    background: #f8b400;
    border-radius: 50%;
    position: absolute;
    /* top: 50%; */
    top: 0%;
    transition: transform 0.3s;
  }

  /* 활성화 스타일 */

  .active .circle {
    /* top: 50%; */
    transform: translateX(25px) translateY(0%);
    background: #4caf50; /* 색상 변경 */
    box-shadow: 0px 1px 9px 1px rgba(0, 0, 0, 0.11);
  }

  & table.btnListTable {
    width: 100%;

    thead {
      td {
        padding: 5px;
        color: #fff;
        font-weight: 500;
        border: 1px solid #fff;
        border-bottom: 1px splid #dcdcdc;
        background: #ff892a;
        vertical-align: middle;
      }

      text-align: center;
      font-size: 13px;
    }

    tbody {
      font-size: 12px;
      font-weight: 500;

      tr {
        &:nth-child(even) {
          background: #ffe1c8;
          color: #ff892a;
        }
      }

      td {
        vertical-align: middle;

        button {
          border-radius: 5px;
          margin: 2px;
          width: 45px;
          height: 25px;
          letter-spacing: -1px;

          &.modify {
            /* background: #19c37d;
            color: #fff; */
            background: #ff892a;
            color: #fff;
          }

          &.delete {
            /* background: darkgray;
            color: #fff; */
            color: #808080;
            background: #fff;
            border: 1px solid #808080;
          }

          &:first-child {
          }

          font-size: 11px;
        }

        &.iconTd {
          img {
            width: 25px;
            height: 25px;
          }
        }
      }
    }
  }
`;

const BtnCt = styled.div`
  display: flex;
  justify-content: center;
  margin: 15px 10px 15px !important;

  & button {
    line-height: normal;
    border-radius: 30px;
    font-size: 14px;
    min-width: 120px;
    font-weight: 500;
    margin-left: 20px;
    @media (max-width: 480px) {
      flex: 0 1 auto;
      margin: 0 5px;
      min-width: 100px;
    }
    &.run {
    }
  }
`;

const BotModifyCt = styled.div`
  border-radius: 10px;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.16);
  background: #fff;
  padding: 15px;
  padding-top: 20px;
  text-align: center;
  margin-top: 15px;

  button {
    &.change {
      & span {
        display: inline-block;
        width: 28px;
        @media (max-width: 480px) {
          width: 16px;
        }
      }
    }
  }
`;

const BotinfoCt = styled.div`
  margin-bottom: 15px;
`;

const BotInfoTitle = styled.p`
  color: #363636;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 12.5px;
  text-align: left;

  &.subTitle {
    font-weight: 400;
    margin-left: 8px;
  }

  & span {
    color: #ff6161;
    font-size: 16px;
  }

  &.sub {
    font-size: 15px;
    font-weight: 500;
  }
  &.sub2 {
    margin-bottom: 0;
  }

  & span.toolTipCt {
    display: inline-block;
    position: relative;
    transform: translateY(2px);
    @media (max-width: 480px) {
      transform: translateY(3px);
    }

    .toolTipIcon {
      display: inline-block;
      width: 16px;
      height: 16px;
      background: url(${({ icon }) => icon}) no-repeat;
      background-position: center;
      background-size: contain;

      &:hover {
        cursor: pointer;
      }
    }

    .toolTipTxt {
      display: none;
      position: absolute;
      width: 200px;
      padding: 10px 5px;

      top: -8px;
      left: 20px;

      font-size: 12px;
      line-height: 15px;
      word-break: keep-all;
      font-weight: 500;
      letter-spacing: -1px;
      text-align: center;
      color: #888;

      background: #f7f9ff;
      border-radius: 5px;

      &.active {
        display: block;
      }
    }
  }

  & button.moreBtn {
    display: inline-flex;
    margin-left: auto;
    align-items: center;

    & img {
      transform: rotate(0deg);
      width: 20px;
      height: 20px;
      margin-right: 5px;
      transition: 0.3s;
    }

    &.active {
      & img {
        transform: rotate(180deg);
      }
    }
  }

  @media (max-width: 480px) {
    font-size: 14px;
    margin-bottom: 14px;
    & span {
      font-size: 14px;
    }
  }
`;
