import { Box, DASH_HOME, FlexColumnBox } from "./dashboardStyle.js";
import React, { memo, useMemo, useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { saveAs } from "file-saver";
import styled from "styled-components";
import botApi from "api/botApi.js";
import { chatMiddle } from "../../../utils/chatUtil.js";
import { PaginationContainer } from "../../../utils/Pagination.js";
import NormalButton from "../../atomic/NormalButton2.js";
import ChatBotSelect from "../../../utils/ChatBotSelect.js";
import { chatContext } from "../../../utils/providers/ChatContextProvider.js";
import { isMobileContext } from "utils/providers/BrowserEnvContextProvider.js";
import iconDownArrow from "../../../assets/images/icon_arrowDownBlack.svg";
import { AlertPop2 } from "components/templates/popup/AlertPop";
import ChatEmpty from "./ChatEmpty.js";
import ConfirmPop from "components/templates/popup/ConfirmPop.js";
import LoadingComponent from "utils/LoadingComponent.js";
import AssignmentReturnedIcon from "@mui/icons-material/AssignmentReturned";

function ChatResponse() {
  const history = useHistory();
  const { chatBotData, setChatBotData } = useContext(chatContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [newQuestion, setNewQuestion] = useState("");
  const [newReceive, setNewReceive] = useState("");
  const [loading, setLoading] = useState(false);

  // comfirmpop
  const [confirmPopState, setConfirmPopState] = useState(false);
  const [popCallback, setPopCallback] = useState({
    normal: () => {},
    cancel: () => {},
  });

  const [recordCount, setRecordCount] = useState(0);

  const [addArrayInClient, setAddArrayInClient] = useState([]);
  const isAsync = true;
  const asyncFuncArgs = useMemo(() => {
    if (chatBotData?.botUid) {
      return [chatBotData?.botUid];
    } else {
      return [];
    }
  }, [chatBotData?.botUid]);

  const [reLoad, setReload] = useState(false);
  // alert pop
  const [popState, setPopState] = useState(false);
  const [popMessage, setPopMessage] = useState("");

  const addQna = async () => {
    let isError = false;
    setLoading(true);
    try {
      if (newQuestion?.trim().length === 0) {
        isError = true;
        setPopMessage("질문란에 글을 입력해 주세요.");
        setPopState((prev) => !prev);
        return;
        // return alert("제목을 입력해 주세요.");
      }

      if ((newQuestion ?? "").trim().length > 300) {
        isError = true;
        setPopMessage("질문의 최대 글자수는 300자 입니다.");
        setPopState((prev) => !prev);
        return;
      }

      if (newReceive?.trim().length === 0) {
        isError = true;
        setPopMessage("응답란에 글을 입력해 주세요.");
        setPopState((prev) => !prev);
        return;
        // return alert("내용을 입력해 주세요.");
      }

      if ((newReceive ?? "").trim().length > 1000) {
        isError = true;
        setPopMessage("응답란의 최대 글자수는 1000자 입니다.");
        setPopState((prev) => !prev);
        return;
      }
      // api logic...
      const result = await chatMiddle(
        {
          toObjTargetName: "knowledgeInfo",
        },
        "addKnowledge",
        chatBotData.botUid,
        chatBotData.indexName,
        null,
        newQuestion,
        newReceive
      );

      if (result?.status === 200 && isAsync) {
        setAddArrayInClient((prev) => [
          {
            ...result.data,
            sendMessage: newQuestion,
            recvMessage: newReceive,
            idx: 0,
          },
          ...prev.map((d, idx) => ({ ...d, idx: idx + 1 })),
        ]);
      }
    } catch (err) {
    } finally {
      setLoading(false);
      if (!isError) {
        setNewQuestion("");
        setNewReceive("");
      }
    }
  };

  const changeFileHandle = async (e) => {
    e.preventDefault();
    const maxFileSize = 100 * 1024 * 1024; // 100MB를 제한하기 위한 값
    const pickFile = e.target.files[0];

    // 1. 용량은 100mb아래서 제한 (확장자 체크는 서버에서 억지로 올린경우 고려.. 엑셀파일이 아닙니다 하려고..)
    if (Number(pickFile.size) > maxFileSize) {
      setPopState((prev) => !prev);
      setPopMessage("엑셀 파일은 총 100MB를 넘을 수 없습니다.");
      return;
    }

    // 2.실제 서버에 업로드해서 처리 진행 시작
    setLoading(true);
    try {
      let params = {
        botUid: chatBotData.botUid,
        indexName: chatBotData.indexName,
        memberUid: chatBotData.memberUid,
      };
      const { status, data } = await botApi.addKnowledgeFromExcel(params, pickFile); // 정상 업로드

      const resultData = data ? data.resultMessage : "응답관리 업로드에 실패했습니다.";
      // codeluafix
      setPopState((prev) => !prev);
      setPopMessage(resultData);

      // 성공 실패 따지지 말고 무조건 데이터 갱신 처리를 위해 state사용
      setReload((prev) => !prev);
    } catch (error) {
      setPopState((prev) => !prev);
      setPopMessage("엑셀 업로드에 실패했습니다. 관리자에게 문의가 필요합니다.");
      return;
    } finally {
      e.target.value = null;
      setLoading(false);
    }
  };

  const { isMobile } = useContext(isMobileContext);

  if (!chatBotData) {
    return (
      <DASH_HOME.Container>
        <ChatEmpty>아직 생성 된 챗봇이 없습니다.</ChatEmpty>
      </DASH_HOME.Container>
    );
  }

  return (
    <DASH_HOME.Container>
      {loading && <LoadingComponent />}
      <FlexColumnBox
        style={
          !isMobile
            ? {
                marginBottom: "20px",
                width: "70%",
              }
            : {
                marginBottom: "20px",
                width: "100%",
              }
        }
      >
        <ChatBotSelect chatBotData={chatBotData} setChatBotData={setChatBotData} />
      </FlexColumnBox>

      {Number(chatBotData?.serviceModel ?? 0) === 0 ? (
        <FreeUserTextCt>
          <CompleteText>해당 챗봇은 무료 버전을 사용중입니다.</CompleteText>
          <CompleteText className="letterSpacing">
            특정 챗봇에 대한 응답 관리를 활용하시려면
            <br /> 유료 모델에 가입하셔야 합니다.
          </CompleteText>

          <NormalButton
            buttonClass={"mid cOrange"}
            buttonTitle={"문의하기"}
            callBackFunc={() => {
              sessionStorage.setItem("DISPLAY_MENU", "QNA");
              history.go(0);
            }}
            // onClick={() => {}}
          ></NormalButton>
        </FreeUserTextCt>
      ) : (
        <>
          {/* 응답 컨테이너 */}
          <ResponseWrapper className="wrapper">
            <ResponseButtonLayer>
              <input
                type="file"
                id="fileUpload"
                multiple
                accept=".xls,.xlsx"
                style={{ display: "none" }}
                onChange={changeFileHandle}
              ></input>

              <FileUpLoadLabel htmlFor="fileUpload">
                <i className="ri-file-upload-fill" />
                엑셀 업로드
              </FileUpLoadLabel>

              <NormalButton
                buttonClass={isMobile ? "small cOrange withIcon" : "mid cOrange withIcon"}
                buttonTitle={
                  <>
                    <AssignmentReturnedIcon sx={{ mr: 1 }} fontSize="small" /> 템플릿 다운
                  </>
                }
                callBackFunc={() => {
                  const fileUrl = "/response_exam.xlsx";
                  const fileName = "응답관리템플릿.xlsx";
                  fetch(fileUrl)
                    .then((response) => response.blob())
                    .then((blob) => {
                      saveAs(blob, fileName);
                    })
                    .catch((error) => {
                      console.log("Error While donwloading the file:", error);
                    });
                }}
                // onClick={(e) => {}}
              ></NormalButton>
            </ResponseButtonLayer>

            <ResponseCt className="registCt">
              {/* 응답 등록 */}
              <ResponseInputCt className="regist">
                <ResponseInput>
                  <p>질문</p>
                  <input type="text" value={newQuestion} onChange={(e) => setNewQuestion(e.target.value)} />
                </ResponseInput>
                <ResponseInput>
                  <p>답변</p>
                  <textarea
                    className={"response_top"}
                    value={newReceive}
                    onChange={(e) => setNewReceive(e.target.value)}
                  ></textarea>
                </ResponseInput>
              </ResponseInputCt>
              <NormalButton
                style={{ marginLeft: "auto" }}
                disabled={false}
                buttonId={"btn"}
                buttonTitle={"등록"}
                buttonClass={"small cGreen"}
                callBackFunc={() => addQna()}
                type={"button"}
              />
            </ResponseCt>

            {/* 이전 응답 기록 */}
            <ResponseHistory>
              <TotalTextCt currentPage={currentPage} recordCount={recordCount} />
              <HeaderInfo>
                <p className="content">내용</p>
                <p className="createAt">작성일</p>
                <p className="detail">자세히</p>
              </HeaderInfo>
              {Object.values(chatBotData ?? {}).length > 0 && (
                <PaginationContainer
                  opt={{
                    isAsync,
                    asyncFuncName: "getKnowledgeByBotUid",
                    asyncFuncArgs: asyncFuncArgs,
                    addArrayInClient,
                    setRecordCount,
                  }}
                  pageLength={isMobile ? 5 : 10}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  botUid={chatBotData.botUid}
                  DataWrapComponent={ResponseWrapper}
                  DataItemComponent={memo((props) => (
                    <CustomDataItem
                      botUid={chatBotData?.botUid ?? ""}
                      indexName={chatBotData?.indexName ?? ""}
                      setPopMessage={setPopMessage}
                      setPopState={setPopState}
                      setPopCallback={setPopCallback}
                      setConfirmPopState={setConfirmPopState}
                      {...props}
                    />
                  ))}
                  ItemComponent={PagingBtnComponent}
                  itemActiveClassName={"current"}
                  reLoad={reLoad}
                  PrevComponent={(props) => <PagingBtn className={"prev"} text={"이전"} {...props} />}
                  NextComponent={(props) => <PagingBtn className={"next"} text={"다음"} {...props} />}
                  PaginationNumWrap={PagingCt}
                />
              )}
            </ResponseHistory>

            {/* 응답 아이템 */}
          </ResponseWrapper>
        </>
      )}

      {popState && <AlertPop2 popState={popState} setPopState={setPopState} text={popMessage} />}
      {confirmPopState && (
        <ConfirmPop
          popClass={"dash"}
          setShowConfirmPop={setConfirmPopState}
          callback={popCallback?.normal}
          cancelCallback={popCallback?.cancel}
          title={popMessage}
        />
      )}
    </DASH_HOME.Container>
  );
}

export default memo(ChatResponse);

const CustomDataItem = memo((props) => {
  const {
    data,
    keyName,
    botUid,
    indexName,
    refetch,
    removeItem,
    setPopState,
    setPopMessage,
    setPopCallback,
    setConfirmPopState,
    ...otherProps
  } = props ?? {};
  const [isModify, setIsModify] = useState(false);
  const [questionTxt, setQuestionTxt] = useState(data.sendMessage);
  const [receiveTxt, setReceiveTxt] = useState(data.recvMessage);
  const [createAt, setCreateAt] = useState(data.creatAt);

  useEffect(() => {
    setQuestionTxt(data.sendMessage);
    setReceiveTxt(data.recvMessage);
    setCreateAt(data.createAt);
  }, [data]);

  useEffect(() => {
    console.log("CustomDatItem re rendering......");
  }, []);

  const cancel = () => {
    setQuestionTxt(data.sendMessage);
    setReceiveTxt(data.recvMessage);

    console.log("cancel??????");
    setIsModify((prev) => !prev);
  };

  const modify = useCallback(async () => {
    if (isModify) {
      if (questionTxt?.trim().length === 0) {
        setPopMessage("질문을 작성해 주세요.");
        setPopState((prev) => !prev);
        return;
        // return alert("질문을 작성해 주세요.");
      }

      if (questionTxt?.trim().length > 300) {
        setPopMessage("질문은 최대 300자 입니다.");
        setPopState((prev) => !prev);
        return;
        // return alert("질문을 작성해 주세요.");
      }

      if (receiveTxt?.trim().length === 0) {
        setPopMessage("응답을 작성해 주세요.");
        setPopState((prev) => !prev);
        return;
        // return alert("응답을 작성해 주세요.");
      }

      if (receiveTxt?.trim().length > 1000) {
        setPopMessage("응답은 최대 1000자 입니다.");
        setPopState((prev) => !prev);
        return;
        // return alert("질문을 작성해 주세요.");
      }

      // db에 저장하는 api logic 추가...
      const result = await chatMiddle(
        {
          toObjTargetName: "knowledgeInfo",
        },
        "updateKnowledge",
        data.knowledgeId,
        botUid,
        indexName,
        data.vsIds,
        questionTxt,
        receiveTxt
      );
      if (result.status === 200) {
        console.log(">>>>>>> changed", questionTxt);
        await refetch();
      } else {
        setPopMessage("지식수정에 실패했습니다.\n 재 수행 후 동일 에러 발생 시 업체에 문의가필요합니다.");
        setPopState((prev) => !prev);
        return;
      }
    }

    setIsModify((prev) => !prev);
  }, [isModify, questionTxt, receiveTxt]);
  const [toggleBoard, setToggleBoard] = useState(false);

  const deleteItem = async (e) => {
    setPopMessage("정말 삭제하시겠습니까?");
    setConfirmPopState((prev) => !prev);

    setPopCallback(() => ({
      cancel: () => {},
      normal: async () => {
        if (true) {
          const result = await chatMiddle(
            {
              toObjTargetName: "knowledgeInfo",
            },
            "deleteKnowledge",
            data.knowledgeId,
            indexName,
            data.vsIds
          );
          console.log("result", result);
          if (result && result.status === 200) {
            // 이경우에 페이지의 전체데이터를 갱신한다.
            console.log("refetch...");
            refetch();
            // removeItem("knowledgeId", data.knowledgeId);
          }
        }
      },
    }));

    // TODO - delete api Logic 추가
    //deleteKnowledge
  };
  return (
    <ResponseCt className={toggleBoard ? "recorded active" : "recorded"} {...otherProps}>
      <PreviewText className={""}>{questionTxt}</PreviewText>
      <CreateAtTag className="createAt">{createAt}</CreateAtTag>
      <MoreInfoBtnCt>
        <MoreInfoBtn
          className={toggleBoard ? "active" : ""}
          onClick={(e) => {
            setToggleBoard((prev) => !prev);
          }}
          icon={iconDownArrow}
        >
          {" "}
        </MoreInfoBtn>
      </MoreInfoBtnCt>

      <InfoWrapper>
        <ResponseInputCt className="recorded">
          <ResponseInput className="recorded Q">
            <p className="recorded">질문</p>
            {isModify ? (
              <input
                className={"none--active"}
                type="text"
                value={questionTxt}
                onChange={(e) => setQuestionTxt(e.target.value)}
              />
            ) : (
              <p className={""}>{questionTxt}</p>
            )}
          </ResponseInput>
          <ResponseInput className="recorded A">
            <p className="recorded">응답</p>
            {isModify ? (
              <textarea
                className={"none--active response_bottom"}
                type="text"
                value={receiveTxt}
                onChange={(e) => setReceiveTxt(e.target.value)}
              />
            ) : (
              <pre>{receiveTxt}</pre>
            )}
          </ResponseInput>
        </ResponseInputCt>
      </InfoWrapper>

      <BtnWrapper className={isModify ? "buttonContainer" : ""}>
        {isModify ? (
          <NormalButton
            type={"button"}
            id={"btn"}
            disabled={false}
            buttonClass={"cDel small"}
            callBackFunc={cancel}
            buttonTitle={"취소"}
          ></NormalButton>
        ) : (
          <NormalButton
            type={"button"}
            id={"btn"}
            disabled={false}
            buttonClass={"cDel small"}
            callBackFunc={() => deleteItem(data?.knowledgeId)}
            // callBackFunc={() => {
            //   setPopMessage("정말 삭제하시겠습니까?");
            //   setConfirmPopState((prev) => !prev);
            // }}
            buttonTitle={"삭제"}
          />
        )}
        <NormalButton
          type={"button"}
          id={"btn"}
          disabled={false}
          buttonClass={"cOrange small"}
          callBackFunc={modify}
          buttonTitle={isModify ? "적용" : "수정"}
        />
      </BtnWrapper>
    </ResponseCt>
  );
});

/* PaginationContainer에서 TotalTextCt를 그려주는데, 해당 부분에 추가한 props를 여기에 가져와서 사용할 수 있다. */
const TotalTextCt = (props) => {
  const { currentPage, recordCount } = props;
  const { isMobile } = useContext(isMobileContext);
  const handlePageCount = (data) => {
    const maxRow = isMobile ? 5 : 10;
    if (data <= maxRow) return 1;

    return data / maxRow !== data % maxRow ? Math.ceil(data / maxRow) : data / maxRow;
  };
  return (
    <SearchItemInfoCt>
      <SearchItemInfoTotal>
        페이지 : {currentPage}
        {" /"}
        <span className="num">{handlePageCount(recordCount)}</span>
      </SearchItemInfoTotal>
      <SearchItemInfoTotal>
        총 개수:
        <span className="num">{recordCount ?? 0}</span>
      </SearchItemInfoTotal>
    </SearchItemInfoCt>
  );
};

const PagingBtn = memo((props) => {
  const { text, ...otherProps } = props ?? {};
  return <PagingBtnComponent {...otherProps}>{text}</PagingBtnComponent>;
});

export const PagingBtnComponent = styled.button`
  border: 1px solid #ff892a;
  border-radius: 5px;
  margin: 10px 5px;
  padding: 5px 8px;

  color: #888;
  border: 1px solid #888;

  width: 45px;
  height: 35px;
  font-size: 13px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  &.current {
    border: 1px solid #ff892a;
    background-color: #ff892a;
    color: #fff;
  }

  &.next {
  }

  &.prev {
  }
`;

const CompleteText = styled.p`
  margin-bottom: 33px;

  line-height: 24px;
  font-weight: 400;

  font-size: 16px;
  color: #999;

  &.letterSpacing {
    letter-spacing: -0.5px;
  }

  @media screen and (max-width: 600px) {
    font-size: 18px;

    &.letterSpacing {
      font-size: 17px;
    }
  }
`;

const ResponseWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;

  @media (min-width: 481px) {
    &.wrapper {
      /* border: 1px solid red; */
      width: 70%;
    }
  }
`;

const ResponseButtonLayer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  & button {
    margin-left: 5px;
  }
`;

const ResponseCt = styled.div`
  @media (max-width: 480px) {
    width: 100%;
    padding: 12px 10px;
  }
  display: flex;
  margin: 10px 0;
  padding: 12px 15px;
  background-color: #fff;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.16);

  border-radius: 15px;

  align-items: center;
  flex-direction: column;

  &.registCt {
    width: 100%;
    align-items: start;

    & .regist {
      width: 100%;

      & > div {
        width: 85%;

        @media (max-width: 480px) {
          width: 97%;
        }
      }
    }

    & button {
      /* flex: 0 1 auto; */
      width: 100px !important;
      @media (max-width: 480px) {
        width: auto !important;
      }
    }
  }

  &.recorded {
    width: 100%;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 0;
    margin: 0;
    border-bottom: 1px solid #b9b9b9;
    box-shadow: unset;

    /* &:hover {
      background: #f2f3f8;
      cursor: pointer;
    } */
    /* 여기를 조절하기 */
    height: 55px;

    &.active {
      height: auto;
    }

    @media (max-width: 480px) {
      height: 50px;
    }

    &.active {
      height: auto;
    }

    overflow: hidden;

    & p,
    pre {
      margin-left: 5px;
    }

    & p.recorded {
      font-weight: 500;
      color: #222;
      margin-left: 0;
    }
  }
`;

const FreeUserTextCt = styled.div`
  @media (max-width: 480px) {
    & button.right {
      float: right;
    }
  }
`;

const ResponseInputCt = styled.div`
  &.recorded {
    width: 100%;
    border-radius: 10px;
    /* border: 3px solid black; */

    @media (max-width: 480px) {
      width: 120px;
      /* border: 1px solid red; */
    }
  }
`;

const ResponseInput = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8px 0;
  /* width: 35vw; */
  width: auto;
  word-break: break-all;
  margin-bottom: 20px;

  @media (max-width: 480px) {
    width: 280px;
  }

  & p,
  pre {
    margin-bottom: 10px;
    color: #898989;
    min-height: 20px;
    width: 100%;
    word-break: break-all;
    white-space: break-spaces;
  }

  & input {
    flex: 1;
  }
  & input,
  & textarea {
    border: 1px solid #dcdcdc;
    padding: 5px;
  }

  & textarea {
    resize: none;

    @media (max-width: 480px) {
      /* margin-left: 5px; */
      &.response_top {
        height: 80px;
      }
      &.response_bottom {
        height: 150px;
      }
    }
    @media (min-width: 481px) {
      &.response_top {
        height: 180px;
      }
      &.response_bottom {
        height: 200px;
      }
    }
  }

  &.recorded {
    /* border: 3px dashed black; */
    margin: 0 0 10px 0;
    padding: 10px;

    &.Q {
      background: #f7f9ff;

      & p {
        line-height: normal;
        padding-right: 15px;
        @media (max-width: 480px) {
          width: auto;
        }
      }
    }

    &.A {
      background: rgba(255, 137, 42, 0.14);

      & pre {
        padding-right: 15px;
        line-height: normal;
      }
    }

    & input,
    & textarea {
      width: 85%;
    }
  }
`;

const PreviewText = styled.p`
  line-height: normal;
  //width: 450px;
  overflow: hidden;
  word-break: break-all;
  white-space: nowrap;
  text-overflow: ellipsis;

  width: 59%;
  max-width: 450px;

  @media (max-width: 480px) {
    /* width: 100px; */
    font-size: 14px;
    font-weight: 400;
    width: 33%;
  }
`;

const CreateAtTag = styled.p`
  width: 120px;
  color: #555454;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  text-align: center;

  @media (max-width: 900px) and (min-width: 481px) {
    width: auto;
  }
`;

const MoreInfoBtnCt = styled.div`
  width: 50px;
  flex: 0 0 auto;
`;

const MoreInfoBtn = styled.button`
  width: 100%;
  height: 35px;
  background: url(${({ icon }) => icon}) no-repeat;
  background-size: 28px;
  background-position: center;
  margin: 0 !important;

  &.active {
    transform: rotate(180deg);
  }
`;

const ResponseHistory = styled.div`
  /* border: 1px dashed #dcdcdc; */
  /* border: 2px dashed green; */
  width: 100%;
  margin-top: 15px;
  @media (max-width: 480px) {
    width: 100%;
  }

  p.empty {
    text-align: center;
    font-size: 16px;
    line-height: 40px;
    color: #888;

    display: flex;
    justify-content: center;
    align-items: center;
    height: 10vw;
    width: 100%;

    @media (max-width: 480px) {
      font-size: 20px;
    }
  }
`;

const HeaderInfo = styled.div`
  display: flex;
  padding: 23px 15px;
  justify-content: space-between;
  text-align: center;
  font-size: 16px;
  background: rgba(192, 192, 192, 0.1);
  border-bottom: 1px solid #b9b9b9;
  color: #555454;
  font-size: 15px;
  font-weight: 400;

  p.content {
    /* border: 1px solid blue; */
    width: 59%;
    max-width: 450px;
    text-align: left;
    @media (max-width: 480px) {
      /* width: 120px; */
      width: 34%;
      /* border: 1px solid red; */
    }
  }

  p.createAt {
    /* border: 1px solid blue; */
    width: 120px;
  }

  p.detail {
    /* border: 1px solid blue; */
    width: 50px;
    flex: 0 0 auto;
  }

  @media (max-width: 480px) {
  }
`;

const PagingCt = styled.div`
  width: 100%;
  text-align: center;

  margin-top: auto;
  margin-bottom: 30px;
`;

const InfoWrapper = styled.div`
  /* height: 70px; */

  display: flex;
  flex: 1 0 100%;
  width: 100%;

  align-items: start;
  justify-content: space-between;

  overflow: hidden;
  margin-top: 10px;

  &.active {
    height: auto;
  }

  @media (max-width: 480px) {
    margin-top: 30px;
  }
`;

const BtnWrapper = styled.div`
  flex: 1 0 100%;

  margin: 10px 0;
  display: flex;
  justify-content: end;

  & button {
    margin-left: 5px;
    font-size: 16px;
    height: 40px;
    padding: 5px 10px;
  }
`;

const SearchItemInfoCt = styled.div`
  margin-bottom: 10px;
  text-align: right;

  @media (min-width: 481px) {
    display: flex;
    justify-content: end;
    align-items: end;
  }
`;
const SearchItemInfoTotal = styled.p`
  display: inline-block;
  font-size: 14px;
  margin-right: 10px;

  padding-right: 5px;

  &:first-child {
    border-right: 1px solid #dcdcdc;
  }
  &:last-child {
    border-right: 0;
    margin-right: 0;
  }
  & span.num {
    font-weight: 500;
    margin-left: 5px;
  }
`;

const FileUpLoadLabel = styled.label`
  background: #808080;
  color: #fff;
  padding: 10px 15px;
  font-size: 16x;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;

  line-height: normal;

  &:hover {
    opacity: 0.7;
  }

  & i {
    margin-right: 10px;
  }
  &.folder {
    background: #111;
  }

  @media (max-width: 480px) {
    font-size: 15px;
    padding: 4.5px 10px;
  }
`;
