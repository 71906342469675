import React from "react";
import "./pp.css";
import { createPortal } from "react-dom";
import styled, { keyframes } from "styled-components";

export default function TermOfUsePolicy({ setTermsOfUse, callFrom }) {
  return (
    <>
      {createPortal(
        <Wrapper>
          <Container>
            <Header>
              서비스이용약관{" "}
              <button
                className="ri-close-line"
                onClick={(e) => {
                  setTermsOfUse((prev) => !prev);
                }}
              ></button>
            </Header>
            <TextContents>
              <div className="policy-container__textBox">
                <h2 className="policy__main__title">서비스 이용약관</h2>
              </div>

              <div className="policy-container__textBox">
                <h2 className="policy__title">제 1 조 목적</h2>
                <p className="policy__desc">
                  본 약관은 주식회사 리스트(이하 &ldquo;회사&rdquo;라 합니다.)가
                  제공하는 온라인 챗봇 생성 및 운영 서비스 (이하
                  &ldquo;메스와이저&rdquo;라 합니다)에 대한 서비스
                  이용약관입니다. 본 서비스 이용약관은 이용자가 서비스를
                  이용함에 있어 회사와 그 이용자의 권리, 의무 및 책임 사항을
                  규정하고 있으므로 주의깊게 살펴봐 주시기 바랍니다.
                </p>
              </div>

              <div className="policy-container__textBox">
                <h2 className="policy__title">제 2 조 정의</h2>
                <p className="policy__desc">
                  본 약관에서 사용하는 용어의 정의는 다음과 같습니다. 정의되지
                  않은 본 약관상 용어의 의미는 일반적인 거래 관행에 의합니다.
                </p>
                <p className="policy__desc no">
                  1. 본 약관에서 사용하는 용어의 정의는 다음 각호와 같습니다.
                  <span className="policy__desc__detail">
                    ① 메스와이저 (MESwiser) : 회원이 제공하는 웹사이트 또는
                    문서, 그 이외의 모든 지식을 수집하여 챗봇을 생성하고
                    운영하는 서비스를 말합니다. (이하 문맥에 따라
                    &ldquo;메스와이저&rdquo; 또는 &quot;MESwiser&quot; 또는
                    &quot;메스와이저 챗봇&quot; 또는 &ldquo;MESwiser
                    Chatbot&rdquo; 이라고 합니다.)
                  </span>
                  <span className="policy__desc__detail">
                    ② 회원 : 서비스에 접속하여 회원가입 절차를 이행하고 본
                    약관에 동의한 후 이메일 회원 가입 또는 SNS 회원가입을 통해
                    회사가 제공하는 서비스를 이용 가능한 자를 말합니다.
                  </span>
                  <span className="policy__desc__detail">
                    ③ 아이디(회원ID) : 회원의 식별과 서비스 이용을 위해 부여된
                    아이디를 말합니다.
                  </span>
                  <span className="policy__desc__detail">
                    ④ 비밀번호 : 회원이 부여받은 아이디와 일치하는 회원임을
                    확인하고 정보 보호를 위해 회원 자신이 설정한 영문, 숫자,
                    특수문자 조합을 말합니다.
                  </span>
                </p>
                <p className="policy__desc no">
                  2. 전 항에서 규정되지 않은 용어의 정의는 관련 법령 및 일반
                  상관례에 정한 바에 따릅니다.
                </p>
              </div>

              <div className="policy-container__textBox">
                <h2 className="policy__title">제 3 조 약관의 명시와 개정</h2>
                <p className="policy__desc no">
                  1. 회사는 서비스약관, 개인정보 취급방침 내용을 회원들이 쉽게
                  볼 수 있도록 웹사이트 화면에 게시하거나 기타의 방법으로
                  회원에게 공지합니다.
                </p>
                <p className="policy__desc no">
                  2. 회사는 약관 규제 등에 「콘텐츠 산업 진흥법」, 「전자상거래
                  등에서의 소비자 보호에 관한 법률」, 「소비자기본법」, 「약관의
                  규제에 관한 법률」, 「정보통신망 이용 촉진 및 정보보호에 관한
                  법률(이하 “정보통신망법”이라 한다.)」 등 관련 법을 위배하지
                  않는 범위에서 본 약관을 개정할 수 있습니다.
                </p>
                <p className="policy__desc no">
                  3. 회사는 약관을 개정할 때는 적용 일자 및 개정 사유를 명시하여
                  현행 약관과 함께 웹사이트 내 연결 화면 등을 통해 그 적용 일자
                  7일 전부터 적용 일자 전날까지 공지합니다. 다만, 회원에게
                  불리한 약관 개정의 경우에는 적용 일자로부터 30일 이전에
                  공지하며 이메일 등으로 회원에게 개별 통지합니다.
                </p>
                <p className="policy__desc no">
                  4. 회원은 변경된 약관에 대해 거부할 권리가 있으며, 회원은
                  변경된 약관에 공지된 지 15일 이내에 변경 약관에 대한 의사
                  거부를 표시할 수 있습니다.
                </p>
                <p className="policy__desc no">
                  5. 전항에 따라 회원이 개정 약관에 대한 거부의 의사표시를 하지
                  않고 시행일 전날까지 회원이 서비스를 이용하는 경우에는 개정된
                  약관에 동의한 것으로 간주합니다. 회원은 변경된 약관에 동의하지
                  않는 경우 언제든지 자유롭게 서비스 이용약관을 해지할 수
                  있습니다.
                </p>
              </div>

              <div className="policy-container__textBox">
                <h2 className="policy__title">제 4 조 약관 외 준칙 등</h2>
                <p className="policy__desc no">
                  1. 약관에서 규정하지 않은 사항에 관해서는 「약관의 규제 등에
                  관한 법률」, 「정보통신망 이용 촉진 및 정보보호 등에 관한
                  법률」, 「개인정보 보호법」, 「전기통신기본법」,
                  「전기통신사업법 등의 관계법령」에 따라 규율됩니다. 또한, 본
                  약관에서 정하지 아니한 사항이나 해석에 관해서는 관련 법령 또는
                  일반적인 상관례를 따릅니다.
                </p>
                <p className="policy__desc no">
                  2. 회원은 회사가 제공하는 서비스를 이용하면서
                  「정보통신망법」, 「전기통신사업법」, 「개인정보 보호법」 등
                  관련 법령을 준수하여야 하며, 이 약관의 규정을 들어 관련 법령
                  위반에 대한 면책을 주장할 수 없습니다.
                </p>
                <p className="policy__desc no">
                  3. 회사는 서비스에 대해 별도의 이용약관 또는 정책(이하 “별도
                  약관”이라 한다.)을 둘 수 있으며, 그 내용이 본 약관과 충돌하는
                  경우 별도 약관이 우선하여 적용됩니다.
                </p>
              </div>

              <div className="policy-container__textBox">
                <h2 className="policy__title">제 5 조 회원가입</h2>
                <p className="policy__desc no">
                  1. 회원은 회사가 정한 가입 절차(SNS 간편 가입, 이메일 가입)에
                  따라 회원 정보를 입력한 후 약관에 동의한다는 의사표시를
                  함으로써 회원가입을 신청합니다.
                </p>
                <p className="policy__desc no">
                  2. 서비스는 제1항과 같이 회원으로 가입할 것을 신청한 이용자
                  중, 본 약관 제8조 3항에 의하여 이전에 회원자격을 박탈한 적이
                  있는 회원은 회원가입이 불가능합니다.
                </p>
              </div>

              <div className="policy-container__textBox">
                <h2 className="policy__title">
                  제 6 조 회원탈퇴 및 자격 상실 등
                </h2>
                <p className="policy__desc no">
                  1. 회원은 서비스에 언제든지 탈퇴를 요청할 수 있으며, 서비스는
                  즉시 회원탈퇴를 처리합니다.
                </p>
                <p className="policy__desc no">
                  2. 회사는 탈퇴의 유형을 아래와 같이 정의합니다.
                  <span className="policy__desc__detail">
                    ① 일반 탈퇴 : 회원이 직접 [회원탈퇴] 절차를 통해 탈퇴 처리한
                    회원을 말합니다.
                  </span>
                  <span className="policy__desc__detail">
                    ② 강제 탈퇴 : 약관과 운영정책에 따라 운영자가 강제 탈퇴
                    처리한 회원을 말합니다.
                  </span>
                </p>
                <p className="policy__desc no">
                  3. 회원이 다음 각호의 사유에 해당하면, 회사는 부득이하게 탈퇴,
                  회원자격 제한 및 정지시킬 수 있습니다.
                  <span className="policy__desc__detail">
                    ① 근거 없이 상습적으로 악의성 게시물 작성 및 이의를 제기하는
                    행위
                  </span>
                  <span className="policy__desc__detail">
                    ② 다른 사람의 서비스 이용을 방해하거나 그 정보를 도용하는 등
                    전자상거래 질서를 위협하 는 경우
                  </span>
                  <span className="policy__desc__detail">
                    ③ 본 약관과 관련된 운영정책에 반하는 행위를 하는 경우
                  </span>
                </p>
                <p className="policy__desc no">
                  4. 제6조 2항에 의한 회원탈퇴 또는 강제탈퇴가 된 경우 회원
                  정보는 다음과 같이 처리됩니다.
                  <span className="policy__desc__detail">
                    ① 탈퇴한 회원의 정보는 개인정보 처리방침 제2조에 규정하는
                    바에 따라 일정 기간 보유 후 삭제처리됩니다.
                  </span>
                  <span className="policy__desc__detail">
                    ② 강제 탈퇴 회원의 정보는 서비스 부정 이용 방지 및 회원의
                    추가적인 피해 방지를 위해 2년간 보유하며 이 기간동안
                    재가입이 불가능합니다.
                  </span>
                </p>
              </div>

              <div className="policy-container__textBox">
                <h2 className="policy__title">제 7 조 개인정보보호</h2>
                <p className="policy__desc">
                  회사는 회원의 개인정보를 소중하게 생각합니다. 회사는 관련
                  법령에 따른 허용범위 및 이용자의 동의 내에서, 서비스의 원활한
                  제공을 위하여 필요한 범위로만 이용자의 개인정보를 처리합니다.
                  회사가 회원의 개인정보를 안전하게 처리하기 위하여 기울이는
                  노력과 기타 관련한 상세한 내용은 「개인정보 수집 및 이용
                  동의」 또는 [개인정보 처리방침]을 확인하여 주시기 바랍니다.
                </p>
              </div>

              <div className="policy-container__textBox">
                <h2 className="policy__title">제 8 조 문의 게시 서비스</h2>
                <p className="policy__desc no">
                  1. 회원은 서비스를 이용하여 운영자와 문의 메시지를 주고받을 수
                  있습니다.
                </p>
                <p className="policy__desc no">
                  2. 주고받은 메시지 입력 내용에 특정 음란한 표현, 비속어 및
                  혐오적인 단어 등을 사용하는 경우, 회사는 내용을 확인한 후
                  회원에 대하여 메시지 서비스 기능을 중단할 수 있습니다.
                </p>
                <p className="policy__desc no">
                  3. 문제 내용에 따라 제12조에 따라 회사는 회원자격을 제한 및
                  정지시킬 수 있습니다.
                </p>
              </div>

              <div className="policy-container__textBox">
                <h2 className="policy__title">제 9 조 ID 및 비밀번호 등</h2>
                <p className="policy__desc no">
                  1. ID 및 비밀번호에 대한 모든 관리책임은 회원에게 있습니다.
                </p>
                <p className="policy__desc no">
                  2. 회원은 ID를 공유, 양도할 수 없습니다.
                </p>
                <p className="policy__desc no">
                  3. 회원에게 부여된 ID에 의하여 발생되는 서비스 이용상의 과실
                  또는 제3자에 의한 부정사용 등에 대한 모든 책임은 회원에게
                  있습니다.
                </p>
              </div>

              <div className="policy-container__textBox">
                <h2 className="policy__title">제 10 조 회사의 의무</h2>
                <p className="policy__desc no">
                  1. 회사는 본 약관에서 정한 내용에 따라 안정적·지속적으로
                  서비스를 제공할 수 있도록 최선을 다하겠습니다.
                </p>
                <p className="policy__desc no">
                  2. 회사는 사용자에게 원활한 서비스 제공을 위하여 지속적인
                  관리와 정보의 보안을 통해 필요한 인력을 배치하고 점검 또는
                  복구 조치 등을 성실하게 이행합니다.
                </p>
                <p className="policy__desc no">
                  3. 회사는 서비스의 이용이나 운영과 관련된 회원의 불만 사항이
                  접수되는 경우, 회사는 이를 지체없이 처리하여 그 결과를 회신할
                  수 있도록 노력합니다. 다만, 접수내용 파악, 처리 등으로 상당한
                  시간이 소요될 경우, 회사는 그 사유와 처리 일정을 회원에게
                  통지합니다.
                </p>
                <p className="policy__desc no">
                  4. 회사는 회원의 동의 없이 회원의 정보를 수집 또는 활용하거나
                  제3자에게 제공하지 않습니다.
                </p>
              </div>

              <div className="policy-container__textBox">
                <h2 className="policy__title">제 11 조 회원의 의무</h2>
                <p className="policy__desc no">
                  1. 회원은 본 약관과 운영정책 사항과 관련 법령을 준수하여야
                  하며 기타 회사 업무에 방해되는 행위를 하여서는 안 됩니다.
                </p>
                <p className="policy__desc no">
                  2. 회사는 회원이 본 약관과 운영정책 사항에 반하는 행위를 할
                  경우 로그인 접속 제한 또는 회원자격을 박탈할 수 있고, 해당
                  게시물에 대해 비공개 또는 삭제할 수 있습니다.
                </p>
                <p className="policy__desc no">
                  3. 회원은 게시물 관리지침에 따라 제13조에 해당하는 행위를
                  하여서는 안 됩니다.
                </p>
              </div>

              <div className="policy-container__textBox">
                <h2 className="policy__title">제 12 조 회원에 대한 통지</h2>
                <p className="policy__desc">
                  메스와이저는 회원과의 의사소통을 중요하게 생각합니다. 회사가
                  회원에 대한 통지를 하는 경우 본 약관에 별도의 규정이 없는 한
                  서비스 제공을 위한 웹사이트를 공지사항을 통해 공지하거나,
                  회원가입 신청 시 입력한 이메일 주소로 이메일 발송을 통해
                  통지할 수 있습니다.
                </p>
              </div>

              <div className="policy-container__textBox">
                <h2 className="policy__title">제 13 조 회원에 대한 통지</h2>
                <p className="policy__desc">
                  1. 회원이 메스와이저 서비스 내에서 수집하는 웹사이트 및 문서
                  등의 지식에 대한 저작권 침해와 관련된 사항은 회원에게 있으며
                  처리된 지식의 저작권은 회원이 소유합니다.
                </p>
                <p className="policy__desc">
                  2. 회사는 회원과 타인의 지식재산권이 서로 존중되고 보호받을 수
                  있도록 노력할 책임이 있고, 특히 타인에 의해 회원의 지식재산권
                  침해가 확인될 경우 즉시 회원의 권리 보호를 위하여 필요한
                  조치를 하고 이를 회원에서 통지하는 등 회사는 지식재산권 보호를
                  위하여 최선을 다하겠습니다.
                </p>
                <p className="policy__desc">
                  3. 회원은 메스와이저 서비스 내에서 수집한 콘텐츠를 회사가 국내
                  또는 국외에서 다음 각 호의 목적에 따라 필요한 범위 내에서 해당
                  콘텐츠를 편집(수정, 복제, 배포 등을 의미하여 이에 한정하지
                  아니함) 및 사용하는 것을 허락합니다.
                  <span className="policy__desc__detail">
                    ① 서비스 내에서 콘텐츠를 사용하거나, 언론홍보, 정책개발,
                    데이터 분석 등을 하기 위한 목적으로 언론사 및 통신사 등에게
                    콘텐츠 내용을 제공하는 경우
                  </span>
                  <span className="policy__desc__detail">
                    ② 인터넷, 모바일, SNS를 포함한 디지털 마케팅 채널 등
                    제휴서비스에 콘텐츠를 제공하는 경우
                  </span>
                </p>
                <p className="policy__desc">
                  4. 메스와이저 서비스의 특성상 회사가 회원의 콘텐츠를 제3자에게
                  제공하는 경우, 회원은 제3자에게도 제3항에 따라 해당 콘텐츠의
                  이용을 허락한 것으로 간주합니다. 이 경우 회사는 회원에게
                  제3자에게 제공하여 이용되는 사실을 사전 또는 사후에 고지하여야
                  하며, 단 제3자가 회사와 협의 없이 임의로 해당 콘텐츠를
                  이용하는 경우 등 회사가 그 이용에 직접 개입하 지 않은 경우에는
                  그러하지 않습니다.
                </p>
                <p className="policy__desc">
                  5. 제3항과 제4항 및 제7항에도 불구하고 회원은 언제든지 회사
                  고객센터 또는 이메일 (help@meswiser.ai)을 통해 자신이 생성한
                  콘텐츠에 대한 삭제, 비공개, 사용중단 등을 요청할 수 있고,
                  회사는 본 약관 제12조에 정한 방법으로 회원에게 콘텐츠의 사용
                  내용을 공지할 수 있습니다.
                </p>
                <p className="policy__desc">
                  6. 회원은 본인이 생성한 콘텐츠가 본 약관 제13조에 위반되지
                  아니함을 보증합니다. 회원이 수집한 콘텐츠가 본 약관 제13조 및
                  관련 법령을 위반한 경우 회사의 귀책 사유가 없는 한 회원은 그로
                  인한 모든 책임을 부담한다는 점을 유의하여 주시기 바랍니다.
                </p>
                <p className="policy__desc">
                  7. 본 약관 제6조에 따라 회원을 탈퇴하는 경우 회원이 메스와이저
                  서비스 내에 게시한 콘텐츠(공유, 댓글 등을 포함합니다.)는 일괄
                  삭제됩니다.
                </p>
                <p className="policy__desc">
                  8. 회원은 서비스를 이용함으로써 얻은 정보 중 회사 지식재산권을
                  보유한 정보를 회사의 사전 승낙 없이 영리 목적으로 이용하거나
                  제3자가 이용하게 하여서는 아니 됩니다.
                </p>
              </div>

              <div className="policy-container__textBox">
                <h2 className="policy__title">제 14 조 컨텐츠 관리지침</h2>
                <p className="policy__desc no">
                  1. 회사는 유해성, 혐오성, 폭력성, 음란성, 청소년 보호에
                  위반되는 텍스트나 콘텐츠를 금지합니다.
                </p>
                <p className="policy__desc no">
                  2. 회사는 유해, 음란한 언어 묘사를 금지하며, 신체 노출
                  관련하여 콘텐츠 게시자의 의도는 고려되지 않으며, 다수의
                  이용자에게 불쾌감을 불러일으키는 콘텐츠는 비공개 또는 삭제
                  처리합니다.
                  <span className="policy__desc__detail">
                    ① 신체 일부의 윤곽 또는 굴곡이 드러날 만큼 강조하거나 확대한
                    이미지
                  </span>
                  <span className="policy__desc__detail">
                    ② 성인용품 이미지
                  </span>
                  <span className="policy__desc__detail">
                    ③ 청소년에게 해로운 게시물
                  </span>
                  <span className="policy__desc__detail">
                    ④ 성행위 묘사 (언어, 이미지, 문자, 음성 등) 및 유도
                  </span>
                  <span className="policy__desc__detail">
                    ⑤ 본인 포함, 특정인 성적 대상화
                  </span>
                  <span className="policy__desc__detail">
                    ⑥ 차별·갈등을 조장하는 게시물
                  </span>
                  <span className="policy__desc__detail">
                    ⑦ 도배 광고 또는 홍보 스팸성 게시물
                  </span>
                  <span className="policy__desc__detail">
                    ⑧ 타인을 사칭하는 게시물
                  </span>
                  <span className="policy__desc__detail">
                    ⑨ 기타 법령에 위반되거나 그에 준하는 게시물
                  </span>
                </p>
                <p className="policy__desc no">
                  3. 개인회원이 등록한 게시물 등으로 인해 제3자로부터 허위사실
                  또는 명예훼손 등을 이유로 삭제 요청이 접수된 경우 또는 회원이
                  등록한 게시물 등으로 인하여 사이트 등의 원활한 운영에 영향을
                  미친다고 판단되는 경우, 회사는 개인회원의 해당 게시물 등을
                  직권으로 비공개 또는 삭제 할 수 있으며, 개인회원에게 해당
                  게시물 등의 삭제 사실 및 사유를 사후에 통지할 수 있습니다.
                </p>
                <p className="policy__desc no">
                  4. 서비스 운영 방해 행위에 대한 제재 내용 회사는 회원의 방해
                  행위의 횟수 또는 심각성에 따라 로그인 정지기간 설정, 영구 정지
                  등을 운영하고 있습니다.
                  <span className="policy__desc__detail">
                    ① 3일 : 본 제15조 2항에 해당하는 2회이상 반복되는 경우
                  </span>
                  <span className="policy__desc__detail">
                    ② 7일 : 본 제15조 2항에 해당하는 3회이상 반복되는 경우
                  </span>
                  <span className="policy__desc__detail">
                    ③ 로그인 영구 정지 : 동일한 위반 행위를 통해 정지 2회 이상
                    반복되거나, 매우 심각한 수준의 부정행위에 대해서는
                    영구적으로 서비스 이용 불가
                  </span>
                </p>
                <p className="policy__desc no">
                  5. 서비스 운영 방해 행위에 대한 제재 절차 회사는 아래와 같은
                  절차를 통해 콘텐츠를 관리, 규제하고 있습니다.
                  <span className="policy__desc__detail">
                    ① 접수 (이용자 신고 및 실시간 모니터링)
                  </span>
                  <span className="policy__desc__detail">
                    ② 확인 (계정 및 활용 위반 내역 확인)
                  </span>
                  <span className="policy__desc__detail">
                    ③ 검토 (운영정책 등 위반사항 검토)
                  </span>
                  <span className="policy__desc__detail">
                    ④ 조치 (경고, 게시물 삭제 또는 비공개, 로그인 정지, 로그인
                    영구 정지)
                  </span>
                </p>
                <p className="policy__desc no">
                  6. 서비스 운영 방해 행위에 대한 제재 절차과정에서 필요할 경우
                  관련 사법 당국에 사건을 보고해 합리적인 처리 방법을 의뢰할 수
                  있습니다.
                </p>
              </div>

              <div className="policy-container__textBox">
                <h2 className="policy__title">제 15 조 게시물에 대한 책임</h2>
                <p className="policy__desc no">
                  1. 회사는 회원의 게시물이 다음 각 호의 경우에 해당한다고
                  판단되는 경우 사전 통지 없이 삭제할 수 있으며, 이에 대해
                  회사는 어떠한 책임도지지 않습니다.
                  <span className="policy__desc__detail">
                    ① 회사, 서비스, 다른 회원 또는 제3자를 비방하거나 명예를
                    손상시키는 내용인 경우
                  </span>
                  <span className="policy__desc__detail">
                    ② 공공질서 및 사회적으로 위반되는 내용의 게시물에 해당되는
                    경우
                  </span>
                  <span className="policy__desc__detail">
                    ③ 범죄행위에 결부된다고 판단되는 경우
                  </span>
                  <span className="policy__desc__detail">
                    ④ 회사 또는 제3자의 저작권, 기타 타인의 권리를 침해하는
                    내용인 경우
                  </span>
                  <span className="policy__desc__detail">
                    ⑤ 회사가 정한 세부이용지침에 반하는 내용인 경우
                  </span>
                  <span className="policy__desc__detail">
                    ⑥ 당사에서 제공하는 서비스와 관련 없는 내용인 경우
                  </span>
                  <span className="policy__desc__detail">
                    ⑦ 불필요하거나 승인되지 않은 광고, 판촉물을 게재하는 경우
                  </span>
                  <span className="policy__desc__detail">
                    ⑧ 타인의 명의 등을 무단으로 도용하여 작성한 내용이거나,
                    타인이 입력한 정보를 무단으로 위·변조한 내용인 경우
                  </span>
                  <span className="policy__desc__detail">
                    ⑨ 동일한 내용을 중복하여 다수 게시하는 등 게시의 목적에
                    어긋나는 경우
                  </span>
                  <span className="policy__desc__detail">
                    ⑩ 컨텐츠의 정보를 외부 서비스에서 사용하는 행위를 금지하는
                    사이트에서 URL 정보를 수집하여 게재하는 경우
                  </span>
                  <span className="policy__desc__detail">
                    ⑪ 기타 관계 법령 및 당사의 개별 서비스 별 세부이용지침 등에
                    위반된다고 판단되는 경우
                  </span>
                </p>
              </div>

              <div className="policy-container__textBox">
                <h2 className="policy__title">제 16 조 분쟁조정</h2>
                <p className="policy__desc">
                  서비스 이용과 관련하여 회사와 회원 간에 분쟁이 발생하면,
                  회사는 분쟁의 해결을 위해 회원과 성실히 협의합니다.
                </p>
              </div>

              <div className="policy-container__textBox">
                <h2 className="policy__title">제 17 조 이의신청</h2>
                <p className="policy__desc no">
                  1. 회원은 서비스 이용 약관에 따른 회원의 의무를 준수하여야
                  하며, 회사로부터 받은 제재에 대해 이의가 있을 경우 정지 기간
                  중 고객센터(help@meswiser.ai)을 통해 이의 신청을 할 수
                  있습니다.
                </p>
                <p className="policy__desc no">
                  2. 접수된 이의신청 내용 검토 후, 이용약관 및 운영정책
                  위반사항에 따라 일시정지, 영구정지 등 조정이 될 수 있습니다.
                </p>
              </div>

              <div className="policy-container__textBox">
                <h2 className="policy__title">
                  제 18 조 권리침해신고 및 유해 콘텐츠 신고, 고객센터
                </h2>
                <p className="policy__desc no">
                  1. 저작권법 제103조 제1항에 따른 권리주장자는 저작권 보호
                  요청을 접수해 주시기 바랍니다.
                </p>
                <p className="policy__desc no">
                  2. 저작권법 제103조 제3항에 따라 권리 소명과 콘텐츠의 전송
                  복제의 재개를 요청할 때에는 자신이 정당한 권리자임을 소명할 수
                  있는 증빙자료와 함께 콘텐츠 재개 요청을 접수해 주시기
                  바랍니다.
                </p>
                <p className="policy__desc no">
                  3. 전기통신사업법 제22조의 5 제1항에 따라, 불법촬영물등
                  유해콘텐츠의 유통 신고&middot;삭제 요청을 접수해주시기
                  바랍니다.
                </p>
                <p className="policy__desc no">
                  4. 접수 이메일 : help@meswiser.ai
                </p>
              </div>

              <div className="policy-container__textBox">
                <h2 className="policy__title">
                  제 19 조 정보의 제공 및 광고의 게재
                </h2>
                <p className="policy__desc no">
                  1. 회사는 메스와이저 서비스를 운영함에 있어 다양한 정보를
                  메스와이저 서비스 내 지정하는 위치에 게시하거나 본 약관
                  제11조에서 정한 방법 등으로 이용자에게 제공할 수 있습니다. 단,
                  상업적 광고는 사전에 광고수신 여부 및 수신방법에 별도로 동의한
                  이용자에 한정하여 제공합니다. 그리고 회원은 회사의 법령에 따라
                  부과된 의무이행과 관련한 절차안내, 고객문의 등에 대한 답변,
                  기타 거래관련 정보 등을 제외하고는 언제든지 정보의 수신을
                  거부할 수 있습니다.
                </p>
              </div>

              <div className="policy-container__textBox">
                <h2 className="policy__title">제 20 조 준거법 및 관할법원</h2>
                <p className="policy__desc no">
                  1. 본 약관 또는 서비스는 대한민국의 법률에 따라 규정되고
                  이행됩니다.
                </p>
                <p className="policy__desc no">
                  2. 본 약관 또는 서비스와 관련된 분쟁은 「민사소송법」상의
                  관할법원에 소를 제기할 수 있습니다.
                </p>
              </div>

              <div className="policy-container__textBox">
                <h2 className="policy__title">제 21 조 시행일</h2>
                <p className="policy__desc">
                  이 약관은 2023년 08월 01일부터 시행합니다.
                </p>
              </div>
            </TextContents>
          </Container>
        </Wrapper>,
        document.getElementById("modal")
      )}
    </>
  );
}

/* 기존 애니메이션 */
const openModal = keyframes`
  0% {
    -webkit-transform: translateY(10%);
    transform: translateY(10%);
    opacity: 0;
  }

  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
    opacity: 1;
  }
`;
const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  height: 100%;

  background: rgba(0, 0, 0, 0.2);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
`;
const Container = styled.div`
  width: 50vw;
  /* height: 50vw; */
  height: 80vh;
  overflow: hidden;

  animation: ${openModal} 0.2s ease-in-out;
  animation-fill-mode: both;
  display: inline-block;
  position: absolute;
  top: 10vh;
  left: 25vw;
  background: #fff;
  opacity: 0;
  border-radius: 15px;

  padding: 0 15px 30px 30px;

  @media (max-width: 480px) {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-height: none;
    padding: 0 30px 30px;
    border-radius: 0;
  }
`;
const TextContents = styled.div`
  /* max-height: 692px; */
  max-height: 100%;
  height: fit-content;
  padding: 0 15px 80px 0;
  overflow-y: scroll;

  h2.policy__main__title {
    font-size: 20px;
  }
  h2.policy__title,
  h2.table__header {
    font-size: 16px;
  }

  @media (max-width: 480px) {
    h2.policy__main__title {
      font-size: 18px;
    }
    h2.table__header {
      font-size: 11px;
    }
  }

  @media (max-width: 480px) {
    max-height: unset;
    height: calc(100% - 78px);
    padding: 0;
  }
`;
const Header = styled.div`
  display: flex !important;
  width: 100%;
  height: 78px;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  padding: 0 10px 0 15px;
  border-bottom: solid 1px #dcdcdc;
  background: #fff;

  button {
    padding: 0;
    font-size: 35px;
  }
`;
