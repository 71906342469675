/* eslint-disable */
import styled from "styled-components";
import { useContext, useEffect, useRef, useState } from "react";
import NormalButton from "components/atomic/NormalButton2";
import InputBox from "components/atomic/InputBox";
import TextArea from "components/atomic/TextArea";
import uuid from "react-uuid";
import iconTopArrow from "../../../assets/images/icon_topArrow.svg";
import ManageExQCt from "components/organisms/ManageExQCt";
import { isMobileContext } from "utils/providers/BrowserEnvContextProvider";

import iconWraning from "../../../assets/images/icon_warning.svg";
import { popupContext } from "../../../utils/providers/PopupProvider.js";

export default function GuideSettingTab({ guideFuncs, guideProps }) {
  const { botData, exList, setExList } = guideFuncs;
  const { showTextPop } = useContext(popupContext);
  const {
    introInName,
    setIntroInName,
    introOutName,
    setIntroOutName,
    introIn,
    setIntroIn,
    introOut,
    setIntroOut,
    exqInName,
    setExqInName,
    exqOutName,
    setExqOutName,
    exqText,
    setExqText,
    exqOut,
    setExqOut,
  } = guideProps;

  const questionRef = useRef();
  const [moreBtnState, setMoreBtnState] = useState(true);
  const [toolTip1, setTooltip1] = useState(false);
  const [toolTip2, setTooltip2] = useState(false);
  const [toolTip3, setTooltip3] = useState(false);

  const { isMobile } = useContext(isMobileContext);

  // 질문 더보기 handler
  const handleMoreBtn = (e) => {
    setMoreBtnState((prev) => !prev);
  };

  useEffect(() => {
    if (!questionRef) return;
    if (moreBtnState) {
      questionRef.current.style.visibility = "visible";
      questionRef.current.style.opacity = "1";
      questionRef.current.style.height = "auto";
      questionRef.current.style.maxHeight = "300px";
    } else {
      questionRef.current.style.visibility = "hidden";
      questionRef.current.style.opacity = "0";
      questionRef.current.style.height = "0px";
    }
  }, [moreBtnState]);

  const addExamplList = (e) => {
    if (exqText.trim(" ") === "") {
      showTextPop("예시 질문을 입력해주세요.");
      return;
    }
    if (exList.some((item) => item.exData === exqText)) {
      showTextPop("동일한 예시 질문은 등록이 불가합니다.");
      return;
    }

    setExList((prevList) => [
      ...prevList,
      {
        exUid: uuid(),
        botUid: botData.botUid,
        exData: exqText,
        seq: prevList.length + 1,
      },
    ]);
    setExqText("");
  };

  return (
    <TableCt>
      {/* 소개 보더 시작 */}
      <BotModifyCt>
        <BotinfoCt>
          <BotInfoTitle icon={iconWraning}>
            내부 팝업 설정{" "}
            {!isMobile ? (
              <span className="toolTipCt">
                <span
                  onMouseOver={() => {
                    setTooltip1((prev) => !prev);
                  }}
                  onMouseOut={() => {
                    setTooltip1((prev) => !prev);
                  }}
                  className="toolTipIcon"
                ></span>
                <span className={toolTip1 ? "toolTipTxt active" : "toolTipTxt"}>
                  미 입력시 더보기에서 보여지지 않습니다.
                </span>
              </span>
            ) : (
              <span className="toolTipCt">
                <span
                  onClick={() => {
                    showTextPop("미 입력시 더보기에서 보여지지 않습니다.");
                  }}
                  className="toolTipIcon"
                ></span>
              </span>
            )}
          </BotInfoTitle>
          <BotInfoTitle className="subTitle">버튼 이름</BotInfoTitle>
          <BotInfoRow>
            <InputForm
              inputclass={"modify"}
              target="introInName"
              value={introInName}
              setValue={setIntroInName}
            />
          </BotInfoRow>
        </BotinfoCt>

        <BotinfoCt>
          <BotInfoTitle className="subTitle">상세 내용</BotInfoTitle>

          <BotInfoRow className={"introTab"}>
            <InputForm
              inputclass={"modify"}
              target="formIntroIn"
              value={introIn}
              setValue={setIntroIn}
            />
          </BotInfoRow>
          {/*  */}
        </BotinfoCt>
      </BotModifyCt>
      {/* 소개 보더 끝 */}
      {/* 예시질문 보더 시작 */}
      <BotModifyCt>
        <BotinfoCt>
          <BotInfoTitle icon={iconWraning}>
            예시 질문 설정{" "}
            {!isMobile ? (
              <span className="toolTipCt">
                <span
                  onMouseOver={() => {
                    setTooltip2((prev) => !prev);
                  }}
                  onMouseOut={() => {
                    setTooltip2((prev) => !prev);
                  }}
                  className="toolTipIcon"
                ></span>
                <span className={toolTip2 ? "toolTipTxt active" : "toolTipTxt"}>
                  미 입력시 더보기에서 보여지지 않습니다.
                </span>
              </span>
            ) : (
              <span className="toolTipCt">
                <span
                  onClick={() => {
                    showTextPop("미 입력시 더보기에서 보여지지 않습니다.");
                  }}
                  className="toolTipIcon"
                ></span>
              </span>
            )}
          </BotInfoTitle>
          <BotInfoTitle className="subTitle">버튼 이름</BotInfoTitle>
          <BotInfoRow>
            <InputForm
              inputclass={"modify"}
              target="exqInName"
              value={exqInName}
              setValue={setExqInName}
            />
          </BotInfoRow>
          <BotInfoTitle className="subTitle">예시 질문 </BotInfoTitle>
          <BotInfoRow className="introTab">
            <div className="inputCt">
              <form className="inputCt">
                <InputForm
                  inputclass={"modify"}
                  target="formExampleInput"
                  value={exqText}
                  setValue={setExqText}
                />
                <NormalButton
                  buttonClass={"small cGreen"}
                  buttonTitle={"추가"}
                  callBackFunc={addExamplList}
                />
              </form>
            </div>
            <BotInfoTitle className="exQ">
              예시질문 목록{" "}
              {exList.length > 0 && (
                <button
                  onClick={handleMoreBtn}
                  className={moreBtnState ? "moreBtn" : "active moreBtn"}
                >
                  <img src={iconTopArrow} alt={"더보기 아이콘"}></img>
                  {moreBtnState ? "접기" : "펼치기"}
                </button>
              )}
            </BotInfoTitle>
            <BotInfoRow className={moreBtnState ? "list" : "close list"}>
              {exList.length > 0 ? (
                <>
                  <div
                    ref={questionRef}
                    className="inputCt"
                    style={{
                      flexDirection: "column",
                      padding: "5px 0px",
                      // boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.1)",
                    }}
                  >
                    {exList.map((data, idx) => {
                      return (
                        // 예시질문 차일드
                        <ManageExQCt
                          key={idx}
                          data={data}
                          idx={idx}
                          setExList={setExList}
                          exList={exList}
                        />
                      );
                    })}
                  </div>
                </>
              ) : (
                <BotInfoDesc ref={questionRef}>
                  추가된 예시 질문이 없습니다. 예시 질문을 추가해보세요
                </BotInfoDesc>
              )}
            </BotInfoRow>
          </BotInfoRow>
        </BotinfoCt>
      </BotModifyCt>
      {/* 예시질문 보더 끝 */}
      {/* 외부 URL 설정 시작 */}
      <BotModifyCt>
        <BotinfoCt>
          <BotInfoTitle icon={iconWraning}>
            외부 URL 설정{" "}
            {!isMobile ? (
              <span className="toolTipCt">
                <span
                  onMouseOver={() => {
                    setTooltip3((prev) => !prev);
                  }}
                  onMouseOut={() => {
                    setTooltip3((prev) => !prev);
                  }}
                  className="toolTipIcon"
                ></span>
                <span className={toolTip3 ? "toolTipTxt active" : "toolTipTxt"}>
                  미 입력시 더보기에서 보여지지 않습니다.
                </span>
              </span>
            ) : (
              <span className="toolTipCt">
                <span
                  onClick={() => {
                    showTextPop("미 입력시 더보기에서 보여지지 않습니다.");
                  }}
                  className="toolTipIcon"
                ></span>
              </span>
            )}
          </BotInfoTitle>
          <BotInfoTitle className="subTitle">버튼1 이름</BotInfoTitle>
          <BotInfoRow>
            <InputForm
              inputclass={"modify"}
              target="introOutName"
              value={introOutName}
              setValue={setIntroOutName}
            />
          </BotInfoRow>

          <BotInfoTitle className="subTitle">연결1 URL</BotInfoTitle>
          <BotInfoRow className={"introTab"}>
            <InputForm
              inputclass={"modify"}
              target="externalURL"
              value={introOut}
              setValue={setIntroOut}
            />
          </BotInfoRow>

          <BotInfoTitle className="subTitle">버튼2 이름</BotInfoTitle>
          <BotInfoRow>
            <InputForm
              inputclass={"modify"}
              target="introOutName"
              value={exqOutName}
              setValue={setExqOutName}
            />
          </BotInfoRow>

          <BotInfoTitle className="subTitle">연결2 URL</BotInfoTitle>
          <BotInfoRow className={"introTab"}>
            <InputForm
              inputclass={"modify"}
              target="externalURL"
              value={exqOut}
              setValue={setExqOut}
            />
          </BotInfoRow>
        </BotinfoCt>
      </BotModifyCt>
      {/* 외부 URL 설정 끝 */}
    </TableCt>
  );
}

export function InputForm({ target, value, setValue, name, onChangeFunc }) {
  if (
    target === "introInName" ||
    target === "introOutName" ||
    target === "exqInName" ||
    target === "exqOutName"
  ) {
    return (
      <InputBox
        inputId={`manage_${target}`}
        value={value}
        setValue={setValue}
        placeholderText={"버튼 이름을 입력해주세요(최대 20자)"}
        style={{ width: "100%" }}
        inputclass={"botTable"}
        onChangeFunc={onChangeFunc}
        name={name}
      />
    );
  } else if (target === "formIntroIn") {
    return (
      <TextArea
        textId={`manage_${target}`}
        value={value}
        setValue={setValue}
        style={{ width: "80%", borderRadius: "10px" }}
        disabled={false}
        placeholderText={"팝업에 들어갈 내용을 입력해 주세요(최대 1,000자)"}
        textClass={"manage"}
      />
    );
  } else if (target === "externalURL") {
    return (
      <InputBox
        inputId={`manage_${target}`}
        value={value}
        setValue={setValue}
        placeholderText={"버튼 클릭 시 이동될 URL 링크를 입력해 주세요"}
        style={{ width: "100%" }}
        inputclass={"botTable"}
      />
    );
  } else if (target === "formExampleInput") {
    return (
      <InputBox
        inputId={`manage_${target}`}
        value={value}
        setValue={setValue}
        placeholderText={"예시 질문을 입력해주세요"}
        style={{ width: "35%", flexGrow: "0" }}
        inputclass={"botTable addUser"}
      />
    );
  } else if (target === "formExampleExtInput") {
    return (
      <InputBox
        inputId={`manage_${target}`}
        value={value}
        setValue={setValue}
        placeholderText={"URL을 작성해주세요."}
        style={{ width: "100%" }}
        inputclass={"botTable"}
      />
    );
  }
}

const BotModifyCt = styled.div`
  border-radius: 10px;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.16);
  background: #fff;
  padding: 15px;
  padding-top: 20px;
  text-align: center;
  margin-top: 15px;

  button {
    &.change {
      & span {
        display: inline-block;
        width: 28px;
        @media (max-width: 480px) {
          width: 16px;
        }
      }
    }
  }
`;

const BotinfoCt = styled.div`
  margin-bottom: 15px;
`;

const BotInfoTitle = styled.p`
  color: #363636;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 12.5px;
  text-align: left;

  &.subTitle {
    font-weight: 400;
    margin-left: 8px;
  }

  & span {
    color: #ff6161;
    font-size: 16px;
  }

  &.exQ {
    font-weight: 400;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
  }

  & span.toolTipCt {
    display: inline-block;
    position: relative;
    transform: translateY(2px);
    @media (max-width: 480px) {
      transform: translateY(3px);
    }

    .toolTipIcon {
      display: inline-block;
      width: 16px;
      height: 16px;
      background: url(${({ icon }) => icon}) no-repeat;
      background-position: center;
      background-size: contain;

      &:hover {
        cursor: pointer;
      }
    }

    .toolTipTxt {
      display: none;
      position: absolute;
      width: 200px;
      padding: 10px 5px;

      top: -8px;
      left: 20px;

      font-size: 12px;
      line-height: 15px;
      word-break: keep-all;
      font-weight: 500;
      letter-spacing: -1px;
      text-align: center;
      color: #888;

      background: #f7f9ff;
      border-radius: 5px;

      &.active {
        display: block;
      }
    }
  }

  & button.moreBtn {
    display: inline-flex;
    margin-left: auto;
    align-items: center;
    & img {
      transform: rotate(0deg);
      width: 20px;
      height: 20px;
      margin-right: 5px;
      transition: 0.3s;
    }

    &.active {
      & img {
        transform: rotate(180deg);
      }
    }
  }

  @media (max-width: 480px) {
    font-size: 14px;
    margin-bottom: 14px;
    & span {
      font-size: 14px;
    }
  }
`;
const BotInfoDesc = styled.p`
  color: #a6a6a6;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.14px;
  text-align: left;

  @media (max-width: 480px) {
    color: #a6a6a6;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.12px;
  }
`;

const BotInfoRow = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
  margin-bottom: 10px;
  &.list {
    overflow-y: auto;
    &.close {
      overflow-y: unset;
    }
  }

  &.introTab {
    display: block;
  }

  & p.exText {
    word-break: break-all;
    margin-right: 10px;
    text-align: left;
    width: 100%;
  }

  & form.inputCt,
  div.inputCt {
    display: flex;
    width: 100%;
    margin-bottom: 5px;
    transition: 0.3s;

    input {
      margin-right: 10px;
      flex: 0 1 auto;
    }
    button {
    }
  }

  & input {
    border-radius: 100px;

    &::placeholder {
      color: #a6a6a6;
      font-size: 16px;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.16px;
      @media (max-width: 480px) {
        font-size: 13px;
      }
    }
  }

  & input,
  textarea {
    background: #f3f3f3;

    &::placeholder {
      color: #a6a6a6;
      font-size: 16px;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.16px;

      @media (max-width: 480px) {
        font-size: 13px;
      }
    }
  }

  & div.btnCt {
    display: flex;
    width: fit-content;

    button {
      margin-left: 5px;
      font-size: 14px;
      line-height: normal;
      height: 30px;
      padding: 5px 10px;
      width: 50px;
    }
  }

  .toggle {
    display: inline-block;
    width: 40px;
    height: 20px;
    background: #ffe7ca;
    border-radius: 20px;
    position: relative;
    cursor: pointer;
    transition: 0.3s;
    &.active {
      background-color: #b9f4bb;
    }
  }

  .circle {
    width: 20px;
    height: 20px;
    background: #f8b400;
    border-radius: 50%;
    position: absolute;
    /* top: 50%; */
    top: 0%;
    transition: transform 0.3s;
  }

  /* 활성화 스타일 */
  .active .circle {
    /* top: 50%; */
    transform: translateX(25px) translateY(0%);
    background: #4caf50; /* 색상 변경 */
    box-shadow: 0px 1px 9px 1px rgba(0, 0, 0, 0.11);
  }
`;

const TableCt = styled.div`
  @media (max-width: 700px) {
    width: 100%;
  }
  display: block;
  width: 70%;
`;

const BtnCt = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 auto;

  @media (max-width: 700px) {
    flex: 1 1 100%;
    margin: 15px 10px 15px !important;
    height: auto !important;
    word-break: keep-all;
  }
  @media (max-width: 480px) {
    width: auto;
    & button {
    }
  }
`;
