import React, { useCallback, useMemo, useState } from "react";
import { useContext, useEffect, useRef } from "react";
import { chatContext } from "../../../utils/providers/ChatContextProvider.js";
import { Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import styled from "styled-components";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ToolTips from "../../../components/organisms/ToolTips.js";
import NormalButton from "components/atomic/NormalButton2";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import VerticalAlignBottomOutlinedIcon from "@mui/icons-material/VerticalAlignBottomOutlined";
import AssignmentReturnOutlinedIcon from "@mui/icons-material/AssignmentReturnOutlined";
import LinkIcon from "@mui/icons-material/Link";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import FolderIcon from "@mui/icons-material/Folder";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import CreateNewFolderOutlinedIcon from "@mui/icons-material/CreateNewFolderOutlined";

import GridOnIcon from "@mui/icons-material/GridOn";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import FormControl from "@mui/material/FormControl";
import Input from "@mui/material/Input";
import SearchIcon from "@mui/icons-material/Search";
import useSearch from "utils/hooks/useSearch.js";
import { popupContext } from "utils/providers/PopupProvider.js";
import { useQuery } from "@tanstack/react-query";
import botApi from "api/botApi.js";
import KnowledgeUtil from "utils/knowledgeUtil.js";
import { debounce } from "lodash";
import { Chip } from "@mui/material";
import { signAppContext } from "../../../utils/providers/SignContextProvider.js";
import { ExtractedDataManageContext } from "utils/providers/ExtractedDataManageProvider.js";
import { ToastMessageContext } from "utils/providers/ToastMessageProvider.js";
import BlockIcon from "@mui/icons-material/Block";

function CustomNoRowsOverlay() {
  return (
    <EmptyDataWrapper>
      <ErrorOutlineIcon color="warning" fontSize="medium" sx={{ mr: 1 }} /> 지식 데이터를 찾을 수 없습니다.{" "}
    </EmptyDataWrapper>
  );
}

const checkTypeReturnIcon = (item) => {
  if (item === "Folder") {
    return <FolderIcon className="" style={{ color: "#ffd874" }} fontSize="small" />;
  } else if (item === "URL") {
    return <LinkIcon className="" style={{ color: "#3ccde1" }} fontSize="small" />;
  } else if (item === "File") {
    return <InsertDriveFileIcon className="" style={{ color: "#9b41ef" }} fontSize="small" />;
  } else if (item === "DB") {
    return <Inventory2Icon className="" style={{ color: "#e8413d" }} fontSize="small" />;
  }
  return "";
};

const TableBtn = ({ onClick, children, ...props }) => {
  return (
    <Button {...props} onClick={onClick}>
      {children}
    </Button>
  );
};

const { listToRows } = KnowledgeUtil;

export default function ChatKnowledgeDataGrid(props) {
  const { knowledgeList, funcProps, dataProps } = props;

  const { toggleInKnowledge, handleDeleteKnow, handleFolderName, clickInfoOrDownBtn, handlecreateFolder } = funcProps;

  const {
    selectionModel,
    setSelectionModel,
    handleMoveFile,
    setParentUid,
    parentUid,
    goBack,
    isLoading,
    setCurrentPage,
    total,
    lengthPerPage,
    currentPage,
    setSearchValue,
    setCurrentTarget,
    setCurrentView,
  } = dataProps;

  const { updateFileInfo } = useContext(ExtractedDataManageContext);
  const {
    myInfo: { memberUid },
  } = useContext(signAppContext);

  const { handleToastMessage } = useContext(ToastMessageContext);

  // 그리드 헤더
  const columns = [
    {
      field: "dataName",
      headerName: "파일명",
      description: "지식의 이름을 보여줍니다",
      width: 300,
      disableColumnMenu: true,
      renderCell: (info) => {
        return info.row.type === "Folder" ? (
          <button
            className="nameBtn folderViewBtn"
            onClick={() => {
              setCurrentPage(0);
              setParentUid(info.row);
              setSelectedRowNumList([]);
            }}
            aria-label="폴더 보기 버튼"
          >
            {checkTypeReturnIcon(info.row.type)} {info.row.dataName}
          </button>
        ) : info.row.type === "URL" ? (
          <button
            className="nameBtn"
            aria-label="파일 이름 확인 버튼"
            onClick={() => {
              setCurrentTarget(info.row);
              info.row.fileCount === 0 && info.row.dataType === 20
                ? showTextPop("빈 폴더만 선택할 경우 다운로드를 진행할 수 없습니다.")
                : clickInfoOrDownBtn(info.row);
            }}
          >
            {checkTypeReturnIcon(info.row.type)} {info.row.dataName}
          </button>
        ) : (
          <button className="nameBtn" aria-label="파일 이름 확인 버튼">
            {checkTypeReturnIcon(info.row.type)} {info.row.dataName}
          </button>
        );
      },
    },
    {
      field: "dataStatus",
      headerName: "활성화",
      width: 70,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (info) => {
        return (
          <TableBtn
            sx={{ minWidth: "auto" }}
            size="small"
            onClick={(e) => {
              e.preventDefault();
              if (info.row.type === "Folder" && info.row.fileCount === 0) {
                showTextPop("활성화 할 폴더의 하위 지식이 없습니다.");
              } else {
                toggleInKnowledge && handleActiveToggle(info.row);
              }
            }}
            aria-label="지식 활성화 버튼"
          >
            {checkActiveSign(info)}
          </TableBtn>
        );
      },
    },
    {
      field: "type",
      headerName: "타입",
      description: "지식의 데이터 타입을 보여줍니다",
      width: 70,
      // sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "fileCount",
      headerName: "활성화지식",
      width: 90,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (info) => {
        return (
          <>
            {info.row.type === "Folder" ? (
              <p>{info.row.fileCount === 0 ? 0 : info.row.activatedFileCount + "/" + info.row.fileCount}</p>
            ) : (
              <p>---</p>
            )}
          </>
        );
      },
    },
    {
      field: "size",
      headerName: "크기",
      description: "지식의 크기를 보여줍니다",
      width: 160,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (info) => {
        return (
          <div className="MuiDataGrid-cellContent">
            {info.row.type === "Folder" ? (
              ""
            ) : info.row.dataRestricted === "Y" ? (
              <ToolTips type="error" message="수집 용량 제한 MESwiser 운영팀에 문의해주세요" data={info.row.size} />
            ) : (
              info.row.size
            )}
          </div>
        );
      },
    },
    // {
    //   field: "path",
    //   headerName: "위치",
    //   description: "지식의 위치를 보여줍니다",
    //   width: 130,
    //   renderCell: (info) => {
    //     return <div className="MuiDataGrid-cellContent">{info.row.currentPath}</div>;
    //   },
    // },

    // {
    //   field: "veiw",
    //   headerName: "폴더 보기",
    //   description: "지식 폴더가 가지고 있는 지식을 볼 수 있습니다",
    //   width: 100,
    //   renderCell: (info) => {
    //     return (
    //       <>
    //         {info.row.type === "Folder" && (
    //           <TableBtn
    //             onClick={() => {
    //               setCurrentPage(0);
    //               setParentUid(info.row);
    //               setSelectedRowNumList([]);
    //             }}
    //             size="medium"
    //             color="info"
    //             variant="contained"
    //             startIcon={<DriveFileMoveIcon />}
    //           ></TableBtn>
    //         )}
    //       </>
    //     );
    //   },
    // },
    {
      field: "modify",
      headerName: "이름 수정",
      description: "지식 폴더의 이름을 변경할 수 있습니다",
      width: 80,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (info) => {
        return (
          <>
            {info.row.type === "Folder" && (
              <TableBtn
                onClick={() => {
                  setCurrentTarget(info.row);
                  handleFolderName(info.row);
                }}
                size="medium"
                color="inherit"
                startIcon={<BorderColorOutlinedIcon style={{ color: "#A09E9E" }} />}
                aria-label="폴더 이름 수정 버튼"
              ></TableBtn>
            )}
          </>
        );
      },
    },
    {
      field: "download",
      headerName: "다운로드",
      description: "지식을 다운로드합니다",
      width: 130,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (info) => {
        return (
          <>
            {info.row.fileCount === 0 && info.row.dataType === 20 ? (
              <></>
            ) : (
              info.row.type !== "URL" && (
                <TableBtn
                  onClick={() => {
                    setCurrentTarget(info.row);
                    info.row.fileCount === 0 && info.row.dataType === 20
                      ? showTextPop("빈 폴더만 선택할 경우 다운로드를 진행할 수 없습니다.")
                      : clickInfoOrDownBtn(info.row);
                  }}
                  endIcon={<VerticalAlignBottomOutlinedIcon />}
                  size="medium"
                  color="inherit"
                  aria-label=" 선택 지식 다운로드 버튼"
                  style={{ border: "1px solid #CCCCCC", backgroundColor: "#fff" }}
                  variant="outlined"
                >
                  다운로드
                </TableBtn>
              )
            )}
          </>
        );
      },
    },
    {
      field: "delete",
      headerName: "삭제",
      description: "지식을 삭제합니다",
      width: 80,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (info) => {
        return (
          <>
            <TableBtn
              aria-label="선택 지식 삭제 버튼"
              onClick={() => {
                info.row.isClicked = true;
                setCurrentTarget(info.row);
                handleDeleteKnow(info.row);
              }}
              size="medium"
              color="inherit"
              style={{ border: "1px solid #CCCCCC", backgroundColor: "#fff" }}
              startIcon={<DeleteForeverIcon />}
            ></TableBtn>
          </>
        );
      },
    },
    {
      field: "tableData",
      headerName: "표 데이터",
      description: "지식의 표 데이터 정보를 볼 수 있습니다.",
      width: 80,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (info) => {
        return (
          <>
            {/* file 이거나 info.row가 extractedData라는 속성을 가지고 있을때 */}
            {info.row.extractedData ? (
              <TableBtn
                onClick={() => {
                  // movePage(info.row);
                  movePage(info.row);
                }}
                size="medium"
                color="inherit"
                style={{ border: "1px solid #CCCCCC", backgroundColor: "#fff" }}
                aria-label="표 데이터 편집 페이지 이동 버튼"
                startIcon={<GridOnIcon color="primary" />}
              />
            ) : (
              <TableBtn
                onClick={() => {
                  // movePage(info.row);
                  handleToastMessage("default", "dataEmpty", "error");
                }}
                size="medium"
                color="inherit"
                style={{ border: "1px solid #CCCCCC", backgroundColor: "#fff" }}
                aria-label="표 데이터 편집 불가"
                startIcon={<BlockIcon color="error" />}
              />
            )}
          </>
        );
      },
    },
  ];

  const { showTextPop } = useContext(popupContext);
  const { chatBotData } = useContext(chatContext);

  const rows = useMemo(() => listToRows(knowledgeList), [knowledgeList]);
  const [selectedRowNumList, setSelectedRowNumList] = useState([]);

  // 최상위에서 폴더를 선택했을 때 해당 폴더 하위의 모든 데이터를 불러와 -> setSelectionModel에 저장하기 위해 아래 state 및 useQuery 및 useEffect 사용.
  const [keyForAddSelectedDataUidList, setKeyForAddSelectedDataUidInfo] = useState([]);
  const { data: listForAddSelectedDataUid } = useQuery({
    queryKey: ["listForAddSelectedDataUid", keyForAddSelectedDataUidList],
    queryFn: () =>
      botApi.getUseKnowDataByPdataUidFromBotUid(chatBotData.botUid, keyForAddSelectedDataUidList).then((response) => {
        if (Number(response?.data?.resultCode ?? "204") === 200) {
          return listToRows(JSON.parse(response?.data?.botKnowDataSetInfo ?? "[]"));
        }

        return [];
      }),
    enabled: keyForAddSelectedDataUidList.length > 0, // 사용 시점 결정. ( 옆의 조건이 true면 실행됨. )
  });

  // 함수 시작

  const movePage = (item) => {
    setCurrentView("pdf");
    sessionStorage.setItem("currentView", "pdf");
    let updatedInfo = {
      dataName: item.dataName,
      dataUid: item.dataUid,
      dateType: item.dataType,
      memberUid: memberUid,
      extension: filterItemExtension(item.dataName),
    };
    updateFileInfo(updatedInfo);
  };

  // 지식 데이터 확장자 구하기
  const filterItemExtension = (str) => {
    let extension = str.split(".")?.at(-1) ?? null;

    return process.env.REACT_APP_CONVERT_EXTENSIONS?.includes(extension) ? "pdf" : extension;
  };

  // 데이터 타입 정의
  const filterRow = (list) => {
    const targetList = list ?? rows;
    // 현재 선택된 리스트에서 각 폴더의 dataUid 별로 파일을 저장해 구분.
    // 전체 선택 되어 있는지 아닌지 확인하기 위해.
    const filterList = targetList.reduce((acc, item) => {
      const isFolder = item.type === "Folder";
      const targetUid = isFolder || (!isFolder && !item?.pdataUid) ? item.dataUid : item.pdataUid;
      if (!acc[targetUid]) acc[targetUid] = { item, fileList: [] };

      acc[targetUid].fileList.push(item);
      return acc;
    }, {});

    return (list ?? rows)
      .filter(
        (item) =>
          selectionModel.findIndex((subItem) => {
            // 폴더의 하위 데이터들이 전체 선택되어 있는지 확인.
            const isAllCheckedInFolder = !filterList[subItem]
              ? false
              : filterList[subItem].fileList.length === subItem.fileCount;
            return (
              subItem.dataUid === item.dataUid ||
              (subItem.dataUid === item.pdataUid && !!item.pdataUid && isAllCheckedInFolder)
            );
          }) !== -1
      )
      .map((item) => item.id);
  };

  // 활성화 상태 정의
  const checkActiveSign = (info) => {
    // activatedFileCount
    // fileCount
    if (info.row.type === "Folder") {
      if (info.row.activatedFileCount === 0) {
        return <RadioButtonUncheckedIcon color="disabled" />;
      } else if (info.row.activatedFileCount === info.row.fileCount) {
        return <RadioButtonCheckedIcon color="success" />;
      } else {
        return <RadioButtonCheckedIcon color="warning" />;
      }
    } else {
      if (info.row.dataStatus === 1) {
        return <RadioButtonCheckedIcon color="success" />;
      } else {
        return <RadioButtonUncheckedIcon color="disabled" />;
      }
    }
  };

  const handleActiveToggle = useCallback(
    debounce((dataInfo) => toggleInKnowledge(dataInfo), 500),
    []
  );

  const handleCurrentPath = useCallback(() => {
    let defaultPosition = ["home"];

    if (knowledgeList && knowledgeList?.length <= 0) {
      if (parentUid !== null) {
        defaultPosition.push(parentUid?.fileName);
        return defaultPosition;
      } else {
        return defaultPosition;
      }
    } else {
      let breadCrumb = knowledgeList[0]?.currentPath.split("/");
      breadCrumb = breadCrumb?.slice(1, breadCrumb.length);
      return breadCrumb;
    }
  }, [knowledgeList]);

  // 함수 끝

  useEffect(() => {
    if (!isLoading) setSelectedRowNumList(() => [...filterRow()]);
  }, [knowledgeList]);

  useEffect(() => {
    if (keyForAddSelectedDataUidList.length > 0) {
      setSelectionModel((prevState) => [
        ...prevState,
        ...listForAddSelectedDataUid.filter(
          (item) => prevState.findIndex((prevStateItem) => prevStateItem?.dataUid === item?.dataUid) === -1
        ),
      ]);
      setKeyForAddSelectedDataUidInfo([]);
    }
  }, [listForAddSelectedDataUid]);

  return (
    <MainWrapper>
      <TableControlCt className="btnCt alignEnd justifyContentBetween">
        <SearchComp setSearchValue={setSearchValue} />
        <NormalButton
          buttonClass={"small round createEmptyFolder notMarginRight"}
          buttonTitle={
            <>
              <CreateNewFolderOutlinedIcon fontSize="large" color="white" />
              <span>폴더 생성</span>
            </>
          }
          callBackFunc={() => {
            handlecreateFolder();
          }}
        />
      </TableControlCt>
      <TableControlCt className="likeTable">
        <BreadCrumbCt>
          {handleCurrentPath()?.map((item, index) => {
            return (
              <MyChip
                className={index === handleCurrentPath().length - 1 ? "current" : ""}
                variant="outlined"
                key={item + index}
                label={item + " /"}
              >
                {/* {item}/ */}
              </MyChip>
            );
          })}
        </BreadCrumbCt>
        <ButtonGroup>
          <Button
            onClick={() => {
              handleMoveFile();
            }}
            color="inherit"
            sx={{ letterSpacing: "-1px", color: "#333333", fontWeight: "350" }}
            aria-label="파일 이동버튼"
          >
            <AssignmentReturnOutlinedIcon style={{ marginRight: "10px", color: "#A09E9E" }} />
            파일 이동
          </Button>
          <Button
            onClick={() => {
              handleDeleteKnow();
            }}
            color="inherit"
            sx={{ letterSpacing: "-1px", color: "#333333", fontWeight: "350" }}
            aria-label="지식 삭제 버튼"
          >
            <DeleteForeverOutlinedIcon style={{ marginRight: "10px", color: "#A09E9E" }} />
            삭제
          </Button>
          <Button
            onClick={() => {
              if (selectionModel.length === 0) return showTextPop("다운로드할 파일/폴더를 선택해 주세요.");
              if (
                (selectionModel?.length !== 0 &&
                  selectionModel?.filter((item) => item.fileCount === 0 && item.dataType === 20).length) ===
                (selectionModel?.length ?? 0)
              ) {
                showTextPop("빈 폴더만 선택할 경우 다운로드를 진행할 수 없습니다.");
                return;
              }
              clickInfoOrDownBtn();
            }}
            color="inherit"
            sx={{ letterSpacing: "-1px", color: "#333333", fontWeight: "350" }}
            aria-label="다운로드 버튼"
          >
            <VerticalAlignBottomOutlinedIcon style={{ marginRight: "10px", color: "#A09E9E" }} />
            다운로드
          </Button>

          {parentUid && (
            <NormalButton
              style={{ marginLeft: "5px", color: "#333333 !important", border: "none" }}
              buttonClass={"small cOrange"}
              buttonTitle={
                <>
                  {/* <ArrowBackOutlinedIcon style={{ color: "#fff" }} /> */}
                  뒤로가기
                </>
              }
              callBackFunc={goBack}
              aria-label="뒤로가기"
            />
          )}
        </ButtonGroup>
      </TableControlCt>

      <DataGrid
        paginationModel={{
          pageSize: lengthPerPage,
          page: currentPage,
        }}
        paginationMode={"server"}
        // 페이지 네이션
        rowCount={total < knowledgeList.length ? knowledgeList.length : total}
        loading={isLoading}
        rows={rows}
        columns={columns}
        slots={{ noRowsOverlay: CustomNoRowsOverlay }}
        checkboxSelection
        disableRowSelectionOnClick
        rowSelectionModel={selectedRowNumList}
        onPaginationModelChange={(pageInfo) => setCurrentPage(pageInfo.page)}
        onRowSelectionModelChange={async (selectedList, details) => {
          const selectabledRows = rows.filter((item) => item.isRowSelectable);
          // 이전 선택된 데이터와, 현재 선택된 데이터의 값을 비교해 추가되었는지 삭제 되었는지 확인.
          const isAdd = selectedRowNumList.length < selectedList.length;
          const manyItemList = isAdd ? selectedList : selectedRowNumList;
          const shortItemList = isAdd ? selectedRowNumList : selectedList;

          // console.log("selectabledRows", manyItemList, selectabledRows);

          // 추가/삭제한 대상 정보를 배열로 가져옴
          const targetItems = manyItemList
            .filter((item) => shortItemList.findIndex((shortItem) => shortItem === item) === -1)
            .map((item) => selectabledRows[selectabledRows.findIndex((row) => row.id === item)]);

          // 추가한 목록을 폴더, 파일별로 분류
          const [foldersInTargetItems, filesInTargetItems] = targetItems.reduce(
            (acc, item) => {
              acc[item?.type === "Folder" ? 0 : 1].push(item);
              return acc;
            },
            [[], []]
          );

          foldersInTargetItems.forEach((item) => {
            if (item.dataType === 20) item["isClicked"] = selectedList.includes(item.id) && isAdd;
          });

          // 폴더를 체크박스 눌러 활성화 시켰을 시 하위 데이터들 가져오기 위해 추가.
          if (isAdd && foldersInTargetItems.length > 0) {
            setKeyForAddSelectedDataUidInfo(foldersInTargetItems);
          }

          setSelectionModel((prevState) => {
            // 현재 item의 dataUid와 선택된 아이템의 dataUid와 일치하는 아이템의 index를 번환.
            const getDataUidMatchDataUid = (item) =>
              !item?.dataUid ? -1 : prevState.findIndex((prevStateItem) => prevStateItem.dataUid === item.dataUid);

            // 즉 현재 아이템의 폴더 index를 반환
            const getDataUidMatchInPdataUid = (item) =>
              !item?.pdataUid ? -1 : prevState.findIndex((prevStateItem) => prevStateItem.dataUid === item.pdataUid);

            // 폴더의 정보를 넘기면  -> 선택된 데이터 리스트 중 해당 폴더의 데이터가 몇 개 선택 되었는지 반환.
            const getSelectedFileCount = (item) =>
              prevState.filter((prevStateItem) => prevStateItem.pdataUid === item?.dataUid).length;

            if (isAdd) {
              // 추가 시
              let addItems = [];
              filesInTargetItems.forEach((item) => {
                // console.log("parentUid", item);
                // 하위 폴더에서 폴더 추가 안 되어 있으면 추가
                if (item?.pdataUid && getDataUidMatchInPdataUid(item) === -1) addItems.push(parentUid);

                addItems.push(item);
              });

              foldersInTargetItems
                .filter((item) => getDataUidMatchDataUid(item) === -1)
                .forEach((item) => addItems.push(item));

              return [...prevState, ...addItems];
            } else {
              // 삭제할 때

              filesInTargetItems.forEach((item) => {
                const idx = getDataUidMatchDataUid(item);
                if (idx !== -1) prevState.splice(idx, 1);
              });

              if (!!parentUid && getSelectedFileCount(parentUid) === 0)
                prevState.splice(getDataUidMatchDataUid(parentUid), 1);

              foldersInTargetItems.forEach((item) => {
                // 해당 폴더 uid와 하위 데이터의 uid를 전부 찾아서 배열에서 제거.
                prevState
                  .filter(
                    (prevStateItem) => prevStateItem.pdataUid === item.dataUid || prevStateItem.dataUid === item.dataUid
                  )
                  .forEach((prevStateItem) => {
                    const idx = prevState.findIndex(
                      (subPrevStateItem) => prevStateItem.dataUid === subPrevStateItem.dataUid
                    );
                    if (idx !== -1) prevState.splice(idx, 1);
                  });
              });
              return [...prevState];
            }
          });

          setSelectedRowNumList(() => [...selectedList]);
        }}
        localeText={{
          MuiTablePagination: {
            labelDisplayedRows: ({ from, to, count }) => `${count}개의 지식 중 ${from} - ${to} `,
            // `총 ${count}개의 지식`,
          },
          footerRowSelected: (count) => `${count}개의 지식 선택`,
        }}
        isRowSelectable={(item) => {
          return item.row.isRowSelectable;
        }}
      />
      {/*<ToastMessage handleToastClose={handleToastClose} toastMessage={toastMessage} toastState={toastState} />*/}
    </MainWrapper>
  );
}

function SearchComp({ setSearchValue }) {
  const [inputvalue, handleInputValue] = useSearch("");
  return (
    <InputContainer>
      <div className="inputFormCt">
        <FormControl sx={{ height: "100%" }} size="md">
          <Input
            value={inputvalue}
            onKeyUp={(e) => {
              e.key === "Enter" && setSearchValue(inputvalue);
            }}
            onChange={handleInputValue}
            startAdornment={<SearchIcon style={{ color: "#CECCCC" }} />}
            // sx={{ borderRadius: "10px 0px 0px 10px" }}
            sx={{ borderRadius: "10px" }}
            placeholder="지식 이름을 검색해보세요"
          />
        </FormControl>
      </div>
    </InputContainer>
  );
}

const MainWrapper = styled.div`
  margin-top: 50px;

  .MuiDataGrid-root {
    border-radius: 0 0 4px 4px;
  }
  .MuiDataGrid-row {
    &:hover {
      background: #e2e8ff !important;
    }
  }
  .MuiDataGrid-cell:focus {
    outline: none;
  }
  .MuiDataGrid-virtualScrollerContent,
  .css-axafay-MuiDataGrid-virtualScroller {
    min-height: 100px !important;
  }

  .css-6xugel,
  .css-1d6wzja-MuiButton-startIcon {
    margin: 0;
  }
  button.nameBtn {
    color: #333333;
    display: flex;
    & svg {
      margin-right: 5px;
    }
  }

  button.folderViewBtn {
    &:hover {
      color: #2a8cff;
      svg {
        color: #2a8cff !important;
      }
    }
  }
`;

const EmptyDataWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TableControlCt = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;

  &.alignEnd {
    align-items: flex-end;
  }

  &.btnCt {
    margin-bottom: 10px;
  }
  &.likeTable {
    margin-bottom: 0;
    border: 1px solid #dcdcdc;
    border-radius: 4px 4px 0 0;
    border-bottom: 0;
  }

  &.justifyContentBetween {
    justify-content: space-between;
  }
`;

const InputContainer = styled.div`
  display: flex;
  /* order:1; */
  & .inputFormCt {
    margin-left: auto;
    .MuiFormControl-root {
      flex-direction: row;
    }
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  flex-wrap: wrap;

  button {
    flex: 0 0 auto;
    margin: 5px;
  }

  @media (max-width: 480px) {
    flex-wrap: wrap;
    margin-top: 20px;
    & button {
      flex: 0 1 30%;
    }
  }
`;

const MyChip = styled(Chip)`
  padding: 2.5px !important;
  padding-right: 0 !important;
  background: transparent !important;
  color: #ccc !important;
  font-weight: 500;
  font-size: 14px !important;
  border: none !important;
  border-radius: 0 !important;
  .css-1jzq0dw-MuiChip-label {
    padding-right: 0;
  }
  & .MuiChip-label {
    display: flex;
    align-items: center;
  }
  &.current {
    color: #5e6fd7 !important;
  }
`;

const BreadCrumbCt = styled.div`
  width: fit-content;
  margin: 0 5px;
  padding: 5px;
  /* background: #f1f2fb; */
  border-radius: 0.5rem;
`;
