import ChatEmpty from "components/pages/dashboard/ChatEmpty.js";
import { useContext, useEffect, useMemo, useRef } from "react";
import { Route, useHistory, useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import { isMobileContext } from "utils/providers/BrowserEnvContextProvider.js";
import ChatBotSelect from "../../utils/ChatBotSelect.js";
import LoadKnowAddtional from "components/templates/dashboard/knowmanage/LoadKnowAddtional.js";
import CrawlUrlView from "../pages/dashboard/CrawlUrlView.js";
import NormalButton, { NormalBtn } from "../atomic/NormalButton2.js";
import InputForm from "../molecules/InputFormKnowledge.js";
import ChatKnowledgeDataGrid from "../pages/dashboard/ChatKnowledgeDataGrid.js";
import ChatKnowledgeDataGridMobile from "components/pages/dashboard/ChatKnowledgeDataGridMobile.js";
import { DASH_HOME, Box, FlexBox, Height100Box } from "../pages/dashboard/dashboardStyle.js";
import { AlertPop2, AlertPopWithRadio, AlertListPop } from "../templates/popup/AlertPop.js";
import ConfirmPop from "../templates/popup/ConfirmPop.js";
import iconWarning from "../../assets/images/icon_warning.svg";
import LoadingComponent from "utils/LoadingComponent.js";
import ConfirmPopInput, { ConfirmPopSelect } from "components/templates/popup/ConfirmPopInput.js";

import AddIcon from "@mui/icons-material/Add";
import Divider from "@mui/material/Divider";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import DescriptionIcon from "@mui/icons-material/Description";
import FolderIcon from "@mui/icons-material/Folder";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import iconUpload from "../../assets/images/icon_fileUpload.png";
import InputIcon from "@mui/icons-material/Input";

import LoadKnowAddtionalMobile from "components/templates/dashboard/knowmanage/LoadKnowAddtionalMobile.js";
import { ALLOWED_EXTENSIONS, viewAllowedExtensions } from "../../utils/hooks/knowledge/useFileHook.js";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { PriceUtil as priceUtil } from "../../utils/priceUtil.js";

/**
 * @property
 *  - chatBotData: 선택된 챗봇 관련 데이터
 *  - knowledgeList: 지식 리스트
 *  - isAddPage: 현재 페이지가 생성 페이지 인지 목록 페이지 인지 구분
 *  - changeIsAddPage: 현재 페이지 변경 함수
 *  - isCreating: 지식을 생성중인지에 대한 여부
 *  - addType: 추가 페이지에서 추가 형식이 파일인지 url인지 구분
 *  - setAddType: addType state 변경 함수
 *
 *  - 처리 함수는 되도록 ChatKnowledge에서 -> view에서는 변수만 받아서 넣도록 수정
 * */
function ChatKnowledgeView(props) {
  const history = useHistory();
  const {
    addProps,
    popProps,
    dataProps,
    knowledgeProps,
    chatBotData,
    userPlan,
    addKnowState,
    knowStateData,
    clickInfoOrDownBtn,
    writeUrl,
    setWriteUrl,
    loading,
    setLoading,
    addtionalBackFunc,
    isKnowledgeFull
  } = props;

  const {
    isAddPage,
    addType,
    serverUpload,
    changeFileHandle,
    changeFolderHandle,
    cancelUpdateFile,
    selectedFile,
    changeIsAddPage,
    changeAddType,
    addWebType,
    folderKey,
    handleDeleteKnow,
    handleMoveFile,
    handleFolderName,
    handlecreateFolder,
    veiwFolder,
  } = addProps;

  const { knowledgeList, clickDeleteKnowledgeBtn, toggleInKnowledge, addUrl } = knowledgeProps;

  const {
    popMessage,
    popState,
    setPopState,
    popCallback,
    listPopState,
    setListPopState,
    listPopMsg,
    listPopItemList,
    confirmPopState,
    setConfirmPopState,
    confirmPopInputState,
    setConfirmPopInputState,
    urlBotCreateState,
    setUrlBotCreateState,
    alertPopCallback,
  } = popProps;

  const {
    selectionModel,
    setSelectionModel,
    openConfirm,
    setOpenConfirm,
    list,
    setParentUid,
    parentUid,
    folderList,
    setFolderList,
    goBack,
    isLoading,
    setCurrentPage,
    total,
    lengthPerPage,
    currentPage,
    setSearchValue,
    searchValue,
    infiniteList,
    fetchNextPage,
    hasNextPage,
    queryKeyInfinite,
    refetchInfinite,
    isLoadingInfinite,
    refetch: pcDataGridRefetch,
    parentInfo,
    setParentInfo,
    currentTarget,
    setCurrentTarget,
    setCurrentView,
  } = dataProps;

  const match = useRouteMatch();
  const { isMobile } = useContext(isMobileContext);
  const refToggle = useRef();
  const refDropTarget = useRef();
  const refDropDesc = useRef();

  const ServiceSizeInfo = () => {
    var size = chatBotData.serviceSize ? chatBotData.serviceSize : 1; // 안되면 1mb라도 되도록
    return size * 1024 * 1024;
  };

  const crawlInfo = (botData) => {
    // const pageStr = botData.crawlPage > 1 ? `${botData.crawlPage} page /` : "";

    // console.log(botData);
    return `${botData?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")} byte`;
  };

  useEffect(() => {
    // 드래그가 아닌 경우가 있으니 변경이 있으면 스타일 초기화
    if (refDropTarget.current && selectedFile.length === 0) {
      refDropTarget.current.style.border = "";
      refDropDesc.current.style.display = "none";
    }

    function dragOverFunc(e) {
      e.preventDefault();
      e.stopPropagation();
      if (selectedFile.length > 0) {
        refDropTarget.current.style.border = "2px dashed #dcdcdc";
        refDropTarget.current.style.backgroundColor = "#fff";
        refDropTarget.current.style.opacity = "0.5";
        // refDropTarget.current.classList.add("in");
        refDropDesc.current.style.display = "flex";
      }
    }

    function dragLeaveFunc(e) {
      if (e.currentTarget.contains(e.relatedTarget)) return;
      e.preventDefault();
      e.stopPropagation();
      refDropTarget.current.style.border = "";
      refDropTarget.current.style.backgroundColor = "";
      refDropTarget.current.style.opacity = "1";
      // refDropTarget.current.classList.remove("in");
      refDropDesc.current.style.display = "none";
    }

    if (addType === "file") {
      document.querySelector(".dashboard--container").addEventListener("dragover", dragOverFunc);

      document.querySelector(".dashboard--container").addEventListener("dragleave", dragLeaveFunc);
    }

    return () => {
      if (addType === "file") {
        document.querySelector(".dashboard--container").removeEventListener("dragover", dragOverFunc);

        document.querySelector(".dashboard--container").removeEventListener("dragleave", dragLeaveFunc);
      }
    };
  }, [addType, selectedFile]);

  // 드랍했을때 css처리
  const dragEndFunc = () => {
    refDropTarget.current.style.backgroundColor = "";
    refDropTarget.current.style.opacity = "1";
    refDropTarget.current.style.border = "";
    refDropDesc.current.style.display = "none";
  };
  const dragFunction = (event, type) => {
    event.preventDefault();
    event.stopPropagation();

    console.log(event);
    if (type === "drop") {
      console.log(">>>> e:", event.dataTransfer.files);
      changeFileHandle(event);
      dragEndFunc();
    }
  };

  if (!chatBotData) {
    return (
      <DASH_HOME.Container>
        <ChatEmpty>아직 생성 된 챗봇이 없습니다.</ChatEmpty>
      </DASH_HOME.Container>
    );
  }

  return (
    <>
      {loading && <LoadingComponent />}
      {listPopState && (
        <AlertListPop
          msg={listPopMsg}
          title={"제외 목록"}
          checkItemList={listPopItemList}
          setPopState={setListPopState}
          type={"except"}
        />
      )}

      {confirmPopInputState && (
        <ConfirmPopInput
          setShowConfirmPop={setConfirmPopInputState}
          callback={popCallback?.normal}
          title={"폴더 이름 입력"}
          description={""}
          popClass={"dash"}
          target={currentTarget}
        />
      )}

      {openConfirm && (
        <ConfirmPopSelect
          setShowConfirmPop={setOpenConfirm}
          callback={popCallback?.normal}
          title="폴더 선택"
          folderList={folderList}
          setFolderList={setFolderList}
          veiwFolder={veiwFolder}
        />
      )}

      {confirmPopState && (
        <ConfirmPop
          popClass={"dash"}
          setShowConfirmPop={setConfirmPopState}
          callback={popCallback?.normal}
          cancelCallback={popCallback?.cancel}
          title={popMessage}
        />
      )}

      {popState && (
        <AlertPop2
          text={popMessage}
          popState={popState}
          setPopState={setPopState}
          callback={alertPopCallback?.normal}
        />
      )}

      {urlBotCreateState && (
        <AlertPopWithRadio
          callback={addWebType}
          cancelCallback={popCallback?.cancel}
          popState={urlBotCreateState}
          setPopState={setUrlBotCreateState}
          text={popMessage}
        />
      )}

      {isAddPage ? (
        // ADDPAGE 일 경우
        <Height100Box
          style={
            !isMobile
              ? {
                  marginBottom: "20px",
                  // width: "70%",
                  padding: "25px 20px",
                  height: "auto",
                }
              : {
                  marginBottom: "20px",
                  width: "100%",
                  padding: "25px 20px",
                  height: "auto",
                }
          }
          // style={
          //   !isMobile
          //     ? {
          //         marginBottom: "20px",
          //         width: "70%",
          //         padding: "25px 20px",
          //         height: "auto",
          //       }
          //     : {
          //         marginBottom: "20px",
          //         width: "100%",
          //         padding: "25px 20px",
          //         height: "auto",
          //       }
          // }
        >
          <AddContainer>
            <Box>
              <Box style={{ display: "flex" }}>
                <NormalButton
                  buttonTitle={
                    <>
                      <KeyboardBackspaceIcon />
                    </>
                  }
                  buttonClass={"cDel backIcon"}
                  callBackFunc={changeIsAddPage}
                />
                <p className="title">지식 등록</p>
              </Box>

              <FlexBox className="tabCt" style={isMobile ? { justifyContent: "center" } : {}}>
                <Box onClick={() => {}} className={addType === "url" ? "RadioCt active" : "RadioCt"}>
                  <input
                    type="radio"
                    id={"add_url"}
                    name={"add_input"}
                    value={"url"}
                    onChange={(e) => {
                      changeAddType(e.target.value);
                    }}
                    checked={addType === "url"}
                  />
                  <label htmlFor={"add_url"}>URL 추가하기</label>
                </Box>
                <Box className={addType === "file" ? "RadioCt active" : "RadioCt"}>
                  <input
                    type="radio"
                    id={"add_file"}
                    name={"add_input"}
                    value={"file"}
                    onChange={(e) => {
                      changeAddType(e.target.value);
                    }}
                    checked={addType === "file"}
                  />
                  <label htmlFor={"add_file"}>파일 추가하기</label>
                </Box>
                <Box className={addType === "load_know" ? "RadioCt active" : "RadioCt"}>
                  <input
                    type="radio"
                    id={"load_know"}
                    name={"add_input"}
                    value={"load_know"}
                    onChange={(e) => {
                      changeAddType(e.target.value);
                    }}
                    checked={addType === "load_know"}
                  />

                  <label style={{ wordBreak: "keep-all" }} htmlFor={"load_know"}>
                    타 챗봇 지식 불러오기
                  </label>
                </Box>
              </FlexBox>
            </Box>
            <Divider style={addType === "url" && !isMobile ? { margin: "0px 0 50px" } : { margin: "0" }} />
            <Box>
              <Box style={isMobile ? { display: "flex", justifyContent: "center" } : {}}>
                <InputCt className="fileCt" style={!isMobile ? { justifyContent: "normal" } : {}}>
                  {addType === "url" ? (
                    <InputForm
                      aria-label="URL 입력 input"
                      formClass="dashboard knowledge"
                      disabled={false}
                      inputValue={writeUrl}
                      inputSetValue={setWriteUrl}
                      placeholderText={"지식 URL을 입력해 주세요"}
                      buttonTitle={"생성"}
                      buttonCallBackFunc={addUrl}
                      inputType={addType !== "url" && "file"}
                      addType={addType}
                    />
                  ) : (
                    <>
                      {addType === "file" ? (
                        !isMobile ? (
                          <FileUpLoadCt>
                            {/* 추가된 파일 목록 */}
                            <>
                              <UploadControlCt>
                                <img className="fileUploadImg" src={iconUpload} alt="파일 추가하기 이미지" />
                                <p className="uploadTitle">파일 및 폴더 업로드</p>
                                <p className="desc">
                                  <span className="textIcon">&#42;</span> {viewAllowedExtensions} 타입의 파일만 업로드 할 수 있습니다.
                                </p>
                                <FileupLoadBtnCt>
                                  <input
                                    type="file"
                                    id="fileUpload"
                                    multiple
                                    accept={ALLOWED_EXTENSIONS.join(", ")}
                                    style={{ display: "none" }}
                                    onChange={changeFileHandle}
                                    aria-label="file 업로드 input"
                                  ></input>

                                  <FileUpLoadLabel htmlFor="fileUpload">
                                    <DescriptionIcon />
                                    <span className="text">파일 업로드</span>
                                  </FileUpLoadLabel>

                                  <>
                                    <input
                                      type="file"
                                      id="folderUpload"
                                      webkitdirectory="true"
                                      style={{ display: "none" }}
                                      key={folderKey}
                                      onChange={changeFolderHandle}
                                      aria-label="폴더 업로드 input"
                                    />
                                    <FileUpLoadLabel className="folder" htmlFor="folderUpload">
                                      <FolderIcon />
                                      <span className="text">폴더 업로드</span>
                                    </FileUpLoadLabel>
                                  </>
                                </FileupLoadBtnCt>
                              </UploadControlCt>
                              <FileListCtDrop
                                onDragEnter={(event) => {
                                  return dragFunction(event, "enter");
                                }}
                                onDragOver={(event) => {
                                  return dragFunction(event, "over");
                                }}
                                onDrop={(event) => dragFunction(event, "drop")}
                              >
                                <div className="fileListDesc">
                                  <FileUploadIcon color="primary" fontSize="large" />
                                  목록 영역에 추가 할 파일을 여기로 이동해보세요
                                </div>
                              </FileListCtDrop>
                            </>

                            <FileListCt
                              ref={refDropTarget}
                              onDragOver={(event) => {
                                return dragFunction(event, "over");
                              }}
                              onDrop={(event) => dragFunction(event, "drop")}
                            >
                              <FileListDragCt ref={refDropDesc}>
                                <FileDragText>추가 할 파일을 여기로 이동해보세요.</FileDragText>
                              </FileListDragCt>
                              <>
                                <FileListTitle className="addedList">
                                  업로드된 파일/폴더 목록 &#40;{selectedFile.length}&#41;
                                </FileListTitle>
                                <FileList>
                                  {/* 파일 아이템 */}
                                  {selectedFile.map((data, idx) => {
                                    return (
                                      <FileListItem key={idx}>
                                        <DescriptionIcon className="icon" color="success" />
                                        <p className="fileName" key={idx}>
                                          {data.name}
                                        </p>
                                        <NormalButton
                                          style={{ border: "none" }}
                                          buttonClass={"small"}
                                          buttonTitle={<HighlightOffIcon color="" />}
                                          callBackFunc={() => cancelUpdateFile(data)}
                                        ></NormalButton>
                                      </FileListItem>
                                    );
                                  })}
                                </FileList>
                              </>
                            </FileListCt>
                          </FileUpLoadCt>
                        ) : (
                          <FileUpLoadCt>
                            {/* 모바일 추가 목록 */}
                            <UploadControlCt>
                              <img className="fileUploadImg" src={iconUpload} alt="파일 추가하기 이미지" />
                              <p className="uploadTitle">파일 및 폴더 업로드</p>
                              <p className="desc">
                                <span className="textIcon">&#42;</span> {viewAllowedExtensions} 타입의 파일만 업로드 할 수 있습니다.
                              </p>
                              <FileupLoadBtnCt>
                                <input
                                  type="file"
                                  id="fileUpload"
                                  multiple
                                  accept={ALLOWED_EXTENSIONS.join(", ")}
                                  style={{ display: "none" }}
                                  onChange={changeFileHandle}
                                  aria-label="파일 업로드 input"
                                ></input>

                                <FileUpLoadLabel htmlFor="fileUpload">
                                  <DescriptionIcon />
                                  <span className="text">파일 업로드</span>
                                </FileUpLoadLabel>
                                <>
                                  <input
                                    type="file"
                                    id="folderUpload"
                                    webkitdirectory="true"
                                    style={{ display: "none" }}
                                    key={folderKey}
                                    onChange={changeFolderHandle}
                                    aria-label="폴더 업로드 input"
                                  />
                                  <FileUpLoadLabel className="folder" htmlFor="folderUpload" onClick={(e) => {}}>
                                    <FolderIcon />
                                    <span className="text">폴더 업로드</span>
                                  </FileUpLoadLabel>
                                </>
                              </FileupLoadBtnCt>
                            </UploadControlCt>
                            <FileListCt
                              ref={refDropTarget}
                              onDragOver={(event) => {
                                return dragFunction(event, "over");
                              }}
                              onDrop={(event) => dragFunction(event, "drop")}
                            >
                              <FileListDragCt ref={refDropDesc}>
                                <FileDragText>추가 할 파일을 여기로 이동해보세요.</FileDragText>
                              </FileListDragCt>
                              <>
                                <FileListTitle className="addedList">
                                  업로드된 파일/폴더 목록 &#40;{selectedFile.length}&#41;
                                </FileListTitle>
                                <FileList>
                                  {/* 파일 아이템 */}
                                  {selectedFile.map((data, idx) => {
                                    return (
                                      <FileListItem key={idx}>
                                        <DescriptionIcon className="icon" color="success" />
                                        <p className="fileName" key={idx}>
                                          {data.name}
                                        </p>
                                        <NormalButton
                                          style={{ border: "none" }}
                                          buttonClass={"small"}
                                          buttonTitle={<HighlightOffIcon color="error" />}
                                          callBackFunc={() => cancelUpdateFile(data)}
                                        ></NormalButton>
                                      </FileListItem>
                                    );
                                  })}
                                </FileList>
                              </>
                              {/* {selectedFile.length > 0 && (
                              )} */}
                            </FileListCt>
                          </FileUpLoadCt>
                        )
                      ) : (
                        addType === "load_know" && (
                          <div
                            style={{
                              width: "100%",
                            }}
                          >
                            {isMobile ? (
                              <LoadKnowAddtionalMobile
                                loading={loading}
                                setLoading={setLoading}
                                changeIsAddPage={changeIsAddPage}
                                callBackFunc={addtionalBackFunc}
                                refetchInfinite={refetchInfinite}
                                selectedFolderInfo={parentUid}
                              />
                            ) : (
                              <LoadKnowAddtional
                                loading={loading}
                                setLoading={setLoading}
                                changeIsAddPage={changeIsAddPage}
                                callBackFunc={addtionalBackFunc}
                                selectedFolderInfo={parentUid}
                                pcDataGridRefetch={pcDataGridRefetch}
                              />
                            )}
                          </div>
                        )
                      )}
                    </>
                  )}
                </InputCt>
              </Box>
            </Box>

            {addType !== "load_know" && (
              <Box style={{ display: "flex" }}>
                {selectedFile.length > 0 && (
                  <NormalButton
                    buttonTitle={
                      <>
                        <AddIcon />
                        지식추가
                      </>
                    }
                    buttonClass={"cOrange createEmptyFolder2 small"}
                    callBackFunc={serverUpload}
                  />
                )}
              </Box>
            )}
          </AddContainer>
        </Height100Box>
      ) : (
        // ADDPAGE가 아닐 경우
        <Height100Box style={{ padding: "25px 20px", overflowY: "scroll" }}>
          <>
            <Route path={`${match.path}/CrawlUrlView`} render={(props) => <CrawlUrlView />} />

            {/* 챗봇 선택 영역 */}
            <Box
              style={
                !isMobile
                  ? {
                      marginBottom: "20px",
                      width: "70%",
                    }
                  : {
                      marginBottom: "20px",
                      width: "100%",
                    }
              }
            >
              <ChatBotSelect />

              {!["adding", "error"].includes(addKnowState) && (
                <Box className={"knowledge"} key={"add_add"} style={{ display: "flex", width: "100%" }}>
                  <>
                    {isKnowledgeFull && (
                      <NormalButton
                        style={{
                          flexDirection: "column",
                          margin: "5px 0",
                        }}
                        buttonClass={"noOpacity withIcon addKnowledge"}
                        buttonTitle={
                          <>
                            {(userPlan.isFreeUser && isKnowledgeFull) && (
                              <>
                                <Desc className="fileName"></Desc>
                                <Desc style={{ marginBottom: "28px" }} className="title ask">
                                  무료 버전에서는 최대 100MB의
                                  <br />
                                  지식 관리 용량을 지원합니다.
                                  <br />
                                  [문의하기]를 남겨주시면 유료버전
                                  <br />
                                  상담을 받으실 수 있습니다.
                                </Desc>
                                <NormalButton
                                  buttonClass={"cOrange mid"}
                                  buttonTitle={"문의하기"}
                                  onClick={() => {
                                    sessionStorage.setItem("DISPLAY_MENU", "QNA");
                                    history.go(0);
                                  }}
                                ></NormalButton>
                              </>
                            )}
                            {(userPlan.isBasicUser && isKnowledgeFull) && (
                              <>
                                <Desc className="fileName"></Desc>
                                <Desc style={{ marginBottom: "28px" }} className="title ask">
                                  베이직 버전에서는 최대 200MB의
                                  <br />
                                  지식 관리 용량을 지원합니다.
                                  <br />
                                  [문의하기]를 남겨주시면 프리미엄 버전
                                  <br />
                                  상담을 받으실 수 있습니다.
                                </Desc>
                                <NormalButton
                                  buttonClass={"cOrange mid"}
                                  buttonTitle={"문의하기"}
                                  onClick={() => {
                                    sessionStorage.setItem("DISPLAY_MENU", "QNA");
                                    history.go(0);
                                  }}
                                ></NormalButton>
                              </>
                            )}
                            {(userPlan.isPremiumUser && isKnowledgeFull) && (
                              <>
                                <Desc className="fileName"></Desc>
                                <Desc style={{ marginBottom: "28px" }} className="title ask">
                                  프리미엄 버전에서는 최대 1GB의
                                  <br />
                                  지식 관리 용량을 지원합니다.
                                  <br />
                                  [문의하기]를 남겨주시면 용량 확장 관련
                                  <br />
                                  상담을 받으실 수 있습니다.
                                </Desc>
                                <NormalButton
                                  buttonClass={"cOrange mid"}
                                  buttonTitle={"문의하기"}
                                  onClick={() => {
                                    sessionStorage.setItem("DISPLAY_MENU", "QNA");
                                    history.go(0);
                                  }}
                                ></NormalButton>
                              </>
                            )}
                          </>
                        }
                        callBackFunc={() => {
                          sessionStorage.setItem("DISPLAY_MENU", "QNA");
                          history.go(0);
                        }}
                      />
                    )}
                    <ButtonGroup>
                      {!isKnowledgeFull && (
                        <NormalButton
                          disabled={isKnowledgeFull}
                          style={{
                            flexDirection: "column",
                          }}
                          buttonClass={" withIcon addKnowledge"}
                          buttonTitle={
                            <>
                              <span className="iconWrap">
                                <AddIcon fontSize="large" color="white" />
                              </span>
                              <span>지식을 추가해 보세요</span>
                            </>
                          }
                          callBackFunc={changeIsAddPage}
                        />
                      )}
                      {!isMobile && (
                        <NormalButton
                          style={{
                            flexDirection: "column",
                          }}
                          buttonClass={" withIcon addKnowledge"}
                          buttonTitle={
                            <>
                              <span className="iconWrap">
                                <InputIcon fontSize="large" color="white" />
                              </span>
                              <span>지식 체크</span>
                            </>
                          }
                          callBackFunc={() => setCurrentView("searchTest")}
                        />
                      )}
                    </ButtonGroup>
                  </>
                </Box>
              )}
            </Box>
            {/*  챗봇 관련 지식 영역 */}
            <Height100Box
              style={{
                margin: "10px 0",
                paddingBottom: "50px",
              }}
            >
              <KnowledgeContainer className={"knowledgeContainer"}>
                {/* 지식을 추가해 보세요 or 생성 부분 */}
                {addKnowState === "adding" ? (
                  <Box className={"knowledge"} key={"creating"}>
                    <LoadingCt>
                      <LoadingIcons className="load-icon center">
                        <span></span>
                        <span></span>
                        <span></span>
                      </LoadingIcons>
                      <LoadingText>{knowStateData}</LoadingText>
                    </LoadingCt>
                  </Box>
                ) : addKnowState === "error" ? (
                  <Box style={{ display: "flex", flexDirection: "column" }} className={"knowledge"}>
                    <Desc className="error fileName">
                      <span className="img"></span>
                      <h2 style={{ fontSize: "18px", marginBottom: "50px" }} className="title">
                        <span>{knowStateData}</span>
                      </h2>
                    </Desc>

                    <NormalButton
                      style={{
                        flexDirection: "column",
                      }}
                      buttonClass={" withIcon addKnowledge"}
                      buttonTitle={
                        <>
                          <span className="iconWrap">
                            <AddIcon fontSize="large" color="white" />
                          </span>
                          <span>지식을 추가해 보세요</span>
                        </>
                      }
                      callBackFunc={changeIsAddPage}
                    />
                  </Box>
                ) : (
                  ""
                )}

                {/* 지식관리 메인 또는 pdf 편집 veiw */}
                {!isMobile ? (
                  <ChatKnowledgeDataGrid
                    knowledgeList={knowledgeList}
                    crawlInfo={crawlInfo}
                    funcProps={{
                      handleDeleteKnow,
                      clickDeleteKnowledgeBtn,
                      toggleInKnowledge,
                      handleFolderName,
                      clickInfoOrDownBtn,
                      handlecreateFolder,
                    }}
                    dataProps={{
                      selectionModel,
                      setSelectionModel,
                      handleMoveFile,
                      list,
                      setParentUid,
                      parentUid,
                      goBack,
                      isLoading,
                      setCurrentPage,
                      total,
                      lengthPerPage,
                      currentPage,
                      setSearchValue,
                      searchValue,
                      parentInfo,
                      setParentInfo,
                      currentTarget,
                      setCurrentTarget,
                      setCurrentView,
                    }}
                  />
                ) : (
                  <ChatKnowledgeDataGridMobile
                    knowledgeList={knowledgeList}
                    crawlInfo={crawlInfo}
                    funcProps={{
                      handleDeleteKnow,
                      clickDeleteKnowledgeBtn,
                      toggleInKnowledge,
                      handleFolderName,
                      clickInfoOrDownBtn,
                    }}
                    dataProps={{
                      selectionModel,
                      setSelectionModel,
                      handleMoveFile,
                      list,
                      setParentUid,
                      parentUid,
                      goBack,
                      isLoading,
                      setCurrentPage,
                      total,
                      lengthPerPage,
                      currentPage,
                      setSearchValue,
                      searchValue,
                      infiniteList,
                      fetchNextPage,
                      hasNextPage,
                      queryKeyInfinite,
                      refetchInfinite,
                      isLoadingInfinite,
                      currentTarget,
                      setCurrentTarget,
                    }}
                  />
                )}
              </KnowledgeContainer>
            </Height100Box>
          </>
        </Height100Box>
      )}
    </>
  );
}

export default ChatKnowledgeView;

const KnowledgeContainer = styled.div`
  max-width: 1200px;

  justify-content: center;
  gap: 20px 10px;
  padding-bottom: 50px;

  @media (min-width: 1545px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (min-width: 1875px) {
    grid-template-columns: repeat(5, 1fr);
  }

  @media (max-width: 1224px) {
    grid-template-columns: repeat(2, 48%);
    justify-content: center;
    gap: 15px;
  }

  @media (max-width: 800px) {
    grid-template-columns: repeat(1, 100%);
    justify-content: center;
    gap: 10px;
  }

  @media (max-width: 480px) {
    width: 100%;
  }

  /* div.knowledge {
    border-radius: 3px;
    margin-top: 5px;
    margin-bottom: 10px;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.16);
    padding: 7.5px 3.5px;
    display: flex;
    flex-direction: column;
  } */

  .title {
    margin-bottom: 25px;
    text-align: center;
    font-size: 16px;
    line-height: 21px;
    word-break: break-all;
    letter-spacing: -0.5px;

    .type {
      font-weight: 600;
      margin-top: 15px;
    }
  }

  .descBox {
    /* margin-top: 20px; */
    margin-top: auto;
    margin-bottom: 20px;
    &.restrict {
      margin-top: 15px;
      & p.point {
        color: #ff892a;
      }
    }

    .descTitle {
      color: #555454;
      font-size: 15px;
      font-weight: 500;
      margin-bottom: 4px;
      word-break: break-all;
    }

    .descNumber {
      color: #888;
      font-size: 15px;
      font-weight: 400;
      word-break: break-all;
    }

    /* margin-bottom: 20%; */
    text-align: center;
  }

  & .buttonContainer {
    margin-top: 10px;
    /* margin-top: auto; */

    & button {
      /* margin: 5px 2.5px; */
      height: 40px;
      line-height: normal;

      &:hover {
        opacity: 0.8;
      }

      &.removeDefault {
        width: 65px;
        padding: 10px;
        font-size: 16px;
      }

      &.knowActive,
      &.knowNone {
        margin-top: 0px;
        width: 64px;
      }

      @media (max-width: 1060px) {
        font-size: 14px !important;
        height: 40px;
      }
    }
  }

  & button.knowledge {
    height: 100%;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    display: flex;
    flex-direction: column;
    padding: 0;
    border: 0;

    & i {
      width: 100%;
      color: #ff892a;
      font-size: 4rem;
      margin-bottom: 30px;
    }

    & span {
      width: 100%;
      color: #fff;
      padding: 15px 10px;
      background: #ff892a;
      border-radius: 100px;
    }
  }
`;

const InputCt = styled.div`
  margin-bottom: 65px;

  & .dashboard {
    width: 100%;
    button {
      flex: 0 0 auto;
      width: 25%;
      min-width: 141px;
      @media (max-width: 1024px) {
        font-size: 17px;
      }
    }
  }

  &:first-child {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 480px) {
    margin: 30px 0;
    & .dashboard {
      width: 100%;
      flex: 1 1 100%;
    }
  }

  @media (min-width: 480px) {
    & > div {
      /* width: 66%; */
      &.knowledge {
        min-width: 470px;
      }
    }
  }
`;

const AddContainer = styled.div`
  max-width: 1200px;

  .title {
    color: #363636;
    font-size: 20px;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: -0.2px;
    margin-bottom: 40px;

    @media (max-width: 480px) {
      line-height: normal;
      font-size: 18px;
      margin-bottom: 15px;
    }
  }

  & .tabCt {
    display: flex;
  }

  & .RadioCt {
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    & label {
      width: 100%;
      padding: 15px 10px;
      font-size: 18px;
      text-align: center;
      cursor: pointer;
      color: #626161;
      font-weight: 400;
    }
    &.active {
      position: relative;
      &::after {
        content: "";
        position: absolute;
        background: #ff892a;
        width: 30%;
        height: 6px;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }

      label {
        /* color: #fff; */
        color: #1a1a1a;
        font-weight: 800;
      }
    }
    flex: 0 0 25%;

    &:last-child {
      border-right: none;
    }
    @media (min-width: 481px) {
      min-width: 140px;
    }

    input {
      width: 25px;
      height: 25px;
      margin-right: 10px;
      display: none;
    }

    label {
      color: #808080;
      font-weight: 400;
      font-size: 20px;
    }

    @media (max-width: 480px) {
      flex: 1 1 auto;
      height: 60px;
      input {
        width: 18px;
        height: 18px;
        margin-right: 5px;
      }

      label {
        color: #808080;
        font-weight: 400;
        font-size: 14px;
      }
    }
  }

  & .fileCt {
    margin-top: 20px;
    @media (max-width: 480px) {
      width: 100%;
    }
  }
`;

const Desc = styled.div`
  font-size: 16px;
  color: #777;
  line-height: 16px;
  /* margin-bottom: -10px; */

  & p.extension {
    line-height: normal;
  }

  &.error {
    display: flex;
    flex-direction: column;
    & span.img {
      background: url(${iconWarning}) no-repeat;
      background-position: center;
      background-size: cover;
      width: 50px;
      height: 50px;
      display: inline-block;
      margin: 0px auto 10px;
    }
  }

  &.fileName {
    margin: 15px 0 0px;

    & h2 {
      font-size: 22px;
      font-weight: normal;
    }

    & h2 span {
      font-size: 22px;
      font-weight: 600;
      color: #fda055;
    }
  }

  & h2 {
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 5px;
  }

  @media (max-width: 480px) {
    font-size: 13px;
    color: #888;
    margin-top: 30px;
  }

  &.ask {
    font-size: 16px;
    word-break: keep-all;
  }
`;

const FileUpLoadCt = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  width: 100%;
`;
const FileupLoadBtnCt = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 7.5px 0;

  @media (min-width: 481px) {
    flex-direction: row;
    justify-content: center;
  }
`;

const FileUpLoadLabel = styled.label`
  background: #2a8cff;
  color: #fff;
  padding: 12px 15px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  margin: 7.5px 0;
  width: 180px;
  border-radius: 100px;
  font-weight: 500;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: #1b59a3;
  }

  & i {
    font-size: 24px;
    margin-right: 5px;
  }

  &.folder {
    color: #1a1a1a;
    background: #fff;
    border: 1px solid #2a8cff;
    & svg {
      color: #71d1fa;
    }
    &:hover {
      background: #2a8cff;
      color: #fff;
      & svg {
        color: #f0ce19;
      }
    }
  }

  @media (max-width: 480px) {
    font-size: 20px;
    width: 240px;
    text-align: center;
  }
  @media (min-width: 481px) {
    margin: 5px 10px;
  }
`;

const FileListCtDrop = styled.div`
  width: 100%;
  height: 200px;
  text-align: center;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed #90c4e2;
  background: #ecf5ff;
  color: #776;
  font-size: 20px;
  border-radius: 15px;
  margin-top: 5px;

  &:hover {
    opacity: 0.7;
  }

  .fileListDesc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 300px;
    color: #293da8;
  }
  @media (min-width: 481px) {
    padding: 10px 20px;
    height: 150px;
    .fileListDesc {
      min-height: auto;
    }
  }
`;

const FileListCt = styled.div`
  width: 100%;
  margin: 0 0 20px;

  position: relative;
`;

const FileListDragCt = styled.div`
  border: 3px solid blue;
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background: rgba(220, 220, 220, 0.6);
  border: 2px dashed #666;

  /* display: flex; */
  justify-content: center;
  align-items: center;
`;
const FileDragText = styled.p`
  color: #333;
  font-size: 20px;
`;

const FileListTitle = styled.p`
  font-size: 14px;
  text-align: center;
  padding: 10px;
  color: #757373;
  margin-top: 10px;

  &.addedList {
    text-align: left;
    display: flex;
    align-items: center;
    .icon {
      margin: 0 5px;
    }
  }
`;

const FileList = styled.ul`
  max-height: 500px;
  overflow-y: scroll;
  border: 1px solid #ccc;
  border-radius: 3px;
  margin: 10px;

  &::-webkit-scrollbar {
    height: 5px; /* 가로 스크롤바의 높이 */
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const FileListItem = styled.li`
  /* margin: 1.5px 0; */
  /* border-radius: 4.5px; */

  padding: 10px;
  border-bottom: 1px solid #ccc;
  background: #fff;

  &:last-child {
    border-bottom: none;
  }

  display: flex;
  align-items: center;

  &:hover {
    background: #e5f1ff;
    & p {
      color: #2a8cff;
    }
  }

  & .icon {
    margin-right: 5px;
  }

  & p {
    font-size: 14px;
    letter-spacing: -0.5px;
    flex: 1 0 auto;
    width: 80%;
    word-break: break-all;
    line-height: 16px;
    display: flex;
    align-items: center;
  }
  & button {
    background: transparent;
    padding: 0 !important;
    font-size: 12px;
    height: 40px;
    & svg {
      width: 18px;
      height: 18px;
      color: #898989;
      &:hover {
        color: #ff0000;
      }
      @media (min-width: 481px) {
        width: 24px;
        height: 24px;
      }
    }
  }
`;

const LoadingCt = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const LoadingText = styled.p`
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;

  @media (max-width: 480px) {
    font-size: 18px;
    margin: 10px 0;
    padding: 0 40px;
    word-break: break-all;
  }
`;
const LoadingIcons = styled.div`
  width: 100px;
  height: 20px;

  margin: 20px 0px 30px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: relative;

  & span {
    position: absolute;
    display: inline-block;
    background: #ff892a;
    width: 20px;
    height: 20px;
    border-radius: 100px;
  }
  & span:nth-of-type(1) {
    left: 0;
  }
  & span:nth-of-type(2) {
    left: 40%;
  }
  & span:nth-of-type(3) {
    right: 0;
  }

  /* Animation */
  & span {
    animation-name: loading;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
  }
  & span:nth-of-type(2) {
    animation-delay: 0.2s;
  }
  & span:nth-of-type(3) {
    animation-delay: 0.4s;
  }

  /* Keyframes */
  @keyframes loading {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  button {
    margin: 5px;
  }
  @media (max-width: 480px) {
    flex-wrap: wrap;
    & button {
      flex: 1 0 40%;
    }
  }
`;

const UploadControlCt = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1 0 100%;
  padding: 30px 25px;

  background: #ecf5ff;
  border: 1px solid #90c4e2;
  border-radius: 15px;

  @media (min-width: 481px) {
    padding: 20px;
  }

  img.fileUploadImg {
    margin: 0 auto;
    width: 80px;
    height: 80px;
    margin-bottom: 20px;
    @media (min-width: 481px) {
      width: 100px;
      height: 100px;
      margin-bottom: 25px;
    }
  }
  p.uploadTitle {
    font-size: 22px;
    font-weight: 700;
    color: #293da8;
    text-align: center;
    margin-bottom: 7px;

    @media (min-width: 481px) {
      margin-bottom: 10px;
    }
  }
  p.desc {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 31px;
    color: #63646b;

    word-break: keep-all;
    text-align: center;
    line-height: 16px;

    @media (min-width: 481px) {
      text-align: center;
      font-size: 16px;
    }
  }
  span.textIcon {
    display: inline-block;
    color: #ff0000;
  }
`;
