//채팅 관련 api..
import axios from "axios";
import { session } from "utils/storage";

const URL = `${process.env.REACT_APP_API_HOST}/api/chatbot/`;
const api = axios.create({ baseURL: URL });

api.interceptors.response.use(
  (response) => {
    const { headers, config, status } = response;
    //response headers의 Authorization, refresh_token을 전달 받았다면
    //새로 갱신된 토큰 값이므로 기존의 sessionStorage에 Authorization,refresh_token 값을 업데이트한다.
    if (status === 205) {
      session.setAuth(headers.authorization, headers.refresh_token, headers.member_email, headers.member_uid, "", "");

      config.headers.authorization = headers.authorization;
      config.headers.refresh_token = headers.refresh_token;
      console.log("acessToken 갱신. 서비스 재 호출", response);
      return api.request(config);
    }

    return response;
  },
  (error) => {
    const { response, config } = error;

    if (response) {
      if (response.status === 401) {
        console.log("accessToken 만료. refresh token으로 요청", response);
        config.headers.refresh_token = session.getRefreshToken();
        return api.request(config);
      } else if (response.status === 403) {
        console.log("accessToken 만료, refreshToken 만료. 강제 로그아웃 ", response, " : ");
        return Promise.reject(error);
      } else {
        console.log("다른 이유로 에러 : ", response);
        return Promise.reject(error);
      }
    } else if (!error?.response && error.message === "Network Error") {
      console.log("서버와 통신 할 수 없습니다.");
      error = {
        response: {
          status: 503,
          errorMessage: "서버와 통신 할 수 없습니다.",
        },
      };
      return Promise.reject(error);
    } else {
      console.log("서버와의 통신에 알 수 없는 문제가 발생했습니다.");
      error = {
        response: {
          status: 503,
          errorMessage: "서버와의 통신에 알 수 없는 문제가 발생했습니다.",
        },
      };
      return Promise.reject(error);
    }
  }
);

const chatApi = {
  sendQuestion: (
    indexName,
    botUid,
    chatRoomId,
    messageId,
    sendMessage,
    ip,
    isFirst,
    role,
    ratio,
    showSource,
    useHistory,
    useRsearch,
    useAddPrompt,
    activedFolderUidList,
    useChatMarkdown
  ) => {
    const chatType = 0;
    let params = {
      indexName,
      botUid,
      chatRoomId,
      messageId,
      sendMessage,
      ip,
      isFirst,
      role,
      chatType,
      ratio,
      showSource,
      useHistory,
      useRsearch,
      useAddPrompt,
      filteredFolderList: activedFolderUidList,
      useChatMarkdown,
    };
    return api.post("sendQuestion", params);
  },
  getKnowledgeAnswer: (knowledgeId) => {
    return api.post("getKnowledgeAnswer", { knowledgeId });
  },
  getCommandList: (command, botUid) => {
    return api.post("getCommandList", { command, botUid });
  },
  getApiData: (className) => {
    return api.post("getApiData", { className });
  },
};

export default chatApi;
