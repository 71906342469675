import { ErrorCatch } from "api/ErrorCatch";
import botApi from "api/botApi";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { signAppContext } from "utils/providers/SignContextProvider";
import { SubscribeContext } from "utils/providers/SubscribeContextProvider";
import { chatContext } from "../../../utils/providers/ChatContextProvider.js";
import useKnowledgeInfiniteScroll from "utils/hooks/useKnowledgeInfiniteScroll.js";
import useKnowledgePagination from "../../../utils/hooks/useKnowledgePagination.js";
import { popupContext } from "../../../utils/providers/PopupProvider.js";
import ChatKnowledgeView from "../../views/ChatKnowledgeviewNew2024.js";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { isMobile } from "react-device-detect";
import { useRouteMatch } from "react-router-dom/cjs/react-router-dom.min.js";
import ExtractedDataCaptionProvider from "utils/providers/ExtractedDataCaptionProvider.js";
import ExtractedDataManageProvider from "utils/providers/ExtractedDataManageProvider.js";
import ExtractedDataHtmlProvider from "utils/providers/ExtractedDataHtmlProvider.js";
import ExtractedDataTabManageProvider from "utils/providers/ExtractedDataTabManageProvider.js";
import ExtractedDataModalInfoProvider from "utils/providers/ExtractedDataModalInfoProvider.js";
import useFileHook, { checkFullDiskAndStoreSizeAndCrawlSize } from "../../../utils/hooks/knowledge/useFileHook.js";
import KnowledgeUtil from "../../../utils/knowledgeUtil.js";
import ExtractedDataManage from "../extractData/ExtractedDataManage.js";
import SearchTest from "../knowledge/SearchTest.js";
import { session } from "../../../utils/storage.js";

const getChatBotData = async ( botUid ) => {
	try {
		const {status, data} = await botApi.getBotInfoByBotUid(botUid);
		if (status === 200) {
			return JSON.parse(data.botInfo);
		}
	} catch (error) {
		console.log('error', error);
		return null;
	}
};

const { filterItems } = KnowledgeUtil;
export default function ChatKnowledge() {
  const queryClient = useQueryClient();

  const { chatBotData, setChatBotData, userPlan } = useContext(chatContext);
  const { handleSignOutApp } = useContext(signAppContext);
  const { subscribeNotification, setSubscribeNotification } = useContext(SubscribeContext);
  const { showTextPop, showConfirmPopCommonCallback, showListPop } = useContext(popupContext);

  const history = useHistory();
  const match = useRouteMatch();

  const [addType, setAddType] = useState("url");
  const [isAddPage, setIsAddPage] = useState(false);
  const [loading, setLoading] = useState(false);

  const [addKnowState, setAddKnowState] = useState("");
  const [knowStateData, setKnowStateData] = useState("");

  const [writeUrl, setWriteUrl] = useState("");

  const [popMessage, setPopMessage] = useState("");
  const [popState, setPopState] = useState(false);
  const [confirmPopState, setConfirmPopState] = useState(false);
  const [confirmPopInputState, setConfirmPopInputState] = useState(false);
  // 예외파일 팝업 처리
  const [listPopState, setListPopState] = useState(false);
  const [listPopMsg, setListPopMsg] = useState("");
  const [listPopItemList, setListPopItemList] = useState(null);

  // dataGrid 사용 state
  const [openConfirm, setOpenConfirm] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);

  // dataGrid pagination
  const [botData, setBotData] = useState();
  const [parentUid, setParentUid] = useState(null);
  const [folderList, setFolderList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [prevCurrentPage, setPrevCurrentPage] = useState(0);
  const [searchValue, setSearchValue] = useState(null);

  const [parentInfo, setParentInfo] = useState(null);
  const [currentTarget, setCurrentTarget] = useState(null);

  // 지식관리 메인페이지 또는 pdf view중 어디를 보여줄지 관리
  const [currentView, setCurrentView] = useState(sessionStorage.getItem("currentView") || "default");

  let lengthPerPage = isMobile ? 1000 : 10;

  let {
    list: knowledgeList,
    queryKey,
    total,
    activedFileCnt,
    isLoading,
    refetch,
  } = useKnowledgePagination({
    botUid: botData?.botUid,
    pdataUid: parentUid?.dataUid ?? null,
    useBookmarkCheck: null,
    searchValue: searchValue,
    currentPage,
    lengthPerPage,
  });

  let {
    currentPage: currentPageInfinite,
    lengthPerPage: lengthPerPageInfinite,
    list: infiniteList,
    isLoading: isLoadingInfinite,
    refetch: refetchInfinite,
    queryKey: queryKeyInfinite,
    fetchNextPage,
    hasNextPage,
  } = useKnowledgeInfiniteScroll({
    lengthPerPage,
    botUid: botData?.botUid,
    currentBotUid: botData?.botUid,
    pdataUid: parentUid?.dataUid ?? null,
    searchValue: searchValue,
    useBookmarkCheck: null,
    currentPage: 0,
  });

  // 챗봇 무료 생성 버튼 popupState
  const [urlBotCreateState, setUrlBotCreateState] = useState(false);

  /*
   * 지식 추가에서 취소 시 input file의 value를 null로 초기화 한다는 등
   * confirm 취소 이후의 동작도 필요할 듯 해서 아래처럼 구조 변경
   * */
  const [popCallback, setPopCallback] = useState({
    normal: () => {},
    cancel: () => {},
  });

  const [alertPopCallback, setAlertPopCallback] = useState({
    normal: () => {},
    cancel: () => {},
  });

  const { cancelUpdateFile, changeFolderHandle, changeFileHandle, selectedFile, folderKey, emptySelectedFile } =
    useFileHook({ knowledgeList, chatBotData });
  
  const ServiceSizeInfo = () => {
    var size = chatBotData.serviceSize ? chatBotData.serviceSize : 1; // 안되면 1mb라도 되도록
    return size * 1024 * 1024;
  };
  
  // const isKnowledgeFull = true;
  const [isKnowledgeFull, setIsKnowledgeFull] = useState(false);
  
  /* 펑션모음 */
  const checkAndApplyFullSize = useCallback(async (chatBotData) => {
    const isFull = await checkFullDiskAndStoreSizeAndCrawlSize(chatBotData);
    if (isKnowledgeFull !== isFull) setIsKnowledgeFull(isFull);
  }, [chatBotData]);
  
  const uploadFileToServer = async () => {
    try {
      let params = {
        botUid: chatBotData.botUid,
        indexName: chatBotData.indexName,
        memberUid: chatBotData.memberUid,
        dataType: 1,
        dataName: selectedFile[0].name,
        pdataUid: parentUid?.dataUid,
      };
      setLoading(true);
      const { status, data } = await botApi.addKnowDataSet(params, selectedFile);
      setLoading(false);
      if (status === 200) {
        const resultData = data ? data.resultMessage : "";
        if (data.resultCode === "200") {
          setAddKnowState("adding");
          setKnowStateData(selectedFile[0].name + "에 대한 지식을 추가중입니다.");
          changeIsAddPage();
        } else {
          setPopState((prev) => !prev);
          setPopMessage(resultData);
          return false;
        }
      }
    } catch (error) {}
  };

  const checkKnowDataSetAdding = async (botUid) => {
    try {
      const { status, data } = await botApi.checkKnowDataSetAdding(botUid);
      if (status === 200) {
        if (data?.botInfo) {
          const info = JSON.parse(data.botInfo);
          setKnowStateData(info.dataName + "에 대한 지식을 추가중입니다.");
          setAddKnowState("adding");
        } else {
          setKnowStateData("");
          setAddKnowState("");
        }
      }
    } catch (error) {
      ErrorCatch(error, handleSignOutApp);
    }
  };

  const getKnowDataSetList = async (botUid) => {
    try {
      console.log(">>> ChatKnowledge.js");
      const { status, data } = await botApi.getUseKnowDataSetFromBotUid(botUid);
      if (status === 200) {
        const retList = data.botKnowDataSetList
          ? JSON.parse(data.botKnowDataSetList).filter((item) => item.dataType !== 10)
          : [];
        if (retList) {
          // setKnowledgeList(retList);
          // console.log("bot KnowDataSetList: ", retList);
        }
      } else {
        console.log("getKnowDataSetList No Data..");
      }
    } catch (error) {
      ErrorCatch(error, handleSignOutApp);
    }
  };

  const getDownloadFile = async (data) => {
    setLoading(true);
    try {
      let updatedList = selectionModel
        .map((item) => {
          return item.dataUid;
        })
        .join();

      // 웹에서 다운로드 처리
      if (selectionModel.length > 0) {
        if (updatedList.length > 0) {
          const a = document.createElement("a");
          a.setAttribute(
            "href",
            `${process.env.REACT_APP_API_HOST}/api/bot/downloadKnowDataSetFile?memberUid=${chatBotData.memberUid}&dataUids=${updatedList}`
          );
          a.setAttribute("download", true);
          a.setAttribute("target", "_blank");
          a.click();
        }
      } else {
        // 모바일에서 다운로드 처리
        if (data) {
          const a = document.createElement("a");
          a.setAttribute(
            "href",
            `${process.env.REACT_APP_API_HOST}/api/bot/downloadKnowDataSetFile?memberUid=${chatBotData.memberUid}&dataUids=${data.dataUid}`
          );
          a.setAttribute("download", true);
          a.setAttribute("target", "_blank");
          a.click();
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const deleteKnowDataSet = async (target) => {
    try {
      setLoading(true);
      const { status, data } = await botApi.deleteKnowDataSet(
        target.botUid,
        target.dataUid,
        target.dataName,
        target.division
      );
      if (status === 200) {
        const resultData = data ? data.resultTotalUsageValue : "0";
        let tmpBotData = sessionStorage.getItem("botData") ? JSON.parse(sessionStorage.getItem("botData")) : null;
        if (tmpBotData) {
          // 지금들어온 용량으로 변경해준다.
          tmpBotData.totalUsageValue = Number(resultData);
          setChatBotData(tmpBotData);
          const stateToPass = JSON.stringify(tmpBotData);
          sessionStorage.setItem("botData", stateToPass);
        } else {
          // 위에서 setChatBotdata를 하면 자동으로 getknowDatasetList하기때문에 아닌경우에만 불러오자. 23.10.05.codelua
          getKnowDataSetList(chatBotData.botUid);
        }
        setPopMessage("정상적으로 삭제 했습니다.");
        setPopState((prev) => !prev);
      }
    } catch (error) {
      ErrorCatch(error, handleSignOutApp);
      setLoading(false);
    }
  };

  const addtionalBackFunc = (message, failList) => {
    getKnowDataSetList(chatBotData.botUid);
    showTextPop(message ?? "지식등록에 성공했습니다.", () => {
      if (!!message && failList.length > 0) showListPop({ title: "불러오기 실패 목록", list: failList });
    });
  };

  const addFileType = async (file) => {
    try {
      console.log(">>> File Adding !!");
      let params = {
        botUid: chatBotData.botUid,
        indexName: chatBotData.indexName,
        memberUid: chatBotData.memberUid,
        dataType: 1,
        buildFilePath: file,
        dataName: file.name,
        pdataUid: parentUid?.dataUid,
      };
      const { status, data } = await botApi.addKnowDataSet(params);
      if (status === 200) {
        const resultData = data ? data.resultMessage : "";
        if (data.resultCode === "200") {
          setAddKnowState("adding");
          setKnowStateData(file.name + "에 대한 지식을 추가중입니다.");
          changeIsAddPage();
        } else {
          setPopState((prev) => !prev);
          setPopMessage(resultData);
          return false;
        }
      }
    } catch (error) {
      ErrorCatch(error, handleSignOutApp);
    }
  };
  //
  const addWebType = async (radioValue) => {
    async function addKnowDataSetFromURL(url) {
      try {
        console.log(">>>>>>>> radioValue:", radioValue);
        // 명시적으로 sync사용.
        let params = {
          botUid: chatBotData.botUid,
          indexName: chatBotData.indexName,
          memberUid: chatBotData.memberUid,
          dataType: 0,
          dataName: url,
          crawlStrategy: radioValue,
          pdataUid: parentUid?.dataUid,
        };
        const { status, data } = await botApi.addKnowDataSet(params);
        if (status === 200) {
          const resultData = data ? data.resultMessage : "";
          if (data.resultCode === "200") {
            setAddKnowState("adding");
            setKnowStateData(writeUrl + "에 대한 지식을 추가중입니다.");
            changeIsAddPage();
          } else {
            setPopState((prev) => !prev);
            setPopMessage(resultData);
            return false;
          }
        }
      } catch (error) {
        ErrorCatch(error, handleSignOutApp);
      }
    }

    addKnowDataSetFromURL(writeUrl);
  };

  const addUrl = async () => {
    if (!checkTargetUrl(writeUrl)) {
      return;
    }
    setUrlBotCreateState((prev) => !prev);
  };

  const changeIsAddPage = () => {
    if (isKnowledgeFull) {
      setIsAddPage((prev) => !prev);
      showTextPop("현재 지식용량이 가득 찼습니다. 관리자에게 문의 해주세요.");
      return;
    }
    
    emptySelectedFile();
    setIsAddPage((prev) => !prev);
    setWriteUrl("");
  };

  const changeAddType = (type) => {
    emptySelectedFile();
    setAddType(type);
    setWriteUrl("");
  };

  const serverUpload = (e) => {
    const message = "추가목록을 모두 업로드 하시겠습니까? ";

    showConfirmPopCommonCallback("지식 등록", message, () => {
      uploadFileToServer();
    });
  };

  const openCrawlUrlViewPage = (target) => {
    history.push({
      pathname: `/dashboard/CrawlUrlView`,
      state: { dataUid: target.dataUid },
    });
  };

  const checkTargetUrl = (dataName) => {
    if (dataName.trim(" ") !== "") {
      const urlRegex = /^(?:(http:\/\/|https:\/\/))?((?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,})(\/.*)?$/;
      if (!urlRegex.test(dataName)) {
        setPopMessage("유효한 URL 형식이 아닙니다!");
        setPopState((prev) => !prev);
        return false;
      } else {
        return true;
      }
    } else {
      setPopMessage("URL을 입력해주세요.");
      setPopState((prev) => !prev);
      return false;
    }
  };

  // 수집정보 or 다운로드 버튼 클릭시 실행되는 함수
  const clickInfoOrDownBtn = (data) => {
    if (data?.dataType === 0) {
      openCrawlUrlViewPage(data);
    } else {
      setPopMessage("다운로드하시겠습니까?");
      setPopCallback(() => ({
        cancel: () => {},
        normal: () => getDownloadFile(data),
      }));
      setConfirmPopState(true);
    }
  };

  // 수집정보 옆의 삭제 버튼을 클릭 시 실행되는 함수
  const clickDeleteKnowledgeBtn = (data) => {
    if (knowledgeList.length <= 1) return showTextPop(`최소 1개의 지식은 반드시 필요합니다.`);

    showConfirmPopCommonCallback("지식 삭제", "정말 삭제하시겠습니까?", () => deleteKnowDataSet(data));
  };

  // 빈폴더 생성하기
  const handlecreateFolder = () => {
    if (!!parentUid) return showTextPop("하위 폴더에 폴더를 생성할 수 없습니다.");
    setPopCallback(() => ({
      cancel: () => {},
      normal: (dataName, botUid) => createFolder({ dataName, botUid }),
    }));

    setConfirmPopInputState((prev) => !prev);
  };

  // 폴더 삭제 핸들러
  const handleDeleteKnow = (data) => {
    if (data) {
      setConfirmPopState((prev) => !prev);
      setPopMessage("지식을 삭제합니다");
      setPopCallback(() => ({
        cancel: () => {},
        normal: () =>
          deleteKnowledge([
            {
              dataUid: data.dataUid,
              dataName: data.dataName,
              dataType: data.dataType,
              division: data.division,
              isClicked: data.isClicked,
              clicked: data.isClicked,
              botUid: chatBotData.botUid,
            },
          ]),
      }));
    } else {
      // 웹에서의 삭제 처리
      if (selectionModel.length > 0) {
        let updatedList = selectionModel.map((item) => {
          return {
            dataUid: item.dataUid,
            dataName: item.dataName,
            dataType: item.dataType,
            botUid: item.botUid,
            division: item.division,
            isClicked: item.isClicked,
          };
        });
        setConfirmPopState((prev) => !prev);
        setPopMessage("지식을 삭제합니다");
        setPopCallback(() => ({
          cancel: () => {},
          normal: () => deleteKnowledge(updatedList),
        }));
      } else {
        return showTextPop("삭제 할 파일을 선택해주세요");
      }
    }
  };

  // 파일 이동 핸들러
  const handleMoveFile = () => {
    // 파일이 1개라도 선택되어 있을때
    let newlist;
    let updatedList;
    if (selectionModel.length > 0) {
      if (parentUid !== null) {
        newlist = selectionModel.filter((item) => item.pdataUid !== null);
        updatedList = newlist.map((item) => {
          return {
            dataUid: item.dataUid,
            pdataUid: item.pdataUid ?? null,
          };
        });
      } else {
        updatedList = selectionModel.map((item) => {
          return {
            dataUid: item.dataUid,
            pdataUid: item.pdataUid ?? null,
          };
        });
      }

      setOpenConfirm((prev) => !prev);
      setPopCallback(() => ({
        cancel: () => {},
        normal: (selectedItem) => moveFiles({ updatedList, selectedItem }),
      }));
    } else {
      showTextPop("이동시킬 파일을 선택해주세요");
    }
  };

  // 폴더 이름 변경 confirm callback
  const getUpdateKnowFolderName = (name, botUid, dataUid) => {
    if (!name || name?.trim().length === 0) return showTextPop("폴더 이름을 입력해 주세요.");
    let newName = name;

    const validCheck = folderList.map((item) => (item.dataName === newName ? false : true));

    if (validCheck.findIndex((item) => item === false) === -1) {
      modifyFolderName({ newName, botUid, dataUid });
    } else {
      showTextPop("이미 같은 이름의 폴더가 존재합니다.");
    }
    // setCurrentTarget(null);
  };

  // 폴더 이름 변경하기
  const handleFolderName = (data) => {
    console.log("handleFolderName() -> data = ", data);
    // showConfirmPopCommonCallback()
    setConfirmPopInputState((prev) => !prev);
    setPopCallback(() => ({
      cancel: () => {},
      normal: (name) => getUpdateKnowFolderName(name, data.botUid, data.dataUid),
    }));
  };

  // 뒤로가기 (폴더보기시)
  const goBack = () => {
    setCurrentPage(prevCurrentPage ?? 0);
    setPrevCurrentPage(0);
    setParentUid(null);
    setSearchValue(null);
  };

  // 활성화 최소 목록 구하는 함수
  const getActivedItemCount = (data) => {
    let minKnowFileCount;
    let folderHadActiveItems;
    let totalCount = 0;

    minKnowFileCount = data.filter((item) => item.dataStatus === 1 && item.dataType !== 20);
    folderHadActiveItems = data.filter((item) => item.activatedFileCount > 0);

    data.forEach((item) => {
      if (item.dataStatus === 1 && item.dataType !== 20) {
        totalCount++;
      }
    });
    data.forEach((item) => {
      if (item.dataType === 20) {
        totalCount += item.activatedFileCount;
      }
    });

    return [minKnowFileCount, folderHadActiveItems, totalCount];
  };

  /* useQuery useMutaion 모음 */

  // 북마크 on/off api
  const { mutate: updateBookmark } = useMutation({
    mutationFn: (info) => {
      let bookBoolean;
      const { dataUid, botUid, parentUid, bookmark } = info;

      if (bookmark === "Y") {
        bookBoolean = "N";
      } else {
        bookBoolean = "Y";
      }

      return botApi.bookmarkFromKnowledge(dataUid, botUid, parentUid, bookBoolean);
    },

    onSuccess: (response) => {
      if (response.data.resultCode === "204") {
        return alert(response.data.resultMessage);
      } else {
        queryClient.invalidateQueries(queryKey);
      }
    },
    onError: () => {
      console.error("updateBookmark() -> 요청 실패 에러");
    },
  });

  // 지식 활성화
  const { mutate: toggleInKnowledge } = useMutation({
    mutationFn: (info) => {
      const isDisableClick = info.dataStatus === 1;

      let newlist;
      let updatedList;

      let type;
      if (info.dataType) {
        if (info.dataType === 20) {
          type = "Folder";
        } else if (info.dataType === 0) {
          type = "URL";
        } else if (info.dataType === 1) {
          type = "File";
        }
      } else if (info.type) {
        type = info.type;
      }

      const getDataStatus = (item) =>
        item.dataType === 20 ? (item.activatedFileCount === 0 ? 4 : 1) : item.dataStatus;

      updatedList = (parentUid !== null ? newlist || [] : selectionModel || []).map((item) => {
        return {
          dataUid: item.dataUid,
          dataType: item.dataType,
          pdataUid: item.pdataUid ?? null,
          dataStatus: getDataStatus(item),
          activatedFileCount: item?.activatedFileCount ?? 0,
        };
      });

      const validCheck = new Set(updatedList.map((item) => item.dataStatus));
      // 활성화 비활성화가 checkbox에서 섞여있을 경우
      if (Array.from(validCheck).length >= 2) {
        throw Error("noneActivation");
      }

      if (info.dataType !== 20 || (info.dataType === 20 && info.activatedFileCount > 0)) {
        const selectedCount = updatedList
          .filter((item) => item.dataStatus === 1)
          .reduce((acc, data) => {
            acc += data.dataType === 20 ? data?.activatedFileCount ?? 0 : 1;
            return acc;
          }, 0);
        const isDisabledAllKnowledge = isDisableClick && activedFileCnt - selectedCount <= 0;

        if (isDisabledAllKnowledge) throw Error("noneKnowledge");
      }

      // 단일 활성화 checkBox 선택된게 없을 경우
      let finalData = {
        botUid: chatBotData.botUid,
        list: updatedList,
      };

      // 선택 파일 전체 비활성화는 이미 로직이 구성되어 있음
      if (selectionModel.length === 0) {
        // 선택 된 파일 없는 경우
        if (activedFileCnt <= 1 && info.dataStatus === 1) {
          throw Error("noneKnowledge");
        }
      }

      if (selectionModel.length === 0) {
        // 단일 활성화가 폴더인 경우
        if (type === "Folder") {
          finalData = {
            botUid: chatBotData.botUid,
            list: [
              {
                dataUid: info.dataUid,
                dataType: 20,
                dataStatus: info.activatedFileCount === 0 ? 4 : 1,
              },
            ],
          };
          setLoading(true);
          return botApi.toggleKnowDataSets(finalData).then((response) => ({ ...response, info }));
        } else {
          setLoading(true);
          return botApi
            .toggleKnowDataSet({
              botUid: info.botUid,
              dataUid: info.dataUid,
              dataStatus: info.dataStatus,
            })
            .then((response) => ({ ...response, info }));
        }
      } else {
        // 다중 선택일 경우
        setLoading(true);
        return botApi.toggleKnowDataSets(finalData).then((response) => ({ ...response, info }));
      }
    },

    onSuccess: (data) => {
      if (data === undefined) {
        showTextPop(`최소 1개의 지식은 활성화가 되어있어야 합니다.`);
      }
      const { info } = data;
      // 활성화1, 비활성화4
      if (data.data.resultCode === "200") {
        var ds = 1;
        if (isMobile ? info.dataType !== 20 : info.type !== "Folder") {
          ds = info.dataStatus === 1 ? 4 : 1;
        } else {
          ds = info.activatedFileCount === 0 ? 1 : 4;
        }
        if (ds === 1) {
          showTextPop("활성화 되었습니다.");
        } else {
          showTextPop("비활성 되었습니다.");
        }
        isMobile ? queryClient.invalidateQueries(queryKeyInfinite) : refetch();
        console.log("요청 성공 changeToggleInKnowledge status");
      } else {
        showTextPop(data.data.resultMessage);
        showTextPop("최소 1개의 지식은 활성화가 되어있어야 합니다.");
      }
      setSelectionModel([]);
    },
    onError: (response) => {
      if (!!response?.message) {
        switch (response.message) {
          case "noneKnowledge":
            return showTextPop("최소 1개의 지식은 활성화가 되어있어야 합니다.");
          case "noneActivation":
            return showTextPop("활성화/비활성화는 한 방향으로만 가능합니다!");
          default:
            return showTextPop("지식을 활성화/비활성화 하는 도중 오류가 발생했습니다. 관리자에게 문의해 주세요.");
        }
      }
      console.error("요청 실패 에러");
    },
    onSettled: () => {
      setLoading(false);
    },
  });

  // 이름 변경
  const { mutate: modifyFolderName } = useMutation({
    mutationFn: (info) => {
      setLoading(true);
      const { dataUid, botUid, newName: dataName } = info;
      return botApi.updateFolderInfo(dataUid, botUid, dataName);
    },
    onSuccess: () => {
      console.log("modifyFolderName() -> success");
      // queryClient.invalidateQueries(queryKey);
      isMobile ? queryClient.invalidateQueries(queryKeyInfinite) : refetch();

      console.log("modifyFolderName() -> 요청 성공");
    },
    onError: () => {
      console.log("modifyFolderName() -> 요청 실패");
    },
    onSettled: () => {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    },
  });

  // 폴더 삭제
  const { mutate: deleteKnowledge } = useMutation({
    mutationFn: (updatedList) => {
      return botApi.deleteFolder({
        list: updatedList,
        botUid: chatBotData?.botUid,
        indexName: chatBotData?.indexName,
        email: session.getEmail()
      });
    },
    onSuccess: (_, data) => {
      queryClient.invalidateQueries(queryKey);

      queryClient.setQueryData(queryKey, (oldData) => {
        let oldItems = JSON.parse(oldData.data.botKnowDataSetInfo).data;
        return filterItems(oldItems, data);
      });
      console.log("deleteKnowledge() -> 요청 성공");
      setSelectionModel([]);
    },
    onError: () => {
      console.log("deleteKnowledge() -> 요청 실패");
    },
  });

  // 폴더 생성
  const { mutate: createFolder } = useMutation({
    mutationFn: (info) => {
      setLoading(true);
      const { dataName, botUid } = info;
      return botApi.createUseKnowDataFolderFromBotUid(dataName, botUid, parentUid?.dataUid);
    },
    onSuccess: (response) => {
      console.log("createFolder() -> 요청 성공");
      if (Number(response.data.resultCode) === 204) return showTextPop(response.data.resultMessage);
      queryClient.invalidateQueries(queryKey);
      // queryClient.setQueryData(queryKey, (oldData) => {
      //   let newdata = (JSON.parse(oldData.data.botKnowDataSetInfo).data);
      //   return newdata
      // })
    },
    onError: () => {
      console.log("createFolder() -> 요청 실패");
    },
    onSettled: () => {
      setLoading(false);
    },
  });

  // 파일 이동
  const { mutate: moveFiles } = useMutation({
    mutationFn: (info) => {
      setLoading(true);
      const { selectedItem: pdataUid } = info;
      const moveList = info.updatedList.map((item, index) => {
        return { dataUid: item.dataUid, pdataUid: pdataUid };
      });
      console.log(moveList);
      return botApi.moveFilesFromKnowledge(moveList);
    },
    onSuccess: (response) => {
      if (response.data.resultCode !== "200") {
        return showTextPop(response.data.resultMessage);
      }
      isMobile ? queryClient.invalidateQueries(queryKeyInfinite) : refetch();
      console.log("moveFiles() -> 요청 성공", response);
      setSelectionModel([]);
    },
    onError: () => {
      console.log("moveFiles() -> 요청 실패");
    },
    onSettled: () => {
      setLoading(false);
    },
  });

  // 폴더 조회
  const { mutate: veiwFolder } = useMutation({
    mutationFn: (botUid) => {
      return botApi.viewFolderListFromKnowledge(botUid);
    },
    onSuccess: (response) => {
      const list = JSON.parse(response?.data?.folderList ?? "[]");
      console.log("veiwFolder() -> list = ", list);
      setFolderList(
        list.filter((item) => (!parentUid ? item.dataName !== "root" : parentUid.dataUid !== item.dataUid))
      );
      queryClient.invalidateQueries(queryKey);
    },
    onError: () => {
      console.log("veiwFolder() -> 요청 실패");
    },
  });

  /* useQuery useMutaion 모음 끝 */
  /* 펑션모음 끝 */

  useEffect(() => {
    if (!chatBotData) return;
    getKnowDataSetList(chatBotData.botUid);
    checkKnowDataSetAdding(chatBotData.botUid);
    checkAndApplyFullSize(chatBotData);
    setBotData(chatBotData);
    veiwFolder(chatBotData.botUid);
  }, [chatBotData]);

  useEffect(() => {
    if (!!chatBotData?.botUid) veiwFolder(chatBotData.botUid);
  }, [parentUid]);

  useEffect(() => {
    async function submit() {
      if (!subscribeNotification) {
        return;
      } else {
        if (subscribeNotification.dataStatus === 1) {
          setAddKnowState("success");
          setKnowStateData("");
          setPopMessage("지식 추가 완료되었습니다.");
          setPopState((prev) => !prev);
          // setKnowledgeList((prev) => [...prev, subscribeNotification]);
          if (chatBotData.dataType === 10) {
            // 세션스토리지 botData에 dataType을 수정하고 setchatBot 해주자
            let tmpBotData = sessionStorage.getItem("botData") ? JSON.parse(sessionStorage.getItem("botData")) : null;
            if (tmpBotData) {
              tmpBotData.dataUid = subscribeNotification.dataUid;
              tmpBotData.dataType = subscribeNotification.dataType;
              setChatBotData(tmpBotData);
              const stateToPass = JSON.stringify(chatBotData);
              sessionStorage.setItem("botData", stateToPass);
            }
          } else {
            // 추가된 용량을 botData에 더해주자.
            // await getActiveChatBotData(chatBotData?.botUid);
            // await getActiveChatBotData(chatBotData?.botUid);
            const newBotData = await getChatBotData(chatBotData?.botUid);
            let tmpBotData = sessionStorage.getItem("botData") ? JSON.parse(sessionStorage.getItem("botData")) : null;
            if (tmpBotData) {
              // 지금들어온 용량을 올려준다.
              tmpBotData.totalUsageValue = Number(tmpBotData.totalUsageValue) + Number(subscribeNotification.crawlSize);
              if (!!newBotData) {
                tmpBotData.storeSize = newBotData?.storeSize;
              }
              
              const stateToPass = JSON.stringify(tmpBotData);
              sessionStorage.setItem("botData", stateToPass);
              setChatBotData(tmpBotData);
            }
          }
        } else if (subscribeNotification.dataStatus === 2) {
          // 성공의 경우에는 setChatbotData를 했기에 지식정보를 새로 불러오나 마지막이 실패 혹은 모두 실패일 수 있으니 여기선 새로고침 시도.
          if (subscribeNotification.buildLimit === "10") {
            // 지식이 들어오면 남은 용량관리등을 해야 하는데 멀티기때문에 chatbotdata1개 갱신으로 안되니 새로고침해버린다.
            setAlertPopCallback(() => ({
              normal: () => {
                setAlertPopCallback({ normal: () => {}, cancel: () => {} });
                history.go(0);
              },
            }));
            setPopMessage(`지식이 업데이트 되었습니다.`);
            setPopState((prev) => !prev);
          }

          console.log(">>>>>>>>>>>>>>>>>>> 지식추가 에러 ");
          setAddKnowState("error");
          const errMsg = subscribeNotification.errorReason ?? subscribeNotification.dataName + " 추가에 실패 했습니다.";
          setKnowStateData(errMsg);
        } else {
          setAddKnowState("");
        }
        setSubscribeNotification(null);
      }
    }

    submit();
  }, [subscribeNotification]);

  useEffect(() => {
    isMobile ? queryClient.invalidateQueries(queryKeyInfinite) : refetch();
  }, [total]);

  return (
    <>
      <ExtractedDataManageProvider>
        <ExtractedDataTabManageProvider>
          <ExtractedDataModalInfoProvider>
            <ExtractedDataHtmlProvider>
              <ExtractedDataCaptionProvider>
                {currentView === "default" ? (
                  <ChatKnowledgeView
                    addProps={{
                      isAddPage,
                      addType,
                      setAddType,
                      serverUpload,
                      changeFileHandle,
                      changeFolderHandle,
                      cancelUpdateFile,
                      selectedFile,
                      changeIsAddPage,
                      changeAddType,
                      addWebType,
                      folderKey,
                      handlecreateFolder,
                      veiwFolder,
                      handleDeleteKnow,
                      handleMoveFile,
                      handleFolderName,
                    }}
                    popProps={{
                      popMessage,
                      popState,
                      setPopState,
                      popCallback,
                      listPopState,
                      setListPopState,
                      listPopMsg,
                      listPopItemList,
                      confirmPopState,
                      setConfirmPopState,
                      confirmPopInputState,
                      setConfirmPopInputState,
                      urlBotCreateState,
                      setUrlBotCreateState,
                      alertPopCallback,
                    }}
                    knowledgeProps={{
                      knowledgeList,
                      toggleInKnowledge,
                      clickDeleteKnowledgeBtn,
                      addUrl,
                    }}
                    dataProps={{
                      selectionModel,
                      setSelectionModel,
                      openConfirm,
                      setOpenConfirm,
                      setParentUid,
                      parentUid,
                      folderList,
                      setFolderList,
                      goBack,
                      isLoading,
                      setCurrentPage,
                      total,
                      lengthPerPage,
                      currentPage,
                      setSearchValue,
                      searchValue,
                      infiniteList,
                      fetchNextPage,
                      hasNextPage,
                      queryKeyInfinite,
                      refetch,
                      refetchInfinite,
                      isLoadingInfinite,
                      setBotData,
                      parentInfo,
                      setParentInfo,
                      currentTarget,
                      setCurrentTarget,
                      currentView,
                      setCurrentView,
                    }}
                    clickInfoOrDownBtn={clickInfoOrDownBtn}
                    chatBotData={chatBotData}
                    userPlan={userPlan}
                    addKnowState={addKnowState}
                    knowStateData={knowStateData}
                    writeUrl={writeUrl}
                    setWriteUrl={setWriteUrl}
                    loading={loading}
                    setLoading={setLoading}
                    addtionalBackFunc={addtionalBackFunc}
                    isKnowledgeFull={isKnowledgeFull}
                  />
                ) : currentView === "searchTest" ? (
                  <SearchTest loading={loading} setLoading={setLoading} setCurrentView={setCurrentView} />
                ) : (
                  <ExtractedDataManage setCurrentView={setCurrentView} />
                )}
              </ExtractedDataCaptionProvider>
            </ExtractedDataHtmlProvider>
          </ExtractedDataModalInfoProvider>
        </ExtractedDataTabManageProvider>
      </ExtractedDataManageProvider>
    </>
  );
}
