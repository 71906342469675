import "remixicon/fonts/remixicon.css";
import styled, { keyframes } from "styled-components";
import "../../../components/pages/chat/chat2.css";

export default function ChatIntroCt({ introInName, introText, setDescPopState, botClass, botImageUrl }) {
  const closePopState = () => {
    setDescPopState("none");
  };

  return (
    <Bg className={botClass}>
      <TextPopUpCt className={botClass}>
        <HeaderCt className={botClass}>
          <p className="title">
            <img className="botImg" src={botImageUrl} alt="챗봇이미지02" />
            {introInName}
          </p>
          <button onClick={closePopState} className="closeBtn"></button>
        </HeaderCt>

        <TextPopUpContent className={botClass}>{introText}</TextPopUpContent>
      </TextPopUpCt>
    </Bg>
  );
}
const fadeIn = keyframes`
  from {
    transform: scale(1.1);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {

    transform: scale(1.1);
    opacity: 1;
  }
  to {
    transform: scale(1);
    opacity: 0;
  }
`;

const Bg = styled.div`
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  position: fixed;

  animation: ${fadeIn} 0.1s ease-in-out forwards;
  transform: scale(1);

  &.widget,
  &.own {
    border-radius: 25px;
    background-color: rgba(0, 0, 0, 0.2);

    @media (max-width: 480px) {
      border-radius: 0;
    }
  }
`;
const TextPopUpCt = styled.div`
  position: absolute;
  background: #fff;
  border-radius: 16px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 65%;

  &.own,
  &.widget {
    width: 85%;
    max-height: 85%;
  }
  /* height: 90%; */
  padding: 10px;

  @media (max-width: 480px) {
    top: 50px;
    transform: translate(-50%, 5%);
    width: 80%;
    max-height: 100%;
    /* height: 80%; */
    *::-webkit-scrollbar {
      width: 3px;
    }
  }
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.42);
`;

const HeaderCt = styled.div`
  color: #000;

  font-family: Noto Sans KR;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  display: flex;
  align-items: center;
  justify-content: space-between;

  & p.title {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: 700;
    line-height: 32px;

    img {
      width: 40px;
      height: 40px;
      /* margin-bottom: 6px; */
      border-radius: 10px;
    }
  }

  &.widget,
  &.own {
    p.title {
      font-size: 13px;
      letter-spacing: -1px;
    }
  }

  & button {
    @media (min-width: 481px) {
      width: 20px;
      height: 20px;
    }

    margin-bottom: auto;
  }

  @media (min-width: 481px) {
    p.title {
      font-size: 20px;
      margin: 10px 0;
      img {
        width: 60px;
        height: 60px;
        /* margin-bottom: 15px; */
        border-radius: 15px;
      }
    }
  }
`;

const TextPopUpContent = styled.pre`
  overflow-x: hidden;

  top: 0;
  left: 0;
  max-height: 350px;
  /* max-height: calc(100% - 62px); */
  height: 100%;

  max-width: 100%;
  width: 100%;

  padding: 10px 20px;
  overflow-y: auto;
  white-space: pre-wrap; /* 공백 유지 및 줄바꿈 처리 */
  word-wrap: break-word; /* 단어 단위 줄바꿈 */
  z-index: -1;

  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  color: #363636;

  border-radius: 18px;
  background: #e8edff;

  &.widget,
  &.own {
    font-size: 12px;
    word-break: keep-all;
  }

  @media (max-width: 1024px) {
    height: 200px;
  }

  @media (max-width: 480px) {
    min-height: 100px;
    max-height: 60vh;
    font-size: 14px;
    line-height: 16px;
    height: 100%;
  }

  &.widget {
    height: 40vh;
  }
`;
