import Button from "@mui/material/Button";
import { useMutation, useQuery } from "@tanstack/react-query";
import { ErrorCatch } from "api/ErrorCatch.js";
import botApi from "api/botApi.js";
import NormalButton from "components/atomic/NormalButton2";
import React, { useContext, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { chatContext } from "utils/providers/ChatContextProvider.js";
import { signAppContext } from "utils/providers/SignContextProvider.js";
import useAddKnowledgeChatBotSelect from "../../../../utils/hooks/useAddKnowledgeChatBotSelect.js";
import { popupContext } from "../../../../utils/providers/PopupProvider.js";
import KnowledgeChatBotSelect from "./KnowledgeChatBotSelect.js";

import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CloseIcon from "@mui/icons-material/Close";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import LinkIcon from "@mui/icons-material/Link";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import FolderIcon from "@mui/icons-material/Folder";
import Inventory2Icon from "@mui/icons-material/Inventory2";

import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import SearchIcon from "@mui/icons-material/Search";
import FormControl from "@mui/material/FormControl";
import Input from "@mui/material/Input";
import useSearch from "utils/hooks/useSearch.js";
import useKnowledgeInfiniteScroll from "utils/hooks/useKnowledgeInfiniteScroll.js";
import AddIcon from "@mui/icons-material/Add";

import KnowledgeUtil from "utils/knowledgeUtil.js";
import { folder } from "jszip";
import zIndex from "@mui/material/styles/zIndex.js";

const getChatBotData = async ( botUid ) => {
	try {
		const {status, data} = await botApi.getBotInfoByBotUid(botUid);
		if (status === 200) {
			return JSON.parse(data.botInfo);
		}
	} catch (error) {
		console.log('error', error);
		return null;
	}
};

export default function LoadKnowAddtionalMobile(props) {
  const { loading, setLoading, changeIsAddPage, callBackFunc, refetchInfinite, selectedFolderInfo } = props;
  const { listToRows } = KnowledgeUtil;
  let lengthPerPage = 1000;

  const { chatBotData, setChatBotData } = useContext(chatContext);
  const { handleSignOutApp } = useContext(signAppContext);
  const { selectedOption } = useAddKnowledgeChatBotSelect();
  const { updatePopInfo, showTextPop, showConfirmPop, showConfirmPopCommonCallback } = useContext(popupContext);

  const [currentPage, setCurrentPage] = useState(0);
  const [prevCurrentPage, setPrevCurrentPage] = useState(0);
  const [searchValue, setSearchValue] = useState(null);
  const [parentUid, setParentUid] = useState(null);

  // 최상위에서 폴더를 선택했을 때 해당 폴더 하위의 모든 데이터를 불러와 -> setSelectedDataUidList에 저장하기 위해 아래 state 및 useQuery 및 useEffect 사용.
  const [selectedDataUidList, setSelectedDataUidList] = useState([]);
  const [keyForAddSelectedDataUidList, setKeyForAddSelectedDataUidInfo] = useState([]);

  const activeItemList = useMemo(() => {
    return selectedDataUidList.map((item) => item.dataUid);
  }, [selectedDataUidList]);

  // 폴더내 파일 검색
  const { data: listForAddSelectedDataUid } = useQuery({
    queryKey: ["listForAddSelectedDataUid", keyForAddSelectedDataUidList],
    queryFn: () =>
      botApi.getUseKnowDataByPdataUidFromBotUid(chatBotData.botUid, keyForAddSelectedDataUidList).then((response) => {
        if (Number(response?.data?.resultCode ?? "204") === 200) {
          return JSON.parse(response?.data?.botKnowDataSetInfo ?? "[]");
        }
        return [];
      }),
    enabled: keyForAddSelectedDataUidList.length > 0, // 사용 시점 결정. ( 옆의 조건이 true면 실행됨. )
  });

  let {
    currentPage: currentPageInfinite,
    lengthPerPage: lengthPerPageInfinite,
    list: infiniteList,
    isLoading: isLoadingInfinite,
    refetch: refetchInfiniteLoadData,
    queryKey: queryKeyInfinite,
    fetchNextPage,
    hasNextPage,
  } = useKnowledgeInfiniteScroll({
    lengthPerPage,
    botUid: selectedOption?.botUid,
    currentBotUid: selectedOption?.botUid,
    pdataUid: parentUid?.dataUid ?? null,
    searchValue: searchValue,
    useBookmarkCheck: null,
    currentPage: 0,
  });

  // 함수 모음 시작
  const goBack = () => {
    setCurrentPage(prevCurrentPage ?? 0);
    setPrevCurrentPage(0);
    setParentUid(null);
  };

  // 선택한 아이템이 폴더인지 파일인지 분류 함수
  // 체크박스 클릭시 동작
  const handleCheckBox = (dataInfo, isClicked) => {
    // 중복 파일 체크
    // newlist에 체크된 아이템 관리(추가, 삭제)
    let newList;

    const exists = selectedDataUidList.some((item) => item.dataUid === dataInfo.dataUid);
    if (dataInfo.dataType === 20 && !exists) dataInfo["isClicked"] = isClicked;

    if (exists) {
      if (dataInfo.dataType === 20) {
        newList = selectedDataUidList.filter(
          (item) => item.pdataUid !== dataInfo.dataUid && item.dataUid !== dataInfo.dataUid
        );
      } else {
        newList = selectedDataUidList.filter((item) => item.dataUid !== dataInfo.dataUid);
      }
    } else {
      if (parentUid !== null) {
        let pdataExists = selectedDataUidList.some((item) => item.dataUid === parentUid.dataUid);

        if (!pdataExists) {
          newList = [dataInfo, parentUid, ...selectedDataUidList];
        } else {
          newList = [dataInfo, ...selectedDataUidList];
        }
      } else {
        newList = [dataInfo, ...selectedDataUidList];
      }
    }

    // 폴더 아이템 selesction에 추가하기
    const [folderItems, filesItems] = getFolderItems([dataInfo], exists);
    // 클릭한 아이템 폴더 파일 나누기
    // const checkListObject = divideFolderAndFile(newList);

    if (parentUid !== null) {
      setSelectedDataUidList(cancleFolderItems(newList));
    } else {
      setSelectedDataUidList(newList);
    }
  };

  // 폴더일 경우 아이템 불러오기 + 폴더 아이템 selesction에 추가하기
  const getFolderItems = (newList, exists) => {
    const [foldersInTargetItems, filesInTargetItems] = newList.reduce(
      (acc, item) => {
        acc[item?.dataType === 20 ? 0 : 1].push(item);
        return acc;
      },
      [[], []]
    );

    if (newList[0].dataType === 20 && !exists) {
      if (foldersInTargetItems.length > 0) {
        let updatelist = foldersInTargetItems.map((item) => {
          return { dataUid: item.dataUid };
        });
        // 여기서 폴더의 아이템을 불러와서 추가함
        setKeyForAddSelectedDataUidInfo(updatelist);
      }
    }

    return [foldersInTargetItems, filesInTargetItems];
  };

  const cancleFolderItems = (newList) => {
    const exists = newList.some((item) => item.pdataUid === parentUid.dataUid);
    if (!exists) {
      return newList.filter((item) => item.dataUid !== parentUid.dataUid);
    } else {
      return [...newList];
    }
  };

  const divideFolderAndFile = (newList) => {
    const changedList = newList.reduce(
      (acc, item) => {
        if (item.dataType === 20 && !acc[item.dataUid])
          acc[item.dataUid] = {
            ...item,
            fileList: [],
          };
        else if (acc[item?.pdataUid ?? "root"]) acc[item?.pdataUid ?? "root"].fileList.push(item);
        return acc;
      },
      { root: { dataName: "root", fileList: [] } }
    );
    return changedList;
  };

  // 불러온 지식들 api 등록 함수
  const copyBuildBase = async () => {
    if (!selectedDataUidList || selectedDataUidList.length < 1) {
      showTextPop("선택 된 지식이 없습니다.", () => setLoading(false));
      return;
    }
    const destIndexName = selectedOption?.indexName;
    if (!destIndexName) {
      showTextPop("선택한 봇에 문제가 있습니다. 관리자에게 문의해주세요");
      return;
    }

    let itemsList = listToRows(selectedDataUidList);

    let updateList = itemsList.map((item) => {
      return {
        botUid: chatBotData.botUid,
        dataType: item.dataType,
        dataUid: item.dataUid,
        pdataUid: item.pdataUid,
        llmModel: item.llmModel,
        indexName: chatBotData.indexName,
        dataName: JSON.parse(JSON.stringify(item.dataName)),
      };
    });

    console.log(
      "selectedFolderInfo",
      selectedFolderInfo,
      itemsList,
      itemsList.findIndex((item) => item.dataType === 20 && item.isClicked)
    );
    if (!!selectedFolderInfo && itemsList.findIndex((item) => item.dataType === 20 && item.isClicked) !== -1) {
      showConfirmPopCommonCallback(
        "지식 등록",
        `데이터를 불러올 때 폴더는 제외하고 파일만 추가됩니다. 진행하시겠습니까?`,
        () => {
          setLoading(true);
          submitCopyBuildBase(updateList.filter((item) => item.dataType !== 20));
        }
      );
    } else {
      setLoading(true);
      submitCopyBuildBase(updateList);
    }
  };

  const { mutate: submitCopyBuildBase } = useMutation({
    mutationFn: (dataList) => botApi.copyBuildBase({
      list: dataList,
      selectedFolderInfo,
      ...chatBotData ?? {},
    }),
    onSuccess: async (response) => {
      console.log("response", response);
      let {
        data: { botInfo, resultCode, resultMessage, failList },
        status,
      } = response ?? {};
      if (Number(resultCode) === 200) {
        botInfo = botInfo ? JSON.parse(botInfo) : "";
        if (botInfo) {
          // 세션스토리지 botData에 dataType을 수정하고 setchatBot 해주자
          let tmpBotData = sessionStorage.getItem("botData") ? JSON.parse(sessionStorage.getItem("botData")) : null;
          if (tmpBotData) {
            tmpBotData.dataType = 1;
            setChatBotData(tmpBotData);
            const stateToPass = JSON.stringify(chatBotData);
            sessionStorage.setItem("botData", stateToPass);
          }
        }
      
      const newBotData = await getChatBotData(chatBotData?.botUid);
				if (!!newBotData) {
					sessionStorage.setItem("botData", newBotData);
					setChatBotData(newBotData);
				}
      }
      callBackFunc &&
        callBackFunc(
          resultMessage,
          Number(resultCode) === 200 ? [] : JSON.parse(failList ?? "[]").map((item) => ({ name: item.dataName }))
        );
      refetchInfinite();
      changeIsAddPage();
    },
    onError: (error) => {
      console.log("error", error);
      ErrorCatch(error, handleSignOutApp);
    },
    onSettled: () => {
      console.log("settled");
      setLoading(false);
    },
  });

  // 함수 모음 끝

  // useEffect 시작

  // selectBox에서 botUid를 변경했을때
  useEffect(() => {
    if (!selectedOption) return;
    // 지식을 불러온다.
    setSelectedDataUidList([]);
  }, [selectedOption]);

  // 아이템 분류를 통해서 값이 변경되었을때
  // handleCheckBox 함수가 실행 되었을때만 이 uesEffect가 실행됨
  useEffect(() => {
    // datauid만 가진 list (폴더내 하위 파일을 가져오기 위한)가 1개라도 추가가되어있을때
    if (keyForAddSelectedDataUidList.length > 0) {
      // 기존에 선택된 아이템 리스트에서 중복되는 파일을 제거하고 가져온다
      setSelectedDataUidList((prevState) => [
        ...prevState,
        ...listForAddSelectedDataUid.filter(
          (item) => prevState.findIndex((prevStateItem) => prevStateItem.dataUid === item.dataUid) === -1
        ),
      ]);
      // 처리하고 나면 usequery사용한 list 초기화
      setKeyForAddSelectedDataUidInfo([]);
    }
  }, [listForAddSelectedDataUid]);

  // useEffect 끝

  return (
    <>
      {/* 선택 할 지식 영역 */}

      <MainWrapper>
        <SelectCt style={{ position: "relative" }}>
          <Container>
            <KnowledgeChatBotSelect style={{ zIndex: 1 }} />
          </Container>
        </SelectCt>
        <TableControlCt className="">
          <KnowTopButtonCt>
            <NormalButton
              // style={{ bottom: "30px", position: "fixed", zIndex: 15 }}
              buttonClass={"small round createEmptyFolder float"}
              buttonTitle={
                <>
                  <AddIcon />
                  지식추가
                </>
              }
              callBackFunc={copyBuildBase}
            />
            <KnowCountText
              onClick={() => {
                const changedList = selectedDataUidList.reduce(
                  (acc, item) => {
                    if (item.dataType === 20 && !acc[item.dataUid]) {
                      acc[item.dataUid] = {
                        ...item,
                        fileList: [],
                      };
                    } else if (acc[item?.pdataUid ?? "root"]) {
                      acc[item?.pdataUid ?? "root"].fileList.push(item);
                    }
                    return acc;
                  },
                  { root: { dataName: "root", fileList: [] } }
                );

                updatePopInfo({
                  isEnabled: true,
                  currentState: "list",
                  props: {
                    checkItemList: changedList,
                  },
                });
              }}
            >
              선택 지식 :
              <KnowCountNum>{selectedDataUidList?.filter((item) => item.dataType !== 20).length ?? 0}</KnowCountNum>개
            </KnowCountText>
          </KnowTopButtonCt>
          <SearchComp setSearchValue={setSearchValue} searchValue={searchValue} goBack={goBack} />

          {parentUid !== null && (
            <Button
              sx={{ width: "50px", borderRadius: "100px", margin: "10px 5px" }}
              variant="contained"
              color="warning"
              onClick={() => {
                goBack();
              }}
            >
              <KeyboardBackspaceIcon />
            </Button>
          )}
          <DataContainer>
            {infiniteList?.length === 0 || infiniteList[0]?.total <= 0 ? (
              <>{CustomNoRowsOverlay()}</>
            ) : (
              infiniteList.map((item, index) => {
                return item.data.map((data, dataIdx) => {
                  return (
                    <DataCardItem
                      key={data.dataUid}
                      dataInfo={data}
                      setCurrentPage={setCurrentPage}
                      setParentUid={setParentUid}
                      handleCheckBox={handleCheckBox}
                      activeItemList={activeItemList}
                    />
                  );
                });
              })
            )}
          </DataContainer>
        </TableControlCt>
      </MainWrapper>

      {/* 전체 alert */}
      {/* 선택된 지식 수를 보여줄 수 있는 alert */}
    </>
  );
}

function CustomNoRowsOverlay() {
  return (
    <EmptyDataWrapper>
      <ErrorOutlineIcon color="warning" fontSize="medium" sx={{ mr: 1 }} /> 지식 데이터를 찾을 수 없습니다.{" "}
    </EmptyDataWrapper>
  );
}

function DataCardItem(props) {
  const { dataInfo, apiProps, setCurrentPage, setParentUid, handleCheckBox, activeItemList } = props;

  const { showTextPop } = useContext(popupContext);

  const [isActive, setIsActive] = useState(false);

  // 데이터 타입 정의
  const checkType = (item) => {
    if (item === 20) {
      return "Folder";
    } else if (item === 0) {
      return "URL";
    } else if (item === 1) {
      return "File";
    } else if (item === 2) {
      return "DB";
    }
    return "";
  };

  const checkTypeReturnIcon = (item) => {
    if (item === 20) {
      return <FolderIcon className="" fontSize="small" />;
    } else if (item === 0) {
      return <LinkIcon className="" fontSize="small" />;
    } else if (item === 1) {
      return <InsertDriveFileIcon className="" fontSize="small" />;
    } else if (item === 2) {
      return <Inventory2Icon className="" fontSize="small" />;
    }
    return "";
  };

  const handleTitleLength = (str) => {
    console.log(str);
    if (str.length >= 15) {
      let computedStr = str.slice(0, 15);
      return computedStr + "...";
    } else {
      return str;
    }
  };

  const activeCheckBox = (dataInfo) => {
    if (activeItemList.includes(dataInfo.dataUid)) {
      return <CheckBoxIcon className="iconChecked" color="success" />;
    } else {
      return <CheckBoxOutlineBlankIcon className="iconUnChecked" color="disabled" />;
    }
  };
  const crawlInfo = (botData) => {
    if (!botData.crawlPage || !botData.crawlSize) return;
    const pageStr = botData.crawlPage > 1 ? `${botData.crawlPage} page /` : "";
    return `${pageStr} ${botData.crawlSize.replace(/\B(?=(\d{3})+(?!\d))/g, ",")} byte`;
  };

  return (
    <Card
      variant="outlined"
      sx={{
        flex: "0 1 47%",
        width: "45%",
        margin: "5px 4px",
        resize: "horizontal",
        zIndex: 0,
        gap: "0.2rem 1rem",
        padding: "7px 8px",
      }}
    >
      <CardContent sx={{ padding: "3px" }}>
        <div>
          <ItemTitleBox>
            <Title
              className="titleBox"
              onClick={() => {
                if (dataInfo.fileCount === 0) {
                  showTextPop("하위 지식이 없습니다.");
                } else {
                  setCurrentPage(0);
                  setParentUid(dataInfo);
                }
              }}
              level="title-md"
            >
              <span className={checkType(dataInfo.dataType) + " iconSpan"}>
                {checkTypeReturnIcon(dataInfo.dataType)}
              </span>
              <span className="text">{handleTitleLength(dataInfo.dataName)}</span>
            </Title>
          </ItemTitleBox>

          <Divider sx={{ mt: 0.5 }} />
        </div>
      </CardContent>
      <CardActions sx={{ padding: 0 }}>
        {dataInfo.dataType === 20 ? (
          dataInfo.fileCount === 0 ? (
            <></>
          ) : (
            <IconButton
              onClick={() => {
                setIsActive((prev) => {
                  return !prev;
                });
                handleCheckBox(dataInfo, dataInfo.dataType === 20);
              }}
              variant="soft"
              color="default"
              sx={{ backgroundColor: "transparent" }}
            >
              {activeCheckBox(dataInfo)}
            </IconButton>
          )
        ) : (
          <IconButton
            onClick={() => {
              setIsActive((prev) => {
                return !prev;
              });
              handleCheckBox(dataInfo, dataInfo.dataType === 20);
            }}
            variant="soft"
            color="default"
            sx={{ backgroundColor: "transparent" }}
          >
            {activeCheckBox(dataInfo)}
          </IconButton>
        )}
        <IconButton sx={{ ml: "auto" }}>
          <InfoOutlinedIcon
            fontSize="small"
            sx={{ color: "#636b74" }}
            onClick={() => {
              let size = crawlInfo(dataInfo);
              showTextPop(
                <>
                  <span level="body-sm">지식이름 : {dataInfo.dataName}</span>
                  <br />
                  <span level="body-sm">지식타입 : {checkType(dataInfo.dataType)}</span>
                  <br />
                  <span level="body-sm">지식크기 : {size !== undefined ? size : "미분류"}</span>
                  {dataInfo.dataType === 20 && <Desc level="body-sm">하위 지식 : {dataInfo.fileCount} </Desc>}
                </>
              );
            }}
            variant="soft"
          />
        </IconButton>
      </CardActions>
    </Card>
  );
}

function SearchComp({ setSearchValue, searchValue, goBack }) {
  const [inputvalue, handleInputValue, resetValue] = useSearch("");
  return (
    <>
      <InputContainer>
        <div className="inputFormCt">
          <FormControl sx={{ height: "100%" }} size="md">
            <Input
              value={inputvalue}
              onKeyUp={(e) => {
                e.key === "Enter" && setSearchValue(inputvalue);
              }}
              onChange={handleInputValue}
              startAdornment={<SearchIcon style={{ color: "#CECCCC" }} />}
              sx={{ borderRadius: "10px" }}
              placeholder="지식 이름을 검색해보세요"
              endAdornment={
                inputvalue !== "" ? (
                  <CloseIcon
                    onClick={() => {
                      goBack();
                      setSearchValue(null);
                      resetValue();
                    }}
                  />
                ) : (
                  <></>
                )
              }
            />
          </FormControl>
        </div>
      </InputContainer>
      {/* {searchValue !== null && searchValue !== "" && (
        <Button
          sx={{ width: "50px", borderRadius: "100px" }}
          variant="outlined"
          color="neutral"
          onClick={() => {
            goBack();
            setSearchValue(null);
            resetValue();
          }}
        >
          <KeyboardBackspaceIcon />
        </Button>
      )} */}
    </>
  );
}

const Container = styled.div`
  .css-1dimb5e-singleValue {
    overflow: unset;
  }

  width: 100%;
  margin-bottom: 15px;
`;

const SelectCt = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  /* 700임시 */
  @media (max-width: 700px) {
    margin: 10px auto;
  }
`;

const KnowTopButtonCt = styled.div`
  display: flex;
  padding: 5px;
  font-size: 16px;
  margin-bottom: 10px;

  @media (max-width: 480px) {
    margin-bottom: 0;
    font-size: 14px;
  }
`;

const KnowCountText = styled.button`
  margin-left: 10px;
  margin-top: -5px;
  margin-bottom: -5px;
  background: #19c37d;
  color: #fff;
  font-size: 14px;
  padding: 5px 17px;
  padding-right: 0;
  border-radius: 5px;
  line-height: normal;

  @media (max-width: 480px) {
    font-size: 13px;
    margin-left: auto;
    background: transparent;
    color: #000;
  }

  &:hover {
    opacity: 0.8;
  }
`;

const KnowCountNum = styled.span`
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;

  @media (max-width: 480px) {
    font-size: 14px;
  }

  &:hover {
    opacity: 0.5;
  }
`;

const Title = styled(Typography)`
  display: flex !important;
  align-items: center;

  .iconSpan {
    flex: 0 0 auto;
    width: 20px;
    height: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    background: #e3efsfe;
    border-radius: 100px;

    &.Folder {
      & svg {
        color: #ffd874;
      }
    }
    &.URL {
      & svg {
        color: #3ccde1;
      }
    }
    &.File {
      & svg {
        color: #9b41ef;
      }
    }
    &.DB {
      & svg {
        color: #e8413d;
      }
    }
  }
  .text {
    font-size: 14px;
    letter-spacing: -0.5px;
    line-height: 14px;

    max-height: 52px;
    min-height: 50px;
    margin-bottom: 2px;

    display: flex;
    align-items: center;
  }
`;

const Desc = styled(Typography)`
  word-break: break-all;
  /* font-size: 11px; */
`;

const InputContainer = styled.div`
  display: flex;
  width: 100%;
  margin: 5px 0 25px;
  & .inputFormCt {
    width: 100%;
    .MuiFormControl-root {
      flex-direction: row;
      width: 100%;
      .MuiInput-root {
        width: 100%;
      }
    }
  }
  @media (max-width: 480px) {
    margin: 5px 0;
  }
`;

const MainWrapper = styled.div`
  margin-top: -20px;
  .MuiDataGrid-cell:focus {
    outline: none;
  }
  .MuiDataGrid-virtualScrollerContent,
  .css-axafay-MuiDataGrid-virtualScroller {
    min-height: 100px !important;
  }
  .css-6xugel,
  .css-1d6wzja-MuiButton-startIcon {
    margin: 0;
  }
`;

const TableControlCt = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 5px;
`;
const DataContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const EmptyDataWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  /* justify-content: center; */

  width: 100%;
  padding: 10px 0;
  padding-left: 10px;
  background: #dcdcdc;
  border-left: 3px solid #ff892a;
  margin: 10px 0;
`;
const ItemTitleBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  cursor: pointer;

  &:hover {
    color: #2a8cff;
  }
  .descBox {
    display: flex;
    justify-content: center;
    width: 100%;
  }
`;
