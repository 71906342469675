import { ErrorCatch } from "api/ErrorCatch.js";
import { memo, useContext, useEffect, useMemo, useState } from "react";
import Select from "react-select";
import botApi from "api/botApi.js";
import { signAppContext } from "utils/providers/SignContextProvider.js";
import { chatContext } from "./providers/ChatContextProvider.js";
import { userChatBotContext } from "./providers/UserChatBotContextProvider.js";
import styled from "styled-components";

/**
 * @property
 * - externalOptions: Array<string>; -> selectBox에 사용할 추가 데이터 ( value, label 속성값은 필수 )
 *   - botUid에 "일반" 과 같이 특수 형태로 넣을 경우, 아래의 exterlnalMenus에도 값을 추가 하여야 함.
 * - externalHandleChange: Function; -> 특수 형태로 넣어진 메뉴들이 선택될 때 마다 실행할 함수.
 * */
const ChatBotSelect = (props) => {
  const { externalHandleChange, externalOptions, initialOption } = props;

  const { chatBotData, setChatBotData } = useContext(chatContext);
  const { userChatBotList } = useContext(userChatBotContext);
  const { handleSignOutApp } = useContext(signAppContext);
  const [selectedOption, setSelectedOption] = useState(
    initialOption ??
      (chatBotData
        ? {
            ...chatBotData,
            value: chatBotData?.botUid,
            label: chatBotData?.botName,
          }
        : "-")
  );

  const externalMenus = useMemo(() => ["general"], []);

  useEffect(() => {
    if (chatBotData?.botUid && selectedOption === "-") {
      handleChange(chatBotData);
    }
  }, [chatBotData, selectedOption]);

  const handleChange = async (option) => {
    const { botUid } = option;
    if (!externalMenus.includes(botUid)) {
      // 같은걸 고른거라고 판단되면 굳이 추가 로딩 하지 말자.
      if (chatBotData && chatBotData.botUid === botUid && selectedOption?.botUid === botUid) {
        console.log("same chatbot selected.");
        // return;
      }

      try {
        const { status, data } = await botApi.getBotInfoByBotUid(botUid);
        if (status === 200) {
          const botInfo = JSON.parse(data.botInfo);
          botInfo.label = botInfo.botName;
          botInfo.value = botInfo.botUid;
          setSelectedOption(botInfo);
          setChatBotData(botInfo);
          if (props?.setSelectedOption) {
            props?.setSelectedOption(botInfo);
          }
        }
      } catch (err) {
        ErrorCatch(err, handleSignOutApp);
      }
    } else {
      externalHandleChange && externalHandleChange(option);
      setSelectedOption(option);
    }
  };

  /*
  23.08.10.codelua dashboard에서 새로고침 시 발생하는 문제를 해결하기 위해 있던 코드로 App.js 전체 로직과 충돌이 있어 주석 처리.
  문의하기 일반문의 문제있다면 여기가 문제.
    useEffect(() => {
  if (!chatBotData) {
    const sessionData = sessionStorage.getItem("botData");
    if (sessionData) {
      const tmpChatBotData = JSON.parse(sessionData);
      console.log(">>>>>>>>>>> CHECK POINT 4");
      setChatBotData(tmpChatBotData);
    } else {
      setSelectedOption(externalOptions[0]);
    }
  }
  }, []);
  */

  // 0725 챗봇 이름 변경시 selectbox내 변경이 안되는 부분 수정
  useEffect(() => {
    setSelectedOption({
      ...chatBotData,
      value: chatBotData?.botUid,
      label: chatBotData?.botName,
    });
  }, [chatBotData]);

  return (
    <Container>
      {((userChatBotList && chatBotData) || externalOptions) && (
        <Select
          value={selectedOption ?? "-"}
          onChange={handleChange}
          // options={selectList}
          options={[
            ...(externalOptions ?? []),
            ...(userChatBotList ?? [])
              .filter((info) => info.status !== "00")
              .map((d) => ({
                ...d,
                value: d.botUid,
                label: d.botName,
              })),
          ]}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  .css-1dimb5e-singleValue {
    overflow: unset;
  }
`;

export default memo(ChatBotSelect);
