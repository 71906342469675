import { DASH_HOME } from "./dashboardStyle.js";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

export default function ChatDirectQna() {
  const history = useHistory();
  return (
    <DASH_HOME.Container>
      <Title>MESwiser 도입 및 기술 문의</Title>
      <ResponseWrapper>
        <ResponseCt>
          <Desc className="name">인공지능 솔루션 서비스 사업실</Desc>
          <Desc className="info">Email : sales@meswiser.ai </Desc>
          <Desc className="info">Phone : 070-4140-7725</Desc>

          <CompleteText>
            MESwiser 인공지능 챗봇의 운영과 기능에 대해 문의는
            <button
              onClick={(e) => {
                sessionStorage.setItem("DISPLAY_MENU", "QNA");
                history.push({
                  pathname: "/dashboard",
                  state: {
                    type: "QNA",
                    chatType: "general",
                  },
                });
                history.go(0);
              }}
            >
              [일반 문의하기]
            </button>{" "}
            <br />
            메뉴를 이용해 주세요.
          </CompleteText>
        </ResponseCt>
      </ResponseWrapper>

      {/* 응답 컨테이너 */}
    </DASH_HOME.Container>
  );
}

const Title = styled.p`
  color: #363636;
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.2px;
  margin-bottom: 40px;

  @media (max-width: 480px) {
    text-align: center;
  }
`;

const Desc = styled.p`
  text-align: center;
  margin: 5px;
  width: 30%;

  @media (min-width: 481px) {
    width: 100%;
    text-align: center !important;
  }

  @media (max-width: 480px) {
    width: 100%;
    font-size: 15px;
  }
  &:hover {
    color: #ff892a;
  }
  &.name {
    margin-bottom: 35px;
    font-size: 22px;
    font-weight: 600;
  }
  &.info {
    text-align: left;

    @media (max-width: 480px) {
      text-align: center;
    }
  }
`;

const CompleteText = styled.p`
  margin-top: 80px;
  //margin-bottom: 33px;

  line-height: 24px;
  font-weight: 400;

  font-size: 16px;
  color: #999;
  text-align: center;

  letter-spacing: -0.3px;

  & button {
    font-size: 16px;
    color: #ff892a;

    @media (max-width: 480px) {
      font-size: 14px;
    }
  }

  @media screen and (max-width: 600px) {
    font-size: 18px;
  }

  @media (max-width: 480px) {
    margin-top: 50px;
    font-size: 12px;
  }
`;

const ResponseWrapper = styled.div`
  /* text-align: center; */
  display: flex;
  /* justify-content: center; */
  min-height: 30%;
`;

const ResponseCt = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: #fff;

  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.11);

  border-radius: 15px;

  width: 50%;
  height: 100%;
  padding: 30px 20px;
  flex: 0 0 50%;

  @media (max-width: 480px) {
    width: 100%;
    flex: 1 0 100%;
    padding: 30px 20px;
  }
`;
