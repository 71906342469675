import { useEffect, useState } from "react";
import { useRef } from "react";
import styled from "styled-components";
import { AlertPop2 } from "./AlertPop";

export default function ProfilePop({
  setShowProfilePop,
  setBotImagePath,
  setPreviewImage,
}) {
  const refFile = useRef();
  const refPopMenu = useRef();
  const [popState, setPopState] = useState(false);
  const [popMessage, setPopMessage] = useState("");

  useEffect(() => {
    // console.log("refFile", refFile.current.value);
    const onClick = (e) => {
      if (refPopMenu.current && !refPopMenu.current.contains(e.target))
        setShowProfilePop(false);
    };
    window.addEventListener("click", onClick);
    return () => {
      window.removeEventListener("click", onClick);
    };
  }, [refPopMenu, setShowProfilePop]);

  const handleFileOnChange = async (e) => {
    e.preventDefault();
    let file = e.target.files[0];

    let reader = new FileReader();

    reader.onloadend = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const validateFile = (e) => {
    e.preventDefault();
    let file = e.target.files[0];
    // 파일 크기 제한 설정 ( 1 = 1MB )
    const maxFileSizeBytes = 1 * 1024 * 1024;

    const imageCheckArray = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      // "image/gif",
    ];
    if (!file?.type || !imageCheckArray.includes(file?.type)) {
      setPopState((prev) => !prev);
      setPopMessage("이미지 파일만 선택이 가능합니다.");
      // alert("이미지 파일만 선택이 가능합니다.");
      e.target.value = null;
      return false;
    }

    if (file.size > maxFileSizeBytes) {
      setPopState((prev) => !prev);
      setPopMessage("파일의 크기가 1MB를 초과하였습니다.");
      // alert("파일의 크기가 1MB를 초과하였습니다.");
      e.target.value = null;
      return false;
    }

    return true;
  };

  return (
    <Wrapper ref={refPopMenu}>
      <Items>
        <Item>
          <button
            className="menu"
            onClick={(e) => {
              refFile.current.click();
            }}
          >
            사진 선택
          </button>
          <input
            type="file"
            className="file-upload"
            id="fileupload"
            accept=".png, .jpg"
            ref={refFile}
            onChange={(e) => {
              if (validateFile(e)) {
                handleFileOnChange(e);
                setBotImagePath(Array.from(e.target.files)[0]);
              }
            }}
            style={{ display: "none" }}
          />
        </Item>
        <Item>
          <button
            className="menu"
            onClick={(e) => {
              e.preventDefault();
              setPreviewImage(null);
              setBotImagePath(null);
            }}
          >
            사진 삭제
          </button>
        </Item>
      </Items>
      {popState && (
        <AlertPop2
          popState={popState}
          setPopState={setPopState}
          text={popMessage}
        />
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: block;
  /* position: fixed; */
  /* left: 17%; */
  position: absolute;
  /* width: 90%;
  bottom: -30px;
  right: -90%;
  z-index: 99; */
  left: 65%;
  top: 65%;
  width: 100px;
  z-index: 1;

  background-color: #fff;
`;

const Items = styled.ul`
  border: 1px solid #dcdcdc;
`;

const Item = styled.li`
  padding: 10px;

  &:hover {
    background: #dcdcdc;
  }

  :not(:first-child) {
    border-top: 1px solid #dcdcdc;
  }

  @media (max-width: 480px) {
    padding: 5px;
  }
`;
