/* eslint-disable */
import NormalButton from "components/atomic/NormalButton2";
import styled, { keyframes } from "styled-components";
import ProfilePop from "../popup/ProfilePop";
import camera_icon from "assets/images/icons/icon_camera.svg";
import user_icon from "assets/images/icons/user_icon.svg";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import InputBox from "components/atomic/InputBox";
import Select from "react-select";
import TextArea from "components/atomic/TextArea";
import { isMobileContext } from "utils/providers/BrowserEnvContextProvider";
import RangeSlider from "react-range-slider-input";
import uuid from "react-uuid";

import iconTopArrow from "../../../assets/images/icon_topArrow.svg";
import ManageExQCt from "components/organisms/ManageExQCt";

export default function BotInfoTable({
  botData,
  modifyFunc,
  deleteFunc,
  exList,
  setExList,
  setPopMessage,
  setPopState,
  setPopStateExpire,
}) {
  const [botName, setBotName] = useState("");
  const [excludeUrl, setExcludeUrl] = useState("");
  const [greetingMessage, setGreetingMessage] = useState("");
  // 소개
  const [introType, setIntroType] = useState("");
  const [introIn, setIntroIn] = useState("");
  const [introOut, setIntroOut] = useState("");

  // 답변 출처 기능
  const [showSource, setShowSource] = useState(false);

  // 연관질문 사용 여부
  const [useRsearch, setUseRsearch] = useState(false);
  const [showRsearch, setShowRsearch] = useState(false);

  // 예시
  const [exqType, setExqType] = useState("");
  const [exqText, setExqText] = useState("");
  const [exqOut, setExqOut] = useState("");

  const [botRole, setBotRole] = useState("");
  const [botImagePath, setBotImagePath] = useState("");
  const { isMobile } = useContext(isMobileContext);
  const [botRatioVal, setBotRatioVal] = useState([0, 20]);

  const [moreBtnState, setMoreBtnState] = useState(true);

  const [expireDateState, setExpireDateState] = useState(false);
  const [toolTip, setTooltip] = useState(false);

  // slider
  const RefRange = useRef("");
  const RefMin01 = useRef();
  const RefMax01 = useRef();

  const questionRef = useRef();

  useEffect(() => {
    if (exList.length === 0) return;
    console.log(">>> effect exList: ", exList);
  }, [exList]);

  const addExamplList = (e) => {
    if (exqText.trim(" ") === "") {
      setPopMessage("예시 질문을 입력해주세요.");
      setPopState((prev) => !prev);
      return;
    }
    if (exList.some((item) => item.exData === exqText)) {
      setPopMessage("동일한 예시 질문은 등록이 불가합니다.");
      setPopState((prev) => !prev);
      return;
    }

    setExList((prevList) => [
      ...prevList,
      {
        exUid: uuid(),
        botUid: botData.botUid,
        exData: exqText,
        seq: prevList.length + 1,
      },
    ]);
    setExqText("");
  };

  // tabState handler
  const handleTabState = (e) => {
    e.target.name === "introText"
      ? setIntroType(e.target.id)
      : setExqType(e.target.id);
  };

  // 질문 더보기 handler
  const handleMoreBtn = (e) => {
    setMoreBtnState((prev) => !prev);
  };

  useEffect(() => {
    if (!questionRef) return;
    if (moreBtnState) {
      questionRef.current.style.visibility = "visible";
      questionRef.current.style.opacity = "1";
      questionRef.current.style.height = "auto";
      questionRef.current.style.maxHeight = "300px";
    } else {
      questionRef.current.style.visibility = "hidden";
      questionRef.current.style.opacity = "0";
      questionRef.current.style.height = "0px";
    }
  }, [moreBtnState]);

  const remaining = () => {
    // let num = botData.totalCount - botData.useCount;
    let num = botData.useCount;

    if (isNaN(num)) return 0;

    if (num.toString().length > 3) {
      num = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return num < 0 ? 0 : num;
  };

  const usesSizeInfo = () => {
    if (!botData.totalUsageValue) return `새로고침 필요`;
    var totalUsageString = botData.totalUsageValue.toString();
    var strSizeInfo = `${totalUsageString.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ","
    )} byte`;

    // Byte to KB
    if (botData.totalUsageValue >= 1024) {
      const totalUsageKB = (botData.totalUsageValue / 1024).toFixed(2);
      strSizeInfo = `${totalUsageKB.replace(/\B(?=(\d{3})+(?!\d))/g, ",")} KB`;
    }

    // Byte to MB
    if (botData.totalUsageValue >= 1024 * 1024) {
      const totalUsageMB = (botData.totalUsageValue / (1024 * 1024)).toFixed(2);
      strSizeInfo = `${totalUsageMB.replace(/\B(?=(\d{3})+(?!\d))/g, ",")} MB`;
    }

    if (botData.serviceSize) {
      const maxSize = botData.serviceSize * 1024 * 1024;
      const currSize = Number(botData.totalUsageValue);
      const avg = (currSize / maxSize) * 100;

      if (!isMobile) {
        strSizeInfo = strSizeInfo + `<br/> (${avg.toFixed(2)}%)`;
      } else {
        strSizeInfo = strSizeInfo + ` (${avg.toFixed(2)}%)`;
      }
    }
    return strSizeInfo;
  };

  const botServiceModel = () => {
    return botData.serviceModel === "1" ? "유료" : "무료";
  };

  //유료서비스 변경 함수
  const serviceUpgrade = () => {
    //과금버튼 callbackFunc
  };

  //챗봇 새창열기
  const openChatbot = () => {
    if (botData.dataType === 10) {
      setPopState((prev) => !prev);
      setPopMessage("지식이 없습니다. 지식관리를 이용해주세요");
      return;
    }

    window.open(`/chat/${botData.botUid}`);
  };

  const beforeDeleteFunc = () => {
    if (confirm("삭제 하시겠습니까?")) deleteFunc && deleteFunc();
  };

  // 챗봇명 200자 제한
  const checkBotName = (type) => {
    if (type === 0) return !((botName?.trim()?.length ?? 0) === 0);
    else return !((botName?.trim()?.length ?? 0) > 200);
  };

  // 챗봇 환영인사 200자 제한
  const checkGreetingMessage = (type) => {
    if (type === 0) return !((greetingMessage?.trim()?.length ?? 0) === 0);
    else return !((greetingMessage?.trim()?.length ?? 0) > 200);
  };

  // 챗봇 내부 소개 1000자 제한
  const checkIntroInText = (type) => {
    if (type === 0) return !((introIn?.trim()?.length ?? 0) === 0);
    else return !((introIn?.length ?? 0) > 1000);
  };

  // 챗봇 외부 소개 페이지 URL 255자 제한
  const checkIntroOutText = (type) => {
    if (type === 0) return !((introOut?.trim()?.length ?? 0) === 0);
    else return !((introOut?.length ?? 0) > 255);
  };

  // 챗봇 예시 질문 내부 ()

  // 챗봇 예시 질문 외부 페이지 ()
  const checkExqOut = (type) => {
    if (type === 0) return !((exqOut?.trim()?.length ?? 0) === 0);
    else return !((exqOut?.length ?? 0) > 255);
  };

  const beforeModifyFunc = () => {
    const urlRegex =
      /^(?:(http:\/\/|https:\/\/))?((?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,})(\/.*)?$/;

    if (!checkBotName(0)) {
      setPopState((prev) => !prev);
      setPopMessage("챗봇 명칭을 입력해 주세요.");
      return;
    }

    if (!checkBotName(1)) {
      setPopState((prev) => !prev);
      setPopMessage("챗봇이름은 200자까지 입력 가능합니다.");
      return;
    }

    if (!checkGreetingMessage(0)) {
      setPopState((prev) => !prev);
      setPopMessage("환영인사 메세지를 작성해주세요.");
      return;
    }

    if (!checkGreetingMessage(1)) {
      setPopState((prev) => !prev);
      setPopMessage("환영인사 메세지는 최대 200자 입니다.");
      return;
    }

    // 소개에 대한 처리.
    if (introType === "in") {
      // 내부소개를 안하고 싶을 수도 있으니 무조건 채우지 않게 하자.
      // if (!checkIntroInText(0)) {
      //   setPopState((prev) => !prev);
      //   setPopMessage("내부 소개를 작성해주세요.");
      //   return;
      // }

      // 작성을했다면 400자를 못넘게 하자.
      if (!checkIntroInText(1)) {
        setPopState((prev) => !prev);
        setPopMessage("내부 소개는 최대 1000자 입니다.");
        return;
      }
    } else {
      // 외부 소개 페이지.
      if (!checkIntroOutText(0)) {
        setPopState((prev) => !prev);
        setPopMessage("소개 외부페이지 URL을 작성해주세요.");
        return;
      }

      if (!urlRegex.test(introOut)) {
        setPopMessage("소개 외부페이지는 URL형식만 작성가능합니다!");
        setPopState((prev) => !prev);
        return;
      }

      if (!checkIntroOutText(1)) {
        setPopState((prev) => !prev);
        setPopMessage("소개 외부페이지 URL은 최대 255자 입니다.");
        return;
      }
    }

    //예시 질문에 대한 처리
    if (exqType === "exIn") {
      // 내부일 경우에는 일단 아무것도 체크하지 않는다.
      // 개수에 대한 한계도 정하지 않았으니 일단 놔둔다.
    } else {
      if (!checkExqOut(0)) {
        setPopState((prev) => !prev);
        setPopMessage("예시질문 외부페이지 URL을 작성해주세요.");
        return;
      }

      if (!urlRegex.test(exqOut)) {
        setPopMessage("예시질문 외부페이지는 URL형식만 작성가능합니다!");
        setPopState((prev) => !prev);
        return;
      }

      if (!checkExqOut(1)) {
        setPopState((prev) => !prev);
        setPopMessage("예시질문 외부페이지 URL은 최대 255자 입니다.");
        return;
      }
    }

    //

    if (confirm("수정 하시겠습니까?")) {
      const ratio = (100 - RefRange.current.value.max) / 100;
      let botInfoParams = {
        botUid: botData.botUid,
        botName,
        excludeUrl: excludeUrl ? excludeUrl.trim().replace(/\n/gi, ",") : "",
        greetingMessage,
        botRatio: ratio,
        botRole,
        showSource: showSource ? "Y" : "N",
        useRsearch: useRsearch ? "Y" : "N",
        botImagePath,
        introType,
        introIn,
        introOut,
        exqType,
        exqOut,
      };

      modifyFunc && modifyFunc(botInfoParams, exList);
    }
  };

  const handleExpireBtn = () => {
    setPopState((prev) => !prev);
    setPopStateExpire((prev) => !prev);
    setPopMessage(
      !isMobile
        ? `요금제 만료일 이후에 유료 모델 사용을 유지하시려면<br/> 아래의 연장하기 버튼을 눌러주시기 바랍니다.<br/> 연장 신청 후 담당자가 연장 관련 안내를 드릴 예정입니다.<br/> 나의 연장 문의는 [문의하기]로 자동 등록돼<br/> 좌측의 문의하기 메뉴에서도 확인하실 수 있습니다.`
        : `요금제 만료일 이후에 유료 모델 사용을 유지하시려면 아래의 연장하기 버튼을 눌러주시기 바랍니다. 연장 신청 후 담당자가 연장 관련 안내를 드릴 예정입니다. 나의 연장 문의는 [문의하기]로 자동 등록돼 좌측의 문의하기 메뉴에서도 확인하실 수 있습니다.`
    );
  };

  useEffect(() => {
    //수정 모드에서 셀렉트 박스로 봇 변경 시 정보 변경을 위함
    setBotName(botData.botName);
    setExcludeUrl(botData.excludeUrl);
    setGreetingMessage(botData.greetingMessage);
    setIntroType(botData.introType ?? "in");
    setIntroIn(botData.introIn ?? "");
    setIntroOut(botData.introOut ?? "");
    setExqType(botData.exqType ?? "exIn");
    //setExqType("exOut");
    setExqOut(botData.exqOut ?? "");
    RefMax01.current.innerText = 100 - (botData.botRatio ?? 0.2) * 100;
    RefMin01.current.innerText = 100 - RefMax01.current.innerText;
    setBotRatioVal([0, RefMax01.current.innerText]);
    setBotRole(botData.botRole);
    if (botData.showSource) {
      setShowSource(botData.showSource === "Y" ? true : false);
    } else {
      setShowSource(false);
    }

    if (botData.useRsearch) {
      setUseRsearch(botData.useRsearch === "Y" ? true : false);
    } else {
      setUseRsearch(false);
    }

    if (botData.createAt) {
      // botData.createAt : 23-11-08 09:49 그러니 20을 일단 더해준다.
      const strTmp = "20" + botData.createAt; // ex) 2023-11-08 09:49
      // 7일 이내의 경우 연장 버튼을 활성화 시켜준다.
      const strDate = new Date(strTmp);
      const baseDate = new Date("2023-11-20 00:00");
      setShowRsearch(strDate > baseDate);
    } else {
      setShowRsearch(false);
    }

    setBotImagePath(botData.botImagePath);
    if (botData.expireDate) {
      // botData.expireDate : 23-11-08 그러니 20을 일단 더해준다.
      const strTmp = "20" + botData.expireDate; // ex) 2023-11-08
      // 7일 이내의 경우 연장 버튼을 활성화 시켜준다.
      const strDate = new Date(strTmp);
      const today = new Date();
      const timeDiff = strDate - today;
      const daysRemain = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
      if (daysRemain <= 7) {
        setExpireDateState(true);
      } else {
        setExpireDateState(false);
      }
    } else {
      setExpireDateState(false);
    }
  }, [botData]);

  return (
    <TableCt>
      <div style={{ textAlign: "center" }}>
        {/* 데이터 컨테이너 */}
        <BotDataCt>
          <BotDataTag>
            <p className="title">질문 사용개수</p>
            <p className="number">
              {remaining()} /{" "}
              {String(botData.totalCount).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </p>
          </BotDataTag>
          <BotDataTag className="Volume">
            <p className="title">지식 사용량</p>
            <div className="number usesSize">
              {/* {usesSizeInfo()} */}
              <div dangerouslySetInnerHTML={{ __html: usesSizeInfo() }}></div>
            </div>
          </BotDataTag>
          <BotDataTag>
            <p className="title">봇 생성일</p>
            <p className="number">{botData.createAt}</p>
          </BotDataTag>

          <BotDataTag>
            <p className="title">멤버십</p>
            <p className="number text">{botServiceModel()}</p>
          </BotDataTag>

          <BotDataTag className="expire">
            <p className="title">
              요금제 만료일
              {expireDateState && (
                <i
                  className={
                    toolTip
                      ? "ri-error-warning-line icon active"
                      : "ri-error-warning-line icon "
                  }
                  onMouseOver={() => {
                    setTooltip((prev) => !prev);
                  }}
                  onMouseOut={() => {
                    setTooltip((prev) => !prev);
                  }}
                >
                  {!isMobile && (
                    <span className="expireTooltip">
                      요금제 만료일 경과 시, <br /> 유료 모델의 기능 사용이
                      제한됩니다.
                    </span>
                  )}
                </i>
              )}
            </p>
            <div className="expireCt">
              <p className="number text">
                {botData.serviceModel === "0"
                  ? "유료버전 미사용"
                  : botData.expireDate
                  ? botData.expireDate
                  : "무기한"}
              </p>
              {expireDateState && (
                <button
                  className="expireBtn"
                  onClick={() => {
                    handleExpireBtn();
                  }}
                >
                  연장
                </button>
              )}
            </div>
          </BotDataTag>
        </BotDataCt>

        {/* 버튼컨테이너 */}
        <BtnCt>
          <NormalButton
            buttonId="loginBtn"
            buttonTitle={
              <>
                <span></span> 챗봇 실행
              </>
            }
            buttonClass="round cOrange mid"
            callBackFunc={openChatbot}
          />
        </BtnCt>

        {/* 챗봇 설정 컨테이너 */}
      </div>

      <BotModifyCt>
        {/* 챗봇 이름 이미지 인풋 */}

        <BotinfoCt>
          <BotInfoTitle>
            챗봇이름<span>*</span>
          </BotInfoTitle>
          <BotInfoRow>
            <InputForm
              target="img"
              value={botImagePath}
              setValue={setBotImagePath}
            />
            <InputForm
              inputclass={"modify"}
              target="name"
              value={botName}
              setValue={setBotName}
            />
          </BotInfoRow>
          <BotInfoDesc>
            이미지 형식: jpg, png{isMobile && <br />} 최대 1MB, 가로 최대 500px,
            세로 최대 500px
          </BotInfoDesc>
        </BotinfoCt>

        <BotinfoCt>
          <BotInfoTitle>
            챗봇 인사말<span>*</span>
          </BotInfoTitle>
          <BotInfoRow>
            <InputForm
              inputclass={"modify"}
              target="greet"
              value={greetingMessage}
              setValue={setGreetingMessage}
            />
          </BotInfoRow>
        </BotinfoCt>

        <BotinfoCt>
          <BotInfoRow className="searchOtp">
            <BotInfoTitle>검색 조건</BotInfoTitle>
            <SearchOpCt
              style={
                !isMobile
                  ? { width: "50%", textAlign: "left" }
                  : { width: "100%" }
              }
            >
              <div className="optionValueCt">
                <p className="minValueTitle">
                  키워드{" "}
                  <span ref={RefMin01} className="minValue">
                    20
                  </span>
                </p>

                <p className="maxValueTitle">
                  시맨틱{" "}
                  <span ref={RefMax01} className="maxValue">
                    80
                  </span>
                </p>
              </div>
              <RangeSlider
                ref={RefRange}
                onInput={(value) => {
                  RefMin01.current.innerText = 100 - RefRange.current.value.max;
                  RefMax01.current.innerText = RefRange.current.value.max;
                  setBotRatioVal(0, RefMax01.current.innerText);
                }}
                thumbsDisabled={[true, false]}
                rangeSlideDisabled={true}
                defaultValue={[0, 20]}
                value={botRatioVal}
                min={0}
                max={100}
                step={10}
              />
            </SearchOpCt>
          </BotInfoRow>
        </BotinfoCt>

        <BotinfoCt>
          <BotInfoRow className="responseStyle">
            <BotInfoTitle>
              응답스타일<span>*</span>
            </BotInfoTitle>
            <InputForm target="role" value={botRole} setValue={setBotRole} />
          </BotInfoRow>
        </BotinfoCt>

        {/* 개발중 기능 시작 */}

        <BotinfoCt>
          <BotInfoRow className="responseStyle">
            <BotInfoTitle>답변 참고자료 표기</BotInfoTitle>
            <div
              id="showSource"
              className={showSource ? "toggle active" : "toggle"}
              onClick={() => {
                setShowSource((prev) => !prev);
              }}
            >
              <div className="circle" id="showSource"></div>
            </div>
          </BotInfoRow>
        </BotinfoCt>

        {showRsearch && (
          <BotinfoCt>
            <BotInfoRow className="responseStyle">
              <BotInfoTitle>연관질문 사용하기</BotInfoTitle>
              <div
                id="useRsearch"
                className={useRsearch ? "toggle active" : "toggle"}
                onClick={() => {
                  setUseRsearch((prev) => !prev);
                }}
              >
                <div className="circle" id="useRsearch"></div>
              </div>
            </BotInfoRow>
          </BotinfoCt>
        )}

        {/* 개발중 기능 종료 */}

        {/* 소개 문구 추가 */}
        <BotinfoCt>
          <BotInfoTitle>
            소개<span></span>
            <span className="inputCt">
              <input
                type="radio"
                id="in"
                name="introText"
                value={"introIn"}
                onChange={handleTabState}
                checked={introType === "in"}
              />
              <label htmlFor="in">채팅창 내부</label>
              <input
                type="radio"
                id="out"
                name="introText"
                value={"introOut"}
                onChange={handleTabState}
                checked={introType === "out"}
              />
              <label htmlFor="out">외부 URL</label>
            </span>
          </BotInfoTitle>

          <BotInfoRow
            className={introType === "in" ? "introTab active" : "introTab"}
          >
            <InputForm
              inputclass={"modify"}
              target="formIntroIn"
              value={introIn}
              setValue={setIntroIn}
            />
          </BotInfoRow>
          <BotInfoRow
            className={introType === "out" ? "introTab active" : "introTab"}
          >
            <InputForm
              inputclass={"modify"}
              target="formIntroOut"
              value={introOut}
              setValue={setIntroOut}
            />
          </BotInfoRow>
        </BotinfoCt>
        {/* 소개 질문 추가 */}

        {/* 예시 질문 추가 */}
        <BotinfoCt>
          <BotInfoTitle>
            예시 질문<span></span>
            <span className="inputCt">
              <input
                type="radio"
                id="exIn"
                name="ExText"
                value={"exIn"}
                onChange={handleTabState}
                checked={exqType === "exIn"}
              />
              <label htmlFor="exIn">채팅창 내부</label>
              <input
                type="radio"
                id="exOut"
                name="ExText"
                value={"exOut"}
                onChange={handleTabState}
                checked={exqType === "exOut"}
              />
              <label htmlFor="exOut">외부 URL</label>
            </span>
          </BotInfoTitle>

          <BotInfoRow
            className={exqType === "exIn" ? "introTab active" : "introTab"}
          >
            <div className="inputCt">
              <form className="inputCt">
                <InputForm
                  inputclass={"modify"}
                  target="formExampleInput"
                  value={exqText}
                  setValue={setExqText}
                />
                <NormalButton
                  style={{ margin: "0" }}
                  buttonClass={"mid cGreen"}
                  buttonTitle={"추가"}
                  callBackFunc={addExamplList}
                />
              </form>
            </div>
            <BotInfoTitle className="exQ">
              예시 질문 목록{" "}
              {exList.length > 0 && (
                <button
                  onClick={handleMoreBtn}
                  className={moreBtnState ? "moreBtn" : "active moreBtn"}
                >
                  <img src={iconTopArrow} alt={"더보기 아이콘"}></img> 목록 보기
                </button>
              )}
            </BotInfoTitle>
            <BotInfoRow>
              {exList.length > 0 ? (
                <>
                  <div
                    ref={questionRef}
                    className="inputCt"
                    style={{
                      flexDirection: "column",
                      padding: "5px 5px",
                      boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.1)",
                    }}
                  >
                    {exList.map((data, idx) => {
                      return (
                        // 예시질문 넘포넌트
                        <ManageExQCt
                          key={idx}
                          data={data}
                          idx={idx}
                          setExList={setExList}
                          exList={exList}
                        />
                      );
                    })}
                  </div>
                </>
              ) : (
                <BotInfoDesc ref={questionRef}>
                  추가된 예시 질문이 없습니다. 예시 질문을 추가해보세요
                </BotInfoDesc>
              )}
            </BotInfoRow>
          </BotInfoRow>
          <BotInfoRow
            className={exqType === "exOut" ? "introTab active" : "introTab"}
          >
            <InputForm
              inputclass={"modify"}
              target="formExampleExtInput"
              value={exqOut}
              setValue={setExqOut}
            />
          </BotInfoRow>
        </BotinfoCt>
        {/* 예시 질문 추가 */}

        <BtnCt>
          <BotinfoCt>
            <NormalButton
              buttonId="loginBtn"
              buttonTitle={"삭제"}
              buttonClass="round cDel mid"
              callBackFunc={beforeDeleteFunc}
            />

            <NormalButton
              buttonId="loginBtn"
              buttonTitle={
                <>
                  <span></span> 설정 변경
                </>
              }
              buttonClass="round cOrange mid"
              callBackFunc={beforeModifyFunc}
            />
          </BotinfoCt>
        </BtnCt>
      </BotModifyCt>
    </TableCt>
  );
}

export function InputForm({ target, value, setValue }) {
  if (target === "img") {
    const [showProfilePop, setShowProfilePop] = useState(false);
    const [previewImage, setPreviewImage] = useState("");

    useEffect(() => {
      setShowProfilePop(false);
    }, [value]);

    const IMAGE_PATH = process.env.REACT_APP_RESOURCE_HOST + "/images/bot/";
    return (
      <Profile>
        <Thumb>
          <img
            src={
              previewImage
                ? previewImage
                : value
                ? IMAGE_PATH + value
                : user_icon
            }
            alt=""
          />
        </Thumb>
        {showProfilePop && (
          <ProfilePop
            setShowProfilePop={setShowProfilePop}
            setPreviewImage={setPreviewImage}
            setBotImagePath={setValue}
          />
        )}
        <Modify
          imrSrc={camera_icon}
          onClick={(e) => setShowProfilePop(!showProfilePop)}
        ></Modify>
      </Profile>
    );
  } else if (target === "name") {
    return (
      <InputBox
        inputId={`manage_${target}`}
        value={value}
        setValue={setValue}
        placeholderText={"최대20자까지 입력 가능합니다."}
        style={{ width: "100%" }}
        inputclass={"botTable"}
      />
    );
  } else if (target === "greet") {
    return (
      <TextArea
        textId={`manage_${target}`}
        value={value}
        setValue={setValue}
        style={{ width: "80%", borderRadius: "10px" }}
        disabled={false}
        placeholderText={"채팅방 시작 시 환영 문구 입니다. (최대 200자)"}
        textClass={"manage"}
      />
    );
  } else if (target === "role") {
    const select = {
      label:
        value === 0
          ? "친절"
          : value === 1
          ? "귀여움"
          : value === 2
          ? "유머러스"
          : "친절",
      value: value ? value : 0,
    };

    const selectList = [
      { label: "친절", value: 0 },
      { label: "귀여움", value: 1 },
      { label: "유머러스", value: 2 },
    ];

    const handleChange = (event) => {
      setValue(event.value);
    };

    return (
      <div style={{ width: "100%", maxWidth: "488px" }}>
        <Select
          value={select}
          // defaultInputValue={select}
          defaultValue={select}
          onChange={handleChange}
          options={selectList}
        />
      </div>
    );
  } else if (target === "excUrl") {
    // 해당은 제외URL인데 초기버전에서는 사용하지 않기에 따로 구현하지 않음. 23.07.25.codelua.
  } else if (target === "formIntroIn") {
    return (
      <TextArea
        textId={`manage_${target}`}
        value={value}
        setValue={setValue}
        style={{ width: "80%", borderRadius: "10px" }}
        disabled={false}
        placeholderText={"봇에 대한 소개를 작성해주세요. (최대 1000자)"}
        textClass={"manage"}
      />
    );
  } else if (target === "formIntroOut") {
    return (
      <InputBox
        inputId={`manage_${target}`}
        value={value}
        setValue={setValue}
        placeholderText={"URL을 작성해주세요."}
        style={{ width: "100%" }}
        inputclass={"botTable"}
      />
    );
  } else if (target === "formExampleInput") {
    return (
      <InputBox
        inputId={`manage_${target}`}
        value={value}
        setValue={setValue}
        placeholderText={"예시 질문을 입력해주세요"}
        style={{ width: "35%", flexGrow: "0" }}
        inputclass={"botTable addUser"}
      />
    );
  } else if (target === "formExampleExtInput") {
    return (
      <InputBox
        inputId={`manage_${target}`}
        value={value}
        setValue={setValue}
        placeholderText={"URL을 작성해주세요."}
        style={{ width: "100%" }}
        inputclass={"botTable"}
      />
    );
  }
}

const BotModifyCt = styled.div`
  border-radius: 10px;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.16);
  background: #fff;
  padding: 22.5px;
  padding-top: 33px;
  text-align: center;
  margin-top: 15px;

  button {
    &.change {
      & span {
        display: inline-block;
        width: 28px;
        @media (max-width: 480px) {
          width: 16px;
        }
      }
    }
  }
`;

const BotinfoCt = styled.div`
  margin-bottom: 36px;
`;

const BotInfoTitle = styled.p`
  color: #363636;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 28.5px;
  text-align: left;

  & .inputCt {
    margin-top: 10px;
    display: block;
    color: #888;
    & label {
      font-weight: 400;
      font-size: 14px;
      margin-right: 5px;
    }
  }

  & span {
    color: #ff6161;
    font-size: 16px;
  }

  &.exQ {
    font-weight: 400;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
  }

  & button.moreBtn {
    display: inline-flex;
    margin-left: auto;
    align-items: center;
    & img {
      transform: rotate(180deg);
      width: 20px;
      height: 20px;
      margin-right: 5px;
      transition: 0.3s;
    }

    &.active {
      & img {
        transform: rotate(0deg);
      }
    }
  }

  @media (max-width: 480px) {
    font-size: 14px;
    margin-bottom: 14px;
    & span {
      font-size: 14px;
    }
  }
`;
const BotInfoDesc = styled.p`
  color: #a6a6a6;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.14px;
  text-align: left;

  @media (max-width: 480px) {
    color: #a6a6a6;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.12px;
  }
`;

const BotInfoRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  &.introTab {
    display: none;
    &.active {
      display: block;
    }
  }

  &.searchOtp {
    flex-direction: column;
    align-items: start;
  }
  &.responseStyle {
    justify-content: space-between;
    align-items: start;
    flex-direction: column;
    margin-bottom: 50px;
    @media (max-width: 480px) {
      flex-direction: column;
      align-items: start;
      margin-bottom: 50px;
    }

    & p {
      margin: 0;
      margin-bottom: 28.5px;
      @media (max-width: 480px) {
        margin-bottom: 14px;
      }
    }
  }

  &.question {
    justify-content: space-around;

    border: 1px solid #dcdcdc;
    padding: 10px;

    /* margin-right: 10px; */
  }

  & p.exText {
    word-break: break-all;
    margin-right: 10px;
    text-align: left;
    width: 100%;
  }

  & form.inputCt,
  div.inputCt {
    /* &.under {
      visibility: hidden;
      opacity: 0;
      height: 0px;
      overflow-y: auto;
    } */
    display: flex;
    width: 100%;
    margin-bottom: 25px;
    transition: 0.3s;

    input {
      margin-right: 10px;
      flex: 0 1 auto;
    }
    button {
    }
  }

  & input {
    border-radius: 100px;

    &::placeholder {
      color: #a6a6a6;
      font-size: 16px;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.16px;
      @media (max-width: 480px) {
        font-size: 13px;
      }
    }
  }

  & input,
  textarea {
    background: #f3f3f3;

    &::placeholder {
      color: #a6a6a6;
      font-size: 16px;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.16px;

      @media (max-width: 480px) {
        font-size: 13px;
      }
    }
  }

  & div.btnCt {
    display: flex;
    width: fit-content;

    button {
      margin-left: 5px;
      font-size: 14px;
      line-height: normal;
      height: 30px;
      padding: 5px 10px;
      width: 50px;
    }
  }

  .toggle {
    display: inline-block;
    width: 40px;
    height: 20px;
    background: #ffe7ca;
    border-radius: 20px;
    position: relative;
    cursor: pointer;
    transition: 0.3s;
    &.active {
      background-color: #b9f4bb;
    }
  }

  .circle {
    width: 20px;
    height: 20px;
    background: #f8b400;
    border-radius: 50%;
    position: absolute;
    /* top: 50%; */
    top: 0%;
    transition: transform 0.3s, background 0.3s;
  }

  /* 활성화 스타일 */
  .active .circle {
    /* top: 50%; */
    transform: translateX(25px) translateY(0%);
    background: #4caf50; /* 색상 변경 */
    box-shadow: 0px 1px 9px 1px rgba(0, 0, 0, 0.11);
  }
`;

const TableCt = styled.div`
  @media (max-width: 700px) {
    width: 100%;
  }
  display: block;
  width: 70%;

  & table {
    width: 80%;
    min-height: 350px;
    height: auto;
    width: 100%;
    margin-top: 64px;
    border-radius: 20px;
    background: #fff;
    box-shadow: 0px 1px 9px 0px rgba(0, 0, 0, 0.22);

    & tbody.modify {
      & td {
        padding-right: 20px !important;
      }
    }

    & td {
      height: 80px;
      padding-right: 5px !important;
      word-break: keep-all;
      letter-spacing: -1px;

      &.crawlTd {
        & button.crawlUrlBtn {
          margin: 0 !important;
          width: auto !important;
          font-size: 14px;
          padding: 5px 10px !important;
        }
      }

      .chargeBtnCt {
        display: flex;
        align-items: center;
      }

      .chargeConsultBtn {
        border-radius: 15px;
        margin: 0;
        width: auto;
        font-size: 14px;
        padding: 5px 5px;
        color: #fff;
        background: #ffca36;
        margin-left: 20px;
        margin-right: 10px;
        font-weight: 500;

        &:hover {
          background: #ffe618;
          color: #6a6a6a;
        }
      }
    }

    @media (max-width: 480px) {
      min-height: auto;

      td {
        padding: 30px 15px;

        &.number {
          font-weight: 600;
        }
      }
    }

    & tbody.modify {
      tr {
      }

      .textTd {
        color: #363636;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        padding-top: 50px;
        padding-bottom: 20px;
      }

      .inputTd.avata {
        display: flex;
        align-items: center;

        .desc {
          margin-left: 55px;

          p {
            color: #a6a6a6;
            font-size: 14px;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.14px;
          }
        }
      }

      .inputTd.avata.mobileType {
        .desc {
          margin-top: 15px;
          margin-left: 0;
          padding-right: 15px;
          letter-spacing: -1px;
          word-break: keep-all;
        }
      }
    }

    & tr {
      height: auto;

      & td {
        padding: 0 20px;
        vertical-align: middle;

        :last-child {
          &input {
            margin-left: 20px;
          }
        }
      }
    }
  }
`;

const BotDataCt = styled.div`
  display: flex;
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.18);
  margin-bottom: 20px;

  flex-wrap: wrap;

  @media (max-width: 480px) {
    border-radius: 10px;
  }
`;

const BotDataTag = styled.div`
  flex: 1 0 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-height: 150px;
  padding: 15px 1vw;

  &.expire {
    position: relative;
    p.title {
      position: relative;
    }
  }

  &:not(:last-child) {
    border-right: 1px solid #dcdcdc;
  }

  p {
    text-align: center;
    &.title {
      font-weight: 500;
      line-height: 15px;
      letter-spacing: -0.5px;
      font-size: 13px;
      margin: auto 0;
      word-break: keep-all;
      & .icon {
        position: absolute;
        bottom: 0;
        color: tomato;
        font-size: 16px;
        cursor: pointer;
        & .expireTooltip {
          display: none;
        }
        &.active {
          display: inline-block;
          & .expireTooltip {
            position: absolute;
            /* background: linear-gradient(135deg, #ff892a, #ffc51e); */
            /* background: tomato; */
            background: rgba(25, 195, 125, 0.9);
            border-radius: 5px;
            top: 0%;
            right: 130%;
            display: block;
            width: 220px;
            font-size: 13px;
            letter-spacing: -1px;
            line-height: 15px;
            word-break: keep-all;
            padding: 10px;
            color: #000;
            /* color: tomato; */
            font-weight: 600;
            &:after {
              content: "";
              width: 10px;
              height: 10px;
              position: absolute;
              top: 0;
              right: 0;
              /* border: 1px solid tomato; */
              /* background: tomato; */
              background: #19c37d;
              transform: rotate(45deg) translateX(50%);
              /* border-left: 1px solid #777; */
              /* border-right: 1px solid #777; */
              /* border-bottom: 1px solid #777; */
            }
          }
        }
      }
    }
  }

  div.number,
  p.number {
    color: #232323;
    font-size: 14px;
    letter-spacing: -0.5px;
    font-weight: 700;
    height: 50px;
    max-width: 115px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 480px) {
      word-break: keep-all;
      padding: 0 15px;
    }
    &.usesSize {
      max-width: 100%;
    }
    &.text {
      color: #ff892a;
    }
    &.wide {
      color: #ff892a;
      max-width: 145px;
    }
  }

  .expireCt {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    .text {
      word-break: keep-all;
    }
  }

  .expireBtn {
    width: 40px;
    height: 20px;

    background: tomato;
    color: #fff;
    font-weight: 700;
    border-radius: 30px;
    font-size: 12px;
    letter-spacing: -1px;
    margin-left: 5px;
    line-height: 10px;

    &:hover {
      background: #19c37d;
      color: #fff;
    }

    @media (max-width: 480px) {
      position: unset;
      margin-left: 10px;
    }
  }

  @media (max-width: 480px) {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    /* order: 2; */
    flex: 1 0 55%;
    padding: 7.5px 10px;

    p.title {
      width: 40%;
    }
    div.number,
    p.number {
      flex: 0 0 auto;
      height: 15px;
      font-size: 13px !important;
      max-width: unset;
    }
    &:not(:last-child) {
      border-right: none;
    }
    &.Volume {
      /* order: 1; */
      div.number,
      p.number {
      }
    }
  }
`;

const BtnCt = styled.div`
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: center;
  /* width: 400px; */
  margin: 0 auto;

  & button {
    margin: 5px 10px !important;
    flex-shrink: 0 !important;
    font-size: 16px;
    min-width: 140px;
    font-weight: 700;
    line-height: normal;
    padding: 22.5px 52px !important;
    height: 68px !important;
    border-radius: 100px !important;

    @media (max-width: 480px) {
      &.remove.removeDefault {
        width: 100%;
      }
      &.change.dashborad {
        margin-left: 0 !important;
      }
    }

    &.start {
      width: 35%;
      min-width: 250px;
      @media (max-width: 480px) {
        flex: 0 1 auto;
        min-width: 100px;
        width: 180px;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        padding: 10px 10px !important;
      }
      & span {
        display: inline-block;
        width: 28px;
        @media (max-width: 480px) {
          width: 16px;
        }
      }
    }
  }

  @media (max-width: 700px) {
    flex: 1 1 100%;
    margin: 15px 10px 15px !important;
    height: auto !important;
    word-break: keep-all;
  }
  @media (max-width: 480px) {
    width: auto;
    & button {
    }
  }
`;

const Profile = styled.div`
  display: inline-block;
  width: 85px;
  height: 85px;
  position: relative;
  margin-right: 15px;

  @media (max-width: 480px) {
    width: 65px;
    height: 65px;
  }
`;

const Thumb = styled.span`
  display: inline-block;
  width: 81px;
  height: 81px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;

  img {
    width: 100%;
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  @media (max-width: 480px) {
    width: 60px;
    height: 60px;
  }
`;

const Modify = styled.button`
  width: 30px;
  height: 30px;
  position: absolute;
  bottom: 2px;
  right: 2px;
  z-index: 0;
  border-radius: 50%;
  border: none;
  background: url(${({ imrSrc }) => imrSrc}) no-repeat;
  background-size: 15px 12px;
  background-position: 50%;
  background-color: #e9e9e9;
`;

const SearchOpCt = styled.div`
  margin: 0px 0 20px;
  & p.title {
    color: #777;
    font-weight: 600;
    margin: 10px 0;
  }

  & .optionValueCt {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    & p {
      color: #888;
      & span {
        font-weight: 500;
      }
    }
  }
`;
