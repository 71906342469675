//챗봇 관련 api..
import axios from "axios";
import { local, session } from "utils/storage";

const URL = `${process.env.REACT_APP_API_HOST}/api/bot/`;
const api = axios.create({ baseURL: URL });

api.interceptors.response.use(
  (response) => {
    const { headers, config, status } = response;
    //response headers의 Authorization, refresh_token을 전달 받았다면
    //새로 갱신된 토큰 값이므로 기존의 sessionStorage에 Authorization,refresh_token 값을 업데이트한다.
    if (status === 205) {
      session.setAuth(headers.authorization, headers.refresh_token, headers.member_email, headers.member_uid, "", "");

      config.headers.authorization = headers.authorization;
      config.headers.refresh_token = headers.refresh_token;
      console.log("acessToken 갱신. 서비스 재 호출", response);
      return api.request(config);
    }

    return response;
  },
  (error) => {
    const { response, config } = error;

    if (response) {
      if (response.status === 401) {
        console.log("accessToken 만료. refresh token으로 요청");
        // 23.07.12.codelua 빈 토큰이 전송 되는 순간 쉬지 않고 서버와 핑퐁을 치는 현상이 발생하여 분실 된 경우 그냥 강제 로그아웃 처리.
        if (!session.getRefreshToken()) {
          console.log("Refresh Token 분실 상태.");
          error = {
            response: {
              status: 403,
              errorMessage: "accessToken 만료, refreshToken 만료. 강제 로그아웃 !",
            },
          };
          return Promise.reject(error);
        }
        config.headers.refresh_token = session.getRefreshToken();
        return api.request(config);
      } else if (response.status === 403) {
        console.log("accessToken 만료, refreshToken 만료. 강제 로그아웃 !");
        // 각 처리 쪽에서 강제 로그아웃 처리 중.
        return Promise.reject(error);
      } else {
        console.log("JWT처리 외 에러. : ", response);
        error = {
          response: {
            status: response.status, //Service Unavailable
            //chatBotData?.botName ?? ""
            errorMessage: response.data.resultMessage ?? "에러가 발생했습니다.",
          },
        };
        return Promise.reject(error);
      }
    } else if (!error?.response && error.message === "Network Error") {
      console.log("서버와 통신 할 수 없습니다.");
      error = {
        response: {
          status: 503, //Service Unavailable
          errorMessage: "서버와 통신 할 수 없습니다.",
        },
      };
      return Promise.reject(error);
    } else {
      // 명삼 여기서 전역으로 오류처리해버리면 각 화면단에서 api호출 마다 뭘 안해도 될거같은데 어떨지 생각해봐줘요
      //이 부분은 errorcode를 504(Gateway Timeout)로 해야하는지..
      console.log("서버와의 통신에 알 수 없는 문제가 발생했습니다.");
      error = {
        response: {
          status: 503,
          errorMessage: "서버와의 통신에 알 수 없는 문제가 발생했습니다.",
        },
      };
      return Promise.reject(error);
    }
  }
);

const botApi = {
  checkHomeChatBot: () => {
    const botType = 0;
    let params = { memberUid: session.getMemberUid(), botType: botType };
    let authorization = session.getAuthorization()
      ? session.getAuthorization()
      : local.getAutoLoginToken()
        ? local.getAutoLoginToken()
        : null;
    let headers = { authorization };
    return api.post("checkHomeChatBot", params, { headers });
  },
  getCommands: (botUid, command) => {
    console.log("call getCommands");
    let headers = {
      authorization: session.getAuthorization(),
    };
    let params = { botUid, command };
    return api.post("getCommands", params, { headers });
  },
  addKnowledgeFromExcel: (botInfo, file) => {
    console.log("call addKnowledgeFromExcel");
    let headers = {
      authorization: session.getAuthorization(),
      "Content-Type": "multipart/form-data",
    };

    let formData = new FormData();
    formData.append("excelFile", file);
    formData.append("paramData", new Blob([JSON.stringify(botInfo)], { type: "application/json" }));

    return api.post("addKnowledgeFromExcel", formData, { headers });
  },

  //bot에 지식데이터셋을 추가.
  addKnowDataSet: (botInfo, files) => {
    console.log("call addKnowDataSet");
    let headers = {
      authorization: session.getAuthorization(),
      "Content-Type": "multipart/form-data",
    };

    botInfo.botType = 0;

    // const buildFile =
    //   typeof botInfo.buildFilePath !== "string" ? botInfo.buildFilePath : null;

    let formData = new FormData();
    files &&
      files.forEach((file) => {
        formData.append("buildFile", file);
      });
    formData.append("paramData", new Blob([JSON.stringify(botInfo)], { type: "application/json" }));

    return api.post("addKnowDataSet", formData, { headers });
  },
  buildEmpty: () => {
    const botType = 0; // 챗봇전용.
    console.log("call buildEmpty, botType: ", botType);
    let params = {
      botType,
      memberUid: session.getMemberUid(),
    };
    let authorization = session.getAuthorization()
      ? session.getAuthorization()
      : local.getAutoLoginToken()
        ? local.getAutoLoginToken()
        : null;
    let headers = { authorization };
    return api.post("buildEmpty", params, { headers });
  },
  buildFile: (botInfo) => {
    const botType = 0;
    console.log("call buildFile, botType: ", botType);
    let headers = {
      authorization: session.getAuthorization(),
      "Content-Type": "multipart/form-data",
    };
    let params = {
      dataName: botInfo.dataName,
      dataType: 1,
      botType: botType,
      memberUid: session.getMemberUid(),
      crawlRestricted: botInfo.crawlRestricted,
    };

    const buildFile = botInfo.buildFilePath;

    let formData = new FormData();
    formData.append("buildFile", buildFile);
    formData.append("paramData", new Blob([JSON.stringify(params)], { type: "application/json" }));

    return api.post("buildFile", formData, { headers });
  },
  buildWeb: (dataName, crawlRestricted, radioValue) => {
    const botType = 0;
    console.log("call buildWeb, botType: ", botType);
    let params = {
      dataName,
      botType: botType,
      memberUid: session.getMemberUid(),
      crawlRestricted,
      crawlStrategy: radioValue,
    };
    let authorization = session.getAuthorization()
      ? session.getAuthorization()
      : local.getAutoLoginToken()
        ? local.getAutoLoginToken()
        : null;
    let headers = { authorization };
    return api.post("buildWeb", params, { headers });
  },
  getBotInfoByBotUid: (botUid) => {
    if (!botUid) {
      console.log("getBotInfoByBotUid Not Found botUid ERROR! return");
      return;
    }
    // console.log("call getBotInfoByBotUid, param: ", botUid);
    return api.post("getBotInfoByBotUid", { botUid });
  },
  getBotInfoByShortUrl: (shortUrl) => {
    // byBotUid와 같은 처리이나 파라미터가 다르다!
    console.log("call getBotInfoByShortUrl, param url: ", shortUrl);
    return api.post("getBotInfoByShortUrl", { shortUrl });
  },
  // 봇을 삭제(폐기) 처리
  disposalBot: (botUid, indexName) => {
    let headers = {
      authorization: session.getAuthorization(),
    };
    return api.post("disposalBot", { botUid, indexName }, { headers });
  },
  // 지식을 삭제(폐기) 처리
  deleteKnowDataSet: (botUid, dataUid, dataName, division) => {
    let headers = {
      authorization: session.getAuthorization(),
    };
    return api.post("deleteKnowDataSet", { botUid, dataUid, dataName, division }, { headers });
  },
  // (다중) 지식을 활성/비활성화 처리 한다.
  toggleKnowDataSets: (data) => {
    let headers = {
      authorization: session.getAuthorization(),
    };
    return api.post("toggleKnowDataSets", data, { headers });
  },

  // (단일) 지식을 활성/비활성화 처리 한다.
  toggleKnowDataSet: ({ botUid, dataUid, dataStatus }) => {
    let headers = {
      authorization: session.getAuthorization(),
    };
    return api.post("toggleKnowDataSet", { botUid, dataUid, dataStatus }, { headers });
  },

  // 봇의 상태를 업데이트 처리.
  updateBotStatus: (botUid, status) => {
    let headers = {
      authorization: session.getAuthorization(),
    };

    return api.post("updateBotStatus", { botUid, status }, { headers });
  },
  //나의 botName, botUid List
  getUserActiveBotList: () => {
    console.log("call getUserActiveBotList");
    if (!session.getAuthorization()) {
      console.log("call getUserActiveBotList Not getAuthorization to return");
      return;
    }
    const botType = 0;
    let params = {
      memberUid: session.getMemberUid(),
      botType: botType,
    };

    let headers = {
      authorization: session.getAuthorization(),
    };

    return api.post("getUserActiveBotList", params, { headers });
  },
  //나의 모든 botName, botUid List 지식불러오기 사용 (기준이 된 botUid는 제외)
  loadAddKnowBotList: (botUid) => {
    if (!session.getAuthorization()) {
      console.log("call loadAddKnowBotList Not getAuthorization to return");
      return;
    }

    let params = {
      memberUid: session.getMemberUid(),
      botUid,
    };

    let headers = {
      authorization: session.getAuthorization(),
    };

    return api.post("loadAddKnowBotList", params, { headers });
  },
  getChatHistoryMessage: (chatRoomId, botUid) => {
    let headers = {
      authorization: session.getAuthorization(),
    };
    return api.post("getChatHistoryMessage", { chatRoomId, botUid }, { headers });
  },
  // 참고용 파일 다운로드 ( 23.11.16기준에서는 지식으로 올린 파일 )
  downloadReferenceFile: (botUid, refFileName) => {
    try {
      return api.get("downloadReferenceFile", {
        params: {
          botUid,
          refFileName,
        },
        responseType: "blob",
      });
    } catch (error) {
      // 여기서 뭔가 다른 에러를 리턴해줘서 관리자 문의를 줘야함.
      console.error(">>> 파일다운로드 에러:", error);
    }
  },
  // PDF viewer를 처리하기 위해 2개의 param값을 받는다.
  getKnowDataUrlParam: (botUid, refFileName) => {
    try {
      return api.get("getKnowDataUrlParam", {
        params: {
          botUid,
          refFileName,
        },
      });
    } catch (error) {
      // 여기서 뭔가 다른 에러를 리턴해줘서 관리자 문의를 줘야함.
      console.error(">>> 파일 정보 획득 실패:", error);
    }
  },
  // 지식으로 올린 파일 다운로드.
  getDownloadKnowData: (dataUid, dataName) => {
    try {
      return api.get("downloadKnowDataSetFile", {
        params: {
          dataUid,
          dataName,
          memberUid: session.getMemberUid(),
        },
        responseType: "blob",
      });
    } catch (error) {
      console.error(">>> 파일다운로드 에러:", error);
    }
  },
  // crawl url text page
  getUrlFileData: (dataUid) => {
    let headers = {
      authorization: session.getAuthorization(),
    };
    return api.post("getUrlFileData", { dataUid }, { headers });
  },
  getChatRoomList: (pageNo, numOfRows, botUid) => {
    const chatType = 0;
    let headers = {
      authorization: session.getAuthorization(),
    };

    return api.post("getChatRoomList", { pageNo, numOfRows, botUid, chatType }, { headers });
  },
  //bot 정보 수정
  botInfoModify: (botInfo, exList, bottomMenuList, apiCommandList) => {
    let headers = {
      authorization: session.getAuthorization(),
      "Content-Type": "multipart/form-data",
    };

    /* botInfoImage << 이미지 수정이 있을 때 imageFile 객체
      null이 아닌 경우 api에서 image 수정으로 판단 */
    let botInfoImage = typeof botInfo.botImagePath !== "string" ? botInfo.botImagePath : null;
    /* botImagePath << 이미지 수정이 없을 때 imageFileName String
      위의 botInfoImage와 botInfo.botImagePath가 null인 경우 api에서 이미지 삭제로 판단 */
    botInfo.botImagePath = typeof botInfo.botImagePath === "string" ? botInfo.botImagePath : null;

    let formData = new FormData();
    if (exList && exList.length > 0) {
      const exJson = JSON.stringify(exList);
      formData.append("exQuestionList", exJson);
    }
    console.log(">>>>>>> botAPI bottomMenuList:", bottomMenuList);
    if (bottomMenuList && bottomMenuList.length > 0) {
      const bottomList = JSON.stringify(bottomMenuList);
      formData.append("bottomMenuList", bottomList);
    }

    formData.append("imageFile", botInfoImage);
    formData.append("paramData", new Blob([JSON.stringify(botInfo)], { type: "application/json" }));

    if (botInfo.useComMenu === "Y" && apiCommandList) {
      formData.append("apiCommandList", JSON.stringify(apiCommandList));
    }

    return api.post("modify", formData, { headers });
  },

  getKnowledgeByBotUid: (pageNo, numOfRows, botUid) => {
    let headers = {
      authorization: session.getAuthorization(),
    };

    return api.post("getKnowledgeByBotUid", { pageNo, numOfRows, botUid }, { headers });
  },
  addKnowledge: (botUid, indexName, messageId, sendMessage, recvMessage) => {
    let headers = {
      authorization: session.getAuthorization(),
    };

    return api.post("addKnowledge", { botUid, indexName, messageId, sendMessage, recvMessage }, { headers });
  },
  deleteKnowledge: (knowledgeId, indexName, vsIds) => {
    let headers = {
      authorization: session.getAuthorization(),
    };

    return api.post("deleteKnowledge", { knowledgeId, indexName, vsIds }, { headers });
  },
  updateKnowledge: (knowledgeId, botUid, indexName, vsIds, sendMessage, recvMessage) => {
    let headers = {
      authorization: session.getAuthorization(),
    };

    return api.post(
      "updateKnowledge",
      { knowledgeId, botUid, indexName, vsIds, sendMessage, recvMessage },
      { headers }
    );
  },
  getQnaListCountByBotUid: (botUid) => {
    const qnaType = 0;
    let headers = {
      authorization: session.getAuthorization(),
    };
    const memberUid = session.getMemberUid();
    return api.post("getQnaListCountByBotUid", { botUid, memberUid, qnaType }, { headers });
  },
  getQnaListByBotUid: (pageNo, numOfRows, botUid) => {
    const qnaType = 0;
    let headers = {
      authorization: session.getAuthorization(),
    };

    const memberUid = session.getMemberUid();
    return api.post("getQnaListByBotUid", { pageNo, numOfRows, botUid, memberUid, qnaType }, { headers });
  },
  addQna: (botUid, subject, detail) => {
    const qnaType = 0;
    let headers = {
      authorization: session.getAuthorization(),
    };

    const memberUid = session.getMemberUid();
    return api.post("addQna", { botUid, subject, detail, memberUid, qnaType }, { headers });
  },
  updateQnaDetail: (qnaUid, subject, detail) => {
    let headers = {
      authorization: session.getAuthorization(),
    };

    return api.post("updateQnaDetail", { qnaUid, subject, detail }, { headers });
  },
  deleteQna: (qnaUid) => {
    let headers = {
      authorization: session.getAuthorization(),
    };

    return api.post("deleteQna", { qnaUid }, { headers });
  },
  getChatStat: (botUid) => {
    let headers = {
      authorization: session.getAuthorization(),
    };
    console.log("Call getChatStat");
    return api.post("getChatStat", { botUid }, { headers });
  },
  getUseKnowDataSetFromBotUid: (botUid) => {
    let headers = {
      authorization: session.getAuthorization(),
    };
    console.log("Call getUseKnowDataSetFromBotUid");
    return api.post("getUseKnowDataSetFromBotUid", { botUid }, { headers });
  },
  checkKnowDataSetAdding: (botUid) => {
    console.log("Call checkKnowDataSetAdding");
    return api.post("checkKnowDataSetAdding", { botUid });
  },
  copyBuildBase: ({ list, selectedFolderInfo, botUid, indexName }) => {
    console.log("dataList call", list);
    let headers = {
      "Content-Type": "application/json",
      authorization: session.getAuthorization(),
    };
    return api.post("copyBuildBase", { botUid, list, indexName, selectedFolderUid: selectedFolderInfo?.dataUid }, { headers });
  },
  selectBotExQuestion: (botUid) => {
    // console.log("Call selectBotExQuestion");
    let headers = {
      authorization: session.getAuthorization(),
    };
    return api.post("selectBotExQuestion", { botUid }, { headers });
  },
  selectBottomMenuList: (botUid) => {
    // console.log("Call selectBottomMenuList");
    let headers = {
      authorization: session.getAuthorization(),
    };
    return api.post("selectBottomMenuList", { botUid }, { headers });
  },

  selectApiCommandList(botUid) {
    let headers = {
      authorization: session.getAuthorization(),
    };
    return api.post("selectApiCommandList", { botUid }, { headers });
  },

  // 폴더 생성
  createUseKnowDataFolderFromBotUid(dataName, botUid, pdataUid = null) {
    let headers = {
      authorization: session.getAuthorization(),
    };
    return api.post("createUseKnowDataFolderFromBotUid", { botUid, dataName, pdataUid }, { headers });
  },

  // 폴더 수정
  updateFolderInfo(dataUid, botUid, dataName) {
    let headers = {
      authorization: session.getAuthorization(),
    };
    return api.patch("updateFolderInfo", { dataUid, botUid, dataName }, { headers });
  },

  // 폴더/파일 다중 삭제
  deleteFolder({ list, botUid, email, indexName }) {
    console.log(list);
    let headers = {
      authorization: session.getAuthorization(),
    };

    return api.post("deleteKnowDataSets", { list, botUid, email, indexName }, { headers });
  },

  // 폴더 목록 조회
  viewFolderListFromKnowledge(botUid) {
    let headers = {
      authorization: session.getAuthorization(),
    };
    return api.post("viewFolderListFromKnowledge", { botUid }, { headers });
  },

  // 파일 / 폴더로 옮기기
  moveFilesFromKnowledge(info) {
    console.log("3차 파일 폴더로 옮기기 열림", info);
    let headers = {
      authorization: session.getAuthorization(),
    };
    return api.patch("moveFilesFromKnowledge", { list: info }, { headers });
  },

  // 지식관리 데이터 조회 (무한스크롤, 페이지네이션)
  getUseKnowDataSetFromBotUid_Pagination(params) {
    let headers = {
      authorization: session.getAuthorization(),
    };
    return api.post("getUseKnowDataSetFromBotUid_Pagination", { ...params }, { headers });
  },

  // bookmarkOnOff
  bookmarkFromKnowledge(dataUid, botUid, parentUid, bookmark) {
    let headers = {
      authorization: session.getAuthorization(),
    };
    return api.patch("bookmarkFromKnowledge", { dataUid, botUid, parentUid, bookmark }, { headers });
  },

  selectKnowledgeFromBookmarked(botUid, pdataUid) {
    let headers = {
      authorization: session.getAuthorization(),
    };
    return api.post("selectKnowledgeFromBookmarked", { param: { botUid: botUid, pdataUid: pdataUid } }, { headers });
  },

  getUseKnowDataByPdataUidFromBotUid(botUid, dataUidList) {
    console.log("getUseKnowDataByPdataUidFromBotUid >>>>>", dataUidList);
    let headers = {
      authorization: session.getAuthorization(),
    };

    return api.post("getUseKnowDataByPdataUidFromBotUid", { botUid, list: dataUidList }, { headers });
  },

  usesBotToSearchInChat: (request) => {
    let headers = {
      authorization: session.getAuthorization(),
    };

    return api.post("usesBotToSearch_chat", { ...request }, { headers });
  },
	validateShortUrl( shortUrl ) {
		let headers = {
      authorization: session.getAuthorization(),
    };

    return api.post("validateShortUrl", { shortUrl }, { headers });
	},
  getUsageFileSize(botUid) {
    let headers = {
      authorization: session.getAuthorization(),
    };
    
    return api.post("getUsageFileSize", { botUid }, { headers })
  }
};

export default botApi;
