import { Route, Switch } from "react-router-dom";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import KakaoLoginSuccess from "utils/KakaoLoginSuccess";
import NaverLoginSuccess from "utils/NaverLoginSuccess";

import Home from "./home";
import WidgetChat from "./widget/WidgetChat";
import Dashboard from "./dashboard/index.js";
import { useContext, useState } from "react";
import { signAppContext } from "utils/providers/SignContextProvider";
import ExternalChat from "./chat/ExternalChat.js";
import ChatViewDetailPop from "components/templates/popup/ChatViewDetailPop";
import PersonalPolicy from "./docs/PersonalPolicy";
import TermOfUsePolicy from "./docs/TermOfUsePolicy";
import MarketingPolicy from "./docs/MarketingPolicy";
import FaqPage from "./docs/FaqPage";
import { AlertPop2 } from "components/templates/popup/AlertPop";

export default function Router() {
  const { isLoggedIn } = useContext(signAppContext);
  const [popState, setPopState] = useState(false);
  const [popMessage, setPopMessage] = useState("");
  return (
    <>
      <Switch>
        <Route
          path="/naverLoginSuccess"
          render={(props) => <NaverLoginSuccess setPopState={setPopState} setPopMessage={setPopMessage} />}
        />
        <Route path="/kakaoLoginSuccess" render={(props) => <KakaoLoginSuccess />} />
        <Route path="/dashboard" render={(props) => (isLoggedIn ? <Dashboard /> : <Home loginPop={true} />)} />

        <Route path="/widget/:botUid" render={(props) => <WidgetChat />} />
        <Route
          path="/chatbot/:botUid"
          render={(props) => (isLoggedIn ? <ChatViewDetailPop /> : <Home loginPop={true} />)}
        />

        <Route path="/chat/:botUid" render={(props) => <ExternalChat />} />
        <Route path="/chat" render={(props) => <ExternalChat />} />

        <Route path="/personalPolicy" render={(props) => <PersonalPolicy />} />
        <Route path="/termOfUsePolicy" render={(props) => <TermOfUsePolicy />} />

        <Route path="/marketingPolicy" render={(props) => <MarketingPolicy />} />

        <Route path="/faqPage" render={(props) => <FaqPage />} />

        <Route path="/" render={(props) => <Home />} />
      </Switch>
      {popState && <AlertPop2 popState={popState} setPopState={setPopState} text={popMessage} />}
    </>
  );
}
