import React, { createContext } from "react";

export const pdfViewerContext = createContext({
  setUseViewer: () => {},
  setPdfUrl: () => {},
  setPdfPage: () => {},
});

export default function PdfViewerContextProvider({
  viewerData,
  children: Components,
}) {
  return (
    <pdfViewerContext.Provider value={viewerData}>
      {Components}
    </pdfViewerContext.Provider>
  );
}
