import NormalButton from "components/atomic/NormalButton2";
import { Fragment, useEffect, useRef, useState } from "react";

import icon1 from "../../../assets/images/icon_chat01.svg";
import icon2 from "../../../assets/images/icon_chat02.svg";
import icon3 from "../../../assets/images/icon_chat03.svg";
import icon4 from "../../../assets/images/icon_chat04.svg";
import icon5 from "../../../assets/images/icon_chat05.svg";
import icon6 from "../../../assets/images/icon_chat06.svg";
import styled, { keyframes } from "styled-components";

export default function BotBottomMenuAddPop({
  bottomMenuList,
  bottomBtnPopType,
  setBottomBtnPop,
  updateInfo,
  setUpdateInfo,
  editBtnInfo,
  callback,
  cancelCallback,
}) {
  const [btnType, setBtnType] = useState("1");
  const [inputValue, setInputValue] = useState("");
  const [textAreaValue, setTextAreaValue] = useState("");
  const [checkedIcon, setCheckedIcon] = useState(1);
  const [errorMessage, setErrorMessage] = useState("");

  const textAreaRef = useRef("");

  let updateItem;
  // input 값 길이 제한

  // 입력값 변경 이벤트 핸들러
  const handleInputChange = (e) => {
    const value = e.target.value;
    if (value.length > 10) {
      return;
    }
    setInputValue(value);
  };
  const handleTextAreaChange = (e) => {
    const value = e.target.value;
    if (value.length > 1000) {
      return;
    }
    setTextAreaValue(value);
  };

  // 라디오 버튼 컨트롤
  const handleRadio = (e) => {
    console.log('click click')
    const { value } = e.target;
    setBtnType(value);
  };

  // img check
  const handleBtnImg = (e) => {
    // e.preventDefault();
    // e.stopPoropation();
    const { id } = e.currentTarget;
    setCheckedIcon(id);
  };

  const handleErrorMessage = () => {
    setTimeout(() => {
      setErrorMessage("");
    }, 3000);
  };

  // 유효성검사
  const handleInputValid = () => {
    if (inputValue.length > 10) {
      setErrorMessage("버튼 이름은 최대 10자까지 입력할 수 있습니다.");
      handleErrorMessage();
      return false;
    }
    if (inputValue.trim("") === "") {
      setErrorMessage("버튼 이름을 입력해주세요");
      handleErrorMessage();
      return false;
    }

    if (Number(btnType) === 1) {
      if (textAreaValue.length > 1000) {
        setErrorMessage("텍스트 내용은 최대 1,000자까지 입력할 수 있습니다.");
        handleErrorMessage();
        return false;
      }

      if (textAreaValue.trim("") === "") {
        setErrorMessage("텍스트 내용을 입력해주세요");
        handleErrorMessage();
        return false;
      }
    } else if (Number(btnType) === 2) {
      console.log(">>>>>> btnType:", btnType);

      const urlRegex =
          /^(?:(http:\/\/|https:\/\/))?((?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,})(:\d+)?(\/.*)?$/;

      if (textAreaValue.trim("") === "") {
        setErrorMessage("URL 주소를 입력해주세요");
        handleErrorMessage();
        return false;
      }

      if (!urlRegex.test(textAreaValue)) {
        setErrorMessage("URL 형식에 맞는 것만 입력 할 수 있습니다.");
        handleErrorMessage();
        return false;
      }
    }
    if (bottomBtnPopType !== "modify" && bottomMenuList.length >= 6) {
      setErrorMessage("버튼은 6개 이상 생성할 수 없습니다.");
      handleErrorMessage();
      return false;
    }

    return true;
  };
  const handleResetValue = () => {
    setInputValue("");
    setTextAreaValue("");
    setCheckedIcon(1);
    setBtnType("1");
  };

  // 취소
  const Cancel = () => {
    setBottomBtnPop(false);
    cancelCallback && cancelCallback();
  };

  // 콜백함수
  const CallBack = (e) => {
    if (!handleInputValid()) {
      return;
    } else {
      setBottomBtnPop(false);
      handleResetValue();
      callback && callback(editBtnInfo, updateInfo);
    }
  };

  // 수정 버튼 클릭시 값 그대로 가져오기
  useEffect(() => {
    if (bottomBtnPopType === "modify") {
      updateItem = bottomMenuList.filter((item) => item.seqNum === editBtnInfo);
      setInputValue(updateItem[0].name);
      setTextAreaValue(updateItem[0].detail);
      setBtnType(String(updateItem[0].operType));
      setCheckedIcon(updateItem[0].imgNo);
    }
  }, []);

  // placeholder 내용 분기처리
  useEffect(() => {
    if (btnType === "1") {
      textAreaRef.current.placeholder =
        "버튼 클릭 시 출력될 텍스트를 입력해주세요(최대 1000자)";
    } else if (btnType === "2") {
      textAreaRef.current.placeholder =
        "버튼 클릭 시 이동될 URL 링크를 입력해주세요(최대 1000자)";
    }
  }, [btnType]);

  // 수정된 값 update
  useEffect(() => {
    let tempNum =
      bottomBtnPopType === "modify" ? editBtnInfo : bottomMenuList.length + 1;
    setUpdateInfo({
      seqNum: tempNum,
      name: inputValue,
      detail: textAreaValue,
      imgNo: checkedIcon,
      operType: btnType,
    });
  }, [inputValue, textAreaValue, checkedIcon, btnType]);

  return (
    <ConfirmModal>
      <Wrpper className={""}>
        <Header>
          {bottomBtnPopType !== "modify" ? (
            <>
              <i className="ri-add-circle-line" />
              버튼 추가
            </>
          ) : (
            <>
              <i className="ri-pencil-line" />
              버튼 수정
            </>
          )}
        </Header>
        <Body>
          <BottomBtnInfoWrapper>
            <BottomBtnInfoCt>
              <BottomBtnTitle>버튼 이름</BottomBtnTitle>
              <input
                maxLength="10"
                onChange={handleInputChange}
                placeholder="버튼 이름을 입력해 주세요(최대 10자)"
                value={inputValue}
              ></input>
            </BottomBtnInfoCt>
            <BottomBtnInfoCt>
              <BottomBtnTitle>메뉴 연결</BottomBtnTitle>
              <div className="typeCt">
                <div className="radioWrapper">
                  <div className="radioCt">
                    <label
                      className={btnType === "1" ? "active" : ""}
                      htmlFor="typeText"
                    >
                      <i className="ri-message-3-line"></i> 텍스트 출력
                    </label>
                    <input
                      onChange={handleRadio}
                      value="1"
                      name="type"
                      id="typeText"
                      type="radio"
                      checked={Number(btnType) === 1 ? "checked" : ""}
                    ></input>
                  </div>
                  <div className="radioCt">
                    <label
                      value="2"
                      className={btnType === "2" ? "active" : ""}
                      htmlFor="typeUrl"
                      // onClick={handleRadio}
                    >
                      <i className="ri-external-link-fill"></i> URL 이동
                    </label>
                    <input
                      onChange={handleRadio}
                      value="2"
                      name="type"
                      id="typeUrl"
                      type="radio"
                      checked={Number(btnType) === 2 ? "checked" : ""}
                    ></input>
                  </div>
                  <div className="radioCt">
                    <label
                      value="3"
                      className={btnType === "3" ? "active" : ""}
                      // onClick={handleRadio}
                      htmlFor="typeUrl2"
                    >
                      <i className="ri-external-link-fill"></i> 부모URL변경
                    </label>
                    <input
                      onChange={handleRadio}
                      value="3"
                      name="type"
                      id="typeUrl2"
                      type="radio"
                      checked={Number(btnType) === 3 ? "checked" : ""}
                    ></input>
                  </div>
                </div>
                <textarea
                  ref={textAreaRef}
                  placeholder=""
                  spellCheck="false"
                  maxLength={1000}
                  onChange={handleTextAreaChange}
                  value={textAreaValue}
                ></textarea>
              </div>
            </BottomBtnInfoCt>
            <BottomBtnInfoCt className="column">
              <BottomBtnTitle className="selectImgTitle">
                이미지 선택
              </BottomBtnTitle>
              <div className="imgBtnCt">
                <BottomBtnIconImg
                  onClick={handleBtnImg}
                  id="1"
                  icon={icon1}
                  className={Number(checkedIcon) === 1 ? "active" : ""}
                >
                  <span className="svg"></span>
                </BottomBtnIconImg>
                <BottomBtnIconImg
                  // icon={icon2}
                  onClick={handleBtnImg}
                  id="2"
                  icon={icon2}
                  className={Number(checkedIcon) === 2 ? "active" : ""}
                >
                  <span data={icon2} className="svg"></span>
                </BottomBtnIconImg>
                <BottomBtnIconImg
                  onClick={handleBtnImg}
                  id="3"
                  icon={icon3}
                  className={Number(checkedIcon) === 3 ? "active" : ""}
                >
                  <span data={icon3} className="svg"></span>
                </BottomBtnIconImg>
                <BottomBtnIconImg
                  onClick={handleBtnImg}
                  id="4"
                  icon={icon4}
                  className={Number(checkedIcon) === 4 ? "active" : ""}
                >
                  {" "}
                  <span data={icon4} className="svg"></span>
                </BottomBtnIconImg>
                <BottomBtnIconImg
                  onClick={handleBtnImg}
                  id="5"
                  icon={icon5}
                  className={Number(checkedIcon) === 5 ? "active" : ""}
                >
                  <span data={icon5} className="svg"></span>
                </BottomBtnIconImg>
                <BottomBtnIconImg
                  onClick={handleBtnImg}
                  id="6"
                  icon={icon6}
                  className={Number(checkedIcon) === 6 ? "active" : ""}
                >
                  {" "}
                  <span data={icon6} className="svg"></span>
                </BottomBtnIconImg>
              </div>
            </BottomBtnInfoCt>
          </BottomBtnInfoWrapper>

          {errorMessage && (
            <p className="error">
              <i className="ri-alarm-warning-line"></i>
              {errorMessage}
            </p>
          )}
        </Body>

        <ButtonGroup>
          <NormalButton
            disabled={false}
            buttonId="cancel"
            buttonTitle="취소"
            buttonClass={"cancel cDel"}
            callBackFunc={Cancel}
          />
          <NormalButton
            disabled={false}
            buttonTitle={
              bottomBtnPopType === "modify" ? "버튼 수정" : "버튼 등록"
            }
            buttonClass={"cOrange confirm"}
            callBackFunc={CallBack}
          />
        </ButtonGroup>
      </Wrpper>
    </ConfirmModal>
  );
}

const openModal = keyframes`
  0% {
    transform: translate(-0%,5%);
    -webkit-transform: translate(-0%,5%);
    opacity: 0;
  }

  100% {
    -webkit-transform: translate(-0%, -0%);
    transform: translate(-0%, -0%);
    opacity: 1;
  }
`;

const openModalWeb = keyframes`
  0% {
    transform: translate(-50%,-45%);
    -webkit-transform: translate(-50%,-45%);
    opacity: 0;
  }

  100% {
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    opacity: 1;
  }
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
    left: 45px;
  }
  100% {
    opacity: 1;
    top: 40px;
  }
`;

const ConfirmModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;

  animation: ${openModal} 0.2s ease-in-out;

  @media (min-width: 481px) {
    position: absolute;
    top: 50%;
    left: 40%;
    transform: translate(-50%, -50%);
    width: 45%;
    height: 70%;
    background: #fff;
    animation: ${openModalWeb} 0.2s ease-in-out;
  }
`;

const Wrpper = styled.div`
  width: 100%;
  height: 100%;

  /* transform: translate(-50%, -50%);
  top: 50%;
  left: 50%; */

  display: flex;
  flex-direction: column;

  top: 0;
  left: 0;

  border: 1px solid #dbdbdb;

  background: #fff;
  z-index: 99;
  box-shadow: 1px 1px 20px 0 rgba(0, 0, 0, 0.2);

  min-width: 82px;

  right: 10px;

  @media (min-width: 481px) {
    border-radius: 15px 15px 0 0;
  }
  &.own {
    left: 50%;
  }
`;

const Header = styled.p`
  background: #fff;

  padding: 15px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  & i {
    margin: 0 5px;
    padding: 7.5px;
    border-radius: 50%;
    &.ri-add-circle-line {
      background: #e6f9f5;
      color: #00c887;
    }
    &.ri-pencil-line {
      color: #363cee;
      background: #e3defb;
    }
  }
`;

const Body = styled.div`
  padding: 0px 20px 17px;

  & span.txt {
    font-size: 13px;
    letter-spacing: -0.5px;
    word-break: keep-all;
  }

  & p.error {
    font-size: 14px;
    letter-spacing: -0.5px;
    width: fit-content;
    height: fit-content;
    /* padding: 10px; */
    margin: 0 auto;
    color: tomato;
    text-align: center;
    border: 1px solid tomato;
    padding: 10px 10px 10px 0;
    background: #fff;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 1.3px 2.5px 5px hsl(0deg 0% 0% / 0.46);
    animation: ${fadeIn} 0.1s linear;

    & i {
      background-color: tomato;
      padding: 12px;

      margin-right: 15px;
      color: #fff;
    }
  }
`;

const ButtonGroup = styled.div`
  margin-top: auto;

  display: flex;
  align-items: center;

  button {
    flex: 1 0 50%;
    height: 45px;
    display: block;
    font-size: 13px;
    margin: 0;
    padding: 0;
    cursor: pointer;
    font-weight: 500;

    border-radius: 0;
    border: none !important;
    border-top: 1px solid #ff892a !important;

    &:hover {
      opacity: 0.8;
    }
    &.cancel {
      color: #ff892a;
    }
    &.confirm {
    }
  }
`;

const BottomBtnInfoWrapper = styled.div``;
const BottomBtnInfoCt = styled.div`
  display: flex;

  margin: 25px 0;

  & input,
  textarea {
    width: 100%;
    border-radius: 3px;
    border: 1px solid #dcdcdc;
    min-height: 32px;
    padding: 0 10px;
    &::placeholder {
      font-size: 11px;
      /* padding: 0 3px; */
    }

    &:focus {
      border: 1px solid #ff892a;
    }
  }

  & input[type="radio"] {
    min-height: auto;
    margin: 0 5px;
  }

  & textarea {
    resize: none;
    height: 100px;
    padding: 5px;
  }

  /* radio button container */
  & .typeCt {
    width: 100%;
    & .radioWrapper {
      display: flex;
      & .radioCt {
        display: flex;
        margin-bottom: 5px;
        margin-right: 5px;
        label {
          width: 150px;
          font-size: 12px;
          font-weight: 300;
          width: 100%;
          &.active {
            color: #ff892a;
            font-weight: 500;
          }
        }
        input {
          width: 12px;
          height: 12px;
        }
      }
    }
  }

  &.column {
    flex-direction: column;
  }

  & .imgBtnCt {
    display: flex;
    flex-wrap: wrap;
    @media (max-width: 480px) {
      justify-content: space-between;
    }
  }
`;
const BottomBtnTitle = styled.p`
  flex: 0 0 100px;
  padding: 0 5px;
  font-size: 13px;
  font-weight: 400;

  &.selectImgTitle {
    flex: 0 0 auto;
    margin-bottom: 10px;
  }
`;
const BottomBtnIconImg = styled.button`
  flex: 1 0 10%;
  max-width: 45px;
  height: 45px;

  margin: 3px;

  border: 1px solid #808080;
  border-radius: 50%;

  @media (max-width: 480px) {
    flex: 1 0 30%;
  }

  & .svg {
    display: inline-block;
    width: 30px;
    height: 30px;
    background: url(${({ icon }) => icon}) no-repeat;
    background-position: center;
    background-size: contain;
  }

  transition: 0.2s;
  &.active {
    & .svg {
      filter: invert(100%) sepia(9%) saturate(4791%) hue-rotate(220deg)
        brightness(111%) contrast(96%);
    }
    border: 1px solid #70b0ff;
    background-color: #5698ea;
  }
`;
