import { memo, useCallback, useContext, useRef, useState } from "react";
import {
  Box,
  FlexAlignCenterBox,
  FlexBetween_AlignCenterBox,
  FlexBox,
  FlexColumnBox,
} from "../dashboard/dashboardStyle.js";
import ChatBotSelect from "../../../utils/ChatBotSelect.js";
import NormalButton from "../../atomic/NormalButton2.js";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace.js";
import styled from "styled-components";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import SearchIcon from "@mui/icons-material/Search";
import TuneIcon from "@mui/icons-material/Tune";
import Card from "@mui/material/Card";
import { Button, Slider, Typography } from "@mui/material";
import { BotInfoRow, ShowTooltipInfo, updateTooltipInfoLogic } from "../settingTabs/BaseSettingTab.js";

import { chatContext } from "../../../utils/providers/ChatContextProvider.js";
import botApi from "../../../api/botApi.js";
import { ToastMessageContext } from "../../../utils/providers/ToastMessageProvider.js";
import LoadingComponent from "../../../utils/LoadingComponent.js";
import { popupContext } from "../../../utils/providers/PopupProvider.js";

const MAIN_COLOR = (opacity) => `rgba(255, 137, 42, ${opacity})`;
const BACKGROUND_COLOR = (opacity) => `rgba(242,242,242, ${opacity})`;

const RANGE_INFO = {
  MAX: 100,
  STEP: 10,
};

const tempItems = [
  {
    searchUid: "FILE",
    memberUid: null,
    botUid: null,
    searchType: 1,
    keyword: "[문화의날] 플로깅 인 종로(2024.06.10)_1.pdf / 3 page",
    answer:
      "도착한 계곡에는 비록 물은 없었지만 인근 정자에서 앉아 땀을 식히며 산 새 소리와 바람 부는  소리를 들으며 명상으로 복잡한 도시를 벗어나 마음과 머리를 맑게 해주었습니다.  <수성동 계곡 & 자연 명상>\n",
    ratio: "3 page",
    embeddingValue: null,
    usageValue: null,
    token: null,
    createAt: null,
    pageNo: 0,
    numOfRows: 0,
    totalCount: 0,
  },
  {
    searchUid: "FILE",
    memberUid: null,
    botUid: null,
    searchType: 2,
    keyword: "[240123] 전통문화플랫폼 진행사항.pdf / 5 page",
    answer: "전통문양 예시(1)\n",
    ratio: "5 page",
    embeddingValue: null,
    usageValue: null,
    token: null,
    createAt: null,
    pageNo: 0,
    numOfRows: 0,
    totalCount: 0,
  },
  {
    searchUid: "FILE",
    memberUid: null,
    botUid: null,
    searchType: 3,
    keyword: "신청서류양식.pdf / 2 page",
    answer:
      "붙임 1 붙임 1 신청 기관(단체) 소개서 ○○○기관(단체)명 주소 및 연락처 주소시(군)구동00-1(우123-456) 연락처 o전화: oFAX: o홈페이지: oE-Mail: 설립목적 o우리나라○○산업의○○○○와균형있는○○○○을실현하기위한 ○○○○을전개함으로써○○○○산업발전에기여(또는기틀마련)등 단체연혁 o’81.11.8○○○창립 o’88.6.15○○○○사단(재단)법인설립허가 등록및 인력현황 등록기관등록일 대표자(공동대표인경우모두기재) 사무국직원수:명 (사무국장,행정부장,...) 회원수 20년 예산현황 o예산총액:천원 o재원구성 구분회비수입 사업자지 원사업비 정부보조사업수익기타 금액(천원) 비율(%) ※붙임1.사업자등록증(고유번호증)1부,인력현황등1부\n",
    ratio: "2 page",
    embeddingValue: null,
    usageValue: null,
    token: null,
    createAt: null,
    pageNo: 0,
    numOfRows: 0,
    totalCount: 0,
  },
];

export default function SearchTest(props) {
  const { setCurrentView, loading, setLoading } = props;
  const [showFilter, setShowFilter] = useState(true);

  const [tooltipInfo, setTooltipInfo] = useState({
    lineChange: {
      type: "lineChange",
      title: "줄바꿈",
      tooltipText: "줄바꿈 처리",
      isEnabled: false,
    },
  });

  const [toggleInfo, setToggleInfo] = useState({
    lineChange: {
      type: "lineChange",
      title: "줄바꿈",
      isEnabled: false,
    },
  });

  const [[keyword, semantics], setRangeValue] = useState([80, 20]);
  const [value, setValue] = useState(20);

  const [searchValue, setSearchValue] = useState("");
  const [searchResultList, setSearchResultList] = useState([]);
  const [searchResultTokens, setSearchResultTokens] = useState([]);
  const { chatBotData } = useContext(chatContext);
  const { handleToastMessage } = useContext(ToastMessageContext);
  const { showTextPop } = useContext(popupContext);

  const debounceRef = useRef(null);
  const sliderDebounceRef = useRef(null);

  const updateTooltipInfo = useCallback((type, isEnabled) => {
    console.log("type, isEnabled", type, isEnabled);
    setTooltipInfo((prevState) => updateTooltipInfoLogic(type, isEnabled, prevState));
  }, []);

  const updateToggleInfo = useCallback(
    (type) => {
      setToggleInfo((prevState) => ({
        ...prevState,
        [type]: {
          ...prevState[type],
          isEnabled: !prevState[type].isEnabled,
        },
      }));
    },
    [toggleInfo.lineChange.isEnabled, searchResultList]
  );

  const onOffFilter = useCallback(() => {
    setShowFilter((prevState) => !prevState);
  }, []);

  // ratio 값 구하기
  const getRatio = (param) => {
    return 1 - (param ?? value) / 100;
  };
  const inputRangeProcess = (event, changeValue) => {
    // range 값 수정 도중 send message가 대기중인 상태면, 취소 시키기
    if (!!sliderDebounceRef.current) {
      clearTimeout(sliderDebounceRef.current);
      sliderDebounceRef.current = null;
    }
    setValue(changeValue);

    setRangeValue(() => {
      let keyword = RANGE_INFO.MAX - changeValue;
      let semantics = RANGE_INFO.MAX - (RANGE_INFO.MAX - changeValue);

      return [keyword, semantics];
    });

    // 키워드 / 시멘틱 range 바꿀 때 마다 즉시 검색이 아닌, 디바운스 적용시켜 변경 후 텀을 두고 검색되도록 설정.
    sliderDebounceRef.current = setTimeout(() => {
      const ratio = getRatio(changeValue);
      sendMessage(searchValue, ratio);
    }, 500);
  };

  const changeSearchValue = async (inputValue) => {
    //타이핑 도중, send message가 대기중인 상태면, 취소 시키기
    if (!!debounceRef.current) {
      clearTimeout(debounceRef.current);
      debounceRef.current = null;
    }

    // 타이핑할 때 마다가 아닌 타이핑 후 텀을 두고 검색되도록 지정 ( 디바운스 )
    debounceRef.current = setTimeout(() => {
      const ratio = getRatio();
      sendMessage(inputValue, ratio);
    }, 500);

    setSearchValue(inputValue);
  };

  // 검색한 결과 메세지 전송
  const sendMessage = async (inputValue, ratio = getRatio()) => {
    if (inputValue?.trim().length === 0) {
      setSearchResultList([]);
      return;
    }
    try {
      console.log("ratio", ratio);
      console.log("send message~~", inputValue);
      const request = {
        botUid: chatBotData.botUid,
        keyword: inputValue,
        memberUid: chatBotData.memberUid,
        ratio: ratio.toString(),
        pageNo: 1,
        numOfRows: 10,
        searchType: 0,
      };
      setLoading(true);
      const result = await botApi.usesBotToSearchInChat(request);
      console.log("result", result);

      setSearchResultList(result?.data?.retList ?? []);
      const tokens = result.data.tokens.split(", ");
      setSearchResultTokens(tokens ?? []);
    } catch (err) {
      console.log("err", err);
      handleToastMessage(
        "none",
        err?.response?.errorMessage || "검색 도중 에러가 발생 했습니다. 잠시 후 다시 시도해 주세요.",
        "error"
      );
      setSearchResultList([]);
      setSearchResultTokens([]);
    } finally {
      setLoading(false);
    }
  };

  const pageMoveBack = () => {
    setCurrentView("default");
    sessionStorage.removeItem("currentView");
  };

  // 무료 유저에 대한 처리.
  // useEffect(() => {
  //   if(!!chatBotData && chatBotData?.serviceModel === "0") {
  //     showTextPop("유료 사용자만 이용할 수 있는 기능입니다.")
  //     pageMoveBack();
  //   }
  // }, [chatBotData]);

  return (
    <>
      {loading && <LoadingComponent />}
      <SearchTestContainer>
        <FlexAlignCenterBox className={"topBox"} style={{ marginBottom: "10px" }}>
          <NormalButton
            buttonTitle={
              <>
                <KeyboardBackspaceIcon />
              </>
            }
            buttonClass={"cDel backIcon"}
            callBackFunc={() => pageMoveBack()}
          />
          <p className="title">지식 체크</p>
        </FlexAlignCenterBox>
        <SelectCt>
          <ChatBotSelect />
        </SelectCt>
        <UtilContainer>
          <FlexBox className={"search-container"}>
            <div className={"search-input-container"}>
              <SearchIcon className={"search-icon"} />
              <input
                name="search"
                type="text"
                value={searchValue}
                onChange={(e) => changeSearchValue(e.target.value)}
              />
            </div>
            <Button className={`tune-btn ${showFilter ? "active" : ""}`} onClick={() => onOffFilter()}>
              <TuneIcon />
            </Button>
          </FlexBox>
          <FlexColumnBox className={`filter ${showFilter ? "" : "hidden"}`}>
            <Typography component="h4" sx={{ fontWeight: "bold" }}>
              검색 조건 설정
            </Typography>
            <Box>
              <FlexAlignCenterBox>
                <BotInfoRow>
                  <ShowTooltipInfo
                    {...tooltipInfo.lineChange}
                    title={tooltipInfo.lineChange.title}
                    titleClass={"sub sub2"}
                    additionalActiveClass={"width250"}
                    updateTooltipInfo={updateTooltipInfo}
                  />
                  <div
                    id="useComMenu"
                    className={`toggle ${toggleInfo.lineChange.isEnabled ? "active" : ""}`}
                    onClick={() => updateToggleInfo(toggleInfo.lineChange.type)}
                  >
                    <div className="circle" id="useComMenu"></div>
                  </div>
                </BotInfoRow>
              </FlexAlignCenterBox>
              <Box className={"slider-container"}>
                <FlexBetween_AlignCenterBox>
                  <p>키워드: {keyword}</p>
                  <p>시멘틱: {semantics}</p>
                </FlexBetween_AlignCenterBox>
                <Slider
                  aria-label="Volume"
                  step={RANGE_INFO.STEP}
                  max={RANGE_INFO.MAX}
                  value={value}
                  onChange={inputRangeProcess}
                />
              </Box>
            </Box>
          </FlexColumnBox>
        </UtilContainer>
        <SearchResultContainer>
          {searchResultList.length === 0 ? (
            <EmptyItem />
          ) : (
            searchResultList.map((item) => (
              <SearchResultItem
                item={item}
                searchValue={searchValue}
                tokens={searchResultTokens}
                lineChange={toggleInfo.lineChange.isEnabled}
              />
            ))
          )}
        </SearchResultContainer>
      </SearchTestContainer>
    </>
  );
}

const SelectCt = styled.div`
  .css-1nmdiq5-menu {
    z-index: 11;
  }
`;

const EmptyItem = memo(() => {
  return (
    <EmptyItemCt>
      <Box className="box">
        <SearchOffIcon sx={{ mb: 1 }} fontSize="large" color="disabled" />
        <p>조회된 목록이 없습니다.</p>
      </Box>
    </EmptyItemCt>
  );
});

const EmptyItemCt = styled.div`
  border: 1px solid #dcdcdc;
  border-radius: 10px;
  padding: 20px 10px;
  .box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const SearchResultItem = memo((prop) => {
  const { item, tokens, lineChange, searchValue } = prop ?? {};
  let { keyword, answer } = item;
  const [isLimit, setIsLimit] = useState(true);

  const changeLimit = useCallback(() => {
    setIsLimit((prevState) => !prevState);
  }, []);

  // tokens?.forEach(token => {
  //   const regex = new RegExp(`${token}`, "gi");
  //   answer = answer.replace(regex, `<mark>${token}</mark>`);
  // })

  const regex = new RegExp(`${searchValue}`, "gi");
  answer = answer.replace(regex, `<mark>${searchValue}</mark>`);

  const lineChangeRegex = new RegExp(`\n`, "gi");
  answer = !lineChange ? answer.replace(lineChangeRegex, "") : answer;

  return (
    <SearchResultItemCSS>
      {/*<FlexAlignCenterBox className={'img-cover'}>*/}
      {/*    <Box>*/}
      {/*        <DescriptionIcon />*/}
      {/*    </Box>*/}
      {/*</FlexAlignCenterBox>*/}
      <Box className={"txt-cover"}>
        <p className={"title"}>{keyword}</p>
        <div className={`answer ${isLimit ? "limit" : ""}`} dangerouslySetInnerHTML={{ __html: answer }}></div>
      </Box>
      <Box className={"util-cover"}>
        <Box>
          <ExpandCircleDownIcon className={isLimit ? "limit" : ""} onClick={() => changeLimit()} />
        </Box>
      </Box>
    </SearchResultItemCSS>
  );
});

const SearchResultContainer = styled(FlexColumnBox)`
  padding: 5px 10px;
  background-color: inherit;
  border-radius: 7px;
`;

const UtilContainer = styled(Box)`
  background-color: ${BACKGROUND_COLOR(1)};
  position: relative;

  margin: 10px 0;
  padding: 10px;
  border-radius: 7px;

  .search-container {
    height: 100%;
    position: relative;

    .search-input-container {
      position: relative;

      .search-icon {
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);

        font-size: 24px;
        color: ${MAIN_COLOR(1)};
      }
    }
  }
  & [name="search"] {
    min-width: 350px;
    height: 100%;

    margin-right: 10px;
    padding-left: 40px;
    border: 1px solid #cacaca;
    border-radius: 7px;
  }

  .tune-btn {
    min-width: 0;
    padding: 5px;
    border: 1px solid ${MAIN_COLOR(1)};
    color: ${MAIN_COLOR(1)};
    transition: 0.25s;

    &.active {
      background-color: ${MAIN_COLOR(0.2)};
      color: ${MAIN_COLOR(1)};
    }
    &:hover {
      background-color: ${MAIN_COLOR(0.2)};
      transform: scale(1.08);
    }
  }

  .filter {
    transition: 0.25s ease-out;
    height: 90px;

    margin: 15px 0 5px 0;
    opacity: 1;
    &.hidden {
      height: 0;
      opacity: 0;
      visibility: hidden;
    }

    .slider-container {
      width: 45%;
      height: 20px;
      & * {
        color: ${MAIN_COLOR(1)};
        // background-color: ${MAIN_COLOR(1)};
      }
    }
  }
`;

const SearchResultItemCSS = styled(Card)`
  position: relative;
  margin: 10px 0;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;

  border: 1px solid ${MAIN_COLOR(0.1)};
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);

  transition: 0.25s;

  &::after {
    content: "";
    position: absolute;
    width: 2.5px;
    height: 100%;
    background-color: ${MAIN_COLOR(1)};
    left: 0;
    top: 0;
  }

  .img-cover {
    margin-right: 10px;
    & > div {
      width: 50px;
      height: 100%;
      background-color: ${MAIN_COLOR(0.9)};
      color: white;

      border-radius: 6px;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .txt-cover {
    flex: auto;
    transition: 0.25s;
    line-height: 22px;
    & * {
      white-space: break-spaces;
      word-break: break-all;
    }

    .title {
      font-weight: bold;
      margin-bottom: 10px;
    }

    .answer {
      &.limit {
        /* max-height: 80px; */
        max-height: 22px;

        display: -webkit-box;
        display: -ms-flexbox;
        margin-top: 1px;
        overflow: hidden;
        vertical-align: top;
        text-overflow: ellipsis;
        word-break: break-all;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1; // 몇 줄부터 ... 처리할지 결정하는 속성
      }
    }
  }

  .util-cover {
    & > div {
      height: 100%;
      color: ${MAIN_COLOR(1)};

      padding: 0 5px 0 10px;

      display: flex;
      align-items: flex-start;

      svg {
        cursor: pointer;
        transform: rotate(180deg);

        &.limit {
          transform: none;
        }
      }
    }
  }
`;
const SearchTestContainer = styled(Box)`
  padding: 10px;
  width: 100%;
  height: 100%;
  position: relative;
`;
