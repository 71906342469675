import UserChatBotContextProvider from "./providers/UserChatBotContextProvider.js";
import ChatContextProvider from "./providers/ChatContextProvider";
import SignContextProvider from "./providers/SignContextProvider";
import SubscribeContextProvider from "./providers/SubscribeContextProvider";
import BrowserEnvContextProvider from "./providers/BrowserEnvContextProvider.js";
import PdfViewerContextProvider from "./providers/PdfViewerContextProvider.js";
import ToastMessageProvider from "./providers/ToastMessageProvider.js";
import ModalProvider from "./providers/ModalProvider.js";

export default function AppContextProvider({
  isMobile,
  signApp,
  subscribeMessage,
  userChatBotList,
  chatBotData,
  viewerData,
  children: AppComponent,
}) {
  return (
    <SubscribeContextProvider subscribeMessage={subscribeMessage}>
      <BrowserEnvContextProvider isMobile={isMobile}>
        <ToastMessageProvider>
          <ModalProvider>
            <PdfViewerContextProvider viewerData={viewerData}>
              <SignContextProvider signApp={signApp}>
                <UserChatBotContextProvider userChatBotList={userChatBotList}>
                  <ChatContextProvider chatBotData={chatBotData}>{AppComponent}</ChatContextProvider>
                </UserChatBotContextProvider>
              </SignContextProvider>
            </PdfViewerContextProvider>
          </ModalProvider>
        </ToastMessageProvider>
      </BrowserEnvContextProvider>
    </SubscribeContextProvider>
  );
}
