import { session } from "utils/storage";
import axios from "axios";
import { SHA256 } from "crypto-js";
/**
 *
 * 주요 HTTP STATUS CODE
 *
 * 200 : 성공
 * 201 : 성공 응답하는 데이터 존재.
 *
 * 401 : access_token이 만료됐으므로, refresh_token과 함께 api를 요청해야함.
 *
 * 205 : access_token이 만료되었으나 refresh_token은 유효함.
 *       access_token과 refresh_token을 갱신함.
 *       해당 응답 코드를 받은 경우, 원래 수행하려던 API 호출을 다시 수행함.
 *
 * 403 : access_token과 refresh_token 모두 만료됨.
 *
 */

const URL = `${process.env.REACT_APP_API_HOST}/api/`;
const api = axios.create({ baseURL: URL });

api.interceptors.response.use(
  (response) => {
    const { headers, config, status } = response;
    //response headers의 Authorization, refresh_token을 전달 받았다면
    //새로 갱신된 토큰 값이므로 기존의 sessionStorage에 Authorization,refresh_token 값을 업데이트한다.
    if (status === 205) {
      session.setAuth(
        headers.authorization,
        headers.refresh_token,
        headers.member_email,
        headers.member_uid,
        "",
        ""
      );

      config.headers.authorization = headers.authorization;
      config.headers.refresh_token = headers.refresh_token;
      console.log("acessToken 갱신. 서비스 재 호출", response);
      return api.request(config);
    }

    return response;
  },
  (error) => {
    const { response, config } = error;

    if (response) {
      if (response.status === 401) {
        if (response.data.errorType && response.data.errorType === "wrong") {
          return Promise.reject(error);
        } else {
          console.log("accessToken 만료. refresh token으로 요청", response);
          config.headers.refresh_token = session.getRefreshToken();
          return api.request(config);
        }
      } else if (response.status === 403) {
        console.log("accessToken 만료, refreshToken 만료. 강제 로그아웃 ");
        return Promise.reject(error);
      } else {
        console.log("다른 이유로 에러 : ", response);
        return Promise.reject(error);
      }
    } else if (!error?.response && error.message === "Network Error") {
      console.log("서버와 통신 할 수 없습니다.");
      error = {
        response: {
          status: 503,
          errorMessage: "서버와 통신 할 수 없습니다.",
        },
      };
      return Promise.reject(error);
    } else {
      console.log("서버와의 통신에 알 수 없는 문제가 발생했습니다.");
      error = {
        response: {
          status: 503,
          errorMessage: "서버와의 통신에 알 수 없는 문제가 발생했습니다.",
        },
      };
      return Promise.reject(error);
    }
  }
);

export const existMemberYn = (id, fromSocialLogin, email) => {
  return api.post("social/existMemberYn", {
    [fromSocialLogin + "SocialId"]: id,
    fromSocialLogin,
    email,
  });
};

export const signUpToEmail = (email, password, agreeItems) => {
  return api.post("member/signUp", {
    email,
    password,
    overAge: agreeItems && agreeItems.overAge ? "Y" : "N",
    termsOfUse: agreeItems && agreeItems.termsOfUse ? "Y" : "N",
    personalData: agreeItems && agreeItems.personalData ? "Y" : "N",
    marketingInfo: agreeItems && agreeItems.marketingInfo ? "Y" : "N",
  });
};

export const signInToEmail = (email, password) => {
  let formData = new FormData();
  const pwdHash = SHA256(password).toString();
  formData.append("email", email);
  formData.append("password", pwdHash);

  return api.post("login", formData);
};
export const autoSignIn = (refreshToken) => {
  let formData = new FormData();
  formData.append("refreshToken", refreshToken);
  formData.append("isAutoLogin", true);
  return api.post("common/autoSignIn", formData);
};
export const signInToSocial = (
  socialId,
  email,
  fromSocialLogin,
  agreeItems
) => {
  return api.post("social/login", {
    email,
    fromSocialLogin,
    [fromSocialLogin + "SocialId"]: socialId,
    overAge: agreeItems && agreeItems.overAge ? "Y" : "N",
    termsOfUse: agreeItems && agreeItems.termsOfUse ? "Y" : "N",
    personalData: agreeItems && agreeItems.personalData ? "Y" : "N",
    marketingInfo: agreeItems && agreeItems.marketingInfo ? "Y" : "N",
  });
};

export const getMyInfo = () => {
  let headers = {
    authorization: session.getAuthorization(),
  };

  return api.post("member/myInfo", {}, { headers });
};

export const deleteUser = (id) => {
  const headers = {
    Authorization: session.getAuthorization(),
  };
  return api.delete(`/deleteUser/${id}`, { headers });
};

export const sendCertification = (email) => {
  return api.post("common/emailCertification", { email });
};

export const checkCertificationKey = (certKey, email) => {
  return api.post("common/checkCertificationKey", {
    certKey,
    email,
  });
};

export const sendPwdChangeCertKey = (email) => {
  return api.post("common/sendPwdChangeCertKey", { email });
};

export const checkPwdChangeCertKey = (certKey, email) => {
  return api.post("common/checkPwdChangeCertKey", {
    certKey,
    email,
  });
};

export const updateMemberPassword = (email, password) => {
  return api.post("member/updateMemberPassword", { email, password });
};
