import { Button, Divider, Modal, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import styled from "styled-components";
import { ModalContext } from "utils/providers/ModalProvider";

const ModalDefault = ({ modalTitle, modalContent }) => {
  const { closeModal, showModal } = useContext(ModalContext);

  return (
    <div>
      <Modal
        open={showModal}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalContentWrapper>
          <ModalContent className="content">
            <Typography
              sx={{ width: "100%", wordBreak: "keep-all" }}
              id="modal-modal-title"
              variant="h6"
              component="h2"
            >
              {modalTitle}
              <Divider sx={{ mt: 1, mb: 1 }} variant="fullWidth" color="#dcdcdc" />
            </Typography>

            {modalContent.subTitle && (
              <Typography variant="h6" id="modal-modal-description" sx={{ mt: 1, wordBreak: "keep-all" }}>
                {modalContent.subTitle}
                <Divider variant="fullWidth" color="#19c37d" />
              </Typography>
            )}
            {modalContent.desc && (
              <Typography id="modal-modal-description" sx={{ mt: 1, mb: 2, wordBreak: "keep-all" }}>
                {modalContent.desc}
              </Typography>
            )}

            {modalContent.use && (
              <Typography id="modal-modal-description" sx={{ mt: 1, mb: 2, wordBreak: "keep-all" }}>
                {modalContent.use}
              </Typography>
            )}

            {modalContent.comparison && (
              <ExContainer>
                <ExBefore>
                  <p className="exTitle">{modalContent.beforeTitle}</p>
                  <Divider sx={{ mt: 1, mb: 1 }} variant="fullWidth" color="#b9f4bb"></Divider>
                  <div dangerouslySetInnerHTML={{ __html: modalContent.before }}></div>
                </ExBefore>
                <ExAfter>
                  <p className="exTitle">{modalContent.afterTitle}</p>
                  <Divider sx={{ mt: 1, mb: 1 }} variant="fullWidth" color="#b9f4bb"></Divider>
                  <div dangerouslySetInnerHTML={{ __html: modalContent.after }}></div>
                </ExAfter>
              </ExContainer>
            )}
          </ModalContent>
          <ModalControlCt>
            <Button variant="outlined" color="inherit" onClick={closeModal}>
              닫기
            </Button>
          </ModalControlCt>
        </ModalContentWrapper>
      </Modal>
    </div>
  );
};

const ModalContentWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  max-width: 700px;
  background-color: #fff;
  border: 2px solid #ccc;
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 20px;

  @media (max-width: 480px) {
    width: 300px;
    max-width: 400px;
  }
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ModalControlCt = styled.div`
  width: 100%;
  display: flex;
  margin-top: 30px;
  & button {
    &:first-child {
      margin-left: auto;
    }
  }
`;

const ExContainer = styled.div`
  display: flex;

  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  .exTitle {
    font-weight: 500;
    margin-bottom: 10px;
    font-size: 16px;
  }
`;

const ExBefore = styled.div`
  width: 40%;
  margin: 0 auto;

  height: 100px;

  font-size: 14px;
  font-weight: 400;
  margin-bottom: 5px;

  @media (max-width: 480px) {
    width: 100%;
  }
`;

const ExAfter = styled.div`
  width: 40%;
  margin: 0 auto;
  letter-spacing: -0.5px;

  height: 100px;

  @media (max-width: 480px) {
    width: 100%;
  }

  .title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
  }
  .desc {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 5px;
  }
`;

export default ModalDefault;
