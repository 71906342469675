import React from "react";
import { useContext } from "react";
import "./pp.css";
import { createPortal } from "react-dom";
import styled, { keyframes } from "styled-components";
import { isMobileContext } from "utils/providers/BrowserEnvContextProvider";

export default function FaqPage({ setFaqState }) {
  const { isMobile } = useContext(isMobileContext);
  const handleCloseModal = () => {
    setFaqState((prev) => !prev);
  };

  return (
    <>
      {createPortal(
        <Wrapper>
          <Container>
            <Header>
              자주 묻는 질문
              <button className="ri-close-line" onClick={handleCloseModal}></button>
            </Header>
            <TextContents>
              <div className="policy-container__textBox">
                <h2 className="policy__title">Q. 무료 챗봇 생성 개수를 넘기면 어떻게 되나요?</h2>
                {isMobile ? (
                  <p className="policy__desc ">
                    A. 하나의 계정 당 무료로 사용 가능한 챗봇의 생성 개수는 최대 2개입니다. 무료 버전에서는 최대 생성
                    개수에 제한이 있으므로, 챗봇을 여러 개 사용하고 싶으시다면 유료로 전환하시기 바랍니다. 유료 전환은
                    [도입 문의] 혹은 [상담 신청]을 통해 하실 수 있습니다.
                  </p>
                ) : (
                  <p className="policy__desc ">
                    A. 하나의 계정 당 무료로 사용 가능한 챗봇의 생성 개수는 최대 2개입니다. 무료 버전에서는 최대 생성
                    개수에 제한이 있으므로, 챗봇을 여러개 사용하고 싶으시다면 유료로 전환하시기 바랍니다. 유료 전환은
                    [도입 문의] 혹은 [상담 신청]을 통해 하실 수 있습니다.
                  </p>
                )}
                <h2 className="policy__title">Q. 무료 질문 개수를 초과하면 어떻게 되나요?</h2>
                {isMobile ? (
                  <p className="policy__desc">
                    A. 계정 당 하루 최대 50회의 질문까지 무료로 사용 가능합니다. 남은 질문 개수를 확인하고 싶을 경우는
                    [챗봇 관리 {">"} 챗봇 관리]를 통해 잔여 질문 개수를 확인 할 수 있습니다. 사용 가능한 무료 질문 개수
                    초과 시, 관련 안내 팝업창이 표시됩니다.
                  </p>
                ) : (
                  <p className="policy__desc">
                    A. 계정 당 하루 최대 50회의 질문까지 무료로 사용 가능합니다. 남은 질문 개수를 확인하고 싶을 경우는
                    [챗봇 관리 {">"} 챗봇 관리]를 통해 잔여 질문 개수를 확인할 수 있습니다. 사용 가능한 무료 질문 개수가
                    초과될 시, 관련 안내 팝업창이 표시됩니다.
                  </p>
                )}

                <h2 className="policy__title">Q. 홈페이지 주소를 입력했는데 챗봇 생성이 되지 않아요.</h2>
                {isMobile ? (
                  <p className="policy__desc">
                    A. 먼저 해당 URL이 정상적으로 접속되는 유효한 주소인지 확인해 주시기 바랍니다. 다음으로 홈페이지에서
                    크롤봇에 대한 정책 제한이 있는지 확인해 주시기 바랍니다. 홈페이지에서 크롤링에 대해 정책 제한이 있을
                    경우 정보 수집이 원활하게 되지 않을 가능성이 있습니다. 해당 홈페이지 자체의 정책이므로 서비스에서
                    해결해 드릴 수 있는 부분이 없으니 참고해 주시기 바랍니다. 위의 사항을 확인한 후에도 챗봇이 생성되지
                    않는다면 로그인 후 [챗봇 관리 {">"} 문의 하기]를 통해 오류 상황에 대한 문의를 남겨주시면 담당자가
                    확인 후 답변드릴 예정입니다.
                  </p>
                ) : (
                  <p className="policy__desc">
                    A. 먼저 해당 URL이 정상적으로 접속되는 유효한 주소인지 확인해 주시기 바랍니다. 다음으로 홈페이지에서
                    크롤봇에 대한 정책 제한이 있는지 확인해 주시기 바랍니다. 홈페이지에서 크롤링에 대해 정책 제한이 있을
                    경우 정보 수집이 원활하게 되지 않을 가능성이 있습니다. 해당 홈페이지 자체의 정책이므로 서비스에서
                    해결해 드릴 수 있는 부분이 없으니 참고해 주시기 바랍니다. 위의 사항을 확인한 후에도 챗봇이 생성되지
                    않는다면 로그인 후 [챗봇 관리 {">"} 문의 하기]를 통해 오류 상황에 대한 문의를 남겨주시면 담당자가
                    확인 후 답변드릴 예정입니다.
                  </p>
                )}

                <h2 className="policy__title">Q. 커스텀(맞춤형) 버전의 요금제는 얼마인가요?</h2>
                {isMobile ? (
                  <p className="policy__desc">
                    A. 커스텀 요금제는 챗봇 생성 수, 질문 개수 등 사용량을 고려한 최대 성능 수준, 필요한 고급 기능 항목,
                    기술 지원 수준을 협의하여 비용을 책정합니다. 자세한 견적은 홈페이지의 [상담 신청]을 통해 문의해
                    주시면 빠른 시일 내에 연락드리겠습니다.
                  </p>
                ) : (
                  <p className="policy__desc">
                    A. 커스텀 요금제는 챗봇 생성 수, 질문 개수 등 사용량을 고려한 최대 성능 수준, 필요한 고급 기능 항목,
                    기술 지원 수준을 협의하여 비용을 책정합니다. 자세한 견적은 홈페이지의 [상담 신청]을 통해 문의해
                    주시면 빠른 시일 내에 연락드리겠습니다.
                  </p>
                )}

                <h2 className="policy__title">Q. 지식관리에 추가 가능한 파일 형식은 뭔가요?</h2>
                {isMobile ? (
                  <p className="policy__desc">
                    현재 json, txt, text, csv, pdf, doc, docx, epub, ppt, pptx, xml, xls, xlsx, hwp, hwpx 파일 형식을
                    지원하고 있습니다. 이 외 파일 형식을 지식관리로 추가할 경우 지식으로 추가되지 않으니 주의해 주시기
                    바랍니다.
                  </p>
                ) : (
                  <p className="policy__desc">
                    현재 json, txt, text, csv, pdf, doc, docx, epub, ppt, pptx, xml, xls, xlsx, hwp, hwpx 파일 형식을
                    지원하고 있습니다. 이 외 파일 형식을 지식관리로 추가할 경우 지식으로 추가되지 않으니 주의해 주시기
                    바랍니다.
                  </p>
                )}

                <h2 className="policy__title">Q. 챗봇 답변이 정확하지 않아요.</h2>
                {isMobile ? (
                  <p className="policy__desc">
                    챗봇은 GPT기반으로 답변을 생성하고 있습니다. 인공지능이 정보를 처리하는 과정에서 발생하는
                    할루시네이션(Hallucination) 현상으로 인해 정확하지 않은 답변이 출력될 가능성이 있습니다. 보다
                    구체적으로 질문하거나 지식관리를 통해 추가적인 지식을 학습시켜 답변의 정확도를 높이는 것을
                    권장드립니다.
                  </p>
                ) : (
                  <p className="policy__desc">
                    챗봇은 GPT기반으로 답변을 생성하고 있습니다. 인공지능이 정보를 처리하는 과정에서 발생하는
                    할루시네이션(Hallucination) 현상으로 인해 정확하지 않은 답변이 출력될 가능성이 있습니다. 보다
                    구체적으로 질문하거나 지식관리를 통해 추가적인 지식을 학습시켜 답변의 정확도를 높이는 것을
                    권장드립니다.
                  </p>
                )}

                <h2 className="policy__title">Q. 추가로 궁금한 점이 있어요.</h2>
                {isMobile ? (
                  <p className="policy__desc">
                    A. 로그인 후 [챗봇 관리 {">"} 문의 하기]를 통해 궁금한 점을 남기실 수 있습니다. 관리자가 확인 후
                    최대 3영업일 내에 답변드릴 예정입니다. 답변이 등록될 경우 가입하신 이메일 주소로 답변 등록 알림
                    메일이 발송됩니다.
                  </p>
                ) : (
                  <p className="policy__desc">
                    A. 로그인 후 [챗봇 관리 {">"} 문의 하기]를 통해 궁금한 점을 남기실 수 있습니다. 관리자가 확인 후
                    최대 3영업일 내에 답변드릴 예정입니다. 답변이 등록될 경우 가입하신 이메일 주소로 답변 등록 알림
                    메일이 발송됩니다.
                  </p>
                )}
              </div>
            </TextContents>
          </Container>
        </Wrapper>,
        document.getElementById("modal")
      )}
    </>
  );
}
/* 기존 애니메이션 */
const openModal = keyframes`
      0% {
        -webkit-transform: translateY(10%);
        transform: translateY(10%);
        opacity: 0;
      }
    
      100% {
        -webkit-transform: translate(0);
        transform: translate(0);
        opacity: 1;
      }
    `;
const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  height: 100%;

  background: rgba(0, 0, 0, 0.2);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
`;
const Container = styled.div`
  width: 50vw;
  /* height: 50vw; */
  height: 80vh;
  overflow: hidden;

  animation: ${openModal} 0.2s ease-in-out;
  animation-fill-mode: both;
  display: inline-block;
  position: absolute;
  top: 10vh;
  left: 25vw;
  background: #fff;
  opacity: 0;
  border-radius: 15px;

  padding: 0 15px 30px 30px;

  & h2 {
    letter-spacing: -0.02em;
    line-height: 160%;
  }

  & p {
    margin-left: 2px;
    margin-bottom: 40px;
    letter-spacing: -0.01em;
    line-height: 160%;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media (max-width: 480px) {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-height: none;
    padding: 0 30px 30px;
    border-radius: 0;
  }
`;
const TextContents = styled.div`
  /* max-height: 692px; */
  max-height: 100%;
  height: fit-content;
  padding: 0 15px 80px 0;
  overflow-y: scroll;

  .policy__title {
    font-size: 16px;
  }

  @media (max-width: 480px) {
    max-height: unset;
    height: calc(100% - 78px);
    padding: 0;
  }
`;
const Header = styled.div`
  display: flex !important;
  width: 100%;
  height: 78px;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  padding: 0 10px 0 15px;
  border-bottom: solid 1px #dcdcdc;
  background: #fff;

  button {
    padding: 0;
    font-size: 35px;
  }
`;
