import { ErrorCatch } from "api/ErrorCatch.js";
import React, { memo, useContext, useEffect, useState } from "react";
import botApi from "api/botApi.js";
import styled from "styled-components";
import ChatBotSelect from "utils/ChatBotSelect.js";
import { DASH_HOME, FlexColumnBox } from "./dashboardStyle.js";
import { signAppContext } from "../../../utils/providers/SignContextProvider.js";
import { chatContext } from "../../../utils/providers/ChatContextProvider.js";
import { isMobileContext } from "utils/providers/BrowserEnvContextProvider.js";
import ChatEmpty from "./ChatEmpty.js";

export default function Statistics() {
  const { chatBotData, setChatBotData } = useContext(chatContext);
  const { handleSignOutApp } = useContext(signAppContext);
  const [statsData, setStatsData] = useState(null);
  const { isMobile } = useContext(isMobileContext);

  const getChatStat = async (botUid) => {
    try {
      const { status, data } = await botApi.getChatStat(botUid);
      if (status === 200) {
        const statInfo = JSON.parse(data.statInfo);
        setStatsData(statInfo);
      }
    } catch (error) {
      console.log(error);
      ErrorCatch(error, handleSignOutApp);
    }
  };

  useEffect(() => {
    if (!chatBotData) return;
    getChatStat(chatBotData.botUid);
    // 해당 챗봇의 통계를 받아오자.
  }, [chatBotData]);

  if (!chatBotData) {
    return (
      <DASH_HOME.Container>
        <ChatEmpty>아직 생성 된 챗봇이 없습니다.</ChatEmpty>
      </DASH_HOME.Container>
    );
  }

  return (
    <DASH_HOME.Container>
      <FlexColumnBox
        style={
          !isMobile
            ? {
                marginBottom: "20px",
                width: "70%",
              }
            : {
                marginBottom: "20px",
                width: "100%",
              }
        }
      >
        <ChatBotSelect
          chatBotData={chatBotData}
          setChatBotData={setChatBotData}
        />
      </FlexColumnBox>
      {statsData && (
        <StatWrapper>
          <StatList>
            <StatCT className="today">
              <StatData className="title">오늘</StatData>
              <StatInfoCt>
                <StatData className="">열린 채팅창</StatData>
                <StatData className="value">{statsData.today_room}</StatData>
              </StatInfoCt>
              <StatInfoCt>
                <StatData className="">질문 수</StatData>
                <StatData className="value">{statsData.today_q}</StatData>
              </StatInfoCt>

              <StatInfoCt className="borderNone">
                <StatData className="">응답 관리 수</StatData>
                <StatData className="value">{statsData.today_know}</StatData>
              </StatInfoCt>
            </StatCT>

            {/* 최근 7일 */}
            <StatCT className="">
              <StatData className="title">최근 7일</StatData>
              <StatInfoCt>
                <StatData className="=">열린 채팅창</StatData>
                <StatData className="value">
                  {statsData.seven_day_room}
                </StatData>
              </StatInfoCt>
              <StatInfoCt>
                <StatData className="=">질문 수</StatData>
                <StatData className="value">{statsData.seven_day_q}</StatData>
              </StatInfoCt>

              <StatInfoCt className="borderNone">
                <StatData className="=">응답 관리 수 </StatData>
                <StatData className="value">
                  {statsData.seven_day_know}
                </StatData>
              </StatInfoCt>
            </StatCT>

            {/* 최근 30일 */}
            <StatCT className="">
              <StatData className="title">최근 30일</StatData>
              <StatInfoCt>
                <StatData className="">열린 채팅창</StatData>
                <StatData className="value ">
                  {statsData.thirty_day_room}
                </StatData>
              </StatInfoCt>
              <StatInfoCt>
                <StatData className="">질문 수</StatData>
                <StatData className="value ">{statsData.thirty_day_q}</StatData>
              </StatInfoCt>

              <StatInfoCt className="borderNone">
                <StatData className="">응답 관리 수</StatData>
                <StatData className="value ">
                  {statsData.thirty_day_know}
                </StatData>
              </StatInfoCt>
            </StatCT>
          </StatList>
        </StatWrapper>
      )}
    </DASH_HOME.Container>
  );
}

const StatWrapper = styled.div`
  /* border: 1px solid red; */

  @media (min-width: 481px) {
    width: 70%;
  }
`;

const StatList = styled.div`
  /* border: 1px dashed blue; */

  display: flex;
  flex-wrap: wrap;
  width: 100%;

  justify-content: center;
  align-items: center;

  @media (max-width: 480px) {
    border-radius: 30px;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.11);
    background: #fff;
    flex-direction: column;
    padding: 10px 20px;
  }

  & div.dateManage {
    flex: 1 0 100%;
    color: #ff892a;
    text-align: right;
    font-weight: 600;
    padding: 10px 20px;
    width: 100%;
    margin-bottom: 10px;
    font-size: 15px;
  }
`;

const StatCT = styled.div`
  background-color: #fff;
  flex: 1 0 47%;
  /* border: 1px solid black; */
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.11);
  border-radius: 10px;

  margin: 9.5px 3px;
  &.today {
    flex: 1 0 100%;
    width: 100%;
  }

  @media (max-width: 480px) {
    /* flex: 1 0 80% !important; */
    /* width: 80%; */

    width: 100%;
    margin: 0;
    margin-bottom: 10px;

    box-shadow: none;

    /* &:nth-child(odd) {
      background: #ffe7ca;
    }
    &:nth-child(even) {
      background: #caecff;
    } */
  }
`;

const StatInfoCt = styled.div`
  /* border: 1px solid red; */
  display: flex;
`;

const StatData = styled.p`
  display: flex;
  padding: 12.5px 29px;

  flex: 1 1 50%;
  justify-content: center;

  @media (max-width: 480px) {
    text-align: left;
    justify-content: start;
    font-size: 14px;
    color: #696969;
  }
  &.title {
    font-size: 14px;

    width: 100%;
    border-bottom: 1px solid #dcdcdc;

    color: #ff892a;
    font-weight: 700;
    line-height: normal;
    justify-content: center;
    padding: 12.5px 10px;
    @media (max-width: 480px) {
      text-align: left;
      justify-content: start;
    }
  }

  &.value {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
    @media (max-width: 480px) {
      justify-content: end;
    }
    &.active {
      color: #ff892a;
    }
  }

  &.date {
    color: #888;
    font-size: 14px;
    margin-top: auto;
    align-self: end;
  }

  @media (max-width: 480px) {
    &.title {
      font-size: 12px;
    }

    &.value {
      font-size: 16px;
    }
  }
`;
