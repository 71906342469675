import React, { lazy, memo, Suspense, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import sanitizeHtml from "sanitize-html";
import { ExtractedDataManageContext } from "../../../utils/providers/ExtractedDataManageProvider.js";
import { TAB_STATE } from "../../../utils/providers/ExtractedDataTabManageProvider.js";
import {
  Button,
  ButtonGroup,
  Checkbox,
  CircularProgress,
  Container,
  FormControl,
  Input,
  NativeSelect,
  Typography,
} from "@mui/material";
import styled from "styled-components";
import NormalButton from "components/atomic/NormalButton2.js";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Box } from "../dashboard/dashboardStyle.js";
import useSearch from "utils/hooks/useSearch.js";
import useExtractDataPagination from "utils/hooks/useExtractDataPagination.js";
import { useQueryClient } from "@tanstack/react-query";
import { ToastMessageContext } from "utils/providers/ToastMessageProvider.js";
import ConfirmPopDefault from "components/templates/popup/ConfirmPopDefault.js";
import DeleteIcon from "@mui/icons-material/Delete";
import { debounce } from "lodash";
import { ExtractedDataManageHtmlContext } from "utils/providers/ExtractedDataHtmlProvider.js";
import { ExtractedDataManageCaptionContext } from "utils/providers/ExtractedDataCaptionProvider.js";
import { ExtractedDataTabManageContext } from "utils/providers/ExtractedDataTabManageProvider.js";

const ExtractedDataManageHtmlModal = lazy(() => import("./ExtractedDataManageHtmlModal.js"));
const ExtractedDataManageCaptionModal = lazy(() => import("./ExtractedDataManageCaptionModal.js"));

// import PDFViewer from "../../molecules/PDFViewer.js";
// import ExtractedDataManageUserview from "./ExtractedDataManageUserview.js";
const PDFViewer = lazy(() => import("../../molecules/PDFViewer.js"));
const ExtractedDataManageUserview = lazy(() => import("./ExtractedDataManageUserview.js"));

/**
 * 추출 데이터 편집 페이지
 * @typedef {Object} fileInfo
 * @property {string} dataName - 지식 이름
 * @property {string} dataUid - 지식 인식 아이디
 * @property {string} dataType - 지식 타입(폴더, 파일, url, db 등)
 * @property {string} memberUid - 멤버 인식 아이디
 * @property {string} extension - 지식 파일 확장자명
 *
 * @param {Object} props - 컴포넌트에 전달되는 props
 * @param {string} props.setCurrentView - 현재 페이지에 대한 분기처리 여기서는 뒤로가기 버튼에 사용됨 default 또는 pdf
 *
 * */

function ErrorPage() {
  return <p>비정상적인 방법으로 접근하였습니다. 지식관리에서 접근을 시도 해주세요.</p>;
}

function SearchComp({ setSearchType, setSearchValue, list, refetch }) {
  const [inputValue, handleInputValue, resetValue, inputValueRef] = useSearch("");
  const [inputType, setInputType] = useState("caption");

  const { tabState } = useContext(ExtractedDataTabManageContext);

  const inputTypeRef = useRef(inputType);

  const handleSearchType = (e) => {
    setInputType(e.target.value);
    inputTypeRef.current = e.target.value;
  };

  // debounce를 사용해서 set은 초기값만 가지고 있기 때문에 ref를 사용해서 변경된 값을 적용 처리했음
  const handleSearchEvent = useCallback(
    debounce(() => {
      setSearchType(inputTypeRef.current);
      setSearchValue(inputValueRef.current);
    }, 500),
    []
  );
  useEffect(() => {
    resetValue();
  }, [tabState]);

  return (
    <InputContainer>
      <div className="inputFormCt">
        <NativeSelect
          onChange={(event) => {
            handleSearchType(event);
          }}
          defaultValue={"caption"}
          inputProps={{
            name: "exDataSearch",
            id: "exDataSearch-native",
          }}
        >
          <option value={"content"}>콘텐츠</option>
          <option value={"caption"}>캡션</option>
          <option value={"contentAndCaption"}>콘텐츠+캡션</option>
        </NativeSelect>
        <FormControl sx={{ height: "100%" }} size="md">
          <Input
            value={inputValue}
            onKeyUp={(e) => {
              e.key === "Enter" && handleSearchEvent();
            }}
            onChange={handleInputValue}
            endAdornment={
              <SearchIcon
                className="btnIcon"
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  handleSearchEvent();
                }}
                style={{ color: "#CECCCC" }}
              />
            }
            // sx={{ borderRadius: "10px 0px 0px 10px" }}
            sx={{ borderRadius: "10px" }}
            placeholder="추출 데이터를 검색해보세요"
          />
        </FormControl>
      </div>
    </InputContainer>
  );
}

const InputContainer = styled.div`
  display: flex;
  & .inputFormCt {
    .MuiFormControl-root {
      flex-direction: row;
    }
    .css-1rqoqxy.MuiNativeSelect-root,
    .css-bz5ng3-MuiInputBase-root-MuiInput-root {
      font-size: 14px;
      padding: 0 0 5px;
      color: #777;
    }
    .css-1vynybe.MuiInput-input,
    .css-1dmqq7i-MuiNativeSelect-select-MuiInputBase-input-MuiInput-input {
      padding: 0;
    }
  }

  & .btnIcon {
    &:hover {
      color: #2a8cff !important;
    }
  }
`;

export default function ExtractedDataManage(props) {
  const { setCurrentView } = props;
  const { fileInfo, updateSelectedItem, selectedItemList, resetSelectedItem } = useContext(ExtractedDataManageContext);

  const { tabState, updateTabState } = useContext(ExtractedDataTabManageContext);
  const { handleToastMessage } = useContext(ToastMessageContext);

  const { htmlContent } = useContext(ExtractedDataManageHtmlContext);
  const { captionContent } = useContext(ExtractedDataManageCaptionContext);

  // use Queyr Hook 사용
  let lengthPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [checkAll, setCheckAll] = useState(false);
  const [searchType, setSearchType] = useState("");
  const [searchValue, setSearchValue] = useState("");

  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmTitle, setConfirmTitle] = useState("");
  const [confirmDesc, setConfirmDesc] = useState("");
  const [confirmCallback, setConfirmCallback] = useState();

  let { total, list, isLoading, queryKey, refetch, deleteInitData } = useExtractDataPagination({
    dataUid: fileInfo.dataUid,
    search: searchValue || null,
    searchType: searchType || "conetent",
    currentPage,
    lengthPerPage,
    isKeyChange: true,
  });

  let paginationTotal = Math.ceil(total / 10);

  // use Query Hook 사용
  const queryClient = useQueryClient();

  const memoizedTotal = useMemo(() => total, [total]);

  // 페이지네이션 관리함수
  const handlePagination = (event, page) => {
    // setCurrentPage(page);
    setCurrentPage(page);
  };

  // dangerous html 태그 검사
  const handleSanitizeHtml = (dirty) => {
    const clean = sanitizeHtml(dirty, {
      allowedTags: ["html", "body", "table", "thead", "tbody", "tr", "th", "td"],
      allowedAttributes: {
        // 이후에 row column에 대해서 병합에 대한 속성이 필요하다면 여기에서 넣어줘야함
        // 'tr': ['colspan']
      },
    });
    return clean;
  };

  const updateLocalData = useCallback(
    (tbUid, updateValue, updateType) => {
      queryClient.setQueryData(queryKey, (oldData) => {
        if (!oldData) return oldData;

        // paginationInfo의 데이터 파싱
        const oldPaginationInfo = oldData?.data?.paginationInfo
          ? JSON.parse(oldData.data.paginationInfo)
          : { total: 0, data: [] };

        const updatedData = oldPaginationInfo.data.map((item) => {
          if (item.tbUid === tbUid) {
            if (updateType === "html") {
              return { ...item, fixContent: handleSanitizeHtml(updateValue) };
            } else if (updateType === "caption") {
              return { ...item, caption: updateValue };
            }
          }
          return item;
        });

        // 업데이트된 paginationInfo를 JSON 문자열로 변환
        const newPaginationInfo = JSON.stringify({ ...oldPaginationInfo, data: updatedData });

        // 전체 데이터 구조를 유지하면서 paginationInfo만 업데이트
        return {
          ...oldData,
          data: {
            ...oldData.data,
            paginationInfo: newPaginationInfo,
          },
        };
      });
    },
    [htmlContent, captionContent]
  );

  // check All
  const handleCheckAll = () => {
    setCheckAll((prev) => !prev);
    updateSelectedItem(!checkAll, list);
  };

  const handleDeleteBtn = () => {
    setShowConfirm(true);
    setConfirmTitle("데이터 삭제");
    setConfirmDesc("데이터를 삭제하시겠습니까?");
    setConfirmCallback(() => () => handleDeleteSelectedItem());
  };

  const handleDeleteSelectedItem = () => {
    if (selectedItemList.length > 0) {
      deleteInitData({ tbUids: selectedItemList, tabState: tabState });
      resetSelectedItem();
      handleToastMessage("default", "deleteComplete", "success");
    } else {
      handleToastMessage("default", "deleteEmpty", "error");
    }
  };

  useEffect(() => {
    setCheckAll(false);
    resetSelectedItem();
    refetch();
    setCurrentPage(1);
    setSearchValue("");
  }, [tabState]);

  if (isLoading) <LoadingComponent />;

  // 파일 인포가 없다면 errorPage를 안내한다
  if (!fileInfo) ErrorPage();

  return (
    <Box style={{ maxWidth: "1500px" }}>
      <ExtractedTopContainer>
        <PageControlContainer className="">
          <Box style={{ display: "flex", alignItems: "center" }}>
            <NormalButton
              buttonTitle={
                <>
                  <KeyboardBackspaceIcon />
                </>
              }
              buttonClass={"cDel backIcon"}
              callBackFunc={() => {
                setCurrentView("default");
                sessionStorage.removeItem("currentView");
                updateTabState(TAB_STATE.TAB1);
              }}
            />
            <PageTitle>데이터 편집</PageTitle>
          </Box>
        </PageControlContainer>

        <TabBtnCt>
          <Button
            className={tabState === "initial" ? "active" : ""}
            variant="text"
            onClick={() => {
              updateTabState(TAB_STATE.TAB1);
            }}
          >
            초기값 데이터
          </Button>
          <Button
            className={tabState === "filtered" ? "active" : ""}
            variant="text"
            onClick={() => {
              updateTabState(TAB_STATE.TAB2);
            }}
          >
            필터링 데이터
          </Button>
        </TabBtnCt>

        <ListDescComponent total={memoizedTotal}>
          <SearchComp list={list} refetch={refetch} setSearchType={setSearchType} setSearchValue={setSearchValue} />
        </ListDescComponent>

        <DataViewOptionCt>
          <CheckCt>
            <div className="checkCt">
              <Checkbox className="checkBoxTag" checked={checkAll} onChange={handleCheckAll} label="Label" />
              <label className="checkAllLabel">전체선택</label>
            </div>
            <DeleteBtn
              onClick={() => {
                handleDeleteBtn();
              }}
              startIcon={<DeleteIcon className="icon" />}
              size="small"
              sx={{ ml: "auto" }}
              variant="text"
            >
              삭제
            </DeleteBtn>
          </CheckCt>
        </DataViewOptionCt>
      </ExtractedTopContainer>

      <ExtractedBottomContainer>
        <ControlContainerSize
          currentPage={currentPage}
          handlePagination={handlePagination}
          total={total}
          paginationTotal={paginationTotal}
          exDataList={list}
          isLoading={isLoading}
          dataName={fileInfo.dataName}
          searchValue={searchValue}
          searchType={searchType}
          setShowConfirm={setShowConfirm}
          setConfirmTitle={setConfirmTitle}
          setConfirmDesc={setConfirmDesc}
          setConfirmCallback={setConfirmCallback}
          setCheckAll={setCheckAll}
        />
      </ExtractedBottomContainer>

      {/* 우선삭제에 관련되서만 confirm이 제공된다. */}
      {showConfirm && (
        <ConfirmPopDefault
          title={confirmTitle}
          description={confirmDesc}
          callback={confirmCallback}
          setShowConfirmDefault={setShowConfirm}
        />
      )}

      {/* loading */}
      <Suspense fallback={<LoadingComponent />}>
        <ExtractedDataManageHtmlModal updateLocalData={updateLocalData} />
        <ExtractedDataManageCaptionModal updateLocalData={updateLocalData} />
      </Suspense>
    </Box>
  );
}

const ListDescComponent = memo((props) => {
  const { children, total } = props;

  const { tabState } = useContext(ExtractedDataTabManageContext);

  return (
    <PageDescCt>
      <PageDesc variant="caption" color={"ThreeDShadow"}>
        {tabState === "initial" ? `총 ${total}개의 추출 데이터` : `총 ${total}개의 저장된 데이터`}
      </PageDesc>
      {children}
    </PageDescCt>
  );
});

const LoadingComponent = () => {
  return (
    <LoadingContainer>
      <CircularProgress />
    </LoadingContainer>
  );
};

const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);

  position: absolute;
  left: 0;
  top: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999999;
`;

const ExtractedTopContainer = styled(Box)`
  width: 100%;
  flex: 1;
  position: relative;
`;

const ExtractedBottomContainer = styled(Box)`
  width: 100%;
  height: calc(76vh - 64px);
  max-height: 70%;
  position: relative;
`;

const PageTitle = styled(Typography)`
  color: #363636;
  font-size: 20px;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: -0.2px;
  margin-bottom: 40px;

  @media (max-width: 480px) {
    line-height: normal;
    font-size: 18px;
    margin-bottom: 15px;
  }
`;

const PageDescCt = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
  width: 96%;
  margin-top: 10px;
  margin-bottom: 5px;
`;
const PageDesc = styled(Typography)`
  display: flex;
  align-items: center;
  margin-left: 20px !important;
`;

const PageControlContainer = styled.div`
  margin: 10px 10px 0 10px;
  padding: 10px 5px;
`;

const TabBtnCt = styled(ButtonGroup)`
  margin: 0 10px;
  margin-bottom: 0px;
  padding: 0 10px;
  border-bottom: 1px solid #dcdcdc;
  width: 95%;
  border-radius: 0 !important;

  & button {
    border-radius: 0;
    color: #1a1a1a;
    font-size: 18px;
    &.active {
      font-weight: 600;
      color: #2a8cff;
      border-radius: 5px 5px 0 0;
      border-bottom: 2px solid #2a8cff;
    }
  }
`;

const DeleteBtn = styled(Button)`
  margin-left: auto;
  padding: 3px 5px;
  letter-spacing: -1px;
  min-width: auto !important;
  height: 32px;

  color: #808080 !important;

  .css-y6rp3m-MuiButton-startIcon {
    margin: 0;
  }
  svg.icon {
    width: 17px;
    height: 17px;
  }
`;

const DataViewOptionCt = styled.div`
  display: flex;
  width: 100%;
  padding: 5px 10px 2px 20px;
  display: flex;
  flex-direction: column;
`;

const CheckCt = styled.div`
  width: 49%;

  margin-top: 5px;
  display: flex;
  align-items: center;
  .checkCt {
    padding-left: 5px;
    .checkBoxTag {
      margin-right: 0;
      border-radius: 0;
      padding: 0px 3.5px;
    }
    label.checkAllLabel {
      font-size: 14px;
      color: #2e2e2e;
    }
  }
`;

function ControlContainerSize({
  currentPage,
  handlePagination,
  total,
  paginationTotal,
  exDataList,
  isLoading,
  searchValue,
  searchType,
  setShowConfirm,
  setConfirmTitle,
  setConfirmDesc,
  setConfirmCallback,
  setCheckAll,
}) {
  return (
    <>
      <Container
        maxWidth={"xl"}
        sx={{ display: "flex", height: "100%", maxHeight: "690px", paddingLeft: "10px", paddingRight: "13px" }}
      >
        <Suspense fallback={<LoadingComponent />}>
          <ExtractedDataManageUserview
            currentPage={currentPage}
            handlePagination={handlePagination}
            total={total}
            paginationTotal={paginationTotal}
            exDataList={exDataList}
            isLoading={isLoading}
            searchValue={searchValue}
            searchType={searchType}
            setShowConfirm={setShowConfirm}
            setConfirmTitle={setConfirmTitle}
            setConfirmDesc={setConfirmDesc}
            setConfirmCallback={setConfirmCallback}
            setCheckAll={setCheckAll}
          />
          <PDFContainer className="pdfContainer">
            <PDFViewer />
          </PDFContainer>
        </Suspense>
      </Container>
    </>
  );
}

const PDFContainer = styled.div`
  width: 50%;
  margin-left: 10px;

  .rpv-core__inner-page {
    padding: 0;
  }
`;
