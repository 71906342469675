import { ErrorCatch } from "api/ErrorCatch";
import botApi from "api/botApi";
import InputForm from "components/molecules/InputForm";
import { useContext, useEffect, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";
import { chatContext } from "utils/providers/ChatContextProvider";
import { signAppContext } from "utils/providers/SignContextProvider";
import { SubscribeContext } from "utils/providers/SubscribeContextProvider";
import { userChatBotContext } from "utils/providers/UserChatBotContextProvider";
import introImgWeb from "../../assets/images/IntroImg_web.png";
import iconDownArrow from "../../assets/images/icon_downArrow.svg";
import { isMobileContext } from "utils/providers/BrowserEnvContextProvider";
import { AlertPop2 } from "components/templates/popup/AlertPop";
import ConfirmPop from "components/templates/popup/ConfirmPop.js";
import LoadingWeb from "../../assets/images/loadingImg_web.svg";
import CompleteWeb from "../../assets/images/completeImg_web.svg";
import ErrorWeb from "../../assets/images/errorImg_web.svg";
import IconDocu from "../../assets/images/iconTabDocu.svg";
import IconUrl from "../../assets/images/iconTabFile.svg";

export default function ChatMain({ crawlRestricted, setLoginPopState, mainContentRef }) {
  const { handleSignOutApp } = useContext(signAppContext);
  const { userChatBotList } = useContext(userChatBotContext);
  const { subscribeMessage } = useContext(SubscribeContext);
  const { chatBotData, setChatBotData } = useContext(chatContext);
  const { isLoggedIn } = useContext(signAppContext);
  const { isMobile } = useContext(isMobileContext);

  const [popState, setPopState] = useState(false);
  const [popMessage, setPopMessage] = useState("");
  const [confirmPopState, setConfirmPopState] = useState(false);
  const [homeStatus, setHomeStatus] = useState(null);
  const [popCallback, setPopCallback] = useState({
    normal: () => {},
    cancel: () => {},
  });

  const [createBotState, setCreateBotState] = useState("home");
  const [errorMessage, setErrorMessage] = useState(null);
  const [urlInputState, setUrlInputState] = useState("");
  const [freeBotCount, setFreeBotCount] = useState(0);
  const [addChangeType, setAddChangeType] = useState("url");
  const mainCtRef = useRef();
  /** 펑션 모음 시작! */

  /** 마지막 챗봇 상태 확인 */
  const LastChatBotStatusCheck = async () => {
    async function getLastUserChatBotData() {
      try {
        const { status, data } = await botApi.checkHomeChatBot();
        if (status === 200) {
          const botInfo = data.botInfo ? JSON.parse(data.botInfo) : "";
          if (botInfo) {
            setHomeStatus(botInfo);
            if (botInfo.status === "01") {
              // 현재 체크한 챗봇이 성공의 경우에만 setChatBotData를 처리하여 모든 영역에서 사용 할 수 잇도록 하자.
              setChatBotData(botInfo);
            }
          }
        } else {
          // console.log("getLastUserChatBotData", data);
        }
      } catch (error) {
        ErrorCatch(error, handleSignOutApp);
      }
    }
    await getLastUserChatBotData();
  };
  //
  const createBotToFile = async (file) => {
    try {
      setErrorMessage(null);
      const restricted = crawlRestricted ? crawlRestricted : "Y";
      let params = {
        buildFilePath: file,
        dataName: file.name,
        crawlRestricted: restricted,
      };
      const { status, data } = await botApi.buildFile(params);
      const resultData = data ? data.resultMessage : "";
      if (data.resultCode === "200") {
        console.log(">>>>>>> sendBuildFile OK: ", resultData);
        setCreateBotState("loading");
      } else {
        setErrorMessage(resultData);
        if (createBotState !== "complete") setCreateBotState("error");
        return false;
      }
    } catch (error) {
      setErrorMessage("error");
      if (createBotState !== "complete") setCreateBotState("error");
      ErrorCatch(error, handleSignOutApp);
    }
  };
  //
  const createBotToURL = async () => {
    async function SendBuildWeb(url) {
      try {
        // 명시적으로 sync사용.
        const radioValue = 5; // 23.10.18.codelua 메인 페이지에서는 라디오버튼이 없으니 고정으로 5번 처리
        // crawlRestricted // 크롤의 제한을 둘것인지 여부. 대문자 N인 경우에만 제한 없는 처리 동작.
        const { status, data } = await botApi.buildWeb(url, crawlRestricted ? crawlRestricted : "Y", radioValue);
        console.log(">>>>>>> SendBuildWeb >> data: ", data);
        const resultData = data ? data.resultMessage : "";
        if (data.resultCode === "200") {
          setErrorMessage(null);
          setCreateBotState("loading");
        } else {
          setErrorMessage(resultData);
          if (createBotState !== "complete") setCreateBotState("error");
          return false;
        }
      } catch (error) {
        setErrorMessage("error");
        if (createBotState !== "complete") setCreateBotState("error");
        ErrorCatch(error, handleSignOutApp);
      }
    }

    if (!isLoggedIn) {
      setLoginPopState(true);
      return;
    }

    if (urlInputState.trim(" ") !== "") {
      // const urlRegex =/^(http:\/\/|https:\/\/)(([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,})(\/.*)?$/;
      const urlRegex = /^(?:(http:\/\/|https:\/\/))?((?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,})(\/.*)?$/;
      if (!urlRegex.test(urlInputState)) {
        setPopMessage("유효한 URL 형식이 아닙니다!");
        setPopState((prev) => !prev);
      } else {
        SendBuildWeb(urlInputState);
      }
    } else if (urlInputState.trim(" ") === "") {
      setPopMessage("URL을 입력해주세요.");
      setPopState((prev) => !prev);
    }
  };
  const validateFile = (file) => {
    if (!file) {
      setPopState((prev) => !prev);
      setPopMessage(`파일을 선택해 주세요.`);
      return false;
    }
    // 파일 크기 제한 설정 ( 50MB )
    const maxFileSizeBytes = 50 * 1024 * 1024;

    const fileCheckArray = [
      "json",
      "txt",
      "text",
      "csv",
      "pdf",
      "doc",
      "docx",
      "epub",
      "ppt",
      "pptx",
      "xml",
      "xls",
      "xlsx",
      "hwp",
      "hwpx",
    ];

    const fileNameSplitList = file.name.split(".");
    const fileType = fileNameSplitList[fileNameSplitList.length - 1];
    const isValid = fileCheckArray.some((type) => {
      return fileType.includes(type);
    });

    if (!file?.type || !isValid) {
      setPopState((prev) => !prev);
      setPopMessage(`${fileType} 형태의 파일은 지원하지 않습니다.`);
      return false;
    }

    if (file.size > maxFileSizeBytes) {
      setPopState((prev) => !prev);
      setPopMessage("파일의 크기가 50MB를 초과하였습니다.");
      return false;
    }

    return true;
  };
  const changeFileHandle = (e) => {
    e.preventDefault();
    let file = e.target.files[0];
    if (validateFile(file)) {
      setPopMessage(file.name + "파일을 업로드 하시겠습니까? ");
      setPopCallback(() => ({
        cancel: () => {
          e.target.value = null;
        },
        normal: () => {
          createBotToFile(file);
        },
      }));
      setConfirmPopState(true);
    } else {
      e.target.value = null;
    }
  };
  /** 펑션 모음 끝! */

  useEffect(() => {
    if (!isLoggedIn) {
      setCreateBotState("home");
      setErrorMessage("");
      setFreeBotCount(0);
      setUrlInputState("");
      return;
    }
    console.log("제한여부: ", crawlRestricted ? crawlRestricted : "Y");
    LastChatBotStatusCheck();
  }, [isLoggedIn]);

  useEffect(() => {
    if (!homeStatus) return;
    let dataName = homeStatus.dataName;
    if (homeStatus.status === "00") {
      setUrlInputState(dataName); // 23.07.05.codelua 로딩중일때만 어떤 URL을 수집중인지 알려준다.
      setErrorMessage(null);
      setCreateBotState("loading");
    } else if (homeStatus.status === "01") {
      setUrlInputState("");
      setErrorMessage(null);
      setCreateBotState("complete");
    } else if (homeStatus.status === "02") {
      const errorReason = homeStatus.errorReason;
      setUrlInputState("");
      setErrorMessage(errorReason);
      setCreateBotState("error");
    }
  }, [homeStatus]);

  useEffect(() => {
    const botInfo = subscribeMessage;
    if (botInfo) {
      if (botInfo.status === "01") {
        setChatBotData(botInfo);
        setHomeStatus(botInfo);
      } else if (botInfo.status === "02") {
        setErrorMessage(botInfo.errorReason);
        // 리턴값이 왔을때 기존 봇 정보가 있었다면 complete해주면 됨.
        if (homeStatus) {
          setCreateBotState("complete");
        } else {
          setCreateBotState("error"); // 실제 error state는 없으나 오류 사항 표기를 위함.
        }
      }
    }
  }, [setChatBotData, subscribeMessage]);

  useEffect(() => {
    if (!userChatBotList) return;
    setFreeBotCount(userChatBotList && userChatBotList.filter((botInfo) => botInfo.serviceModel === "0").length);
  }, [userChatBotList]);

  useEffect(() => {
    if (freeBotCount) console.log("freeBotCount:", freeBotCount);
  }, [freeBotCount]);

  return (
    <ChatMainCt className={createBotState} id="chat--main">
      <div ref={mainCtRef} className="MainCt">
        <IntroContainer>
          {createBotState === "home" ? (
            !isMobile ? (
              <img src={introImgWeb} alt="인트로이미지" />
            ) : (
              <img src={introImgWeb} alt="인트로이미지" />
            )
          ) : createBotState === "loading" ? (
            !isMobile ? (
              <img src={LoadingWeb} alt="인트로이미지" />
            ) : (
              <img src={LoadingWeb} alt="인트로이미지" />
            )
          ) : (
            createBotState === "complete" &&
            (!isMobile ? <img src={introImgWeb} alt="인트로이미지" /> : <img src={introImgWeb} alt="인트로이미지" />)
          )}
          {createBotState !== "loading" && (
            <UrlCt>
              <MainTitle className="mainTitle">홈페이지 주소만 입력하세요</MainTitle>
              {!isMobile ? (
                <MainTitle className="desc web">
                  <strong>MESwiser가 GPT기반 인공지능 챗봇을 무료</strong>로 만들어 드립니다
                </MainTitle>
              ) : (
                <MainTitle className="desc">
                  <strong>
                    MESwiser가 GPT기반 인공지능 챗봇을
                    <br />
                    무료
                  </strong>
                  로 만들어 드립니다
                </MainTitle>
              )}
            </UrlCt>
          )}
        </IntroContainer>

        {createBotState !== "loading" && (
          <ButtonContainer className="tab">
            <button
              className={addChangeType === "url" ? "active" : ""}
              onClick={(e) => {
                setAddChangeType("url");
              }}
            >
              <img src={IconUrl} alt={"url생성"} />
              URL로 생성
            </button>
            <button
              className={addChangeType === "file" ? "active" : ""}
              onClick={(e) => {
                setAddChangeType("file");
              }}
            >
              <img src={IconDocu} alt={"문서로생성"} /> 문서로 생성
            </button>
          </ButtonContainer>
        )}

        <UrlForm name="" id="mainform" action="">
          {createBotState !== "loading" &&
            (addChangeType === "url" ? (
              <InputCt>
                <InputForm
                  disabled={createBotState === "loading" ? true : freeBotCount > 4 ? true : false}
                  inputValue={urlInputState}
                  inputSetValue={setUrlInputState}
                  placeholderText={
                    freeBotCount > 4
                      ? "무료 생성 개수 초과 '문의하기'를 통해서 생성 개수를 늘려보세요."
                      : "http(s)://로 시작하는 URL을 입력해 보세요."
                  }
                  buttonTitle="챗봇 무료 생성"
                  buttonCallBackFunc={createBotToURL}
                />
              </InputCt>
            ) : (
              <InputCt>
                <input
                  type="file"
                  id="fileUpload"
                  style={{ display: "none" }}
                  accept=".json, .txt, .text, .csv, .pdf, .doc, .docx, .epub, .ppt, .pptx, .xml, .xls, .xlsx, .hwp, .hwpx"
                  onChange={changeFileHandle}
                />

                <FileUpLoadLabel
                  className="file"
                  htmlFor="fileUpload"
                  onClick={(e) => {
                    if (!isLoggedIn) {
                      e.preventDefault();
                      setLoginPopState(true);
                      return;
                    }
                  }}
                >
                  <i className="ri-file-upload-fill" />
                  파일 업로드
                </FileUpLoadLabel>
              </InputCt>
            ))}
        </UrlForm>

        {createBotState === "home" ? (
          <InputCt></InputCt>
        ) : createBotState === "loading" ? (
          <LoadingCt>
            <LoadingIcons className="load-icon center">
              <span></span>
              <span></span>
              <span></span>
            </LoadingIcons>
            <MainTitle className="creating">
              MESwiser가 인공지능 챗봇을 생성 중입니다.
              <br />수 분이 소요 될 수 있습니다. 생성이 완료되면 메일로 알려드릴게요!
            </MainTitle>
            <MainTitle className="creating mobile">
              MESwiser가 인공지능 챗봇을 생성 중입니다.
              <br />수 분이 소요 될 수 있습니다.
              <br />
              생성이 완료되면 메일로 알려드릴게요!
            </MainTitle>
            <LoadingText>챗봇 생성중...</LoadingText>
          </LoadingCt>
        ) : (
          <CompleteCt id="chat--complete">
            {errorMessage && (
              <ErrorCt>
                <CompleteText className="mainTitle error red">챗봇 생성 실패</CompleteText>
                {errorMessage === "error" ? (
                  <CompleteText className="mainTitle ">
                    MESwiser가 챗봇 생성에 실패했습니다. <br /> 유효한 홈페이지 주소&#40;URL&#41;인지 확인해 보시기
                    바라며, <br /> 계속해서 실패했다면 &#91;챗봇 관리&#93;의 문의하기 메뉴를 활용하시기 바랍니다.
                  </CompleteText>
                ) : (
                  <CompleteText className="mainTitle error url">{errorMessage}</CompleteText>
                )}
              </ErrorCt>
            )}
            {createBotState === "complete" && (
              <>
                <CompleteText className="mainTitle">{`[${homeStatus.dataName}] 챗봇이 생성 되었습니다! `}</CompleteText>
                {homeStatus.crawlRestricted === "Y" && (
                  <CompleteText className="mainTitle desc">
                    MESwiser가 챗봇을 완성하였습니다. <br /> &#40; 무료 생성 챗봇은 용량 제한이 있습니다.
                    &#91;챗봇관리&#93; 메뉴에서 확인하세요. &#41;
                  </CompleteText>
                )}
                <CompleteBtn
                  id="useChatBtn"
                  className="useChatBtn"
                  onClick={(e) => {
                    if (homeStatus.dataType === 10) {
                      setPopState((prev) => !prev);
                      setPopMessage("지식이 없습니다. 지식관리를 이용해주세요");
                      return;
                    }
                    window.open(`/chat/${homeStatus.botUid}`);
                  }}
                >
                  챗봇 사용해보기 <i className="ri-external-link-line"></i>
                </CompleteBtn>
              </>
            )}
          </CompleteCt>
        )}
        <MoreInfoBtn
          onClick={(e) => {
            mainContentRef.current.scrollIntoView({ behavior: "smooth" });
            // let rect = mainContentRef.current.getBoundingClientRect();
            // window.scrollTo({
            //   top: rect.top - 65,
            //   behavior: "smooth",
            // });
          }}
          className="moreInfoBtn"
          aria-label="더보기 버튼"
          icon={iconDownArrow}
        ></MoreInfoBtn>
      </div>
      {confirmPopState && (
        <ConfirmPop
          popClass={""}
          setShowConfirmPop={setConfirmPopState}
          callback={popCallback?.normal}
          cancelCallback={popCallback?.cancel}
          title={"파일 업로드"}
          description={popMessage}
        />
      )}
      {popState && <AlertPop2 popState={popState} setPopState={setPopState} text={popMessage} />}
    </ChatMainCt>
  );
}

const UpDown = keyframes`
  0% {
    transform: rotate(0deg);
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: rotate(360deg);
  }
`;
const DownUp = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const ChatMainCt = styled.div`
  display: flex;
  justify-content: center;
  /* min-height: fit-content; */
  min-height: calc(100vh - 64px);
  padding-bottom: 50px;

  &.loading {
    /* height: fit-content; */
    /* padding-bottom: 50px; */
  }

  @media (max-width: 480px) {
    /* height: calc(100vh - 64px); */
    height: fit-content;
    padding: 0 20px 50px;
  }

  & .MainCt {
    flex: 0 1 auto;
    height: auto;
    position: relative;
    background-color: #f7f9ff;
  }
`;

const IntroContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  //max-width: 1050px;
  /* padding-top: 150px; */
  padding-top: 10vh;

  @media (max-width: 1000px) {
    padding-top: 20vw;
  }

  & img {
    width: 380px;
    /* height: fit-content; */

    margin-right: 10px;

    @media (max-width: 480px) {
      width: 333px;
      height: auto;
      margin: 0;
    }
    @media (max-width: 1000px) {
      margin-bottom: 40px;
    }
  }
`;

const MainTitle = styled.p`
  margin-left: 10px;
  color: #000;
  letter-spacing: -0.01em;
  text-align: left;

  font-size: 40px;
  font-weight: 700;
  line-height: 60px;

  @media (max-width: 1000px) {
    font-size: 22px;
    text-align: center;
  }

  @media (max-width: 480px) {
    margin-left: 0;
    color: #1a1a1a;
    font-size: 24px;
    line-height: 162.3%;
    letter-spacing: -1px;
    min-width: 100%;
    &.mainTitle {
      font-size: 22px;
    }
  }

  & strong {
    font-weight: bold;
  }

  &.desc {
    font-size: 25px;
    font-weight: 400;
    word-break: keep-all;
    &.web {
      width: 100%;
      letter-spacing: -0.5px;
    }

    @media (max-width: 480px) {
      font-size: 18px;
    }
  }

  &.creating {
    font-size: 25px;
    text-align: center;
    line-height: normal;

    &.mobile {
      display: none;
    }

    @media (max-width: 480px) {
      display: none;

      &.mobile {
        display: block;
        font-size: 18px;
      }
    }
  }
`;

const UrlCt = styled.div`
  //flex: 0 1 66%;

  display: flex;
  flex-direction: column;
  justify-content: center;

  &.inputBox {
    @media (min-width: 481px) {
      width: 100%;
      padding: 0 10%;
    }
    @media (max-width: 480px) {
      padding: 0 10%;
    }
  }

  @media (max-width: 1000px) {
    flex: 0 0 60%;
  }

  @media (max-width: 480px) {
    flex: 0 0 100%;
  }
`;

const UrlForm = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 30px;

  width: 75%;
  margin: 20px auto;
`;

const InputCt = styled.div`
  margin-bottom: 25px;

  &:first-child {
    display: flex;
    justify-content: center;
    align-items: center;

    & button {
      width: unset;
      padding: 10px 25px;
      margin-right: 7px;

      @media (max-width: 480px) {
        font-size: 18px;
      }
    }
  }
`;

const Progress = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Bounce = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-30px);
  }
`;

const LoadingCt = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const LoadingText = styled.p`
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;

  @media (max-width: 480px) {
    font-size: 18px;
    margin: 10px 0;
  }
`;

// complete
const CompleteCt = styled.div`
  text-align: center;
`;

const BotAnimation = keyframes`
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
`;

const CompleteText = styled.p`
  margin: 20px auto 25px;
  font-size: 25px;
  line-height: 24px;
  font-weight: 600;
  text-align: center;
  word-break: break-all;

  max-width: 980px;

  &.chargeInfo {
    font-size: 17.5px;
    /* color: #fff; */
  }

  &.desc {
    font-weight: 300;
    font-size: 16px;
    color: #dcdcdc;
  }

  @media screen and (max-width: 600px) {
    font-size: 18px;
  }

  &.error.red {
    color: tomato;
  }

  &.error.url {
    padding: 0 20px;
    width: 80%;
    margin: 0 auto;
    color: tomato;
    font-weight: 400;

    /* overflow: hidden; */
    /* word-break: break-all; */
    /* white-space: nowrap; */
    /* text-overflow: ellipsis; */
  }
`;

const CompleteBtn = styled.button`
  min-width: 240px;
  width: 137px;
  height: 78px;
  margin-right: 5px;
  padding: 10px;
  margin-bottom: 20px;
  border: 1.5px solid #fff;
  justify-content: center;
  align-items: center;

  color: #fff;

  font-size: 25px;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: -0.01em;

  transition: 0.2s;

  border-radius: 100px;
  background: linear-gradient(340deg, #ff892a 0%, #ff892a 48.78%, #ffe618 100%);

  &.homeViewBtn {
    border: 1px solid #ff892a;
    background: transparent;
    color: #ff892a;
  }
  @media (max-width: 480px) {
    z-index: 1;
    height: 50px;
    font-size: 18px;
  }

  &:hover {
    opacity: 0.8;
  }
`;

const MoreInfoBtn = styled.button`
  width: 72px;
  height: 19px;

  position: absolute;
  bottom: -30px;
  left: calc(50% - 36px);

  background: url(${({ icon }) => icon}) no-repeat;
  background-size: contain;
  background-position: center;
  cursor: pointer;

  animation: ${BotAnimation} 1s infinite linear;

  @media (max-width: 480px) {
    bottom: -30px;
  }
`;

const LoadingIcons = styled.div`
  width: 100px;
  height: 20px;

  margin: 20px 0px 30px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: relative;

  & span {
    position: absolute;
    display: inline-block;
    background: #ff892a;
    width: 20px;
    height: 20px;
    border-radius: 100px;
  }
  & span:nth-of-type(1) {
    left: 0;
  }
  & span:nth-of-type(2) {
    left: 40%;
  }
  & span:nth-of-type(3) {
    right: 0;
  }

  /* Animation */
  & span {
    animation-name: loading;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
  }
  & span:nth-of-type(2) {
    animation-delay: 0.2s;
  }
  & span:nth-of-type(3) {
    animation-delay: 0.4s;
  }

  /* Keyframes */
  @keyframes loading {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const ErrorCt = styled.div`
  text-align: center;
`;
const InputOpt = styled.div`
  margin-top: 20px;

  padding: 5px 0;
  width: fit-content;
  border-bottom: 1px solid #ff892a;

  & input {
    display: none;
  }
  & label {
    padding: 0 15px;
    font-size: 16px;

    &.active {
      color: #fff;
      border: 1px solid #ff892a;
      background: #ff892a;
      font-weight: 500;
    }
    &:hover {
      cursor: pointer;
      /* color: #ff892a; */
      opacity: 0.7;
    }
  }
`;

const FileUpLoadLabel = styled.label`
  background: #808080;
  color: #fff;
  padding: 15px 25px;
  font-size: 20px;
  border-radius: 5px;
  cursor: pointer;

  margin-top: 0px;
  width: 240px;

  border-radius: 100px;
  text-align: center;

  &:hover {
    opacity: 0.7;
  }

  & i {
    margin-right: 10px;
  }

  &.file {
  }
  &.folder {
    background: #111;
  }

  @media (max-width: 480px) {
    font-size: 18px;
    width: 100%;
  }
`;

const ButtonContainer = styled.div`
  &.tab {
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 100px;
    background: #ededf3;
    width: 280px;
    margin: 0 auto 10px;

    @media (max-width: 1000px) {
      margin: 20px auto 10px;
    }

    & button {
      padding: 10px 16px;
      display: flex;
      align-items: center;
      justify-content: center;

      color: #8c8c8c;
      font-size: 16px;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.16px;
      flex: 1 0 auto;
      &:hover {
        opacity: 0.8;
      }

      &.active {
        color: #ff892a;
        text-align: center;
        font-weight: 700;
        background: #fff;
        border: 1px solid #ff892a;
        border-radius: 100px;
      }
      & img {
        margin-right: 4px;
      }
    }

    @media (max-width: 480px) {
      & button {
        font-size: 14px;
        letter-spacing: -0.5px;
      }
    }
  }
  &.default {
  }
`;
