import styled from "styled-components";
import ThemeSettingItem from "./ThemeSettingItem";
import { ThemeUtil } from "utils/themeUtil";
import { Typography } from "@mui/material";

export default function ThemeSettingTab({ chatThemeType, setChatThemeType, menuProps }) {
  const { chatTheme } = ThemeUtil;

  const handleThemeCheck = (value) => {
    setChatThemeType(value);
  };
  
  

  return (
    <>
      <Typography sx={{ mt: 1 }} variant="h6">
        챗봇테마
      </Typography>
      <Typography color="#888" variant="body2">
        챗봇테마를 선택해주세요
      </Typography>
      <ThemeContainer>
        {chatTheme.map((item, index) => {
          return (
            <ThemeSettingItem
              key={index}
              item={item}
              chatThemeType={chatThemeType}
              handleThemeCheck={handleThemeCheck}
            />
          );
        })}
      </ThemeContainer>
    </>
  );
}

const ThemeContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0;
`;
