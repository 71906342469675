import { Fragment, useContext } from "react";
import ViewMarkdownTextToHtml from "../../utils/ViewMarkdownTextToHtml.js";
import { userChatBotContext } from "../../utils/providers/UserChatBotContextProvider.js";
import { chatContext } from "../../utils/providers/ChatContextProvider.js";
export default function ChatPlainWriter({ data }) {
  const { chatBotData } = useContext(chatContext);
  ///([http(s)][^\s\n]+)/g
  ///([http|https][^\s\n]+)/g
  const refineText = data.message.split(/([http|https][^\s\n]+)/g).map((word, idx) => {
    if (word.match(/http(s)?:\/\/\S+/g)) {
      var urlStr = [];
      console.log(">> parsing: " + word.replace(urlStr[0], ""));
      urlStr.push(word.replace(urlStr[0], ""));
      return urlStr.map((splWord, idx) => {
        if (idx === 0) {
          return (
            <a
              key={idx}
              rel="noreferrer"
              href="_EXTERNALURL"
              style={{
                color: "#191CEE",
              }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                const url = splWord;
                window.open(url);
              }}
            >
              {splWord}
            </a>
          );
        } else {
          return <Fragment key={idx}>{`${splWord}`}</Fragment>;
        }
      });
    }
    return word;
  });

  if (!!chatBotData && !!chatBotData?.useChatMarkdown && chatBotData.useChatMarkdown === "Y")
    return <ViewMarkdownTextToHtml text={data.message} />;
  else return refineText;
}
