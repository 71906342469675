import { useQuery } from "@tanstack/react-query";
import { preTreatmentApi } from "api/preTreatmentApi.js";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useContext, useEffect } from "react";
import { ExtractedDataManageContext } from "../providers/ExtractedDataManageProvider.js";
import { chatContext } from "../providers/ChatContextProvider.js";
import { ExtractedDataTabManageContext } from "utils/providers/ExtractedDataTabManageProvider.js";
import { session } from "../storage.js";
import { ToastMessageContext } from "../providers/ToastMessageProvider.js";

export default function useExtractDataPagination(props) {
  const { currentPage, lengthPerPage, search, searchType, isKeyChange } = props ?? {};
  const {
    fileInfo: { dataName, dataUid },
  } = useContext(ExtractedDataManageContext);

  const { tabState } = useContext(ExtractedDataTabManageContext);
  const { handleToastMessage } = useContext(ToastMessageContext);

  const { chatBotData } = useContext(chatContext);

  const { indexName } = chatBotData ?? {};

  const queryKey = !!isKeyChange
    ? [
        "extractedData",
        {
          currentPage,
          lengthPerPage,
          param: {
            dataUid,
            search,
            searchType,
            tabState,
          },
        },
      ]
    : JSON.parse(localStorage.getItem("queryKey_extractedData"));

  const { data, refetch, isLoading } = useQuery({
    queryKey,
    queryFn: () => preTreatmentApi.selectByPagination(queryKey[1]),
    staleTime: 1000 * 60 * 3,
    cacheTime: 1000 * 60 * 3,
    enabled: !!chatBotData,
  });

  const { total, data: list } = JSON.parse(
    data?.data?.paginationInfo ??
      JSON.stringify({
        total: 0,
        data: [],
      })
  );

  const queryClient = useQueryClient();

  const { mutate: updateInitData } = useMutation({
    mutationFn: (info) => {
      const { tbUid, ptStatus, caption, fixContent } = info;
      return preTreatmentApi.updateData({
        botUid: chatBotData?.botUid,
        tbUid,
        ptStatus,
        caption,
        fixContent,
        indexName,
        // fileName: dataName,
      });
    },
    onSuccess: (_, data) => {
      // console.log("성공 수정!!");
      queryClient.invalidateQueries(queryKey);
    },
    onError: (error) => {
      if (error?.response?.errorMessage) {
        handleToastMessage("none", error?.response.errorMessage, "error");
      }
      console.log("Error!", error);
    },
    onSettled: () => {
      // console.log("실행했음");
    },
  });

  const { mutate: deleteInitData } = useMutation({
    mutationFn: (info) => {
      const { tbUids, tabState } = info;
      return preTreatmentApi.deleteData({ tbUids, tabState, botUid: chatBotData?.botUid, indexName: chatBotData?.indexName, email: session.getEmail() });
    },
    onSuccess: (_, data) => {
      // console.log("삭제 성공!");

      let updatedQueryKey = [
        "extractedData",
        {
          ...queryKey[1], // queryKey의 두 번째 요소(객체)를 펼침
          param: {
            ...queryKey[1].param, // param 객체를 펼침
            tabState: tabState, // tabState 속성만 변경
          },
        },
      ];
      queryClient.invalidateQueries(updatedQueryKey);
    },
    onError: (error) => {
      console.log("Error", error);
      if (error?.response?.errorMessage) {
        handleToastMessage("none", error?.response?.errorMessage, "error");
      }
    },
    onSettled: () => {
      // console.log("실행했음");
    },
  });

  useEffect(() => {
    if (!!isKeyChange) localStorage.setItem("queryKey_extractedData", JSON.stringify(queryKey));
  }, []);

  return {
    currentPage,
    lengthPerPage,
    total,
    list,
    isLoading,
    refetch,
    queryKey,
    updateInitData,
    deleteInitData,
  };
}
