import styled, { css, keyframes } from "styled-components";

import iconStart from "../../assets/images/icon_start.svg";
import iconSetting from "../../assets/images/icon_settingWhite.svg";

export default function NormalButton({
  disabled,
  buttonId,
  buttonTitle,
  buttonClass,
  callBackFunc,
  type,
  style,
  setMyalvisBtn,
  icon,
}) {
  return (
    <NormalBtn
      type={type}
      id={buttonId ? buttonId : "submit_btn"}
      disabled={disabled}
      className={buttonClass ? buttonClass : ""}
      style={{ ...style }}
      onClick={(e) => {
        e.preventDefault();
        callBackFunc && callBackFunc(e);
      }}
      icon={icon}
    >
      {buttonTitle}
    </NormalBtn>
  );
}
export const SmallBtn = styled.button`
  padding: 2.5px 10px;
  border-radius: 5px;

  font-size: 13px;
  line-height: 22px;
  font-weight: 500;
  transition: 0.2s;

  &.close {
    color: #ff892a;
    border: 1px solid #ff892a;
  }

  &.open {
    border: 1px solid transparent;
    color: #fff;
    background: #ff892a;
  }

  &:hover {
    opacity: 0.8;
  }
`;

export const NormalBtn = styled.button`
  padding: 9.5px 32.5px;
  border-radius: 4px;
  background-color: #ff892a;
  color: #fff;

  font-size: 20px;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: -0.01em;

  transition: 0.2s;
  min-width: fit-content;

  &.round {
    border-radius: 100px;
    @media (max-width: 480px) {
      width: 100%;
      height: 50px;
      font-size: 16px;
    }
  }

  &.knowledge.file,
  &.knowledge.folder {
    background: url(${({ icon }) => icon}) no-repeat;
    background-position: top 3px left 3px;
    background-size: 20px;
  }
  &.knowledge.file {
    background-color: #ff892a;
  }
  &.knowledge.folder {
    background-color: #ffbe1f;
  }
  @media (max-width: 480px) {
    padding: 6px 19px;
    font-size: 13px;
    line-height: normal;
    letter-spacing: -0.2px;
    &.knowledge.Add {
      font-size: 16px;
    }
  }

  &.confirm {
    color: #808080 !important;
    border-radius: 0 !important;
    border: 1px solid #dcdcdc !important;
  }

  &.confirm {
    background-color: #ffffff;
    /* color: #ff892a; */

    &:hover {
      background: #fda055;
      color: #ffffff;
    }
  }

  &.cancel.confirm {
    color: #ff6347 !important;
    border-right: none !important;
    &:hover {
      background: #fda055;
      color: #ffffff;
    }
  }

  &:hover {
    background: #fda055;
    color: #ffffff;
  }

  &.myalvis {
    background: #fff;
    color: #ff892a;
    /* border: 2px solid #ffe618; */
    padding: 9.5px 20px;
  }
  &.crawlUrlBtn {
  }

  &.removeDefault {
    color: #808080;
    border: 1px solid #808080;
    background: #fff;

    &:hover {
      color: #222 !important;
    }
  }

  &.history {
    border-radius: 7px;
    background: #19c37d;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.16px;

    margin: 15px 0 0 auto;

    &.knowNone {
      background: #ff6161;
    }

    &.knowActive {
    }
  }

  &.history.backList {
    width: 182px;
    margin: 0 auto;
    padding: 14px;
    background-color: #ffffff;
    border: 1px solid #808080;
    border-radius: 50px;
    font-size: 18px;
    color: #808080;
    &.left {
      margin: 0 auto 0 0;
      /* width: 120px; */
    }

    &:hover {
      color: #222;
    }
    &.addKnow {
      border: none;
      background-color: #19c37d;
      color: #fff;
      margin-left: 20px !important;
      @media (max-width: 480px) {
        margin-left: 10px !important;
      }
    }

    @media (max-width: 480px) {
      /* padding: 10px;
      width: 150px; */

      padding: inherit 10px;
      max-width: 130px;
      width: 100px;
      font-size: 14px;
      padding: 10px;
    }
  }

  &.history2 {
    margin-top: 3px;
    float: left;
    font-size: 14px;
    font-weight: 400;
    border-radius: 25px;
  }

  &.noclick {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.radius30 {
    border-radius: 30px;
    margin-right: 20px;
    font-weight: 500;
    font-size: 20px;
    line-height: 29px;

    padding: 9px 42px;
  }

  &.cancel {
    background-color: #ffff;
    color: #ff6347;
    border: 1px solid #ff6347;

    &:hover {
      border: 1px solid #ff8b8b;
      color: #ff8b8b;
    }
  }

  &.cancel2 {
    color: #fff;
    background: #808080;
  }

  &.mainBtn {
    width: 137px;
    height: 52px;
    margin-right: 5px;
    padding: 10px;
    border-radius: 64px;
    border: 1.5px solid #fff;
    @media (max-width: 480px) {
      font-size: 17px;
    }

    &.emptyChat {
      width: auto;
      padding: 10px 30px;
    }
  }

  &.header.mobile {
    margin-right: 0;
    margin-left: auto;
    padding: 10px;
    width: 80px;
    height: 32px;
    line-height: 12px;
    font-size: 12px;
  }

  &.response {
    flex: 0 0 auto;
    align-self: flex-start;
    padding: 10px;
    line-height: normal;
  }
  /* 응답관리용 버튼 스타일 작성 */
  &.responseHist {
    flex: 0 0 auto;
    align-self: flex-start;
    padding: 8px;
    font-size: 11px;
    line-height: normal;
    background-color: #19c37d;
    margin-top: 0;
  }

  &.regist {
    margin-left: auto;
  }
  &.dashborad {
    font-size: 18px;
    padding: 10px;
    line-height: normal;
    height: 45px;
    border-radius: 5px;
    &.start {
      position: relative;
      &:before {
        content: "";
        position: absolute;
        background: url(${iconStart}) no-repeat;
        width: 24px;
        height: 24px;
        @media (max-width: 480px) {
          left: 40px;
        }
      }
    }
    &.remove {
      background: #fff !important;
      border: 1px solid #808080;
      color: #808080;
    }
    &.change {
      border-radius: 100px;
      background: #ff6161;
      color: #fff;
      position: relative;
      padding: 16px 60px;
      height: 58px;

      &:before {
        content: "";
        position: absolute;
        background: url(${iconSetting}) no-repeat;
        width: 24px;
        height: 24px;

        @media (max-width: 480px) {
          left: 45px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      &:hover {
        background: #ff8b8b;
      }

      @media (max-width: 480px) {
        width: 100% !important;
        margin-right: 0;
        padding: 10px 55px !important;
        height: 46px !important;
      }
    }
  }

  &.download_btn {
    padding: 10px;
    line-height: normal;
  }

  @media (max-width: 480px) {
    &.remove {
      flex: 0 1 auto;
      margin: 0 !important;
      padding: 10px 15px !important;
      font-size: 16px !important;
      width: 30%;
      min-width: 100px;
      height: 46px !important;
    }
    &.crawlUrlBtn {
      flex: 0 1 auto;
      padding: 10px 19px !important;
      font-size: 16px !important;
      height: 46px !important;
    }
    &.start {
      font-size: 16px !important;
      padding: 10px 68px !important;
      height: 46px !important;
    }
  }

  ${(props) =>
    props.disabled &&
    `
      opacity: 0.5;
      cursor: not-allowed;
    `}

  ${({ type }) => {
    if (type === "drop") {
      return css`
        position: absolute;
        top: 40px;
        left: 50%;
        /* width: 90%; */
        width: 150px;
        line-height: 16px !important;
        transform: translateX(-50%);
        font-size: 15px !important;
        font-weight: bold;
        height: 40px;
        animation: ${fadeIn} 0.3s linear;

        &:hover {
          opacity: 0.8;
        }

        &:after {
          position: absolute;
          content: "";
          width: 10px;
          height: 10px;
          top: -5px;
          left: 50%;
          transform: translateX(-50%) rotate(45deg);
          background-color: #ff892a;
        }
      `;
    }
  }}
`;

const fadeIn = keyframes`
  0% {
    opacity: 0.7;
    /* top: 45px; */
  }
  100% {
    opacity: 1;
    /* top: 40px; */
  }
`;
