import { getMyInfo, autoSignIn } from "api/memberApi";
import axios from "axios";
import "assets/styles/reset.css";
import "assets/styles/style.css";
import AppContextProvider from "utils/AppContextProvider";
import { useCallback, useEffect, useState, useRef, useMemo } from "react";
import { BrowserRouter } from "react-router-dom";
import { auth, local, session } from "utils/storage";
import SocketService from "utils/SocketService.js";
import Route from "components/pages/route";
import botApi from "api/botApi";
import { useMediaQuery } from "react-responsive";
import useOuterClick from "utils/hooks/useOuterClick";
import cookie from "react-cookies";
import styled from "styled-components";

function App() {
  // ChatBot Provider 설정 처리
  const [userChatBotList, setUserChatBotList] = useState(null);
  const [chatBotData, setChatBotData] = useState(null);
  const [chatRoomId, setChatRoomId] = useState(null);

  // 전역적인 PDF Viewer처리..
  const [useViewer, setUseViewer] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [pdfPage, setPdfPage] = useState(0);

  const [myIP, setMyIP] = useState(null);
  const [myInfo, setMyInfo] = useState(
    auth.getMyInfo()
      ? JSON.parse(auth.getMyInfo())
      : {
          memberUid: "",
          email: "",
        }
  );
  const [isLoggedIn, setIsLoggedIn] = useState(
    session.getAuthorization()
      ? session.getAuthorization()
      : local.getAutoLoginToken()
      ? local.getAutoLoginToken()
      : null
  );
  
  const userPlan = useMemo(() => {
    return {
      isFreeUser: chatBotData ? chatBotData?.serviceModel === "0" : true,
      isBasicUser: chatBotData && chatBotData?.serviceModel === "1",
      isPremiumUser: chatBotData && chatBotData?.serviceModel === "2",
    };
  }, [chatBotData])

  //로그아웃 처리
  const handleSignOutApp = useCallback(() => {
    setChatBotData(null);
    setUserChatBotList(null);
    const autoLogintoken = local.getAutoLoginToken();
    autoLogintoken && local.removeKeepId();
    session.removeAuth();
    local.removeAutoLoginToken();
    local.removeMyInfo();
    console.log("내가 확실히 클리어하고 있어요!!! ");
    setMyInfo({
      memberUid: "",
      email: "",
    });
    setIsLoggedIn(null);
  }, [myInfo, chatBotData, userChatBotList]);

  //로그인 처리
  //props 중 grantType은 사용 안하므로 제거
  const handleSignInApp = useCallback(
    async (
      authorization,
      refreshToken,
      email,
      memberUid,
      socialYn,
      signInType
    ) => {
      session.setAuth(
        authorization,
        refreshToken,
        email,
        memberUid,
        socialYn,
        signInType
      );
      try {
        const {
          status,
          data: { myInfo },
        } = await getMyInfo();

        if (status === 200) {
          const { email } = JSON.parse(myInfo);
          setMyInfo({
            memberUid,
            email,
          });
          setIsLoggedIn(authorization);
        }
      } catch (error) {
        console.log(error);
        handleSignOutApp();
      }
    },
    [handleSignOutApp]
  );

  const onFullSizeViewClose = () => {
    // console.log(">>>>> onFullSizeViewClose!!!!!!");
    setUseViewer(false);
  };

  // useOuterClick(FullSizeViewRef, onFullSizeViewClose);

  useEffect(() => {
    /*
      suitue 제품군 끼리 같은 로그인정보를 사용하게 하기 위한 처리.
      최초 새로고침 시 useEffect를 제일 먼저 타는쪽에 세팅했으니 수정 시 주의할것.
      삼항식을 안쓰고 가독성을 위해 변수 활용.
    */
    const cAuthorization = cookie.load("authorization");
    const cRefreshToken = cookie.load("refresh_token");
    const cEmail = cookie.load("email");
    const cMemberUid = cookie.load("memberUid");

    const sAuthorization = sessionStorage.getItem("authorization")
      ? sessionStorage.getItem("authorization")
      : cAuthorization;
    const sRefreshToken = sessionStorage.getItem("refresh_token")
      ? sessionStorage.getItem("refresh_token")
      : cRefreshToken;
    const sEmail = sessionStorage.getItem("email")
      ? sessionStorage.getItem("email")
      : cEmail;
    const sMemberUid = sessionStorage.getItem("memberUid")
      ? sessionStorage.getItem("memberUid")
      : cMemberUid;

    // access token 정보와 email정보가 없다면
    if (!session.getAuthorization() || !session.getEmail()) {
      // 4개 정보가 모두 세션정보에 있다면 세팅 하도록 한다.
      if (sAuthorization && sRefreshToken && sEmail && sMemberUid) {
        session.setAuth(sAuthorization, sRefreshToken, sEmail, sMemberUid);
        setMyInfo({ memberUid: sMemberUid, email: sEmail });
      }
    } else {
      // access token 정보가 메모리에 있는데 세션스토리지값이 하나라도 비었다면 로그인을 유도해야 한다.
      if (!sAuthorization || !sRefreshToken || !sEmail || !sMemberUid) {
        session.removeAuth();
        setMyInfo({
          memberUid: "",
          email: "",
        });
      }
    }
  }, []);

  const autoLoginApp = useCallback(async () => {
    try {
      const { headers } = await autoSignIn(local.getAutoLoginToken());

      handleSignInApp(
        headers.authorization,
        headers.refresh_token,
        headers.member_email,
        headers.member_uid,
        headers.social_yn,
        local.getLoginType() ? local.getLoginType() : ""
      );
    } catch (error) {
      if (error.response) {
        const {
          response: { data, status },
        } = error;
        console.log(status);
        if (status === 404) console.log(data.errorDescription);
        else if (status === 403) console.log(data.errorDescription);
        else if (status === 500) console.log(data.errorDescription);

        handleSignOutApp();
      }
    }
  }, [handleSignInApp, handleSignOutApp]);

  useEffect(() => {
    /*
      autoLoginApp 호출을 위한 제약
      1. local에 autoLoginToken 이 존재
      2. session에 값이 없어야 함 ( << autoLogin 실행 후 session에 값을 채움)
    */
    local.getAutoLoginToken() && !session.getAuthorization() && autoLoginApp();
  }, [autoLoginApp]);

  useEffect(() => {
    auth.setMyInfo(JSON.stringify(myInfo));
  }, [myInfo]);

  // 23.07.06.codelua. 로그인여부와 상관없이 누구든 IP정보를 획득하도록 한다.
  useEffect(() => {
    axios.get("https://geolocation-db.com/json/").then((res) => {
      setMyIP(res.data.IPv4);
    });
  }, []);

  // useEffect(() => {
  //   if (!myIP) return;
  //   console.log("SET MY IP: ", myIP);
  // }, [myIP]);

  const getActiveChatBotData = useCallback(async (botUid) => {
    try {
      const { status, data } = await botApi.getBotInfoByBotUid(botUid);
      if (status === 200) {
        const resultData = JSON.parse(data.botInfo);
        setChatBotData(resultData);
      }
    } catch (error) {}
  }, []);

  const getUserChatBotList = useCallback(async () => {
    try {
      const { status, data } = await botApi.getUserActiveBotList();
      if (status === 200) {
        const resultData = JSON.parse(data.userBotList);
        if (resultData && resultData.length > 0) {
          setUserChatBotList(resultData);
        } else {
          console.log("userChatBotList: ", "ZERO!!");
          setUserChatBotList(null);
        }
      } else {
        console.log("userChatBotList: ", "ZERO!!");
        setUserChatBotList(null);
      }
    } catch (error) {
      console.log(error);
    }
  }, [isLoggedIn]);

  useEffect(() => {
    // HOME이 아닌 화면에서 새로고침이 될 때 마지막 봇 데이터를 세팅하기 위함.
    if (!chatBotData) {
      // 1. 세션에 없는지를 먼저 판단. (새로고침등을 통해 state가 완전 초기화 된 경우 여기를 탐.)
      var isSelect = true;
      const tmpSessionData = sessionStorage.getItem("botData");
      if (tmpSessionData) {
        const info = JSON.parse(tmpSessionData);
        if (info.botType !== 0) {
          sessionStorage.removeItem("botData");
          console.log(">>> Not Chat Bot");
        } else {
          isSelect = false;
          getActiveChatBotData(info.botUid);
        }
      }

      // 세션에 타입에 맞는 봇데이터가 없다면 신규 로딩 처리.
      if (isSelect) {
        // MyAlvis상태에서 가지고 있던 챗봇을 삭제 해버렸을 가능성이 매우 농후함. 그럼 남은 리스트 중에 마지막 활성화를 따져보자.
        if (userChatBotList && userChatBotList.length >= 1) {
          const pickBot = userChatBotList.reduce(function (acc, tmp) {
            if (acc == null && tmp.status === "01") {
              return tmp;
            }
            return acc;
          }, null);
          if (pickBot !== null) {
            getActiveChatBotData(pickBot.botUid);
          }
        }
      }
    }
  }, [userChatBotList]);

  useEffect(() => {
    /*
      isLoggedIn은 초기화 시 sessionStorage 또는 localStorage 에서 채워주기 때문에
      isLoggedIn만으로는 현재 로그인 상태(sessionStorage에 Token 값이 Setting된)를 명확히 판단하기 어려움
      sessionStorage의 값은 Login 행위가 끝난 후 채워주기 때문에
      초기 값을 조회하는 App.js 에서는 isLoggedIn과 session의 값을 함께 체크해주어야 할 듯
     */
    if (!isLoggedIn || !session.getAuthorization()) return;

    getUserChatBotList();
    // 최종 챗봇도 하나 가져오도록 하자.
  }, [isLoggedIn]);

  // Socket 설정 처리 23.06.12 codelua
  const [subscribeMessage, setSubscribeMessage] = useState(null);
  const [subscribeNotification, setSubscribeNotification] = useState(null);
  const [isConnectedSocket, setIsConnectedSocket] = useState(false);
  const [isNewMessage, setIsNewMessage] = useState(false);
  const [socket, setSocket] = useState(null);
  const [stompClient, setStompClient] = useState(null);

  const onClose = () => {
    setStompClient(null);
    setSocket(null);
    setIsConnectedSocket(false);
  };

  const onOpen = () => {
    setIsConnectedSocket(false);
  };

  const initSocket = useCallback(() => {
    SocketService.init(setSocket, setStompClient, onOpen, onClose);
  }, []);

  const disconnectSocket = useCallback(() => {
    SocketService.disconnect(socket, stompClient);
  }, [socket, stompClient]);

  useEffect(() => {
    //window initSocket, disconnectSocket 함수 초기화
    window.initSocket = initSocket;
    window.disconnectSocket = disconnectSocket;

    //unmount 시 수행
    return () => {
      window.initSocket = () => {};
      window.disconnectSocket = () => {};
    };
  }, [initSocket, disconnectSocket]);

  const callBackMakeChatBotNoti = (response) => {
    const callBackChatBotData = JSON.parse(response.body);
    if (callBackChatBotData.status === "01") {
      // 이떄 리스트에 담아준다.
      setUserChatBotList((prev) => {
        if (Array.isArray(prev)) {
          return [...prev, callBackChatBotData];
        } else {
          return [callBackChatBotData];
        }
      });
    }
    setSubscribeMessage(callBackChatBotData);
  };

  const callBackAddKnowDataSetReply = (response) => {
    const addKnowDataInfo = JSON.parse(response.body);
    setSubscribeNotification(addKnowDataInfo);
  };

  useEffect(() => {
    if (!stompClient) {
      initSocket();
    } else {
      if (stompClient.ws.readyState === 3) {
        initSocket();
      } else if (stompClient.connected !== isConnectedSocket) {
        setIsConnectedSocket(stompClient.connected);
      } else if (!stompClient.connected || !isConnectedSocket) {
        SocketService.connect(stompClient, setIsConnectedSocket);
      }
    }
  }, [stompClient, isConnectedSocket, initSocket]);

  useEffect(() => {
    let recvMakeChatBotReply = null;
    let recvAddKnowDataSetReply = null;
    if (
      stompClient &&
      stompClient.connected &&
      isConnectedSocket &&
      myInfo.memberUid
    ) {
      recvMakeChatBotReply = SocketService.subscribe(
        stompClient,
        `/bot/${myInfo.memberUid}/reply`,
        callBackMakeChatBotNoti
      );
      recvAddKnowDataSetReply = SocketService.subscribe(
        stompClient,
        `/bot/${myInfo.memberUid}/addknow`,
        callBackAddKnowDataSetReply
      );
    }

    return () => {
      recvMakeChatBotReply && SocketService.unsubscribe(recvMakeChatBotReply);
      recvAddKnowDataSetReply &&
        SocketService.unsubscribe(recvAddKnowDataSetReply);
    };
  }, [stompClient, isConnectedSocket, myInfo.memberUid]);
  // Socket 설정 처리 끝.

  // chatBotData가 바뀔때마다 session에 정하도록 한다.
  useEffect(() => {
    if (!chatBotData) {
      // 일단 암거도 안함.
    } else {
      // 어디선 setChatBotData가 호출 되면 세션을 처리하기 위함.
      // 다만 진행중인 챗봇은 해당 화면에서만 사용되도록 공유하지 않음.
      if (chatBotData.status !== "00") {
        const stateToPass = JSON.stringify(chatBotData);
        sessionStorage.setItem("botData", stateToPass);
      } else if (chatBotData.status === "00") {
        sessionStorage.removeItem("botData");
      }
    }
  }, [chatBotData]);

  const isMobile = useMediaQuery({
    query: "screen and (min-width: 0px) and (max-width: 480px)",
  });

  return (
    <AppContextProvider
      isMobile={{ isMobile }}
      signApp={{
        isLoggedIn,
        myInfo,
        setMyInfo,
        handleSignInApp,
        handleSignOutApp,
      }}
      subscribeMessage={{
        subscribeMessage,
        setSubscribeMessage,
        subscribeNotification,
        setSubscribeNotification,
        isNewMessage,
        isConnectedSocket,
        setIsConnectedSocket,
        stompClient,
      }}
      userChatBotList={{
        userChatBotList,
        setUserChatBotList,
        getUserChatBotList,
      }}
      chatBotData={{
        chatBotData,
        setChatBotData,
        chatRoomId,
        setChatRoomId,
        myIP,
        setMyIP,
        getActiveChatBotData,
        userPlan
      }}
      viewerData={{
        useViewer,
        setUseViewer,
        pdfUrl,
        setPdfUrl,
        pdfPage,
        setPdfPage,
      }}
    >
      <BrowserRouter>
        <Route />
      </BrowserRouter>
    </AppContextProvider>
  );
}

const FullSizeView = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 999999999;
  background-color: rgba(0, 0, 0, 0.8);
`;

const RootPopView = styled.div`
  position: absolute;
  top: 70%;
  left: 70%;
  transform: translate(-70%, -70%);
  width: 70%;
  height: 70%;
  border: 5px solid gray;
  z-index: 999999999;
`;

export default App;
