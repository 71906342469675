import "assets/styles/widget.css";
import { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import ChatBotImage from "../../../assets/images/chatBotImg_new.svg";
import styled from "styled-components";
import BaseChat from "../chat/BaseChat";
import botApi from "../../../api/botApi.js";
import { getNow } from "../../../utils/dateUtil.js";
import uuid from "react-uuid";
import { isMobileContext } from "utils/providers/BrowserEnvContextProvider";

export default function WidgetChat() {
  const [openChatRoom, setOpenChatRoom] = useState(false);
  const { botUid } = useParams();
  const [messages, setMessages] = useState([]);
  const [chatBotData, setchatBotData] = useState(null);
  const [showGreeting, setShowGreeting] = useState(false);
  const randomUUID = useRef(uuid());
  const referer = document.referrer;
  const [exList, setExList] = useState([]);
  const [bottomData, setBottomData] = useState([]);

  const { isMobile } = useContext(isMobileContext);

  const getBotExampleQuestionList = async (botUid) => {
    try {
      const { status, data } = await botApi.selectBotExQuestion(botUid);
      if (status === 200 && data?.infoList) {
        setExList(JSON.parse(data.infoList));
      } else {
        setExList([]);
      }
      // 하단메뉴를 사용 안한다면 굳이 보여주지 않는다.
      if (["Y", "A"].includes(chatBotData?.useBottomMenu)) {
        const bottomMenuResponse = await botApi.selectBottomMenuList(botUid);
        if (bottomMenuResponse.status === 200 && bottomMenuResponse.data?.bottomMenuList) {
          setBottomData(JSON.parse(bottomMenuResponse.data.bottomMenuList));
        } else {
          setBottomData([]);
        }
      } else {
        setBottomData([]);
      }
    } catch (error) {
      //
    } finally {
      // setLoading(false);
    }
  };

  useEffect(() => {
    if (!botUid) return;
    // botId를 통해 챗봇 정보를 불러와야 함.

    let isMount = true;

    if (isMount) {
      (async () => {
        const { status, data } = await botApi.getBotInfoByBotUid(botUid);
        if (status === 200) {
          // referrer 를 체크 해서 alvis를 통한게 아니라면 튕겨주자. 유료봇이라면 상관없다.
          const resultData = JSON.parse(data.botInfo);
          setchatBotData(resultData);
          if (resultData.serviceModel === "1") {
            setchatBotData(resultData);
          } else {
            console.log("유료모델이 아니므로  레퍼러 체크: ", referer);
            if (referer.includes("alvis.kr") || referer.includes("meswiser.ai")) {
              console.log(">>> 레퍼러 체크 OK");
              setchatBotData(resultData);
            }
          }
        }
      })();
    }

    window.addEventListener("message", function (e) {
      const eventOrigin = e.origin;
      let message = e.data;

      if (message === "showGreeting") {
        setShowGreeting((prev) => true);
      }

      console.log("[alvichat CLIENT v1]", eventOrigin, "[message]", message);
    });

    return () => {
      isMount = false;
    };
  }, [botUid]);

  useEffect(() => {
    if (messages.length === 0 && chatBotData) {
      let message = "";
      if (chatBotData.greetingMessage) message = chatBotData.greetingMessage;
      else message = "입력하신 URL주소에 포함 된 내용을 기반으로 답변드립니다.";

      setMessages([
        {
          message: message,
          type: "bot",
          messageType: "text",
          time: getNow(),
          id: uuid(),
        },
      ]);

      // 예시질문에 대해서 쿼리한다.
      getBotExampleQuestionList(chatBotData.botUid);
    }
  }, [chatBotData]);

  const handleImgClick = () => {
    // if (!chatBotData) alert("123");
    if (showGreeting) {
      setShowGreeting(() => false);
    }

    const messageData = {
      text: !openChatRoom ? "showChatBot" : "hideChatBot",
    };

    const targetWindow = window.parent;
    targetWindow.postMessage(messageData, "*");
    removeFollow();
  };

  const removeFollow = () => {
    setOpenChatRoom((prev) => !prev);
  };

  return (
    <>
      <Container className="widgetCt">
        {showGreeting && <ChatAdsTextBox>눌러서 대화 시작~</ChatAdsTextBox>}
        <BaseChat
          showQuestionBox={true}
          messages={messages}
          setMessages={setMessages}
          popupInfo={{
            isPopup: false,
            openChatRoom,
            setOpenChatRoom,
            clickFunc: handleImgClick,
          }}
          chatBotData={chatBotData}
          chatRoomId={randomUUID.current}
          handleSignOutApp={() => {}}
          isHistoryBack={false}
          exList={exList}
          BottomData={bottomData}
          botClass="widget"
        />

        {isMobile && openChatRoom ? (
          ""
        ) : (
          <img
            className={isMobile ? "botImg mobile" : "botImg"}
            src={ChatBotImage}
            alt="챗봇이미지02"
            onClick={handleImgClick}
          />
        )}
      </Container>
    </>
  );
}

const Container = styled.div`
  /* width: 450px; */
  width: 390px;
  height: 100%;
  position: fixed;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  cursor: pointer;
  .botImg {
    width: 86px;
    height: 86px;
  }

  .chat--bot2 {
    top: 5px;
  }

  @media (max-width: 480px) {
    width: 100%;

    .botImg.mobile {
      /* margin-bottom: 20px; */
    }
    .chat--bot2.widget {
      margin: 0;
      top: 0;
      border-radius: 0;
    }
  }
`;

const ChatAdsTextBox = styled.div`
  padding: 10px 15px;
  border-radius: 10px;
  position: relative;
  background: #ff892a;
  margin: 10px 5px 0 0;
  color: #fff;
  &::after {
    content: "";
    position: absolute;
    background: #ff892a;
    z-index: -1;
    width: 10px;
    height: 10px;
    bottom: -5px;
    left: 70%;
    transform: rotate(45deg);
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.16);
  }
`;
